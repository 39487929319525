import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { List, Avatar, Spin, Empty, Button, Select } from "antd";
import { GET_EMPLOYEE_ABSENCE_REQUESTS } from "graphql/queries/Configurations";
import { absenceType } from "./HomeTypes";
import moment from "moment";
import { getDaysFromToday, getDaysUntilReturn, isTodayInVacation } from "./helpers/absenceListHelper";
import { absenceActiveImg, absenceImg } from "helpers/appImages";
import { TFunction } from "i18next";

interface IProps {
  t: TFunction;
}

const { Option } = Select;

const HomeAbsenceList: React.FC<IProps> = ({ t }) => {
  const { data, loading, error } = useQuery(GET_EMPLOYEE_ABSENCE_REQUESTS);
  const employeeAbsenceRequestsData = data?.getEmployeeAbsenceRequests;
  const [filteredData, setFilteredData] = useState<absenceType[]>([]);
  const [filteredDataFromSearch, setFilteredDataFromSearch] = useState<absenceType[]>([]);
  const [visibleItems, setVisibleItems] = useState(3);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    if (employeeAbsenceRequestsData) {
      const today = moment().startOf("day");
      const filteredData = employeeAbsenceRequestsData.filter((absenceLogItem: absenceType) => {
        const lastDateOfAbsence = moment(absenceLogItem?.endDate);
        return lastDateOfAbsence.isSameOrAfter(today) && absenceLogItem?.approveStatus === true;
      });
      setFilteredData(filteredData);
      setFilteredDataFromSearch(filteredData);
    }
  }, [employeeAbsenceRequestsData]);

  const handleFilterChange = (value: string) => {
    setFilterValue(value);
    const newFilteredData = filteredData.filter((absenceLogItem: absenceType) => {
      const fullName = `${absenceLogItem.employee.firstName} ${absenceLogItem.employee.lastName}`;
      return fullName === value || value === "";
    });
    setFilteredDataFromSearch(newFilteredData);
  };

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }

  if (!data || !data.getEmployeeAbsenceRequests) {
    return <Empty />;
  }

  if (error) {
    return <p>Error</p>;
  }

  // Sort absences by the latest date in ascending order
  filteredDataFromSearch.sort((a, b) => {
    const latestDateOfA = moment(a.startDate);
    const latestDateOfB = moment(b.startDate);
    return latestDateOfA.diff(latestDateOfB);
  });

  const uniqueNames = Array.from(
    new Set(
      employeeAbsenceRequestsData.map((item: absenceType) => `${item.employee.firstName} ${item.employee.lastName}`)
    )
  );

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  return (
    <>
      <Select
        showSearch
        value={filterValue}
        onChange={handleFilterChange}
        style={{ width: "100%", marginBottom: "16px" }}>
        <Option value="">All</Option>
        {uniqueNames.map((name: any) => (
          <Option key={name} value={name}>
            {name}
          </Option>
        ))}
      </Select>
      <List
        itemLayout="horizontal"
        dataSource={filteredDataFromSearch.slice(0, visibleItems)}
        renderItem={(absenceLogItem: absenceType) => {
          const daysFromToday = getDaysFromToday(absenceLogItem);
          const isInVacation = isTodayInVacation(absenceLogItem);
          const daysUntilReturn = getDaysUntilReturn(absenceLogItem);
          const totalDaysOfAbsence = absenceLogItem?.duration;
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar shape="square" src={isInVacation ? absenceActiveImg : absenceImg} />}
                title={
                  <span>
                    <h3 style={{ marginBottom: "-2px" }}>
                      {absenceLogItem?.employee?.firstName} {absenceLogItem?.employee?.lastName}
                    </h3>
                    <h5>
                      {absenceLogItem?.absenceType?.absenceTypeName +
                        " " +
                        `(${totalDaysOfAbsence > 1 ? totalDaysOfAbsence + " days" : totalDaysOfAbsence + " day"}) `}
                    </h5>
                  </span>
                }
                description={
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ marginRight: "10px" }}>
                      {moment(absenceLogItem?.datesOfAbsence[0]).format("DD/MM/YYYY") +
                        " - " +
                        moment(absenceLogItem?.datesOfAbsence[absenceLogItem?.datesOfAbsence.length - 1]).format(
                          "DD/MM/YYYY"
                        )}
                    </p>
                    {isInVacation ? (
                      <p style={{ marginTop: -10, marginBottom: -5 }}>
                        {daysUntilReturn !== 0
                          ? `In vacation (Returning in ${daysUntilReturn} days)`
                          : "Returning tomorrow"}
                      </p>
                    ) : (
                      <p style={{ marginTop: -10, marginBottom: -5 }}>{`(${daysFromToday} days from today)`}</p>
                    )}
                  </span>
                }
              />
            </List.Item>
          );
        }}
      />
      {filteredDataFromSearch.length > visibleItems && (
        <Button size="small" onClick={handleLoadMore}>
          Load More
        </Button>
      )}
    </>
  );
};

export default HomeAbsenceList;
