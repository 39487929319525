import gql from 'graphql-tag';

export const GET_MILESTONES = gql`
  query getMilestones($month: DateTimeISO, $clientId: Int) {
    getMilestones(month: $month, clientId: $clientId) {
      id
      tag {
        id
        name
        project {
          id
          name
        }
      }
      effort
      amount
      startDate
      endDate
    }
  }
`;

export const GET_LEAVE_TYPES = gql`
  query getLeaveTypes {
    leaveTypes {
      id
      leaveTypeName
      holidayLimit
      exclude
      canUploadDocument
    }
  }
`;

export const GET_ABSENCE_TYPES = gql`
  query GetAbsenceTypes {
    getAbsenceTypes {
      id
      CompanyBranchId
      absenceTypeName
      description
      expirationDate
      numberOfDays
      createdAt
      updatedAt
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_TYPES = gql`
  query GetEmployeeAbsenceTypes {
    getEmployeeAbsenceTypes {
      id
      CompanyBranchId
      absenceTypeName
      description
      expirationDate
      numberOfDays
      createdAt
      updatedAt
    }
  }
`;

export const GET_HOLIDAY_TYPES = gql`
  query GetHolidayTypes {
    getHolidayTypes {
      id
      CompanyBranchId
      description
      date
      createdAt
      updatedAt
    }
  }
`;

export const GET_HOLIDAY_TYPES_BY_ID = gql`
  query GetHolidayTypeById($id: Int!) {
    getHolidayTypeById(id: $id) {
      id
      CompanyBranchId
      description
      date
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANY_BRANCH = gql`
  query GetCompanyBranches {
    getCompanyBranches {
      id
      companyId
      country
      city
      iban
      nipt
      accountNumber
      contactNumber
      virtual
      startOfWorkDay
      endOfWorkDay
      daysOfWork
      holidayTypes {
        id
        CompanyBranchId
        description
        date
        createdAt
        updatedAt
      }
      absenceTypes {
        id
        CompanyBranchId
        absenceTypeName
        description
        expirationDate
        numberOfDays
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANY_BRANCH_BY_ID = gql`
  query GetCompanyBranchById($id: Int!) {
    getCompanyBranchById(id: $id) {
      id
      companyId
      country
      city
      iban
      nipt
      accountNumber
      contactNumber
      virtual
      startOfWorkDay
      endOfWorkDay
      daysOfWork
      holidayTypes {
        id
        CompanyBranchId
        description
        date
        createdAt
        updatedAt
      }
      absenceTypes {
        id
        CompanyBranchId
        absenceTypeName
        description
        expirationDate
        numberOfDays
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_REQUESTS = gql`
  query GetEmployeeAbsenceRequests {
    getEmployeeAbsenceRequests {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      datesOfAbsence
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
      createdAt
      updatedAt
      deletedAt
      employee {
        firstName
        lastName
      }
      manager {
        firstName
        lastName
      }
      replacer {
        firstName
        lastName
      }
      absenceType {
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME = gql`
  query GetEmployeeAbsenceRequestForHomeScreen {
    getEmployeeAbsenceRequestForHomeScreen {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      datesOfAbsence
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
      createdAt
      updatedAt
      deletedAt
      manager {
        firstName
        lastName
      }
      replacer {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
      absenceType {
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_REQUEST = gql`
  query GetEmployeeAbsenceRequest {
    getEmployeeAbsenceRequest {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      datesOfAbsence
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
      createdAt
      updatedAt
      deletedAt
      manager {
        firstName
        lastName
      }
      replacer {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
      absenceType {
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_PROFILE = gql`
  query GetAllEmployeeAbsenceRequestsForProfile($id: Int!) {
    getAllEmployeeAbsenceRequestsForProfile(id: $id) {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      datesOfAbsence
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
      createdAt
      updatedAt
      deletedAt
      manager {
        firstName
        lastName
      }
      replacer {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
      absenceType {
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_ABSENCE_FOR_ADMIN = gql`
  query GetAllEmployeesAbsenceRequestsForAdmin {
    getAllEmployeesAbsenceRequestsForAdmin {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      datesOfAbsence
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
      createdAt
      updatedAt
      deletedAt
      manager {
        firstName
        lastName
      }
      replacer {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
      absenceType {
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_BALANCE = gql`
  query GetEmployeeAbsenceBalance {
    getEmployeeAbsenceBalance {
      id
      EmployeeId
      takenDays
      availableDaysOff
      plannedDaysOff
      AbsenceTypeId
      absenceTypes {
        id
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_EMPLOYEE_ABSENCE_BALANCE_FOR_PROFILE = gql`
  query GetEmployeeAbsenceBalanceForProfile($id: Int!) {
    getEmployeeAbsenceBalanceForProfile(id: $id) {
      id
      EmployeeId
      takenDays
      availableDaysOff
      plannedDaysOff
      AbsenceTypeId
      createdAt
      absenceTypes {
        id
        CompanyBranchId
        absenceTypeName
        description
        numberOfDays
        expirationDate
      }
    }
  }
`;

export const GET_REVIEW_CYCLE = gql`
  query GetReviewCycles {
    getReviewCycles {
      id
      EmployeeId
      ManagerId
      CompanyId
      expireDate
      managerComment
      commentIsPrivate
      closedAt
      hasExpired
      Manager
      Employee
      selfReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      peerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      managerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_REVIEW_CYCLE_HISTORY = gql`
  query GetReviewCyclesHistory($userId: Int!) {
    getReviewCyclesHistory(userId: $userId) {
      id
      EmployeeId
      ManagerId
      CompanyId
      expireDate
      managerComment
      commentIsPrivate
      closedAt
      hasExpired
      Manager
      Employee
      selfReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      peerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      managerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_REVIEW_REQUESTS = gql`
  query GetReviewRequests {
    getReviewRequests {
      id
      EmployeeId
      ManagerId
      CompanyId
      expireDate
      managerComment
      commentIsPrivate
      closedAt
      hasExpired
      Manager
      Employee
      selfReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      peerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      managerReviews {
        id
        ReviewCycleId
        ReviewerId
        TemplateId
        isPrivate
        responses
        submittedAt
        createdAt
        updatedAt
        deletedAt
        template {
          id
          title
          description
          questions
          CompanyId
          createdAt
          updatedAt
          deletedAt
        }
        Reviewer
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_REVIEW_TOTAL_REQUESTS = gql`
  query GetTotalReviewRequests {
    getTotalReviewRequests {
      totalSelfReviews
      totalPeerReviews
      totalManagerReviews
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query GetAllDocumentsByEmployee($EmployeeId: Int!) {
    getAllDocumentsByEmployee(EmployeeId: $EmployeeId) {
      id
      EmployeeId
      fileName
      fileType
      fileSize
      category
      filePath
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
