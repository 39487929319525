import React, { Fragment, useEffect, useState } from "react";
import { TFunction } from "i18next";
import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd";
import { Mutation } from "@apollo/react-components";
import { CREATE_TAG } from "../../../../../../graphql/mutations";
import { FormComponentProps } from "antd/lib/form";
import client from "../../../../../../graphql/ApolloClient";
import { GET_PROJECTS, GET_PROJECT_TAGS } from "../../../../../../graphql/queries";

interface IProps extends FormComponentProps {
  t: TFunction;
  projectId: number;
  projectName: string;
}

const TagCreate: React.FC<IProps> = ({ t, form, projectId, projectName }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [projects, setProjects] = useState<any>([]);

  // useEffect(() => {
  //   client.query({ query: GET_PROJECTS }).then((res) => {
  //     setProjects(res.data.projects);
  //   });
  // }, []);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  return (
    <Fragment>
      <Button type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.TAGS.CREATE_TAG")}
      </Button>
      <Mutation
        mutation={CREATE_TAG}
        refetchQueries={[
          {
            query: GET_PROJECT_TAGS,
            variables: { projectId },
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.TAGS.MESSAGES.TAG_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createTag, { loading }) => (
          <Modal
            title={t("appConfiguration.TAGS.CREATE_TAG")}
            width={600}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const {
                    name,
                    project: { key },
                  } = values;

                  createTag({
                    variables: {
                      name,
                      projectId: key,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("timelogs.CREATE_TAG.PROJECT")}>
                    {form.getFieldDecorator("project", {
                      initialValue: { key: projectId, label: projectName },
                      rules: [{ required: true, message: t("appConfiguration.TAGS.MESSAGES.PROJECT_REQUIRED") }],
                    })(
                      <Select
                        disabled={true}
                        optionFilterProp="children"
                        showSearch={true}
                        labelInValue={true}
                        placeholder={t("timelogs.CREATE_TAG.PROJECT")}>
                        {projects.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("addRoleModal.LABELS.NAME")}>
                    {form.getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.TAGS.MESSAGES.NAME_REQUIRED"),
                        },
                      ],
                    })(<Input placeholder={t("addRoleModal.LABELS.NAME")} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(TagCreate);
