import React from "react";
import TitleSection from "../../../common/SectionTitle/title";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GET_COMPANY } from "../../../graphql/queries/index";
import { Query } from "@apollo/react-components";
import { Spin } from "antd";
interface ICompanyRouterProp {
  companyId: string;
}
const CompanyDetails = (props: RouteComponentProps<ICompanyRouterProp>) => {
  return (
    <div className="company-details">
      <TitleSection title="Company Details" iconType="reconciliation" />
      <div className="wrapper">
        <Query query={GET_COMPANY} variables={{ companyId: parseFloat(props.match.params.companyId) }}>
          {(state) => {
            const error = state?.error;
            const loading = state.loading;
            const data = state?.data;
            if (error) {
              return <div>Error</div>;
            }
            let company;
            if (!loading && data && data.company) {
              company = data.company;
            }
            if (loading) {
              return <Spin />;
            }
            return <div>{company.name}</div>;
          }}
        </Query>
      </div>
    </div>
  );
};

export default withRouter(CompanyDetails);
