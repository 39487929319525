import React, { useState } from "react";
import { Upload, Button, notification } from "antd";
import { useMutation } from "@apollo/react-hooks";

const UploadContractPdf = ({ id, mutation }) => {
  const [uploadContract] = useMutation(mutation);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFileUpload = async (file) => {
    setLoading(true);

    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;

        const { data } = await uploadContract({
          variables: {
            contractId: id,
            contractPdf: base64String,
          },
        });

        // Handle the response data
        notification.success({ message: "File uploaded successfully!" });
      };
      setLoading(false);

      reader.readAsDataURL(file);
    } catch (error) {
      // Handle errors
      notification.error({ message: "Failed to upload file." });
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const allowedFormats = ["application/pdf"];
    const isPDF = allowedFormats.includes(file.type);

    if (!isPDF) {
      notification.error({ message: "You can only upload PDF files!" });
      return false; // Prevent file upload
    }

    handleFileUpload(file); // Upload the file
    return false; // Prevent default behavior of the Upload component
  };

  return (
    <Upload accept=".pdf" beforeUpload={beforeUpload} showUploadList={false}>
      <Button size="small" type="primary" ghost icon={"cloud-upload"} loading={loading}>
        {loading ? "Uploading..." : "Upload Contract"}
      </Button>
    </Upload>
  );
};

export default UploadContractPdf;
