import React, { PureComponent } from "react";
import Select, { SelectProps } from "antd/lib/select";
import { Spin, Icon } from "antd";
import { Query } from "@apollo/react-components";
import { GET_DEPUTIES } from "../../../../graphql/queries/LeaveManagementQueries";
import { TFunction } from "i18next";

interface IProps extends SelectProps {
  startDate: any;
  endDate: any;
  t: TFunction;
}

class SelectDeputy extends PureComponent<IProps> {
  public render() {
    const { ...rest } = this.props;
    const { t, startDate, endDate } = this.props;
    return (
      <Query query={GET_DEPUTIES} variables={{ startDate, endDate }} fetchPolicy="network-only">
        {(state) => {
          const loading = state.loading;
          const data = state?.data;
          const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;
          if (!loading && !!data && data.deputies) {
            const deputies = data.deputies;
            return (
              <>
                <Select
                  {...rest}
                  placeholder={t("placeholders.SELECT_DEPUTY")}
                  style={{ width: "100%" }}
                  disabled={!(startDate && endDate)}>
                  {deputies.map(({ userId, name }, index) => (
                    <Select.Option value={userId} key={userId + index}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </>
            );
          } else {
            return (
              <>
                <Select placeholder={t("placeholders.LOADING_DEPUTIES")} style={{ width: "100%" }}>
                  <Select.Option value="">
                    <Spin size={"small"} indicator={antIcon} className="spin" />
                  </Select.Option>
                </Select>
              </>
            );
          }
        }}
      </Query>
    );
  }
}

export default SelectDeputy;
