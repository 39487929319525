import { useQuery } from "@apollo/react-hooks";
import { Table } from "antd";
import {
  GET_CALCULATED_UTILIZATION_RATE_FOR_EACH_EMPLOYEE,
  GET_CALCULATED_YEARLY_UTILIZATION_RATE,
} from "graphql/queries";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getMonthDateRange } from "../helpers";

const columns = [
  {
    title: "Year",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "Utilization Rate",
    dataIndex: "utilizationRate",
    key: "utilizationRate",
  },
];
const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;
const nextYear = currentYear + 1;

const YearlyUtilizationRate = React.memo(() => {
  const { t } = useTranslation();
  const {
    data: data_lastYear,
    loading: loading_lastYear,
    error,
  } = useQuery(GET_CALCULATED_YEARLY_UTILIZATION_RATE, {
    variables: { year: `${lastYear}` },
  });
  const { data: data_currentYear, loading: loading_currentYear } = useQuery(GET_CALCULATED_YEARLY_UTILIZATION_RATE, {
    variables: { year: `${currentYear}` },
  });
  const { data: data_nextYear, loading: loading_nextYear } = useQuery(GET_CALCULATED_YEARLY_UTILIZATION_RATE, {
    variables: { year: `${nextYear}` },
  });

  const lastMonthIndex = new Date().getMonth();
  const lastMonthUtilizationRateParams = {
    startDate: getMonthDateRange(currentYear, lastMonthIndex + 1).start,
    endDate: getMonthDateRange(currentYear, lastMonthIndex + 1).end,
  };
  const {
    data: lastMonthUtilizationRateData,
    loading: lastMonthUtilizationRateLoading,
    error: lastMonthUtilizationRateError,
  } = useQuery(GET_CALCULATED_UTILIZATION_RATE_FOR_EACH_EMPLOYEE, {
    variables: lastMonthUtilizationRateParams,
    fetchPolicy: "cache-and-network",
  });

  let dataSource: { year: number; utilizationRate: string; key: string }[] = [];
  if (data_lastYear && data_currentYear && data_nextYear) {
    dataSource = [
      { data: data_lastYear, year: lastYear },
      { data: data_currentYear, year: currentYear },
      { data: data_nextYear, year: nextYear },
    ].map(({ data, year }) => {
      return { key: `${year}`, year, utilizationRate: data.getCalculatedYearlyUtilizationRate };
    });
  }

  const lastYearUtilizationRate = dataSource.find((el) => el.year === lastYear);
  const currentYearUtilizationRateExludingRemainingMonths = dataSource.find((el) => el.year === currentYear);

  const getLastMonthCalculatedUtilizationRate = useCallback(() => {
    let lastMonthUtilizationRateForAllEmployees = 0;
    if (lastMonthUtilizationRateData) {
      let totalAvailableHoursForAllEmployees = 0;
      let totalBilledHoursForAllEmployees = 0;
      lastMonthUtilizationRateData.getCalculatedUtilizationRateForEachEmployee.forEach(
        ({ availableHours, billedHours }) => {
          totalBilledHoursForAllEmployees += billedHours;
          totalAvailableHoursForAllEmployees += availableHours;
        }
      );
      lastMonthUtilizationRateForAllEmployees =
        totalBilledHoursForAllEmployees === 0
          ? 0
          : parseFloat(((totalBilledHoursForAllEmployees / totalAvailableHoursForAllEmployees) * 100).toFixed(2));
    }

    return lastMonthUtilizationRateForAllEmployees;
  }, [lastMonthUtilizationRateData]);

  const boxes = [
    {
      boxHeader: "Last year Utilization Rate",
      value: lastYearUtilizationRate ? lastYearUtilizationRate.utilizationRate : t("general.CALCULATING"),
    },
    {
      boxHeader: "Yearly Utilization Rate until now",
      value: currentYearUtilizationRateExludingRemainingMonths
        ? currentYearUtilizationRateExludingRemainingMonths.utilizationRate
        : t("general.CALCULATING"),
    },
    {
      boxHeader: "Last Month Utilization Rate",
      value: !lastMonthUtilizationRateLoading
        ? `${getLastMonthCalculatedUtilizationRate()}%`
        : t("general.CALCULATING"),
    },
  ];

  return (
    <div className="yearlyUtilizationRate-container">
      <div className="boxes-container">
        {boxes.map(({ boxHeader, value }) => (
          <div key={boxHeader} className="box">
            <p className="box-header">{boxHeader}</p>
            <p className="box-value">{value}</p>
          </div>
        ))}
      </div>
      <h1 className="yearlyUtilizationRate-heading">Yearly Utilization Rate</h1>
      {error || (lastMonthUtilizationRateError && <p className="paragraph">{t("GENERAL.ERROR")}</p>)}
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading_lastYear || loading_currentYear || loading_nextYear}
      />
    </div>
  );
});

export default YearlyUtilizationRate;
