import { useState, useEffect } from "react";
import moment, { Moment } from "moment";

const useTimeOffPolicyHome = (
  firstDayOfAbsence: any,
  selectedDatesOfAbsenceLength: any,
  selectedDatesCreatedAt: any
): [boolean, boolean] => {
  const [firstRule, setFirstRule] = useState<boolean>(false);
  const [secondRule, setSecondRule] = useState<boolean>(false);

  useEffect(() => {
    const businessDiff = (startDate: Moment, endDate: Moment): number => {
      let currentDate = moment(startDate);
      const diff = moment(endDate).diff(startDate, "days");
      let businessDays = 0;

      for (let i = 0; i <= diff; i++) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          businessDays++;
        }
        currentDate.add(1, "days");
      }

      return businessDays;
    };

    const timeOffPolicy = (
      firstDayOfAbsence: any,
      selectedDatesOfAbsenceLength: any,
      selectedDatesCreatedAt: Moment
    ): void => {
      const lengthOfAbsence = selectedDatesOfAbsenceLength;
      const firstDay = firstDayOfAbsence;
      const createdAt = selectedDatesCreatedAt;
      const diff = businessDiff(createdAt, firstDay); // Calculates the difference in business days

      if (lengthOfAbsence <= 5 && diff < lengthOfAbsence * 2) {
        setSecondRule(false);
        setFirstRule(true);
      } else if (lengthOfAbsence > 5 && diff < 20) {
        setFirstRule(false);
        setSecondRule(true);
      } else {
        setFirstRule(false);
        setSecondRule(false);
      }
    };

    timeOffPolicy(firstDayOfAbsence, selectedDatesOfAbsenceLength, selectedDatesCreatedAt);
  }, [firstDayOfAbsence]);

  return [firstRule, secondRule];
};

export default useTimeOffPolicyHome;
