import React from "react";
import { Statistic, Skeleton, Select } from "antd";
import { getWorkingYears } from "../../utils/availableYears";
import moment from "moment";

const HolidaysLeft = ({
  t,
  holidaysLeft,
  allowedHolidays,
  loading,
  employmentType,
  countrySuffix,
  selectedYear,
  setSelectedYear,
  contractStart,
  employeeId,
}) => {
  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const currentYear = moment().year();

  return (
    <div className="holidays-statistic">
      <Skeleton
        loading={loading}
        active={true}
        title={{ width: "72vw" }}
        paragraph={{ rows: 2, width: ["70vw", "75vw"] }}>
        <Statistic
          title={`${t("employees.HOLIDAYS_LEFT")} ${selectedYear}`}
          value={holidaysLeft}
          suffix={`/ ${allowedHolidays} days`}
          valueStyle={
            holidaysLeft <= 5 ? { color: "red", textAlign: "center" } : { color: "green", textAlign: "center" }
          }
        />
        <Statistic
          title={t("employees.CONTRACT_START")}
          value={capitalize(moment(contractStart).format("DD-MM-YYYY"))}
          valueStyle={{ fontSize: "15pt", textAlign: "center" }}
        />
        <Statistic
          title={t("employees.EMPLOYMENT_TYPE")}
          value={capitalize(employmentType)}
          valueStyle={{ fontSize: "15pt", textAlign: "center" }}
        />
        <Statistic
          title={t("employees.WORKING_COUNTRY")}
          value={countrySuffix}
          valueStyle={{ fontSize: "15pt", textAlign: "center" }}
        />
        <Statistic
          title={t("employees.FILTER_BY_WORKING_YEAR")}
          formatter={() => (
            <Select
              style={{ width: "90px" }}
              defaultValue={selectedYear}
              onChange={(val) => {
                setSelectedYear(val);
              }}>
              {getWorkingYears(JSON.stringify(moment(contractStart).year()), currentYear).map((item) => (
                <Select.Option key={item}>{item}</Select.Option>
              ))}
            </Select>
          )}
        />
      </Skeleton>
    </div>
  );
};

export default HolidaysLeft;
