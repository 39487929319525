import React, { PureComponent } from "react";
import Select, { SelectProps } from "antd/lib/select";
import { Spin, Icon } from "antd";
import { Query } from "@apollo/react-components";
import { GET_LEAVE_TYPES } from "../../../../graphql/queries/LeaveManagementQueries";
import { TFunction } from "i18next";

interface IProps extends SelectProps {
  t: TFunction;
}

class SelectLeaveType extends PureComponent<IProps> {
  public render() {
    const { t, ...rest } = this.props;
    return (
      <Query query={GET_LEAVE_TYPES}>
        {(state) => {
          const loading = state.loading;
          const data = state?.data;
          let leaveTypes;
          if (!loading && !!data && data.leaveTypes) {
            leaveTypes = data.leaveTypes;
          }
          const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;

          if (!loading && leaveTypes.length > 0) {
            return (
              <>
                <Select {...rest} placeholder={t("placeholders.SELECT_LEAVE_TYPE")} style={{ width: "100%" }}>
                  {leaveTypes.map(({ id, leaveTypeName }, index) => (
                    <Select.Option value={id} key={id + index}>
                      {leaveTypeName}
                    </Select.Option>
                  ))}
                </Select>
              </>
            );
          } else {
            return (
              <>
                <Select placeholder={t("placeholders.LOADING_LEAVE_TYPES")} style={{ width: "100%" }}>
                  <Select.Option value="">
                    <Spin size={"small"} indicator={antIcon} className="spin" />
                  </Select.Option>
                </Select>
              </>
            );
          }
        }}
      </Query>
    );
  }
}

export default SelectLeaveType;
