import React, { useState } from "react";
import { TFunction } from "i18next";
import { Button, Form, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { UPDATE_LEAVE_REQUEST } from "../../../../graphql/mutations/LeaveManagementMutations";
import EditLeaveRequestModal from "../../Components/MyLeaveRequests/editLeaveRequestModal";
import { FormComponentProps } from "antd/lib/form";
import { GET_LEAVE_REQUEST_DETAILS } from "../../../../graphql/queries/LeaveManagementQueries";

interface IProps extends FormComponentProps {
  t: TFunction;
  data: any;
}

const EditLeaveRequest: React.FC<IProps> = ({ t, form, data }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleModal = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Button icon="edit" onClick={toggleModal} style={{ marginLeft: "5px" }} htmlType="reset">
        {t("buttons.EDIT")}
      </Button>

      <Mutation
        mutation={UPDATE_LEAVE_REQUEST}
        refetchQueries={[
          {
            query: GET_LEAVE_REQUEST_DETAILS,
            variables: { id: data.id },
          },
        ]}
        onCompleted={() => {
          toggleModal();
          notification.success({
            message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_UPDATED"),
          });
        }}
        onError={(err) => {
          form.resetFields();
          toggleModal();
          notification.error({
            message: err.message.split(":")[1],
          });
        }}>
        {(updateLeaveRequest, { loading }) => (
          <>
            <EditLeaveRequestModal
              t={t}
              loading={loading}
              visible={visible}
              data={data}
              closeModal={toggleModal}
              updateLeaveRequest={updateLeaveRequest}
              form={form}
            />
          </>
        )}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(EditLeaveRequest);
