import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Button, Form, Col, Modal, Row, Select, Icon, Upload, message, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { fileCategory } from "./Helpers/fileCategory";
import { allowedFileTypes } from "./Helpers/allowedFileType";
import { IUser } from "screens/Profile/Interfaces";
import { CREATE_DOCUMENT } from "graphql/mutations";
import { GET_DOCUMENTS } from "graphql/queries/Configurations";

interface IProps extends FormComponentProps {
  t: TFunction;
  userData: IUser;
  userId: number;
}
const { Option } = Select;
const { Dragger } = Upload;

const DocumentUpload: React.FC<IProps> = ({ userData, t, userId, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const currentUserFirstName = userData?.firstName;
  const currentUserId = userData?.id;

  const [base64File, setBase64File] = useState(null);
  const [fileList, setFileList] = useState<any>([]);
  const [fileName, setFileName] = useState<String>("");

  const handleChange = async ({ file }) => {
    if (file.status !== "removed") {
      // Check if the selected file is of allowed type
      if (allowedFileTypes.includes(file.type)) {
        const base64String: any = await getBase64(file.originFileObj);
        setBase64File(base64String);
        setFileName(file.name.substr(0, file.name.lastIndexOf(".")) || file.name);
        setFileList([{ ...file, status: "success" }]);
      } else {
        setBase64File(null);
        setFileList([]);
        message.error("Unsupported file type. Please select a valid file.");
      }
    } else {
      setBase64File(null);
      setFileList([]);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const props = {
    name: "file",
    multiple: false,
    fileList,
    onChange: handleChange,
  };

  return (
    <Fragment>
      <Button type="primary" icon="upload" onClick={toggleModal}>
        {t("appConfiguration.DOCUMENTS.UPLOAD")}
      </Button>
      <Mutation
        mutation={CREATE_DOCUMENT}
        refetchQueries={[
          {
            query: GET_DOCUMENTS,
            variables: { EmployeeId: userId },
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.DOCUMENTS.MESSAGES.DOCUMENT_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createDocument, { loading }) => (
          <Modal
            title={`${t("appConfiguration.DOCUMENTS.UPLOAD")} for ${currentUserFirstName}`}
            width={600}
            centered={true}
            okButtonProps={{
              loading: loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { Category } = values;

                  createDocument({
                    variables: {
                      EmployeeId: currentUserId,
                      category: Category,
                      file: base64File,
                      fileName: fileName,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
              setBase64File(null);
              setFileList([]);
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.DOCUMENTS.LABELS.CATEGORY")}>
                    {form.getFieldDecorator("Category", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.DOCUMENTS.MESSAGES.CATEGORY_REQUIRED"),
                        },
                      ],
                    })(
                      <Select showSearch allowClear>
                        {fileCategory.category.map((categoryName) => (
                          <Option key={categoryName} value={categoryName}>
                            {categoryName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.DOCUMENTS.LABELS.FILE")}>
                    {form.getFieldDecorator("File", {
                      rules: [
                        {
                          required: false,
                          message: t("appConfiguration.DOCUMENTS.MESSAGES.FILE_REQUIRED"),
                        },
                      ],
                    })(
                      <>
                        <Dragger {...props}>
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">{t("appConfiguration.DOCUMENTS.ALLOWED_FILE_TYPE")}</p>
                        </Dragger>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(DocumentUpload);
