import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { PureComponent } from 'react';
import {
  notification,
  Button,
  Form,
  Modal,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import { Mutation } from '@apollo/react-components';
import { CREATE_INVOICE } from 'graphql/mutations';
import jwt_decode from 'jwt-decode';
import { toBase64 } from 'utils/file';

import CreateInvoiceForm from './createInvoiceForm';

interface IProps extends FormComponentProps {
  refetch: any;
  permissions: any;
  employee: any;
}

interface IState {
  modalIsOpen: boolean;
  invoiceItems: string[];
  error: boolean;
  hasPaymentDueDateOffset: boolean;
}

class GenerateInvoiceModal extends PureComponent<IProps & WithTranslation, IState> {
  public state = {
    modalIsOpen: false,
    invoiceItems: [],
    error: false,
    hasPaymentDueDateOffset: true,
  };

  public toggleModal = () => {
    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  public resetModal = () => {
    this.props.form.resetFields();
  };

  public handleCancel = () => {
    this.toggleModal();
    this.resetModal();
  };

  public fetchInvoiceItems = (invoiceItems: any) => {
    this.setState({ invoiceItems });
  };

  public generateInvoice = (createInvoice) => {
    this.props.form.validateFields((err, values) => {
      const generate = async () => {
        if (!err) {
          const { invoiceItems } = this.state;
          if (invoiceItems.length === 0) {
            notification.error({
              message: "Please add an item to create invoice",
            });
          } else {
            const { month, timesheetAttachment, otherAttachment } = values;
            const invoiceItemsPayload = invoiceItems.map((item: any) => {
              return {
                description: item.description,
                hours: item.hours,
                hourlyRate: item.hourlyRate,
                projectId: item.project.key,
              };
            });

            const decoded: any = jwt_decode("" + localStorage.getItem("token"));

            createInvoice({
              variables: {
                timesheetAttachment: await toBase64(timesheetAttachment[1][0].originFileObj),
                otherAttachment: otherAttachment[2] ? await toBase64(otherAttachment[2][0]?.originFileObj) : null,
                month: month[0].toDate(),
                invoiceData: JSON.stringify(invoiceItemsPayload),
                contractorId: decoded.userId,
              },
            });
          }
        }
      };
      generate();
    });
  };

  public render() {
    const { t, employee, refetch } = this.props;

    return (
      <>
        <Button type="primary" onClick={this.toggleModal} style={{ marginBottom: "15px" }} data-cy="add-timelog">
          {t("timelogs.CREATE_MODAL.NEW_INVOICE")}
        </Button>
        <Mutation
          mutation={CREATE_INVOICE}
          update={() => {
            refetch();
            notification.success({
              message: t("createInvoiceModal.MESSAGES.INVOICE_GENERATED"),
            });
            this.toggleModal();
          }}
          onError={(error) => {
            notification.error({
              message: error.message.split(":")[1],
            });
          }}>
          {(createInvoice, { loading }) => (
            <>
              {this.state.modalIsOpen && (
                <Modal
                  width={"100%"}
                  visible
                  maskClosable={false}
                  title={t("timelogs.CREATE_MODAL.CREATE_INVOICE")}
                  data-cy="timelog-modal"
                  onCancel={this.handleCancel}
                  className="createInvoiceModal"
                  centered
                  footer={[
                    <Button
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      onClick={() => this.generateInvoice(createInvoice)}
                      disabled={!this.state.hasPaymentDueDateOffset}
                    >
                      {t("buttons.CREATE_INVOICE")}
                    </Button>,
                  ]}>
                  <CreateInvoiceForm
                    t={t}
                    form={this.props.form}
                    userId={employee}
                    fetchInvoiceItems={this.fetchInvoiceItems}
                    isEditing
                    setHasPaymentDueDateOffset={(hasOffset: boolean) => this.setState({ hasPaymentDueDateOffset: hasOffset })}
                  />
                </Modal>
              )}
            </>
          )}
        </Mutation>
      </>
    );
  }
}

export default Form.create<IProps>()(withTranslation()(GenerateInvoiceModal));
