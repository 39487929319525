import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Layout, Divider, Spin, Badge } from "antd";
import HomeRequestForAbsence from "./components/HomeRequestForAbsence";
import HomeAbsenceList from "./components/HomeAbsenceList";
import HomeAnniversaries from "./components/HomeAnniversaries";
import HomeBirthdays from "./components/HomeBirthdays";
import { getUserData } from "common/AccessControl/Permissions";
import HomeHolidays from "./components/HomeHolidays";
import HomeReviewTotalRequests from "./components/HomeReviewTotalRequests";

interface IProps {}
const { Content } = Layout;

const Home: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const currentUser = getUserData();
  return (
    <>
      <TitleSection title={t("sideMenu.HOME")} iconType="home" />
      <Content className="grid-container">
        <div className="left-div">
          <div className="section1">
            <span className="shake-hand">
              <h1>{`👋`}</h1>
            </span>
            <h1>{`Hello ${currentUser.firstName}!`}</h1>
          </div>
          <div className="sectionNotify">
            <span style={{ display: "flex" }}>
              <h4>Performance review</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Private"}</h6>
            </span>
            <HomeReviewTotalRequests t={t} />
          </div>
          <div className="section2">
            <span style={{ display: "flex" }}>
              <h4>Requests for absence</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Private"}</h6>
            </span>
            <HomeRequestForAbsence t={t} />
          </div>
        </div>
        <div className="middle-div">
          <div className="section">
            <span style={{ display: "flex" }}>
              <h4>Out of office</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Public"}</h6>
            </span>
            <HomeAbsenceList t={t} />
          </div>
          <div className="section">
            <span style={{ display: "flex" }}>
              <h4>Upcoming birthdays</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Public"}</h6>
            </span>
            <HomeBirthdays t={t} />
          </div>
          <div className="section">
            <span style={{ display: "flex" }}>
              <h4>Upcoming anniversaries</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Public"}</h6>
            </span>
            <HomeAnniversaries t={t} />
          </div>
        </div>
        <div className="right-div">
          <div className="section">
            <span style={{ display: "flex" }}>
              <h4>Upcoming holidays</h4>
              <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Public"}</h6>
            </span>
            <HomeHolidays t={t} />
          </div>
        </div>
      </Content>
    </>
  );
};

export default Home;
