import React from "react";
import { TFunction } from "i18next";
import TagsTable from "./components/TagsTable";
import TagCreate from "./components/TagCreate";

interface IProps {
  t: TFunction;
  projectId: number;
  projectName: string;
}

const Tags: React.FC<IProps> = ({ t, projectId, projectName }) => {
  return (
    <div>
      <TagCreate t={t} projectName={projectName} projectId={projectId} />
      <TagsTable t={t} projectId={projectId} />
    </div>
  );
};

export default Tags;
