import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import SelfReviewRequests from "./SelfReviewRequests";
import PeerReviewRequests from "./PeerReviewRequests";
import ManagerReviewRequests from "./ManagerReviewRequests";
import { getUserData } from "common/AccessControl/Permissions";

const { TabPane } = Tabs;

const ReviewRequests = () => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const selectedKey = pathname.split("/")[3];

  const currentUserRole = getUserData();
  const isAdmin = currentUserRole?.roles?.some((role) => role.name === "Admin");

  return (
    <div style={{ marginTop: 20, marginLeft: -20 }}>
      <Tabs tabPosition={"left"} size="small" defaultActiveKey={selectedKey}>
        {!isAdmin && (
          <TabPane tab={t("appConfiguration.PERFORMANCE_REVIEW.SELF_REVIEW_REQUESTS")} key="1">
            <SelfReviewRequests />
          </TabPane>
        )}
        <TabPane tab={t("appConfiguration.PERFORMANCE_REVIEW.PEER_REVIEW_REQUESTS")} key="2">
          <PeerReviewRequests />
        </TabPane>
        <TabPane tab={t("appConfiguration.PERFORMANCE_REVIEW.MANAGER_REVIEW_REQUESTS")} key="3">
          <ManagerReviewRequests />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ReviewRequests;
