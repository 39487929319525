import React, { Fragment, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Layout, Skeleton } from "antd";
import FilterUtilization from "./Components/FilterUtilization";
import Chart from "./Components/Chart";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { GET_FINANCES } from "../../graphql/queries/FinanceQueries";
import { roundTwoDecPlaces } from "../../helpers/round_number";
import { IFinances } from "../../graphql/queries/Interfaces";
import MultipleCharts from "./Components/MultipleCharts";
import { Collapse } from "antd";

const initialFilters = {
  startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
  endDate: moment().endOf("week").format("YYYY-MM-DD"),
  team: null,
  projects: null,
  grossProfit: false,
};

const isTruthy = (value: number | null) => {
  return value || value === 0;
};

const isFalsy = (value: number | null) => {
  return !value || value === 0;
};

const FinanceOverview: React.FC<WithTranslation> = ({ t }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [withinMonthRange, setWithinMonthRange] = useState<boolean>(true);
  // const [isMonthRange, setIsMonthRange] = useState<boolean>(false);

  const onDateChange = (startDate, endDate, rangeIsWithinMonth, isMonth) => {
    setFilters({
      ...filters,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
    setWithinMonthRange(rangeIsWithinMonth);
    // setIsMonthRange(isMonth);
  };

  const onChange = (prop, items) => {
    let elements: number[] | null = null;

    if (items.length > 0) {
      elements = items.map((item) => +item);
    }

    setFilters({ ...filters, [prop]: elements });
  };

  const onRevenueChange = (grossProfit: boolean) => {
    setFilters({ ...filters, grossProfit });
  };

  const calculateRevenue = (item: IFinances, index: number, array: any) => {
    if (item?.prediction && isFalsy(item?.revenue)) return null;

    if (index === 0) {
      return roundTwoDecPlaces(item?.revenue ?? 0);
    } else {
      return roundTwoDecPlaces(item?.revenue + array[index - 1]?.revenue);
    }
  };

  const calculatePrediction = (item: IFinances, index: number, array: any) => {
    if (isTruthy(item?.prediction)) {
      if (index > 0 && isTruthy(array[index - 1]?.revenue)) {
        return roundTwoDecPlaces(item?.prediction + array[index - 1]?.revenue);
      }

      return roundTwoDecPlaces(item?.prediction + array[index - 1]?.prediction);
    }

    return null;
  };

  return (
    <Fragment>
      <TitleSection title={t("sideMenu.ANALYTICS.FINANCE_OVERVIEW")} iconType="area-chart" />

      <Layout.Content className="wrapper">
        <MultipleCharts t={t} />

        <Collapse expandIconPosition="right" style={{ marginBottom: "10px" }}>
          <Collapse.Panel header={t("analytics.FINANCE_OVERVIEW.GENERAL_OVERVIEW")} key={4}>
            <FilterUtilization
              t={t}
              dateChange={onDateChange}
              elementsChange={onChange}
              revenueChange={onRevenueChange}
            />
            <Query query={GET_FINANCES} variables={filters}>
              {({ loading, error, data }: any) => {
                let mappedFinances: any = [];

                // Map last object with revenue to have prediction equal to next object
                if (!loading && data?.finances) {
                  const finances = data.finances.map((item, index, array) =>
                    isTruthy(item?.revenue) &&
                    !item?.prediction &&
                    !array[index + 1]?.revenue &&
                    array[index + 1]?.prediction
                      ? {
                          ...item,
                          prediction: item.revenue,
                        }
                      : item
                  );

                  if (withinMonthRange) {
                    // if (isMonthRange) {
                    //   finances.forEach((item, index) => {
                    //     mappedFinances.push({
                    //       date: moment(item.date).format("DD MMMM"),
                    //       revenue: calculateRevenue(item, index, mappedFinances),
                    //       prediction: calculatePrediction(item, index, mappedFinances),
                    //     });
                    //   });
                    // } else {
                    //   mappedFinances = finances.map(({ date, revenue, prediction }) => ({
                    //     date: moment(date).format("DD MMMM"),
                    //     revenue: prediction && isFalsy(revenue) ? null : roundTwoDecPlaces(revenue),
                    //     prediction: isTruthy(prediction) ? roundTwoDecPlaces(prediction) : null,
                    //   }));
                    // }
                    finances.forEach((item, index) => {
                      mappedFinances.push({
                        date: moment(item.date).format("DD MMMM"),
                        revenue: calculateRevenue(item, index, mappedFinances),
                        prediction: calculatePrediction(item, index, mappedFinances),
                      });
                    });
                  } else {
                    mappedFinances = finances.map(({ month, revenue, prediction }) => ({
                      date: moment(month).format("MMM YYYY"),
                      revenue: prediction && isFalsy(revenue) ? null : roundTwoDecPlaces(revenue),
                      // prediction: isTruthy(prediction) ? roundTwoDecPlaces(prediction) : null,
                    }));
                  }
                }

                if (error) {
                  return <p>Error</p>;
                }

                if (loading) {
                  return <Skeleton active={true} />;
                }

                return <Chart t={t} data={mappedFinances} showPrediction={!filters.grossProfit} />;
              }}
            </Query>
          </Collapse.Panel>
        </Collapse>
      </Layout.Content>
    </Fragment>
  );
};

export default withTranslation()(FinanceOverview);
