import React from "react";
import { Icon } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
interface IProps extends WithTranslation {
  documentUrl: string;
}
const DownloadInvoiceDocument = (props: IProps) => {
  const { t, documentUrl } = props;
  if (documentUrl != "" && documentUrl != null) {
    return (
      <div>
        <a href={`${documentUrl}`}>
          <Icon type="download" />
        </a>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withTranslation()(DownloadInvoiceDocument);
