import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { GET_PROJECT_ASSIGNABLE_CLIENTS } from "../../../../../../graphql/queries";

interface IProps extends SelectProps {
  projectId: number;
}

export default class extends Component<IProps> {
  public render() {
    const { projectId, ...rest } = this.props;
    return (
      <Query query={GET_PROJECT_ASSIGNABLE_CLIENTS} variables={{ clientId: projectId }}>
        {(state) => {
          const loading = state.loading;
          const data = state.data;
          let assignableClients;
          if (!loading && !!data) {
            assignableClients = data.client.assignableClientUsers;
          }

          return (
            <>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Select
                  {...rest}
                  optionFilterProp="children"
                  showSearch={true}
                  style={{ width: "100%" }}
                  placeholder="Select a Client">
                  {!!assignableClients &&
                    assignableClients.map((user) => (
                      <Select.Option key={user.id} value={user.id}>
                        {`${user.firstName} ${user.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}
