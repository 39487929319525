import React, { useEffect, useState } from "react";
import { Tag, Empty, Spin, Button, Icon, Tooltip, Divider } from "antd";
import moment from "moment";
import { GET_EMPLOYEE_ABSENCE_REQUEST } from "graphql/queries/Configurations";
import { useQuery } from "@apollo/react-hooks";
import { getStatusColor, getStatusIcon, getStatusText } from "helpers/approveStatusAndAbsence";
import CalendarsAbsenceLogDelete from "./CalendarsAbsenceLogDelete";
import { useTranslation } from "react-i18next";
import CalendarsAbsenceLogDetails from "./CalendarsAbsenceLogDetails";
import { absenceType } from "screens/Home/components/HomeTypes";

const CalendarsAbsenceLogs = () => {
  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_ABSENCE_REQUEST);
  const emplyeeAbsenceLogData: absenceType[] = data?.getEmployeeAbsenceRequest;
  const [displayCount, setDisplayCount] = useState(2);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefetchTrigger((prevTrigger) => prevTrigger + 1);
    }, 5000); // Adjust the interval duration as needed

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (refetchTrigger > 0) {
      refetch();
    }
  }, [refetchTrigger]);

  if (loading) {
    return (
      <Spin
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}
        size="default"
      />
    );
  }

  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      {!emplyeeAbsenceLogData || emplyeeAbsenceLogData.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        emplyeeAbsenceLogData.slice(0, displayCount).map((absenceLogItem: absenceType, index: number) => (
          <div className="pill-container" key={index}>
            <div className="left-content">
              <h4>{absenceLogItem?.absenceType?.absenceTypeName}</h4>
              <h5>
                {moment(absenceLogItem?.startDate).format("DD/MM/YYYY") +
                  " - " +
                  moment(absenceLogItem?.endDate).format("DD/MM/YYYY")}
              </h5>
              <div className="approve-status">
                <h5 style={{ marginRight: 5 }}>{"Manager: "}</h5>
                <Tooltip title={getStatusText(absenceLogItem?.approvedByManager)}>
                  <Icon
                    style={{
                      marginRight: 15,
                      marginTop: 2,
                      color: `${getStatusColor(absenceLogItem?.approvedByManager)}`,
                    }}
                    type={getStatusIcon(absenceLogItem?.approvedByManager)}
                  />
                </Tooltip>
                <h5 style={{ marginRight: 5 }}>{"Substitute: "}</h5>
                <Tooltip title={getStatusText(absenceLogItem?.approvedByReplacer)}>
                  <Icon
                    style={{
                      marginTop: 2,
                      fontSize: "bold",
                      color: `${getStatusColor(absenceLogItem?.approvedByReplacer)}`,
                    }}
                    type={getStatusIcon(absenceLogItem?.approvedByReplacer)}
                  />
                </Tooltip>
              </div>
            </div>
            <Divider type="vertical" style={{ height: 50 }} />
            <div className="right-content">
              <Tag color={getStatusColor(absenceLogItem?.approveStatus)}>
                {getStatusText(absenceLogItem?.approveStatus)}
              </Tag>
            </div>
            <Divider type="vertical" style={{ height: 50 }} />
            <div className="button-delete-log-container">
              {absenceLogItem?.approveStatus === null && (
                <CalendarsAbsenceLogDelete t={t} absenceLogItem={absenceLogItem} />
              )}
              <CalendarsAbsenceLogDetails t={t} absenceLogItem={absenceLogItem} buttonTheme={false} />
            </div>
          </div>
        ))
      )}

      {/* Render load more button */}
      {emplyeeAbsenceLogData && emplyeeAbsenceLogData.length > displayCount && (
        <Button
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => setDisplayCount((prevCount) => prevCount + 2)}>
          Load More
        </Button>
      )}
    </>
  );
};

export default CalendarsAbsenceLogs;
