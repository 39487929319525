// centric View Permissions constants

export default {
  READ_OWN_LEAVE_REQUESTS: ["readOwn"],

  READ_OWN_EMPLOYEE: ["readOwn"],
  SCREENS: {
    HOME: ["read", "readOwn", "create", "createOwn"],
    CALENDARS: ["read", "readOwn", "create", "createOwn"],
    ORGANIZATION: ["read", "readOwn", "create", "createOwn"],
    GENERAL_SETTINGS: ["read", "readOwn", "create", "createOwn"],
    TIMELOGS: ["read", "readOwn", "create", "createOwn"],
    REPORTS: ["read", "readOwn"],
    MY_LEAVE_REQUESTS: ["readOwn", "createOwn"],
    UPCOMING_LEAVE_REQUESTS: ["read"],
    USERS: ["read", "create"],
    CLIENTS: ["read", "create"],
    UTILIZATION: ["read", "readOwn"],
    FINANCE_OVERVIEW: ["read"],
    UTILIZATION_RATE: ["read"],
    ROLES_AND_PERMISSIONS: ["read", "create"],
    APP_CONFIGURATION: ["read"],
    DEPUTY_REQUESTS: ["read", "readOwn"],
    CALENDAR_SUMMARY: ["read", "readOwn"],
    UNDER_MANAGMENT: ["read", "readOwn"],
    CANCELED_LEAVE_REQUESTS: ["read"],
    REQUESTS: ["read", "readOwn"],
    INVOICES: ["read", "create", "readOwn", "createOwn"],
    PROFILE: ["readOwn"],
    PROJECT_USERS: ["read"],
    CONTRACTS: ["read", "readOwn"],
  },
  SIDE_MENUS_MODULES: {
    HOME: ["timelogs.read", "timelogs.readOwn", "timelogs.createOwn"],
    CALENDAR: ["timelogs.read", "timelogs.readOwn", "timelogs.createOwn"],
    ORGANIZATION: ["timelogs.read", "timelogs.readOwn", "timelogs.createOwn"],
    MANAGMENT: ["users.read", "roles.read", "clients.read", "leaveRequestConfigurations.read"],
    TIME_TRACKING: [
      "timelogs.read",
      "timelogs.readOwn",
      "timelogs.createOwn",
      "reports.readOwn",
      "reports.read",
      "analytics.read",
    ],
    LEAVE_MANAGMENT: ["leaveRequests.read", "leaveRequests.readOwn", "leaveRequests.createOwn", "leaveRequests.create"],
    INVOICES: ["invoices.read", "invoices.create", "invoices.readOwn", "invoices.createOwn"],
    ANALYTICS: ["analytics.read", "analytics.readOwn"],
    CONTRACTS: ["contracts.read", "contracts.readOwn"],
  },
  SIDE_MENU_ITEMS: {
    MANAGMENT: {
      CLIENTS: ["clients.read", "clients.readOwn", "clients.create"],
      USERS: ["users.read", "users.create"],
      APP_CONFIGURATION: ["leaveRequestConfigurations.read"],
      ROLES_AND_PERMISSIONS: ["roles.read", "roles.create"],
    },
    TIME_TRACKING: {
      TIMELOGS: ["timelogs.read", "timelogs.readOwn", "timelogs.createOwn"],
      REPORTS: ["reports.read", "reports.readOwn"],
    },
    LEAVE_MANAGMENT: {
      MY_LEAVE_REQUESTS: ["leaveRequests.createOwn", "leaveRequests.create", "leaveRequests.readOwn"],
      DEPUTY_REQUESTS: ["leaveRequests.read", "leaveRequests.readOwn"],
      CALENDAR_SUMMARY: ["leaveRequests.read", "leaveRequests.readOwn"],
      UPCOMING_LEAVE_REQUESTS: ["leaveRequests.read"],
      CANCELED_LEAVE_REQUESTS: ["leaveRequests.read"],
      UNDER_MANAGMENT: ["leaveRequests.read"],
      REQUESTS: ["leaveRequests.read", "leaveRequests.readOwn"],
    },
    INVOICES: {
      INVOICES: ["invoices.read", "invoices.create", "invoices.readOwn", "invoices.createOwn"],
    },
    ANALYTICS: {
      UTILIZATION: ["analytics.read", "analytics.readOwn"],
      FINANCE_OVERVIEW: ["finance.read"],
      UTILIZATION_RATE: ["analytics.read"],
    },
  },
  BUTTONS: {
    CREATE_ROLE: ["create"],
    UPDATE_ROLE_PERMISSIONS: ["update"],
    CREATE_USER: ["create"],
    DELETE_USER: ["delete"],
    UPDATE_USER: ["update"],
    CREATE_PROJECT: ["create"],
    UPDATE_PROJECT: ["update"],
    UPDATE_CLIENT: ["update"],

    DELETE_INVOICE: ["delete", "deleteOwn"],
    UPDATE_INVOICE: ["update"],
    CREATE_LEAVE_REQUEST: ["create", "createOwn"],
    CREATE_CLIENT: ["create"],
  },
  LISTS: {
    ROLES: ["read"],
    READ_CLIENTS: ["read"],
    READ_PROJECTS: ["read"],
    READ_OWN_LEAVE_REQUESTS: ["readOwn"],
    READ_USERS: ["read"],
    READ_INVOICE: ["read", "readOwn"],
    CREATE_INVOICE: ["create", "createOwn"],
    READ_TIMELOGS: ["read", "readOwn"],
  },
};
