import React from "react";
import { QueryResult } from "@apollo/react-common";
import { ApolloError } from "apollo-boost";

const Spinner = () => <div>Loading...</div>;
const Error = ({ error }) => <div>An error ocurred {error}...</div>;

export function useHandleData<TData, TVariables>(
  result: QueryResult<TData, TVariables>,
  config?: { loadingComponent?: React.FC<{ loading: boolean }>; errorComponent?: React.FC<{ error: ApolloError }> }
) {
  return function ResultHandler(fn: (data?: TData) => React.FunctionComponentElement<any>) {
    const { data, loading, error } = result;

    if (loading) return config?.loadingComponent ? config.loadingComponent({ loading }) : <Spinner />;
    if (error) return config?.errorComponent ? config.errorComponent({ error }) : <Error error={error.toString()} />;

    return fn(data);
  };
}
