import * as React from "react";
import { IRoleColor, IRoleData, ITableFields, IUserData, IUsersProps, IUsersState } from "./Interfaces";
import { generateKey } from "../../utils/listKey";
import { Query } from "@apollo/react-components";
import { Breadcrumb, Icon, Layout, Table, Tabs, Tag } from "antd";
import UserUtilizationView from "./components/userUtilization";

import { ColumnProps } from "antd/lib/table";
import searchFilter from "../../common/SearchFilter";
import TitleSection from "../../common/SectionTitle/title";
import { GET_NEW_USERS, GET_ROLE_FOR_TAGS } from "../../graphql/queries";
import { withTranslation } from "react-i18next";
import CreateUser from "./components/createUser";
import UpdateUser from "./components/updateUser";
import DeactivateUser from "./components/deactivateUser";
import { AccessControl } from "../../common/AccessControl";
import Permissions from "../../constants/viewPermissions";
import { withPermissions } from "../../decorators/permissions";
import constants from "../../constants";
import { mergeRolesPermissions, renderEmployeeFields } from "../../helpers/permissions";
import client from "../../graphql/ApolloClient";

const { Content } = Layout;
const tagColors = ["volcano", "green", "purple", "blue", "magenta", "red", "cyan"];
@withPermissions([constants.permissionResources.USERS])
class View extends React.Component<IUsersProps, IUsersState> {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      selectedUserTableIndex: 0,
      role: "all",
      roles: [],
      rolesColor: [],
    };
  }
  public componentDidMount = () => {
    client
      .query({ query: GET_ROLE_FOR_TAGS })
      .then((res) => {
        const data: IRoleColor[] = [];
        const roles: IRoleData[] = res.data.rolesForTags;
        for (let i = 0; i < roles.length; i++) {
          if (i <= tagColors.length - 1) {
            data.push({
              id: roles[i].id,
              color: tagColors[i],
            });
          } else {
            data.push({
              id: roles[i].id,
              color: tagColors[0],
            });
          }
        }

        this.setState({
          roles,
          rolesColor: data,
        });
      })
      .catch(() => {});
  };
  public refetch = () => [
    {
      query: GET_NEW_USERS,
    },
  ];
  public getRoleColor = (id) => {
    const finded = this.state.rolesColor.find((role) => role.id === id);
    return finded ? finded.color : tagColors[0];
  };

  public renderUsersTableByRoleId = (roleId) => {
    return (
      <Query query={GET_NEW_USERS}>
        {(state) => {
          const loading = state?.loading;
          const error = state?.error;
          const data = state?.data || {};
          if (loading) {
            return <div>Loading</div>;
          }
          if (error) {
            return <div>Error</div>;
          }
          let tableData;
          if (data.getNewUsers) {
            const { getNewUsers } = data;
            tableData = getNewUsers.filter((user) => user.roles.some((role) => role.id === roleId));
            return this.renderTable(tableData, this.dataColumns(this.props));
          }
          return this.renderTable([], this.dataColumns(this.props));
        }}
      </Query>
    );
  };

  public renderRolesTabsWithUsers = () => {
    const { TabPane } = Tabs;
    const { roles } = this.state;
    return (
      <Content className="wrapper">
        <AccessControl userPermissions={this.props.permissions.users} allowedPermissions={Permissions.LISTS.READ_USERS}>
          <Tabs type="card" defaultActiveKey="1">
            {roles.map((role) => (
              <TabPane tab={role.name} key={role.id.toString()}>
                {this.renderUsersTableByRoleId(role.id)}
              </TabPane>
            ))}
          </Tabs>
        </AccessControl>
      </Content>
    );
  };

  public render() {
    const { t } = this.props;
    return (
      <div>
        <TitleSection title={t("sideMenu.USERS")} iconType="team" />
        <Content className="wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard/general-settings">
              <Icon type="left" />
              <span>{t("sideMenu.GENERAL_SETTINGS")}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("sideMenu.USERS")}</Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="wrapper">
          <AccessControl
            userPermissions={this.props.permissions.users}
            allowedPermissions={Permissions.BUTTONS.CREATE_USER}>
            <CreateUser refetch={this.refetch} />
          </AccessControl>
          {this.renderRolesTabsWithUsers()}
        </Content>
      </div>
    );
  }
  private renderTable = (users: IUserData[], columns: ColumnProps<IUserData>[]) => {
    return (
      <Table
        dataSource={users}
        columns={columns}
        rowKey={() => generateKey()}
        size="small"
        pagination={{ defaultPageSize: 50 }}
      />
    );
  };
  private renderUserName = (text, item, index): React.ReactNode => `${item.firstName} ${item.lastName}`;

  private dataColumns = (props): ColumnProps<ITableFields>[] => {
    const { t } = props;

    return [
      {
        title: t("usersTableColumns.NAME"),
        dataIndex: "firstName",
        render: this.renderUserName,
        ...searchFilter(this, "firstName", "searchText", t("projectsTableColumns.users.SEARCH_USER_NAME")),
        key: "name",
        width: 400,
      },
      {
        title: t("usersTableColumns.EMAIL"),
        dataIndex: "email",
        key: "email",
        width: 200,
      },
      {
        title: t("usersTableColumns.ROLES"),
        align: "center",
        render: ({ roles }): any => (
          <div>
            {roles.map(({ name, id }) => (
              <Tag key={id} color={this.getRoleColor(id)} style={{ fontSize: "10pt", textAlign: "center" }}>
                {name.toUpperCase()}
              </Tag>
            ))}
          </div>
        ),
      },
      {
        title: t("usersTableColumns.ACTIONS"),
        align: "center",
        key: "action",
        width: 200,
        render: ({ id, status, firstName, countryId, lastName, roles }): any => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {renderEmployeeFields(mergeRolesPermissions(roles.map(({ permissions }) => permissions))) && (
                <UserUtilizationView userId={id} />
              )}
              <AccessControl
                userPermissions={this.props.permissions.users}
                allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
                <UpdateUser
                  permissions={this.props.permissions.users}
                  userId={id}
                  countryId={countryId}
                  roles={roles}
                  fullName={`${firstName} ${lastName}`}
                  status={status === "active"}
                  refetch={this.refetch}
                />
              </AccessControl>
              {
                <AccessControl
                  userPermissions={this.props.permissions.users}
                  allowedPermissions={Permissions.BUTTONS.DELETE_USER}>
                  <DeactivateUser t={this.props.t} userId={id} refetch={this.refetch} />
                </AccessControl>
              }
            </div>
          );
        },
      },
    ];
  };
}

export default withTranslation()(View);
