import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Divider, Layout, Row, Spin, Tabs } from "antd";
import DocumentCard from "./Components/DocumentCard";
import DocumentTable from "./Components/DocumentTable";
import DocumentActions from "./Components/DocumentActions";
import { IUser } from "screens/Profile/Interfaces";
import { GET_DOCUMENTS } from "graphql/queries/Configurations";
import { useQuery } from "@apollo/react-hooks";
import { documentCategoryFilter } from "./Components/Helpers/documentFilter";
import { IDocumentType } from "./types";

interface IProps {
  userData: IUser;
}

const { Content } = Layout;
const { TabPane } = Tabs;

const Documents: React.FC<IProps> = ({ userData }) => {
  const { t } = useTranslation();
  const [dataDisplayType, setDataDisplayType] = useState<string>(localStorage.getItem("dataDisplayType") || "card");
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [selectFileTypeValue, setSelectFileTypeValue] = useState();

  useEffect(() => {
    localStorage.setItem("dataDisplayType", dataDisplayType);
  }, [dataDisplayType]);

  const userId = userData?.id;
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTS, {
    variables: {
      EmployeeId: userId,
    },
  });
  const documents = data?.getAllDocumentsByEmployee;

  const uniqueCategories = [...new Set(documents?.map((item: IDocumentType) => item.category))];
  const uniqueFileTypes = [...new Set(documents?.map((item: IDocumentType) => item.fileType))];

  const { documentsCategoryCertificates, documentsCategoryContract, documentsCategoryOther } = documentCategoryFilter(
    documents,
    searchValue,
    selectFileTypeValue
  );

  const contractLength = documentsCategoryContract?.length;
  const certificateLength = documentsCategoryCertificates?.length;
  const otherLength = documentsCategoryOther?.length;

  const documentDataMap = {
    "Work contracts": documentsCategoryContract,
    Certificates: documentsCategoryCertificates,
    Other: documentsCategoryOther,
  };

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }

  if (error) {
    return <p>Error</p>;
  }

  const handleDisplayTypeChange = (value: string) => {
    setDataDisplayType(value);
  };

  return (
    <div className="content-wrapper">
      <DocumentActions
        selectFileTypeValue={selectFileTypeValue}
        setSelectFileTypeValue={setSelectFileTypeValue}
        uniqueFileTypes={uniqueFileTypes}
        uniqueCategories={uniqueCategories}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        setSearchValue={setSearchValue}
        userData={userData}
        dataDisplayType={dataDisplayType}
        handleDisplayTypeChange={handleDisplayTypeChange}
        userId={userId}
      />
      <Content>
        {dataDisplayType === "card" ? (
          <>
            <div className="divider-wrapper">
              <div style={{ width: "15%", marginLeft: "-1rem" }}>
                <Divider orientation="left">
                  <h4 className="divider-text">{"Category"}</h4>
                </Divider>
              </div>
              <div style={{ width: "90%" }}>
                <Divider orientation="left">
                  <h4 className="divider-text">{"Documents"}</h4>
                </Divider>
              </div>
            </div>
            <Tabs type="card" tabPosition="left" size="small" defaultActiveKey="Work contract">
              <TabPane
                tab={
                  <span>
                    {"Work contract"}
                    <Badge
                      showZero
                      count={contractLength}
                      style={{ backgroundColor: "#2e9df2" }}
                      className="badge-style"
                    />
                  </span>
                }
                key="Work contract">
                <Row gutter={[16, 16]}>
                  <DocumentCard
                    documents={documentDataMap["Work contracts"]}
                    dataDisplayType={dataDisplayType}
                    userId={userId}
                  />
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    {"Certificates"}
                    <Badge
                      showZero
                      count={certificateLength}
                      style={{ backgroundColor: "#2e9df2" }}
                      className="badge-style"
                    />
                  </span>
                }
                key="Certificates">
                <Row gutter={[32, 32]}>
                  <DocumentCard
                    documents={documentDataMap["Certificates"]}
                    dataDisplayType={dataDisplayType}
                    userId={userId}
                  />
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    {"Other"}
                    <Badge
                      showZero
                      count={otherLength}
                      style={{ backgroundColor: "#2e9df2" }}
                      className="badge-style"
                    />
                  </span>
                }
                key="Other">
                <Row gutter={[32, 32]}>
                  <DocumentCard
                    documents={documentDataMap["Other"]}
                    dataDisplayType={dataDisplayType}
                    userId={userId}
                  />
                </Row>
              </TabPane>
            </Tabs>
          </>
        ) : (
          <DocumentTable
            t={t}
            documents={documents}
            loading={loading}
            error={error}
            dataDisplayType={dataDisplayType}
            searchValue={searchValue}
            selectValue={selectValue}
            selectFileTypeValue={selectFileTypeValue}
            userId={userId}
          />
        )}
      </Content>
    </div>
  );
};

export default Documents;
