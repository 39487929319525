export const aionManagers = (usersObject) => {
  const managerIds = new Set();
  for (let i = 0; i < usersObject?.length; i++) {
    const user = usersObject[i];
    const managerId = user?.employee?.manager ? user?.employee?.manager?.id : null;
    if (managerId) {
      managerIds.add(managerId);
    }
  }
  return Array.from(managerIds);
};

export const aionManagersButNoAdmin = (usersObject, managers) => {
  const adminIds = new Set();
  for (let i = 0; i < usersObject?.length; i++) {
    const user = usersObject[i];
    const userId = user ? user?.id : null;
    const admin = user?.roles?.some((role) => role.name === "Admin");

    if (admin) {
      adminIds.add(userId);
    }
  }
  const admins = Array.from(adminIds);
  const result = managers.filter((element) => !admins.includes(element));

  return result;
};
