import "./styles/main.scss";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { LastLocationProvider } from "react-router-last-location";
import * as serviceWorker from "./registerServiceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales";
import { ApolloProvider } from "@apollo/react-hoc";
import App from "./App";
import client from "./graphql/ApolloClient";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./styles/main.scss";

Sentry.init({
  beforeSend(event) {
    if (process.env.NODE_ENV !== "development") {
      return event;
    }
    return null;
  },
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

declare global {
  // tslint:disable-next-line:interface-name //////////
  interface Window {
    Cypress: any;
    tgHistory: History;
  }
}
if (window.Cypress) {
  const browserHist = createBrowserHistory();
  window.tgHistory = browserHist;
}
ReactDOM.render(
  <GoogleOAuthProvider clientId="283926888098-62inp07jcd7kbd145gq17tjcqad2s8gv.apps.googleusercontent.com">
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <LastLocationProvider>
            <App />
          </LastLocationProvider>
        </Router>
      </I18nextProvider>
    </ApolloProvider>
  </GoogleOAuthProvider>,
  document.getElementById("root") as HTMLElement
);
serviceWorker.unregister();
