import React, { Component } from "react";
import Select, { SelectProps } from "antd/lib/select";
import { Spin, Icon } from "antd";
import { Query } from "@apollo/react-components";
import { EMPLOYEE_GET_PROJECTS_MINIFIED } from "../../../../graphql/queries";
import { TFunction } from "i18next";
import { IProjectTimelog } from "../../Interfaces/tag";

interface IProps extends SelectProps {
  selectProject: (project: IProjectTimelog) => void;
  t: TFunction;
  userId?: number;
}

class SelectProject extends Component<IProps> {
  public handleChange = (value) => {
    this.props.selectProject({ id: value.key, name: value.label });
  };

  public render() {
    const { selectProject, t, ...rest } = this.props;

    return (
      <Query query={EMPLOYEE_GET_PROJECTS_MINIFIED} variables={{ userId: this.props.userId }}>
        {({ loading, error, data }: any) => {
          let projects;
          if (!loading && !!data && data.employee && data.employee.projects) {
            projects = data.employee.projects;
          }
          const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;

          if (!loading && projects.length > 1) {
            return (
              <Select
                {...rest}
                optionFilterProp="children"
                showSearch={true}
                labelInValue={true}
                placeholder={t("placeholders.SELECT_PROJECT")}
                onSelect={this.handleChange}>
                {projects.map(({ id, name }, index) => (
                  <Select.Option value={id} key={id + index}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            );
          } else if (!loading && projects.length === 1) {
            return (
              <Select
                {...rest}
                labelInValue={true}
                placeholder={t("placeholders.LOADING_PROJECTS")}
                onSelect={this.handleChange}>
                {projects.map(({ id, name }, index) => (
                  <Select.Option value={id} key={id + index}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            );
          } else if (loading) {
            return (
              <Select placeholder={t("placeholders.LOADING_PROJECTS")}>
                <Select.Option value="">
                  <Spin size={"small"} indicator={antIcon} className="spin" />
                </Select.Option>
              </Select>
            );
          } else {
            return <Select placeholder={t("placeholders.NO_PROJECT_ASSIGNMENTS")}>{null}</Select>;
          }
        }}
      </Query>
    );
  }
}

export default SelectProject;
