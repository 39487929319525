import React from "react";
import { TFunction } from "i18next";
import HistoricalChart from "./HistoricalChart";
import MonthlyPredictionChart from "./MonthlyPredictionChart";
import PredictionChart from "./PredictionChart";
import { Collapse } from "antd";

interface IProps {
  t: TFunction;
}

const MultipleCharts: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <Collapse expandIconPosition="right" style={{ marginBottom: "10px" }}>
        <Collapse.Panel header={t("analytics.FINANCE_OVERVIEW.HISTORICAL_DATA")} key={1}>
          <HistoricalChart t={t} />
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIconPosition="right" style={{ marginBottom: "10px" }}>
        <Collapse.Panel header={t("analytics.FINANCE_OVERVIEW.MONTHLY_DATA")} key={2}>
          <MonthlyPredictionChart t={t} />
        </Collapse.Panel>
      </Collapse>

      <Collapse expandIconPosition="right" style={{ marginBottom: "10px" }}>
        <Collapse.Panel header={t("analytics.FINANCE_OVERVIEW.PREDICTION_DATA")} key={3}>
          <PredictionChart t={t} />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default MultipleCharts;
