import React, { Fragment, useState } from "react";
import { Button, Table, Tag } from "antd";
import PerformanceReviewCycleDetails from "../PerformanceReviewCycle/PerformanceReviewCycleDetails";
import { useTranslation } from "react-i18next";
import { Query } from "@apollo/react-components";
import { GET_REVIEW_CYCLE_HISTORY } from "../../../../graphql/queries/Configurations/index";
import moment from "moment";
import { reviewCyclesType } from "../../types";

const PerformanceReviewHistory = ({ userData }) => {
  const { t } = useTranslation();

  const userProfileId: number = userData?.id;

  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const handleRowClick = (rowKey: number, rowData: any) => {
    setSelectedRowKey(rowKey);
    setSelectedRowData(rowData);
  };

  const columns = [
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CREATED_AT"),
      dataIndex: "createdAt",
      render: (createdAt) => <span>{moment(createdAt).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEWEE"),
      dataIndex: "Employee",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER"),
      dataIndex: "Manager",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_DATE"),
      dataIndex: "expireDate",
      render: (expireDate) => <span>{moment(expireDate).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_STATUS"),
      dataIndex: "hasExpired",
      render: (hasExpired) => (
        <Tag color={hasExpired ? "red" : "orange"}>{hasExpired ? "Has expired" : "In progress"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_AT"),
      dataIndex: "closedAt",
      render: (closedAt) => <span>{closedAt ? moment(closedAt).format("DD/MM/YYYY") : "----------"}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_STATUS"),
      dataIndex: "closedAt",
      render: (closedAt) => (
        <Tag color={closedAt != null ? "red" : "green"}>{closedAt != null ? "Closed" : "Opened"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEW_STATUS"),
      dataIndex: "peerReviews",
      render: (peerReviews) => (
        <Tag color={peerReviews?.submittedAt ? "green" : "red"}>
          {peerReviews?.submittedAt ? "Submitted" : "Not submitted"}
        </Tag>
      ),
    },
    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row: reviewCyclesType) => (
        <div style={{ display: "flex", gap: 10 }}>
          <Button type="primary" size="small" icon="unordered-list" onClick={() => handleRowClick(row?.id, row)}>
            {"View details"}
          </Button>
        </div>
      ),
    },
  ];

  const handleBackButtonClick = () => {
    setSelectedRowKey(null);
  };

  return (
    <Fragment>
      <Query query={GET_REVIEW_CYCLE_HISTORY} variables={{ userId: userProfileId }}>
        {({ loading, error, data }: any) => {
          let reviewHistory: reviewCyclesType[] = [];

          if (!loading && data?.getReviewCyclesHistory) {
            reviewHistory = data?.getReviewCyclesHistory?.filter((request) => request?.EmployeeId === userProfileId);
          }

          if (error) {
            return <p>Error</p>;
          }

          return (
            <div>
              {!selectedRowKey && (
                <>
                  <Table loading={loading} size="middle" dataSource={reviewHistory} columns={columns} />
                </>
              )}
              {selectedRowKey && (
                <>
                  <Button style={{ marginBottom: 15 }} icon="left" type="default" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <PerformanceReviewCycleDetails selectedReview={selectedRowData} t={t} />
                </>
              )}
            </div>
          );
        }}
      </Query>
    </Fragment>
  );
};
export default PerformanceReviewHistory;
