import React from "react";
import { Collapse, Icon } from "antd";
import { withPermissions } from "decorators/permissions";
import constants from "../../../../constants/index";
import RoleCollapedView from "./roleCollapedView";
interface IRolePanel {
  role: IRole;
  key: number;
  permissions?: any;
}
interface IRole {
  id: number;
  name: string;
}
interface IState {
  permissions: any;
}
@withPermissions([constants.permissionResources.ROLES])
class RolePanel extends React.Component<IRolePanel, IState> {
  constructor(props) {
    super(props);
    this.state = {
      permissions: {} as any,
    };
  }
  public render = () => {
    const genExtra = () => (
      <Icon
        style={{ fontSize: "15pt" }}
        type="idcard"
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    );

    return (
      <Collapse
        expandIconPosition="right"
        style={{ marginBottom: 10 }}
        key={this.props.role.id}
        onChange={(value) => localStorage.setItem("openedPanel", JSON.stringify(value))}>
        <Collapse.Panel header={this.props.role.name} key={this.props.role.id} extra={genExtra()}>
          <RoleCollapedView roleId={this.props.role.id} />
        </Collapse.Panel>
      </Collapse>
    );
  };
}
export default RolePanel;
