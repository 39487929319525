import React, { Component } from "react";
import { Mutation } from "@apollo/react-components";
import { Button, Modal, Form, InputNumber, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { UPDATE_EMPLOYEE_ASSIGNMENT } from "../../../../../../graphql/mutations";
import { withTranslation, WithTranslation } from "react-i18next";

interface IFormProps extends FormComponentProps {
  data: {
    id: number;
    hourlyRate: number;
  };
}
interface IProps extends IFormProps, WithTranslation {}

class EditAssignment extends Component<IProps> {
  public state = {
    modalIsOpen: false,
  };

  public render() {
    const { t } = this.props;
    return (
      <>
        <Button onClick={this.openModal} style={{ marginLeft: "10px" }} icon="edit">
          {t("projectsTableColumns.buttons.EDIT")}
        </Button>
        <Mutation
          mutation={UPDATE_EMPLOYEE_ASSIGNMENT}
          update={() => {
            this.closeModal();
            notification.success({
              message: t("projectsTableColumns.messages.EDIT"),
            });
          }}>
          {(updateAssignment) => (
            <>
              <Modal
                title="Update Employee Assignment"
                visible={this.state.modalIsOpen}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      updateAssignment({
                        variables: { id: this.props.data.id, ...values },
                      });
                    }
                  });
                }}
                onCancel={this.closeModal}>
                <Form>
                  <Form.Item>
                    {this.props.form.getFieldDecorator("hourlyRate", {
                      rules: [{ required: true, message: "Please enter hourlyRate" }],
                      initialValue: this.props.data.hourlyRate,
                    })(<InputNumber placeholder="amount" style={{ width: "100%" }} />)}
                  </Form.Item>
                </Form>
              </Modal>
            </>
          )}
        </Mutation>
      </>
    );
  }

  private openModal = () => this.setState({ modalIsOpen: true });
  private closeModal = () => this.setState({ modalIsOpen: false });

  private onChange = (value) => {
    this.props.form.setFieldsValue({
      hourlyRate: value,
    });
  };
}

export default Form.create<IFormProps>()(withTranslation()(EditAssignment));
