import React from "react";
import { TFunction } from "i18next";
import { CartesianGrid, ComposedChart, Line, Area, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend } from "recharts";
import { Query } from "@apollo/react-components";
import { Skeleton } from "antd";
import { GET_FINANCES } from "graphql/queries/FinanceQueries";
import moment from "moment";
import { roundTwoDecPlaces } from "helpers/round_number";
import { IFinances } from "graphql/queries/Interfaces";

interface IProps {
  t: TFunction;
}

const isTruthy = (value: number | null) => {
  return value || value === 0;
};

const isFalsy = (value: number | null) => {
  return !value || value === 0;
};

const MonthlyPredictionChart: React.FC<IProps> = ({ t }) => {
  const calculateRevenue = (item: IFinances, index: number, array: any) => {
    if (item?.prediction && isFalsy(item?.revenue)) return null;

    if (index === 0) {
      return roundTwoDecPlaces(item?.revenue ?? 0);
    } else {
      return roundTwoDecPlaces(item?.revenue + array[index - 1]?.revenue);
    }
  };

  const calculatePrediction = (item: IFinances, index: number, array: any) => {
    if (isTruthy(item?.prediction)) {
      if (index > 0 && isTruthy(array[index - 1]?.revenue)) {
        return roundTwoDecPlaces(item?.prediction + array[index - 1]?.revenue);
      }

      return roundTwoDecPlaces(item?.prediction + array[index - 1]?.prediction);
    }

    return null;
  };

  return (
    <Query
      query={GET_FINANCES}
      variables={{
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        team: null,
        projects: null,
        grossProfit: false,
      }}>
      {({ loading, error, data }: any) => {
        const mappedFinances: any = [];

        if (!loading && data?.finances) {
          const finances = data.finances.map((item, index, array) =>
            isTruthy(item?.revenue) && !item?.prediction && !array[index + 1]?.revenue && array[index + 1]?.prediction
              ? {
                  ...item,
                  prediction: item.revenue,
                }
              : item
          );

          finances.forEach((item, index) => {
            mappedFinances.push({
              date: moment(item.date).format("DD MMM"),
              revenue: calculateRevenue(item, index, mappedFinances),
              prediction: calculatePrediction(item, index, mappedFinances),
            });
          });
        }

        if (error) {
          return <p>Error</p>;
        }

        if (loading) {
          return <Skeleton active={true} />;
        }

        return (
          <>
            <ResponsiveContainer height={300}>
              <ComposedChart data={mappedFinances}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Legend />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="revenue"
                  name={t("analytics.STATISTICS.REVENUE")}
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  strokeWidth="2"
                />
                <Line
                  type="monotone"
                  dataKey="prediction"
                  stroke="#8884d8"
                  strokeWidth="2"
                  strokeDasharray="5 5"
                  name={t("analytics.STATISTICS.PREDICTION")}
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </>
        );
      }}
    </Query>
  );
};

export default MonthlyPredictionChart;
