import React from "react";
import { withTranslation } from "react-i18next";
import Benchmark from "./Benchmark/Benchmark";
import { Breadcrumb, Button, Icon, Layout, Tabs } from "antd";
import TitleSection from "../../../common/SectionTitle/title";
import CompanyBranches from "./CompanyBranches/CompanyBranches";
import Milestones from "./Milestones/Milestones";
import CompanyInformation from "./CompanyInformation/CompanyInformation";
import AbsenceTypes from "./AbsenceTypes/AbsenceTypes";
import HolidayTypes from "./HolidayTypes/HolidayTypes";

const View = ({ t }) => {
  const { TabPane } = Tabs;
  const { Content } = Layout;

  return (
    <>
      <TitleSection title={t("sideMenu.MANAGMENT.MODULES_CONFIGURATION")} iconType="setting" />
      <Content className="wrapper">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard/general-settings">
            <Icon type="left" />
            <span>{t("sideMenu.GENERAL_SETTINGS")}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("sideMenu.MANAGMENT.MODULES_CONFIGURATION")}</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <Content className="wrapper">
        <Tabs type="card" defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <Icon type="shop" />
                {t("appConfiguration.COMPANY_INFO.TITLE")}
              </span>
            }
            key="1">
            <CompanyInformation t={t} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="branches" />
                {t("appConfiguration.COMPANY_BRANCHES.TITLE")}
              </span>
            }
            key="2">
            <CompanyBranches t={t} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="schedule" />
                {t("appConfiguration.ABSENCE_TYPES.TITLE")}
              </span>
            }
            key="3">
            <AbsenceTypes t={t} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="calendar" />
                {t("appConfiguration.HOLIDAY_TYPES.TITLE")}
              </span>
            }
            key="4">
            <HolidayTypes t={t} />
          </TabPane>
          {/* <TabPane tab={t("appConfiguration.BENCHMARK.TITLE")} key="5">
            <Benchmark t={t} />
          </TabPane>
          <TabPane tab={t("appConfiguration.MILESTONES.TITLE")} key="6">
            <Milestones t={t} />
          </TabPane> */}
        </Tabs>
      </Content>
    </>
  );
};

export default withTranslation()(View);
