import React, { PureComponent } from "react";
import { Query } from "@apollo/react-components";
import { GET_MANAGERS } from "../../../graphql/queries";
import Select, { SelectProps } from "antd/lib/select";
import { Spin, Icon } from "antd";
import { TFunction } from "i18next";

interface IProps extends SelectProps {
  t: TFunction;
}

class SelectManager extends PureComponent<IProps> {
  public render() {
    const { ...rest } = this.props;
    return (
      <Query query={GET_MANAGERS}>
        {(state) => {
          const loading = state.loading;
          const data = state?.data;
          const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;
          if (!loading && !!data && data.possibleHolidaysManagers) {
            const managersData = data.possibleHolidaysManagers;

            return (
              <>
                <Select
                  {...rest}
                  showSearch={true}
                  optionFilterProp="children"
                  placeholder={this.props.t("placeholders.SELECT_MANAGER")}
                  defaultActiveFirstOption={false}>
                  {managersData.map(({ name, userId }) => {
                    return (
                      <Select.Option key={userId} value={userId}>
                        {name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </>
            );
          } else {
            return (
              <>
                <Select placeholder={this.props.t("placeholders.LOADING_MANAGERS")} style={{ width: "200px" }}>
                  <Select.Option value="">
                    <Spin size={"small"} indicator={antIcon} className="spin" />
                  </Select.Option>
                </Select>
              </>
            );
          }
        }}
      </Query>
    );
  }
}

export default SelectManager;
