import React from "react";
import { Query } from "@apollo/react-components";
import { GET_USER_PROFILE } from "../../../graphql/queries";
import "antd/dist/antd.css";
import { Menu, Icon, Avatar, Spin, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { WithApolloClient } from "@apollo/react-hoc";
import { RouteComponentProps } from "react-router";
import { hrImg, managerImg } from "helpers/appImages";

class ProfileDropdown extends React.Component<WithApolloClient<any> & RouteComponentProps> {
  public handleLogout = async () => {
    localStorage.clear();
    this.props.History.push("/login");
    await this.props.Client.cache.reset();
  };

  public render() {
    const menu = (
      <Menu className="dropdown-menu">
        <Menu.Item key="0" className="menu-item">
          <Link to={"/dashboard/profile"}>
            <Icon type="user" style={{ paddingRight: "7px" }} />
            Profile
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1" onClick={this.handleLogout} className="logout-handler">
          <Icon type="logout" style={{ paddingRight: "2px" }} />
          Logout
        </Menu.Item>
      </Menu>
    );
    return (
      <Query query={GET_USER_PROFILE}>
        {(state) => {
          const data = state.data;
          const error = state.error;
          const loading = state.loading;
          const User = data && data.User;
          const Male = User?.gender === "Male";
          const Gender = User?.gender;

          if (loading) {
            return <Spin />;
          }
          if (error) {
            return <h1>ERROR</h1>;
          }

          if (!loading) {
            return (
              <div style={{ display: "flex" }}>
                <Dropdown
                  overlay={menu}
                  overlayClassName="overlay"
                  trigger={["hover"]}
                  placement="bottomRight"
                  className="user-name-dropdown">
                  <div className="dropdown-div">
                    <Avatar
                      style={{
                        marginRight: "5px",
                        verticalAlign: "middle",
                        backgroundColor: "#f0f5ff",
                      }}
                      src={Male || !Gender ? managerImg : hrImg}
                      size="default"
                    />

                    {User && `${User.firstName} ${User.lastName}`}
                  </div>
                </Dropdown>
              </div>
            );
          }
          return null;
        }}
      </Query>
    );
  }
}

export default ProfileDropdown;
