import React, { useEffect } from "react";
import { Button, Icon, Popconfirm, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { TFunction } from "i18next";
import { DELETE_DOCUMENT } from "graphql/mutations";
import { GET_DOCUMENTS } from "graphql/queries/Configurations";
import { IDocumentType } from "../types";

interface IProps extends FormComponentProps {
  t: TFunction;
  document: IDocumentType;
  dataDisplayType: string;
  userId: number;
}

const DocumentDelete: React.FC<IProps> = ({ t, document, dataDisplayType, userId }) => {
  return (
    <Mutation
      mutation={DELETE_DOCUMENT}
      refetchQueries={[
        {
          query: GET_DOCUMENTS,
          variables: { EmployeeId: userId },
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.DOCUMENTS.MESSAGES.DOCUMENT_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteDocument, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteDocument({ variables: { documentId: document?.id } })}>
          {dataDisplayType === "card" ? (
            <Icon type="delete" key="delete" style={{ color: "red" }} />
          ) : (
            <Button loading={loading} className="button-delete" type="danger" size="small" icon="delete" />
          )}
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(DocumentDelete);
