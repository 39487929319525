import React from "react";
import { Route, Switch, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import Login from "../screens/Login";
import LoginGoogle from "../screens/LoginGoogle";
import ResetPasswordFrom from "../screens/Login/ForgotPassword/resetPasswordForm";
import Dashboard from "../screens/Dashboard";
import PageNotFound from "../screens/404";
import PrivateRoutes from "./privateRoutes";

const Routes = (props: RouteComponentProps) => {
  return (
    <Switch>
      <Route path="/login/reset-password/:token" component={ResetPasswordFrom} />
      <Route exact={true} path="/">
        <Redirect from="/" to="/login" />
      </Route>
      <PrivateRoutes
        isAuthenticated={!!localStorage.getItem("token") ? true : false}
        path="/login"
        redirectTo="/dashboard/home"
        component={LoginGoogle}
        exact={true}
      />
      <PrivateRoutes
        isAuthenticated={!!localStorage.getItem("token") ? true : false}
        path="/login-old"
        redirectTo="/dashboard/home"
        component={Login}
        exact={true}
      />
      <PrivateRoutes
        isAuthenticated={!localStorage.getItem("token")}
        redirectTo="/login"
        path="/dashboard"
        component={Dashboard}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default withRouter(Routes);
