import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Empty, Icon, Tag, Tooltip } from "antd";
import { fileTypeImage } from "./Helpers/fileTypeImage";
import { IDocumentType } from "../types";
import moment from "moment";
import DocumentEdit from "./DocumentEdit";
import DocumentDelete from "./DocumentDelete";

const { Meta } = Card;

interface IProps {
  documents: IDocumentType[];
  dataDisplayType: string;
  userId: number;
}

const DocumentCard: React.FC<IProps> = ({ documents, dataDisplayType, userId }) => {
  const { t } = useTranslation();
  const [visibleCardCount, setVisibleCardCount] = useState(12);
  const [spanValue, setSpanValue] = useState(6); // Default span value

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 2000) {
        // Adjust this value based on your design
        setSpanValue(3); // Set span to 3 for smaller screens
      } else {
        setSpanValue(6); // Set span to 6 for larger screens
      }
    };

    handleResize(); // Initial call to set span value based on current screen width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoadMore = () => {
    setVisibleCardCount((prevCount) => prevCount + 12);
  };

  if (!documents || documents.length === 0) {
    return (
      <div style={{ height: "30vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Empty />
      </div>
    );
  }

  return (
    <div>
      {documents.slice(0, visibleCardCount).map((docs: IDocumentType, index: number) => {
        const filePath = docs?.filePath;
        const fileType = docs?.fileType;
        const fileName = docs?.fileName;
        const fileSize = docs?.fileSize;
        const mbValue = fileSize / (1024 * 1024);
        const roundedMBValue = mbValue.toFixed(2);
        const createdAt = docs?.createdAt;
        const updatedAt = docs?.updatedAt;
        const isUpdated = !moment(createdAt).isSame(updatedAt, "minutes");

        return (
          <Col span={spanValue}>
            <Card
              key={index} // Add a unique key to each card
              hoverable
              className="card-style"
              cover={
                <span className="image-wrapper">
                  <img className="image-card" alt="example" src={fileTypeImage(fileType)} />
                </span>
              }
              actions={[
                <Icon type="download" key="cloud-download" onClick={() => window.open(filePath as string, "_blank")} />,
                <DocumentEdit document={docs} t={t} dataDisplayType={dataDisplayType} userId={userId} />,
                <DocumentDelete document={docs} t={t} dataDisplayType={dataDisplayType} userId={userId} />,
              ]}>
              <Tooltip placement="topLeft" title={fileName}>
                <Meta
                  style={{ fontSize: 10 }}
                  title={fileName}
                  description={`Size: ${roundedMBValue}/MB  | Type: ${fileType.toUpperCase()}`}
                />
              </Tooltip>
            </Card>
          </Col>
        );
      })}
      {visibleCardCount < documents.length && (
        <span
          style={{ width: "100%", height: "10vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button style={{ width: "15%" }} type="ghost" block onClick={handleLoadMore}>
            Load more
          </Button>
        </span>
      )}
    </div>
  );
};

export default DocumentCard;
