import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Button, Form, Col, Modal, Row, Select, Icon, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { fileCategory } from "./Helpers/fileCategory";
import { UPDATE_DOCUMENT } from "graphql/mutations";
import { GET_DOCUMENTS } from "graphql/queries/Configurations";
import { IDocumentType } from "../types";

interface IProps extends FormComponentProps {
  t: TFunction;
  document: IDocumentType;
  dataDisplayType: string;
  userId: number;
}
const { Option } = Select;

const DocumentEdit: React.FC<IProps> = ({ document, t, dataDisplayType, userId, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const documentId = document?.id;
  const category = document?.category;
  const fileName = document?.fileName;

  return (
    <Fragment>
      {dataDisplayType === "card" ? (
        <Icon type="edit" key="edit" onClick={toggleModal} />
      ) : (
        <Button size="small" type="primary" icon="edit" onClick={toggleModal} />
      )}
      <Mutation
        mutation={UPDATE_DOCUMENT}
        refetchQueries={[
          {
            query: GET_DOCUMENTS,
            variables: { EmployeeId: userId },
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.DOCUMENTS.MESSAGES.DOCUMENT_MODIFIED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(updateDocument, { loading }) => (
          <Modal
            title={`${t("appConfiguration.DOCUMENTS.EDIT")} - ${fileName} `}
            width={400}
            centered={true}
            okButtonProps={{
              loading: loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { Category } = values;

                  updateDocument({
                    variables: {
                      documentId: documentId,
                      category: Category,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.DOCUMENTS.LABELS.CATEGORY")}>
                    {form.getFieldDecorator("Category", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      initialValue: category,
                    })(
                      <Select showSearch allowClear>
                        {fileCategory.category.map((categoryName) => (
                          <Option key={categoryName} value={categoryName}>
                            {categoryName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(DocumentEdit);
