import React from "react";
import { Button, Popconfirm, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { TFunction } from "i18next";
import { DELETE_HOLIDAY_TYPE } from "graphql/mutations";
import { GET_HOLIDAY_TYPES } from "graphql/queries/Configurations";

interface IProps extends FormComponentProps {
  t: TFunction;
  holidayType: any;
}

const HolidayTypesDelete: React.FC<IProps> = ({ t, holidayType }) => {
  return (
    <Mutation
      mutation={DELETE_HOLIDAY_TYPE}
      refetchQueries={[
        {
          query: GET_HOLIDAY_TYPES,
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.HOLIDAY_TYPE_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteHolidayType, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteHolidayType({ variables: { id: holidayType.id } })}>
          <Button type="danger" className="mr-10" loading={loading} icon="delete" />
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(HolidayTypesDelete);
