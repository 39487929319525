import { IDocumentType } from "screens/Documents/types";

const filteredValuesForCards = (documentCategory: any, searchValue: string, selectFileTypeValue: any) => {
  if (searchValue && selectFileTypeValue) {
    return documentCategory
      ?.filter((doc: IDocumentType) => doc?.fileName.toLowerCase().includes(searchValue.toLowerCase()))
      ?.filter((doc: IDocumentType) => doc?.fileType.toLowerCase().includes(selectFileTypeValue.toLowerCase()));
  } else if (searchValue) {
    return documentCategory?.filter((doc: IDocumentType) =>
      doc?.fileName.toLowerCase().includes(searchValue.toLowerCase())
    );
  } else if (selectFileTypeValue) {
    return documentCategory?.filter((doc: IDocumentType) =>
      doc?.fileType.toLowerCase().includes(selectFileTypeValue.toLowerCase())
    );
  }
  return documentCategory;
};

export const documentCategoryFilter = (documents: any, searchValue: string, selectFileTypeValue: any) => {
  let documentsCategoryContract =
    documents?.filter((document: IDocumentType) => document?.category === "Work contracts") || [];

  let documentsCategoryCertificates =
    documents?.filter((document: IDocumentType) => document?.category === "Certificates") || [];

  let documentsCategoryOther = documents?.filter((document: IDocumentType) => document?.category === "Other") || [];

  documentsCategoryContract = filteredValuesForCards(documentsCategoryContract, searchValue, selectFileTypeValue);

  documentsCategoryCertificates = filteredValuesForCards(
    documentsCategoryCertificates,
    searchValue,
    selectFileTypeValue
  );
  documentsCategoryOther = filteredValuesForCards(documentsCategoryOther, searchValue, selectFileTypeValue);

  return {
    documentsCategoryContract,
    documentsCategoryCertificates,
    documentsCategoryOther,
  };
};

export const documenTableFilter = (
  documents: any,
  searchValue: string,
  selectValue: string,
  selectFileTypeValue: string
) => {
  if (searchValue && selectValue && selectFileTypeValue)
    return documents
      ?.filter((doc: IDocumentType) => doc?.fileName.toLowerCase().includes(searchValue.toLowerCase()))
      ?.filter((doc: IDocumentType) => doc?.category.includes(selectValue))
      ?.filter((doc: IDocumentType) => doc?.fileType.toLowerCase().includes(selectFileTypeValue.toLowerCase()));
  else if (searchValue)
    return documents?.filter((doc: IDocumentType) => doc?.fileName.toLowerCase().includes(searchValue.toLowerCase()));
  else if (selectValue)
    return documents?.filter((doc: IDocumentType) => doc?.category.toLowerCase().includes(selectValue.toLowerCase()));
  else if (selectFileTypeValue)
    return documents?.filter((doc: IDocumentType) =>
      doc?.fileType.toLowerCase().includes(selectFileTypeValue.toLowerCase())
    );
  return documents;
};
