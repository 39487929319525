import React from "react";
import "antd/dist/antd.css";
import { Col, Form, Row, Select, Skeleton } from "antd";
import { FormComponentProps } from "antd/lib/form";
import DateRangePicker from "../../../common/Form/DateRangePicker";
import { TFunction } from "i18next";
import { Query } from "@apollo/react-components";
import { GET_REVENUE_FILTERS } from "../../../graphql/queries";
import moment from "moment";

interface IProps extends FormComponentProps {
  t: TFunction;
  elementsChange: (prop: any, items: any) => void;
  revenueChange: (revenue: any) => void;
  dateChange: (startDate: any, endDate: any, monthRange: boolean, isMonth: boolean) => void;
}

const DATE_FORMAT = "YYYY-MM-DD";

const FilterUtilization: React.FC<IProps> = ({ t, form, dateChange, elementsChange, revenueChange }) => {
  const { Option } = Select;

  const rangeIsWithinMonth = (startDate: moment.Moment, endDate: moment.Moment) => {
    return endDate.diff(startDate, "days") + 1 <= 31;
  };

  const dateRangeIsMonth = (startDate: moment.Moment, endDate: moment.Moment) => {
    return !(
      startDate.year() !== endDate.year() ||
      startDate.month() !== endDate.month() ||
      endDate.date() - startDate.date() + 1 !== startDate.daysInMonth()
    );
  };

  const changeHandler = (dates) => {
    if (dates.length > 0) {
      const startDate: moment.Moment = dates[0];
      const endDate: moment.Moment = dates[1];

      dateChange(
        startDate.format(DATE_FORMAT),
        endDate.format(DATE_FORMAT),
        rangeIsWithinMonth(startDate, endDate),
        dateRangeIsMonth(startDate, endDate)
      );
    }
  };

  const revenueChangeHandler = (revenue) => {
    if (revenue === "gross") {
      revenueChange(true);
    } else {
      revenueChange(false);
    }
  };

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Query query={GET_REVENUE_FILTERS}>
            {({ loading, error, data }: any) => {
              let filters;

              if (!!data && !loading && data.getReportFilters) {
                filters = data.getReportFilters;
              }

              if (loading) {
                return (
                  <div className="mlr8">
                    <Skeleton active={true} />
                  </div>
                );
              }

              if (error) {
                return <p>Error</p>;
              }

              return (
                <React.Fragment>
                  <Col span={6}>
                    <DateRangePicker change={changeHandler} t={t} />
                  </Col>
                  {filters && !!filters.projects && (
                    <Col span={6}>
                      <Form.Item label={t("reports.PROJECTS_REPORT")} hasFeedback={true}>
                        {form.getFieldDecorator("projects")(
                          <Select
                            mode="multiple"
                            onChange={(e) => elementsChange("projects", e)}
                            loading={loading}
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder={t("reports.PLACEHOLDERS.SELECT_PROJECTS")}>
                            {filters.projects.map((project) => {
                              return <Option key={project.id}>{project.name}</Option>;
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {filters && !!filters.team && (
                    <Col span={6}>
                      <Form.Item label={t("reports.TEAM_REPORT")} hasFeedback={true}>
                        {form.getFieldDecorator("team")(
                          <Select
                            mode="multiple"
                            onChange={(e) => elementsChange("team", e)}
                            loading={loading}
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder={t("reports.PLACEHOLDERS.SELECT_MEMBERS")}>
                            {filters.team.map((member) => {
                              return <Option key={member.id}>{member.name}</Option>;
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={6}>
                    <Form.Item label={t("analytics.REVENUE.TITLE")}>
                      {form.getFieldDecorator("revenue", {
                        initialValue: "net",
                      })(
                        <Select
                          onChange={revenueChangeHandler}
                          style={{ width: "100%" }}
                          placeholder={t("analytics.REVENUE.SELECT_REVENUE")}>
                          <Option key="net">{t("analytics.REVENUE.GROSS")}</Option>
                          <Option key="gross">{t("analytics.REVENUE.NET")}</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </React.Fragment>
              );
            }}
          </Query>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create<IProps>()(FilterUtilization);
