import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Icon, Input, Table, Tag } from "antd";
import { Query } from "@apollo/react-components";
import { GET_COMPANY_BRANCH } from "../../../../../graphql/queries/Configurations";
import CompanyBranchesDelete from "./CompanyBranchesDelete";
import CompanyBrenchesDetail from "./CompanyBrenchesDetail";
import CompanyBranchesEdit from "./CompanyBranchesEdit";
interface IProps {
  t: TFunction;
}

const CompanyBranchesTable: React.FC<IProps> = ({ t }) => {
  const [filters, setFilters] = useState({});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilters({ ...filters, [dataIndex]: selectedKeys[0] });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setFilters({ ...filters, [dataIndex]: "" });
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a onClick={() => handleReset(clearFilters, dataIndex)}>Reset</a>
          <a onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>Search</a>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (filtered ? <Icon type="file-search" /> : <Icon type="search" />),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.COUNTRY"),
      dataIndex: "country",
      ...getColumnSearchProps("country"),
    },
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.CITY"),
      dataIndex: "city",
      ...getColumnSearchProps("city"),
    },
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.IBAN"),
      dataIndex: "iban",
    },
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.TAX_ID"),
      dataIndex: "nipt",
    },
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.ACCOUNT_NUMBER"),
      dataIndex: "accountNumber",
      ...getColumnSearchProps("accountNumber"),
    },
    {
      title: t("appConfiguration.COMPANY_BRANCHES.LABELS.OFFICE_TYPE"),
      dataIndex: "virtual",
      render: (virtual) => (
        <Tag color={virtual ? "blue" : "purple"}>
          {virtual
            ? t("appConfiguration.COMPANY_BRANCHES.LABELS.VIRTUAL_OFFICE")
            : t("appConfiguration.COMPANY_BRANCHES.LABELS.PHYSICAL_OFFICE")}
        </Tag>
      ),
      filters: [
        { text: "Virtual office", value: "virtual" },
        { text: "Physical office", value: "physical" },
      ],
      onFilter: (value, record) => record.virtual === (value === "virtual"),
    },

    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row) => (
        <div style={{ display: "flex" }}>
          <CompanyBranchesDelete t={t} branchType={row} />
          <CompanyBranchesEdit t={t} branchType={row} />
          <CompanyBrenchesDetail t={t} branchType={row} />
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Query query={GET_COMPANY_BRANCH}>
        {({ loading, error, data }: any) => {
          let companyBranches;

          if (!loading && data?.getCompanyBranches) {
            companyBranches = data.getCompanyBranches;
          }

          if (error) {
            return <p>Error</p>;
          }
          return (
            <Table
              size="small"
              columns={columns}
              bordered={true}
              rowKey="id"
              loading={loading}
              dataSource={companyBranches}
            />
          );
        }}
      </Query>
    </Fragment>
  );
};

export default CompanyBranchesTable;
