import { ATTACH_DOCTOR_REPORT } from "../../../../graphql/mutations";
import { Upload, notification, Button, Icon } from "antd";
import { Mutation } from "@apollo/react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_LEAVE_REQUEST_DETAILS } from "../../../../graphql/queries/LeaveManagementQueries";
interface IProps {
  id: number;
  mutation?: any;
}

const UploadFile: React.FC<IProps> = ({ id, mutation }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any>([]);
  const { t } = useTranslation();
  const handleUpload = async (attachDoctorReport, leaveRequestId) => {
    setUploading(true);

    try {
      const variables = mutation
        ? {
            contractId: leaveRequestId,
            contractPdf: fileList[0],
          }
        : {
            doctorReport: fileList[0],
            leaveRequestId,
          };

      const response = await attachDoctorReport({
        variables,
      });
      if (!(response && response.data)) {
        return;
      }
      const parsedResponse = JSON.parse(!mutation ? response.data.attachDoctorReport : response.data.url);

      setUploading(false);
      setFileList(parsedResponse);
    } catch (err) {
      setUploading(false);
      setFileList([]);
    }
  };

  const onChange = ({ file, fileList }) => {
    if (fileList.length > 0) return;

    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    setFileList([file]);

    const reader = new FileReader();
    reader.readAsText(file);
    return true;
  };

  const uploadProps = {
    beforeUpload,
    fileList,
    loading: { uploading },
    multiple: false,
    onChange,
  };

  return (
    <>
      <Mutation
        onCompleted={() =>
          notification.success({
            message: t("messages.DOCUMENT_UPLOADED"),
          })
        }
        refetchQueries={!mutation ? [{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }] : undefined}
        mutation={!mutation ? ATTACH_DOCTOR_REPORT : mutation}>
        {(attachDoctorReport) => (
          <Upload
            {...uploadProps}
            customRequest={() => {
              handleUpload(attachDoctorReport, id);
            }}>
            <Button>
              <Icon type="upload" /> Upload
            </Button>
          </Upload>
        )}
      </Mutation>
    </>
  );
};

export default UploadFile;
