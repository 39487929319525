import { ApolloError } from "apollo-boost";
import { notification } from "antd";
import { GraphQLError } from "graphql";

const renderNotification = (
  errors: ApolloError | readonly GraphQLError[] | undefined,
  data: any,
  type: string,
  title: string
): void => {
  if (errors) {
    notification.error({
      duration: 5,
      message: "Sorry your session has expired, you need to login again.",
    });
  }
  if (data && data[type]) {
    notification.success({
      duration: 3,
      message: `Congrats you added a ${title}`,
    });
  }
};
export default renderNotification;
