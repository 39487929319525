import React, { Fragment, useEffect, useState } from "react";
import { Descriptions, Icon, Tag } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { TFunction } from "i18next";
import moment from "moment";
import { IUser } from "screens/Profile/Interfaces";
import { Link } from "react-router-dom";

interface IProps extends FormComponentProps {
  t: TFunction;
  userData: IUser;
}

const PublicProfileInfo: React.FC<IProps> = ({ userData, t }) => {
  return (
    <div style={{ padding: "20px 20px" }}>
      <Descriptions title="Main details" bordered size="small">
        <Descriptions.Item span={1} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.FIRST_NAME")}>
          {userData?.firstName ? userData?.firstName : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={2} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.LAST_NAME")}>
          {userData?.lastName ? userData?.lastName : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.WORK_EMAIL")}>
          {userData?.email ? userData?.email : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.PHONE_NUMBER")}>
          {userData?.employee?.phoneNumber ? userData?.employee?.phoneNumber : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.BIRTHDAY")}>
          {userData?.birthday ? moment(userData?.birthday).format("DD/MM/YYYY") : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={1} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.GENDER")}>
          {userData?.gender ? userData?.gender : "-------"}
        </Descriptions.Item>
        <Descriptions.Item span={3} label={t("appConfiguration.PUBLIC_PROFILE.LABELS.ADDRESS")}>
          {userData?.employee?.address ? userData?.employee?.address : "-------"}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions bordered title="Other details" size={"small"}>
        <Descriptions.Item label={t("appConfiguration.PUBLIC_PROFILE.LABELS.MANAGER")}>
          {
            <Tag color="green">
              {userData?.employee?.manager?.firstName && userData?.employee?.manager?.lastName
                ? userData?.employee?.manager?.firstName + " " + userData?.employee?.manager?.lastName
                : "-------"}
            </Tag>
          }
        </Descriptions.Item>
        <Descriptions.Item label={t("appConfiguration.PUBLIC_PROFILE.LABELS.ROLE")}>
          {<Tag color="blue">{userData?.roles?.[0]?.name ? userData?.roles?.[0].name : "-------"}</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label={t("appConfiguration.PUBLIC_PROFILE.LABELS.COMPANY_BRANCH")}>
          {userData?.employee?.companyBranch?.country && userData?.employee?.companyBranch?.city
            ? userData?.employee?.companyBranch?.country + " /  " + userData?.employee?.companyBranch?.city
            : "-------"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default Form.create<IProps>()(PublicProfileInfo);
