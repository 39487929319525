import * as constant from "../constants/leaveRequestStatus";
import { ILeaveRequestStatus } from "../screens/LeaveManagement/Interfaces";
import { TFunction } from "i18next";

export const leaveRequestStatus = (
  employeeStatus: string,
  deputyStatus: string,
  managerStatus: string,
  t: TFunction
): ILeaveRequestStatus => {
  if (employeeStatus === constant.statusInactive)
    return {
      message: t("leaveRequests.MESSAGES.AWAITING_EMPLOYEE_CONFIRMATION"),
      color: "grey",
    };

  if (
    employeeStatus === constant.statusActive &&
    deputyStatus === constant.statusPending &&
    managerStatus === constant.statusPending
  )
    return {
      message: t("leaveRequests.MESSAGES.AWAITING_DEPUTY_CONFIRMATION"),
      color: "#f5222d",
    };

  if (
    employeeStatus === constant.statusActive &&
    deputyStatus === constant.statusApproved &&
    managerStatus === constant.statusPending
  )
    return {
      message: t("leaveRequests.MESSAGES.AWAITING_MANAGER_CONFIRMATION"),
      color: "#2f54eb",
    };

  if (
    employeeStatus === constant.statusActive &&
    deputyStatus === constant.statusApproved &&
    managerStatus === constant.statusApproved
  )
    return {
      message: t("leaveRequests.MESSAGES.REQUEST_APPROVED_MANAGER_DEPUTY"),
      color: "#52c41a",
    };

  return {
    message: t("leaveRequests.MESSAGES.REQUEST_CANCELLED_DECLINED"),
    color: "#faad14",
  };
};
