export const categoryColor = (category: string) => {
  if (category === "Work contracts") {
    return "purple";
  } else if (category === "Certificates") {
    return "geekblue";
  } else if (category === "Other") {
    return "blue";
  }
  return undefined;
};
