import ApolloClient from "apollo-client";
import renderNotification from "../common/Notification";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getRoute } from "../expiredRoute";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";

const getToken = () => {
  return localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : "";
};

const link = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getToken(),
    },
  };
});

export const getApolloClient = () => client;

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (graphQLErrors.length > 0) {
          const errorCode = graphQLErrors[0].extensions!.code;
          if (errorCode === "UNAUTHENTICATED") {
            if (getRoute()) {
              getRoute().replace({ pathname: "/login" });
              getRoute().replace({ pathname: "/home" });
            }
          } else if (errorCode === "Unauthorized") {
            localStorage.clear();
            getRoute().replace({ pathname: "/login" });
          }
        } else {
          renderNotification(graphQLErrors, {}, "", "");
        }
        // TODO - Handle graphql error
        if (process.env.NODE_ENV === "development") {
          // tslint:disable-next-line:no-console
          console.log(graphQLErrors);
        }
      }

      if (networkError) {
        renderNotification(graphQLErrors, {}, "", "");
        // TODO - Handle network error
        if (process.env.NODE_ENV === "development") {
          // tslint:disable-next-line:no-console
          console.log(networkError);
        }
      }
    }),
    authLink,
    link,
  ]),
  cache: new InMemoryCache(),
});

export default client;
