import React from "react";
import "antd/dist/antd.css";
import { Checkbox, Col, Form, Row, Select, Skeleton } from "antd";
import { TFunction } from "i18next";
import { FormComponentProps } from "antd/lib/form";
import { GET_UTILIZATION_FILTERS } from "../../../graphql/queries";
import { Query } from "@apollo/react-components";
import DateRangePicker from "../../../common/Form/DateRangePicker";
import moment from "moment";

interface IProps extends FormComponentProps {
  t: TFunction;
  checked: boolean;
  teamChange: (items: any) => void;
  dateChange: (startDate: any, endDate: any, benchmark: boolean) => void;
  metricChange: (value: any) => void;
  holidaysChange: (value: boolean) => void;
}

const DATE_FORMAT = "YYYY-MM-DD";

const FilterUtilization: React.FC<IProps> = ({
  t,
  form,
  teamChange,
  dateChange,
  metricChange,
  holidaysChange,
  checked,
}) => {
  const { Option } = Select;

  const dateRangeIsMonth = (startDate: moment.Moment, endDate: moment.Moment) => {
    return !(
      startDate.year() !== endDate.year() ||
      startDate.month() !== endDate.month() ||
      endDate.date() - startDate.date() + 1 !== startDate.daysInMonth()
    );
  };

  const changeHandler = (dates) => {
    if (dates.length > 0) {
      const startDate: moment.Moment = dates[0];
      const endDate: moment.Moment = dates[1];

      dateChange(startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT), dateRangeIsMonth(startDate, endDate));
    }
  };

  const hideHolidaysHandler = (e) => {
    holidaysChange(e.target.checked);
  };

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Query query={GET_UTILIZATION_FILTERS}>
            {(state) => {
              const error = state?.error;
              const loading = state.loading;
              const data = state?.data;
              let filters;

              if (!!data && !loading && data.getReportFilters) {
                filters = data.getReportFilters;
              }

              if (loading) {
                return (
                  <div className="mlr8">
                    <Skeleton active={true} />
                  </div>
                );
              }

              if (error) {
                return <p>Error</p>;
              }

              return (
                <React.Fragment>
                  <Col span={6}>
                    <DateRangePicker change={changeHandler} t={t} />
                  </Col>
                  {filters && !!filters.team && (
                    <Col span={4}>
                      <Form.Item label={t("reports.TEAM_REPORT")} hasFeedback={true}>
                        {form.getFieldDecorator("utilizationMembers")(
                          <Select
                            mode="multiple"
                            onChange={(e) => teamChange(e)}
                            loading={loading}
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            placeholder={t("reports.PLACEHOLDERS.SELECT_MEMBERS")}>
                            {filters.team.map((member) => {
                              return <Option key={member.id}>{member.name}</Option>;
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={4}>
                    <Form.Item label={t("analytics.UTILIZATION.METRIC")} hasFeedback={true}>
                      {form.getFieldDecorator("utilizationMetric", {
                        initialValue: "true",
                      })(
                        <Select
                          onChange={(e) => metricChange(e)}
                          style={{ width: "100%" }}
                          placeholder={t("analytics.UTILIZATION.SELECT_METRIC")}>
                          <Option key="true">{t("timelogs.CREATE_TAG.HOURS")}</Option>
                          <Option key="false">%</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={10} className="mt-32">
                    <Checkbox checked={checked} onChange={hideHolidaysHandler}>
                      {t("utilization.SHOW_WORKING_DAYS")}
                    </Checkbox>
                  </Col>
                </React.Fragment>
              );
            }}
          </Query>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create<IProps>()(FilterUtilization);
