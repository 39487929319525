import React from "react";
import { TFunction } from "i18next";
import AbsenceTypesTable from "./components/AbsenceTypesTable";
import AbsenceTypesCreate from "./components/AbsenceTypesCreate";

interface IProps {
  t: TFunction;
}

const Tags: React.FC<IProps> = ({ t }) => {
  return (
    <div>
      <AbsenceTypesCreate t={t} />
      <AbsenceTypesTable t={t} />
    </div>
  );
};

export default Tags;
