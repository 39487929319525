import React from "react";
import { Button, Popconfirm, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { TFunction } from "i18next";
import { DELETE_ABSENCE_TYPE } from "graphql/mutations";
import { GET_ABSENCE_TYPES } from "graphql/queries/Configurations";

interface IProps extends FormComponentProps {
  t: TFunction;
  absenceType: any;
}

const AbsenceTypesDelete: React.FC<IProps> = ({ t, absenceType }) => {
  return (
    <Mutation
      mutation={DELETE_ABSENCE_TYPE}
      refetchQueries={[
        {
          query: GET_ABSENCE_TYPES,
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.ABSENCE_TYPE_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteAbsenceType, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteAbsenceType({ variables: { id: absenceType.id } })}>
          <Button type="danger" className="mr-10" loading={loading} icon="delete" />
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(AbsenceTypesDelete);
