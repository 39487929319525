import React from "react";
import { Mutation } from "@apollo/react-components";
import { Button, Col, DatePicker, Form, Modal, notification } from "antd";
import { EDIT_INVOICE } from "graphql/mutations";
import { TFunction } from "i18next";
import { useState } from "react";
import moment from "moment";
import { Row } from "antd/es/grid";
import Text from "antd/lib/typography/Text";

interface Props {
  invoice: any;
  setEditInvoiceVisibility: (value: boolean) => void;
  t: TFunction;
  isVisible: boolean;
  refetch: any;
}

export const EditInvoice = (props: Props) => {
  const { isVisible, invoice, setEditInvoiceVisibility, t, refetch } = props;
  const [selectedMonth, setSelectedMonth] = useState(invoice?.monthDate);
  return (
    <Mutation
      mutation={EDIT_INVOICE}
      update={() => {
        notification.success({
          message: t("createInvoiceModal.MESSAGES.INVOICE_UPDATED"),
        });
        setEditInvoiceVisibility(false);
        refetch();
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(editInvoice, { loading }) => (
        <Modal
          visible={isVisible}
          maskClosable={false}
          title={`${t("buttons.EDIT_INVOICE")} #${invoice?.id}`}
          onCancel={() => {
            setEditInvoiceVisibility(!isVisible);
            setSelectedMonth(null);
          }}
          footer={[
            <Button
              type="primary"
              loading={loading}
              htmlType="submit"
              onClick={() => editInvoice({ variables: { id: invoice.id, month: selectedMonth } })}>
              {t("buttons.EDIT_INVOICE")}
            </Button>,
          ]}>
          <Row>
            <Col span={24}>
              <Text>{t("invoicesTableColumns.MONTH")}</Text>
              <Row>
                <DatePicker.MonthPicker
                  format="MMMM YY"
                  className="is-fullwidth"
                  onChange={(month) => setSelectedMonth(month)}
                  value={selectedMonth || moment(invoice?.monthDate)}
                />
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
    </Mutation>
  );
};
