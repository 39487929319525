import React, { Component } from "react";
import { Mutation } from "@apollo/react-components";
import { Button, Modal, InputNumber, Form, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { withTranslation, WithTranslation } from "react-i18next";
import { ASSIGN_EMPLOYEE_TO_PROJECT } from "../../../../../../../graphql/mutations";
import {
  GET_PROJECT_ASSIGNABLE_EMPLOYEES,
  GET_PROJECT_EMPLOYEE_ASSIGNMENTS,
} from "../../../../../../../graphql/queries";

import SelectEmployee from "./components/SelectEmployee";
import { EmployeeAssignment } from "types/api";

interface IFormProps extends FormComponentProps {
  projectId: number;
}
interface IProps extends IFormProps, WithTranslation {}

class AssignEmployee extends Component<IProps> {
  public state = {
    modalIsOpen: false,
    data: {
      projectId: this.props.projectId,
    },
  };

  public openModal = () =>
    this.setState({
      modalIsOpen: true,
    });

  public closeModal = () =>
    this.setState({
      modalIsOpen: false,
    });

  public onChange = ({ key, value }) => {
    this.props.form.setFieldsValue({
      [key]: value,
    });
  };

  public clearForm = () => this.props.form.resetFields();

  public render() {
    const { t } = this.props;
    return (
      <Mutation<EmployeeAssignment>
        mutation={ASSIGN_EMPLOYEE_TO_PROJECT}
        refetchQueries={() => {
          return [
            {
              query: GET_PROJECT_EMPLOYEE_ASSIGNMENTS,
              variables: { id: this.props.projectId },
            },
            {
              query: GET_PROJECT_ASSIGNABLE_EMPLOYEES,
              variables: { id: this.props.projectId },
            },
          ];
        }}
        update={() => {
          this.clearForm();
          this.closeModal();
          notification.success({
            message: t("projectsTableColumns.ASSIGN_EMPLOYEE.MESSAGES.ASSIGNED_EMPLOYEE"),
          });
        }}>
        {(assignEmployee, { loading }): any => {
          return (
            <>
              <Button type="primary" onClick={this.openModal} style={{ marginBottom: "15px" }}>
                {t("projectsTableColumns.ASSIGN_EMPLOYEE.ADD_EMPLOYEE")}
              </Button>
              <Modal
                title={t("projectsTableColumns.ASSIGN_EMPLOYEE.TITLE")}
                visible={this.state.modalIsOpen}
                okButtonProps={{
                  "aria-label": "ok-button",
                  loading,
                }}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      assignEmployee({
                        variables: {
                          projectId: this.props.projectId,
                          ...values,
                        },
                      }).catch(() => {});
                    }
                  });
                }}
                onCancel={() => {
                  this.closeModal();
                  this.props.form.resetFields();
                }}>
                <Form>
                  <Form.Item>
                    {this.props.form.getFieldDecorator("employeeId", {
                      rules: [
                        {
                          required: true,
                          message: t("projectsTableColumns.ASSIGN_EMPLOYEE.MESSAGES.EMPLOYEE_REQUIRED"),
                        },
                      ],
                    })(
                      <SelectEmployee
                        projectId={this.props.projectId}
                        onChange={(value) => this.onChange({ key: "employeeId", value })}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {this.props.form.getFieldDecorator("hourlyRate", {
                      rules: [
                        {
                          required: true,
                          message: t("projectsTableColumns.ASSIGN_EMPLOYEE.MESSAGES.RATE_REQUIRED"),
                        },
                      ],
                    })(
                      <InputNumber
                        onChange={(value) => this.onChange({ key: "hourlyRate", value })}
                        style={{ width: "100%" }}
                        placeholder={t("projectsTableColumns.placeholder.HOURLY_RATE")}
                        min={0}
                      />
                    )}
                  </Form.Item>
                </Form>
              </Modal>
            </>
          );
        }}
      </Mutation>
    );
  }
}

export default Form.create<IFormProps>()(withTranslation()(AssignEmployee));
