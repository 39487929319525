import React, { PureComponent } from "react";
import { Form, Row, Col, InputNumber } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { withTranslation, WithTranslation } from "react-i18next";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SelectProject from "screens/TimeLogs/ModifyTimelogs/components/SelectProject";
import TextArea from "antd/lib/input/TextArea";

interface IProps extends FormComponentProps {
  isEditing?: number;
  form: any;
  item?: InvoiceItem;
  userId: number;
}

export interface InvoiceItem {
  project: {
    key: number;
    label: string;
  };
  description: string;
  hours: number;
  hourlyRate: number;
  index: number;
}

class InvoiceItemForm extends PureComponent<IProps & WithTranslation> {
  render() {
    const { t, form, item, userId } = this.props;
    return (
      <Form>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t("invoices.CREATE_INVOICE.PROJECT")}>
              {form.getFieldDecorator("project", {
                initialValue: !!item?.project ? { key: item.project.key, label: item.project.label } : undefined,
                rules: [
                  {
                    required: true,
                    message: t("invoices.CREATE_INVOICE.MESSAGES.PROJECT_REQUIRED"),
                  },
                ],
              })(<SelectProject userId={userId} t={t} selectProject={() => null} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("invoices.CREATE_INVOICE.DESCRIPTION")}>
              {form.getFieldDecorator("description", {
                initialValue: !!item?.description ? item.description : undefined,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("invoices.CREATE_INVOICE.HOURS")}>
              {form.getFieldDecorator("hours", {
                initialValue: !!item?.hours ? item.hours : undefined,
                rules: [
                  {
                    required: true,
                    message: t("invoices.CREATE_INVOICE.MESSAGES.HOURS_REQUIRED"),
                  },
                ],
              })(<InputNumber min={0} step={0.1} placeholder="Amount" style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("invoices.CREATE_INVOICE.HOURLY_RATE")}>
              {form.getFieldDecorator("hourlyRate", {
                initialValue: item?.hourlyRate,
                rules: [
                  {
                    required: true,
                    message: t("invoices.CREATE_INVOICE.MESSAGES.HOURLY_RATE_REQUIRED"),
                  },
                ],
              })(<InputNumber min={0} step={0.1} placeholder="Amount" style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default withTranslation()(InvoiceItemForm);
