import * as React from "react";
import TitleSection from "../../common/SectionTitle/title";
import { withTranslation, WithTranslation } from "react-i18next";
import { Empty, Layout, Skeleton } from "antd";
import "antd/dist/antd.css";
import FilterUtilization from "./Component/FilterUtilization";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { GET_UTILIZATION } from "../../graphql/queries";
import { IUtilization } from "../../graphql/queries/Interfaces";
import { roundTwoDecPlaces } from "../../helpers/round_number";
import BarChartGraph from "./Component/BarChartGraph";
import LineChartGraph from "./Component/LineChartGraph";

interface IState {
  showbarChart: boolean;
  showBenchMark: boolean;
  month: moment.Moment;
  utilizaton: IUtilization[];
  hideHolidays: boolean;
  filters: any;
}

type Props = WithTranslation;
class View extends React.Component<Props, IState> {
  public state = {
    showbarChart: true,
    showBenchMark: false,
    month: moment(),
    utilizaton: [],
    filters: {
      startDate: moment().startOf("week").add(1, "days").toDate(),
      endDate: moment().endOf("week").toDate(),
      team: null,
    },
    hideHolidays: false,
  };

  public setDatakey = (data: IUtilization[]) => {
    if (!!data[0].date) {
      return "date";
    }

    if (!!data[0].week) {
      return "week";
    }

    return "month";
  };

  public handleTeamChange = (items) => {
    let elements: number[] | null = null;

    if (items.length > 0) {
      elements = items.map((item) => +item);
    }

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        team: elements,
      },
    }));
  };

  public handleMetricsChange = (value) => {
    this.setState({
      showbarChart: value === "true",
    });
  };

  public handleDateRangeChange = (startDate, endDate, benchmark: boolean) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
      },
      showBenchMark: benchmark,
      month: benchmark ? startDate : moment().format("YYYY-MM-DD"),
    }));
  };

  public handleHolidaysChange = (value: boolean) => {
    this.setState({ hideHolidays: value });
  };

  public render() {
    const { t } = this.props;

    return (
      <div>
        <TitleSection title={t("sideMenu.ANALYTICS.UTILIZATION")} iconType="stock" />
        <Layout.Content className="wrapper">
          <FilterUtilization
            t={t}
            checked={this.state.hideHolidays}
            teamChange={this.handleTeamChange}
            metricChange={this.handleMetricsChange}
            dateChange={this.handleDateRangeChange}
            holidaysChange={this.handleHolidaysChange}
          />

          <Query query={GET_UTILIZATION} variables={{ ...this.state.filters }}>
            {({ loading, error, data }: any) => {
              let utilization;

              if (!!data && !loading && data.getUtilization) {
                utilization = this.state.hideHolidays
                  ? data.getUtilization.filter(
                      ({ possibleWorkHours, workedHours }) => possibleWorkHours > 0 || workedHours > 0
                    )
                  : data.getUtilization;
              }

              if (error) {
                return <p>Error</p>;
              }

              if (loading) {
                return <Skeleton active={true} />;
              }

              const formattedData: IUtilization[] = utilization.map((item) => ({
                totalHours: roundTwoDecPlaces(item.possibleWorkHours),
                workedHours: roundTwoDecPlaces(item.workedHours),
                nonWorkedHours:
                  item.possibleWorkHours === 0 || item.possibleWorkHours < item.workedHours
                    ? 0
                    : item.possibleWorkHours - item.workedHours,
                percentage: roundTwoDecPlaces(item.percentage),
                date: !!item.date ? moment(item.date).format("DD MMM") : null,
                week: !!item.week ? `Week ${item.week.split("-")[1]}` : null,
                month: !!item.month ? moment(`${item.month}-01`).format("MMM YYYY") : null,
              }));

              if (formattedData.length === 0) {
                return (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                    description={<span>{t("appConfiguration.BENCHMARK.MESSAGES.NO_DATA")}</span>}
                  />
                );
              }

              return this.state.showbarChart ? (
                <BarChartGraph dataKey={this.setDatakey(formattedData)} utilization={formattedData} t={t} />
              ) : (
                <LineChartGraph
                  dataKey={this.setDatakey(formattedData)}
                  utilization={formattedData}
                  month={this.state.month}
                  showBenchMark={this.state.showBenchMark}
                  t={t}
                />
              );
            }}
          </Query>
        </Layout.Content>
      </div>
    );
  }
}

export default withTranslation()(View);
