import * as React from "react";
import { Layout, Icon } from "antd";
import SideMenu from "./SideMenu";
import { RouteComponentProps, Route, Switch } from "react-router";
import PageNotFound from "../404";
import Projects from "../Clients";
import ProjectDetails from "../Clients/ProjectDetails";
import CalendarSummary from "../LeaveManagement/CalendarSummary/index";
import Requests from "../LeaveManagement/Requests";
import DeputyRequestDetails from "../LeaveManagement/Components/DeputyRequests/deputyRequestDetails";
import LeaveRequestDetails from "../LeaveManagement/UnderManagement/components/leaveRequestDetails";
import TimeLogs from "../TimeLogs";
import UnderManagement from "../LeaveManagement/UnderManagement";
import Invoices from "../Invoices2";
import Companies from "../Companies";
import Roles from "../Managment/Roles";
import Configurations from "../Managment/Configurations";
import CompanyDetails from "../Companies/components/companyDetails";
import User from "../Users";
import PrivateRoute from "./privateRoute";
import Reports from "../Reports";
import Profile from "../Profile";
import { withApollo, WithApolloClient } from "@apollo/react-hoc";
import ProfileDropdown from "./profileSettings/dropdown";
import FinanceOverview from "../FinanceOverview/index";
import UtilizationRate from "../UtilizationRate/index";
import moment from "moment";
import TGetPermissions from "../../common/AccessControl/Permissions";
import Permissions from "../../constants/viewPermissions";
import { isAllowed } from "../../common/AccessControl";
import { getAccessibleRoute } from "../../helpers/permissions";
import Utilization from "../Utilization";
import { Permissions as IPermissions } from "__generated__/globalTypes";
import Contracts from "screens/Contracts";
import AssignedProjects from "../TimeLogs/assignedProjects";
import OwnProjectDetails from "../TimeLogs/assignedProjects/OwnProjectDetails";
import Home from "screens/Home/index";
import Calendars from "screens/Calendars/Calendars";
import Organization from "screens/Organization/Organization";
import GeneralSettings from "screens/GeneralSettings/GeneralSettings";
import Absences from "screens/Absences/Absences";
import PublicProfile from "screens/PublicProfile/PublicProfile";
import PerformanceReview from "screens/PerformanceReview/PerformanceReview";
const { Content, Header, Footer } = Layout;

interface IProps {
  collapsed: boolean;
  appTitle: "AION";
  collapsedWidth: string;
  spin: boolean;
  leftMargin: string;
  permissions: IPermissions | null;
}
class View extends React.Component<WithApolloClient<RouteComponentProps & IProps>> {
  public state = {
    collapsed: false,
    appTitle: "AION",

    collapsedWidth: "80",
    spin: false,
    leftMargin: "250px",
    permissions: TGetPermissions(),
  };

  public toggle = () => {
    if (!this.state.collapsed) {
      this.setState({
        collapsed: true,
        appTitle: "",
        spin: true,
        leftMargin: "80px",
      });
      setTimeout(() => {
        this.setState({
          spin: false,
        });
      }, 500);
    } else {
      this.setState({
        collapsed: false,
        appTitle: "AION",
        leftMargin: "250px",
      });
    }
  };

  public componentDidMount() {
    if (window.screen.width <= 1000) {
      this.setState({
        collapsed: true,
        appTitle: "",
        collapsedWidth: "0",
      });
    }
  }

  public render() {
    return (
      <div className="dashboard-container">
        <div className="left-menu">
          <SideMenu
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
            client={this.props.client}
            collapsed={this.state.collapsed}
            appTitle={this.state.appTitle}
            collapsedWidth={this.state.collapsedWidth}
            spin={this.state.spin}
          />
        </div>

        <div className="content-container" style={{ marginLeft: this.state.leftMargin }}>
          <Layout className="content-layout">
            <Header className="header">
              <div>
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={this.toggle}
                />
              </div>
              <div className="profile-dropdown">
                <ProfileDropdown History={this.props.history} Client={this.props.client} />
              </div>
            </Header>
            <Content
              className="content"
              onClick={() => {
                if (window.screen.width <= 1000) {
                  if (!this.state.collapsed) {
                    this.setState({
                      collapsed: true,
                      appTitle: "",
                      collapsedWidth: "0",
                    });
                  }
                }
              }}>
              <Switch>
                <PrivateRoute
                  exact={true}
                  isAllowed={localStorage.getItem("isAdmin") === "true"}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/companies`}
                  component={Companies}
                />

                <Route exact={true} path={`${this.props.match.path}/companies/:companyId`} component={CompanyDetails} />
                <PrivateRoute
                  isAllowed={isAllowed(this.state?.permissions?.roles, Permissions.SCREENS.ROLES_AND_PERMISSIONS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/roles`}
                  component={Roles}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.USERS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/configurations`}
                  component={Configurations}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.projects, Permissions.SCREENS.PROJECT_USERS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/:projectName/:projectId/details`}
                  component={ProjectDetails}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.USERS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/users`}
                  component={User}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.clients, Permissions.SCREENS.CLIENTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/clients`}
                  component={Projects}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.contracts, Permissions.SCREENS.CONTRACTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/contracts`}
                  component={Contracts}
                />
                <Route
                  exact={true}
                  path={`${this.props.match.path}/deputy-requests/:leaveRequestId`}
                  component={DeputyRequestDetails}
                />
                <Route
                  exact={true}
                  path={`${this.props.match.path}/leave-request/:leaveRequestId`}
                  component={LeaveRequestDetails}
                />
                <PrivateRoute
                  exact={true}
                  isAllowed={isAllowed(this.state.permissions?.leaveRequests, Permissions.SCREENS.REQUESTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/requests`}
                  component={Requests}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.leaveRequests, Permissions.SCREENS.UNDER_MANAGMENT)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/requests/under-management`}
                  component={UnderManagement}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.leaveRequests, Permissions.SCREENS.CALENDAR_SUMMARY)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/requests/calendar-summary`}
                  component={CalendarSummary}
                />
                <PrivateRoute
                  exact={true}
                  isAllowed={isAllowed(this.state.permissions?.invoices, Permissions.SCREENS.INVOICES)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/invoices`}
                  component={Invoices}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.reports, Permissions.SCREENS.REPORTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/reports`}
                  component={Reports}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.reports, Permissions.SCREENS.REPORTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/assigned/projects`}
                  component={AssignedProjects}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.reports, Permissions.SCREENS.REPORTS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/details/:projectName/:projectId/own`}
                  component={OwnProjectDetails}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.PROFILE)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/:id?/profile`}
                  component={Profile}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.TIMELOGS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/:id?/public-profile`}
                  component={PublicProfile}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.TIMELOGS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/timelogs`}
                  component={TimeLogs}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.finance, Permissions.SCREENS.FINANCE_OVERVIEW)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/finance`}
                  component={FinanceOverview}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.finance, Permissions.SCREENS.FINANCE_OVERVIEW)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/absences`}
                  component={Absences}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.analytics, Permissions.SCREENS.UTILIZATION)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/utilization`}
                  component={Utilization}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.finance, Permissions.SCREENS.FINANCE_OVERVIEW)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/utilization-rate`}
                  component={UtilizationRate}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.HOME)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/home`}
                  component={Home}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.HOME)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/performance-review`}
                  component={PerformanceReview}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.CALENDARS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/calendars`}
                  component={Calendars}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.ORGANIZATION)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/organization`}
                  component={Organization}
                />
                <PrivateRoute
                  isAllowed={isAllowed(this.state.permissions?.users, Permissions.SCREENS.USERS)}
                  redirectTo={getAccessibleRoute()}
                  path={`${this.props.match.path}/general-settings`}
                  component={GeneralSettings}
                />
                <Route component={PageNotFound} />
              </Switch>
            </Content>
            <Footer style={{ paddingTop: "5px", paddingBottom: "5px", textAlign: "center", width: "100%" }}>
              Made with <span role="img">❤</span> by Softup Technologies ©{`${moment().year()}`}
            </Footer>
          </Layout>
        </div>
      </div>
    );
  }
}

export default withApollo<RouteComponentProps & IProps>(View);
