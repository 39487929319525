import * as React from "react";
import draftToHtml from "draftjs-to-html";

/**
 * Convert DraftJS object to HTML
 */
export const stringToHTML = (description: string) => {
  if (isJson(description)) {
    return <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(description)) }} />;
  }
  return <p>{description}</p>;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const removeHtmlFromText = (text: string) => {
  const tmp = document.implementation.createHTMLDocument("New").body;
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || "";
};

export const draftJSToRawData = (text: string | undefined) => {
  if (!!text) {
    if (isJson(text)) {
      const htmlString = draftToHtml(JSON.parse(text));

      return removeHtmlFromText(htmlString);
    }

    return text;
  }

  return "";
};
