import React, { Fragment, useState } from "react";
import { Button, Collapse, notification, Popconfirm } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
import TitleSection from "../../../common/SectionTitle/title";
import UpcomingRequests from "../Components/UpcomingRequests";
import DeputyRequests from "../Components/DeputyRequests/index";
import CancelledRequests from "../Components/CancelledRequests";
import { AccessControl } from "../../../common/AccessControl";
import Permissions from "../Permissions";
import { withPermissionsFnc } from "../../../decorators/permissions";
import constants from "../../../constants";
import MyLeaveRequests from "../Components/MyLeaveRequests/index";
import { Mutation } from "@apollo/react-components";
import { SYNC_ALL_GOOGLE_CALENDAR } from "../../../graphql/mutations";

interface IProps extends WithTranslation {
  permissions: any;
}

const { Panel } = Collapse;

const View: React.FC<IProps> = ({ t, permissions }) => {
  const [openPanel, setOpenPanel] = useState<string[]>(JSON.parse(localStorage.getItem("requestsOpenPanel") as string));

  const ExtraComponent = (props) => {
    return (
      <AccessControl userPermissions={permissions.leaveRequests} allowedPermissions={Permissions.READ_REQUESTS}>
        <Panel {...props}>{props.child}</Panel>
      </AccessControl>
    );
  };

  // commented will be reviewed and re-implemented later
  const SyncGoogleCalendar = (props) => {
    return (
      <AccessControl userPermissions={permissions.leaveRequests} allowedPermissions={Permissions.READ_REQUESTS}>
        <Mutation
          mutation={SYNC_ALL_GOOGLE_CALENDAR}
          onCompleted={() => {
            notification.success({
              message: t("timelogs.MESSAGES.SYNC_CALENDAR"),
            });
          }}>
          {(syncCalendar, { loading }) => {
            return (
              <Popconfirm
                title={t("popConfirms.TITLE")}
                okText={t("popConfirms.ON_OK")}
                onConfirm={() => syncCalendar()}>
                <Button type="primary" icon="save" className="mr-10 mb-10" loading={loading}>
                  {t("timelogs.MESSAGES.SYNC_CALENDAR_TITLE")}
                </Button>
              </Popconfirm>
            );
          }}
        </Mutation>
      </AccessControl>
    );
  };

  const panelChange = (key) => {
    localStorage.setItem("requestsOpenPanel", JSON.stringify(key));
    setOpenPanel(key);
  };

  return (
    <Fragment>
      <TitleSection title={t("sideMenu.LEAVE_MANAGEMENT.REQUESTS")} iconType="carry-out" />
      <div className="wrapper">
        {/* <SyncGoogleCalendar />*/}

        <Collapse style={{ marginBottom: 10 }} activeKey={openPanel} expandIconPosition="right" onChange={panelChange}>
          <ExtraComponent
            header={t("sideMenu.LEAVE_MANAGEMENT.UPCOMING_REQUESTS")}
            key="1"
            child={<UpcomingRequests t={t} />}
          />

          <Panel header={t("sideMenu.LEAVE_MANAGEMENT.DEPUTY_REQUESTS")} key="2">
            <DeputyRequests t={t} />
          </Panel>

          <Panel header={t("sideMenu.LEAVE_MANAGEMENT.LEAVE_REQUESTS")} key="3">
            <MyLeaveRequests />
          </Panel>

          <ExtraComponent
            header={t("sideMenu.LEAVE_MANAGEMENT.CANCELED_REQUESTS")}
            key="4"
            child={<CancelledRequests t={t} />}
          />
        </Collapse>
      </div>
    </Fragment>
  );
};

export default withPermissionsFnc([constants.permissionResources.LEAVE_REQUESTS])(withTranslation()(View));
