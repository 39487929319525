import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { Table } from "antd";

import { GET_CLIENT_ASSIGNMENT } from "../../../graphql/queries";
import AssignClient from "./components/AssignClient";
import UnAssignButton from "./components/UnAssignButton";
import Permissions from "../../../constants/viewPermissions";
import { AccessControl } from "../../../common/AccessControl";
import { withPermissions } from "../../../decorators/permissions";
import constants from "../../../constants";
import { withTranslation, WithTranslation } from "react-i18next";
interface IProps extends WithTranslation {
  clientId: number;
  permissions?: any;
  clientName: string;
}
@withPermissions([constants.permissionResources.CLIENTS])
class Clients extends Component<IProps> {
  public refetch = [
    {
      query: GET_CLIENT_ASSIGNMENT,
      variables: { userId: this.props.clientId },
    },
  ];

  public render() {
    const { t } = this.props;
    return (
      <>
        <AccessControl
          userPermissions={this.props.permissions.clients}
          allowedPermissions={Permissions.BUTTONS.UPDATE_CLIENT}>
          <AssignClient clientId={this.props.clientId} clientName={this.props.clientName} />
        </AccessControl>
        <Query query={GET_CLIENT_ASSIGNMENT} variables={{ userId: this.props.clientId }}>
          {({ loading, data, error }: any) => {
            let clients;
            if (!loading && data) {
              clients = data.clients[0].userAssignments.map(({ id, User: { firstName, lastName } }) => ({
                id,
                firstName,
                lastName,
                key: id,
              }));
            }
            return (
              <>
                {error && <div>Error</div>}
                <>
                  <Table pagination={false} size="small" loading={loading} dataSource={clients}>
                    <Table.Column
                      title={t("clientUsers.tableColumns.FIRSTNAME")}
                      dataIndex="firstName"
                      key="firstName"
                      width="100px"
                    />
                    <Table.Column
                      title={t("clientUsers.tableColumns.LASTNAME")}
                      dataIndex="lastName"
                      key="lastName"
                      width="100px"
                    />
                    <Table.Column
                      title={t("clientUsers.tableColumns.ACTIONS")}
                      key="id"
                      width="100px"
                      align="center"
                      render={({ id }) => (
                        <>
                          <AccessControl
                            userPermissions={this.props.permissions.clients}
                            allowedPermissions={Permissions.BUTTONS.UPDATE_CLIENT}>
                            <UnAssignButton
                              id={id}
                              t={t}
                              refetch={this.refetch}
                              clientId={this.props.clientId}
                              clientName={this.props.clientName}
                            />
                          </AccessControl>
                        </>
                      )}
                    />
                  </Table>
                </>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default withTranslation()(Clients);
