export default {
  HOME: "home",
  ABSENCES: "absences",
  GENERAL_SETTINGS: "general_settings",
  CALENDARS: "calendars",
  ORGANIZATIONL: "organization",
  TIMELOGS: "timelogs",
  USERS: "users",
  PROJECTS: "projects",
  CLIENTS: "clients",
  EMPLOYEES: "employees",
  REPORTS: "reports",
  INVOICES: "invoices",
  ROLES: "roles",
  LEAVE_REQUESTS: "leaveRequests",
  CONTRACTS: "contracts",
  DOCUMENTS: "documents",
};
