import React from "react";
import SectionTitle from "../../../common/SectionTitle/title";
import RolePanel from "./components/rolePanels";
import { Empty, Button, Layout, Icon, Breadcrumb } from "antd";
import { Skeleton } from "antd";
import { Query } from "@apollo/react-components";
import { GET_ROLES_SIMPLE } from "../../../graphql/queries";
import { withPermissions } from "../../../decorators/permissions";
import { AccessControl } from "../../../common/AccessControl";
import constants from "../../../constants/index";
import { withTranslation, WithTranslation } from "react-i18next";
import Permissions from "../../../constants/viewPermissions";
import AddRoleModal from "./components/addRoleModal";

interface IState {
  addRoleModalVisibility: boolean;
}

interface IProps extends WithTranslation {
  permissions: any;
  history: any;
}

@withPermissions([constants.permissionResources.ROLES])
class Roles extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      addRoleModalVisibility: false,
    };
  }
  public renderCreateRoleButton = () => {
    const { t } = this.props;

    return (
      <AccessControl
        userPermissions={this.props.permissions.roles}
        allowedPermissions={Permissions.BUTTONS.CREATE_ROLE}>
        <Button type="primary" onClick={this.showAddRoleModal} style={{ marginBottom: 10 }}>
          {t("buttons.CREATE_ROLE")}
        </Button>
      </AccessControl>
    );
  };
  public renderRoleModal = () => {
    return (
      <AccessControl
        userPermissions={this.props.permissions.roles}
        allowedPermissions={Permissions.BUTTONS.CREATE_ROLE}>
        <AddRoleModal isOpen={this.state.addRoleModalVisibility} onCancel={this.hideAddRoleModal} />
      </AccessControl>
    );
  };

  public render() {
    const { t } = this.props;
    return (
      <div className="roles-section">
        <SectionTitle title={t("sideMenu.MANAGMENT.ROLES")} iconType="control" />
        <Layout.Content className="wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard/general-settings">
              <Icon type="left" />
              <span>{t("sideMenu.GENERAL_SETTINGS")}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("sideMenu.MANAGMENT.ROLES")}</Breadcrumb.Item>
          </Breadcrumb>
        </Layout.Content>
        <Layout.Content className={"wrapper"}>
          {this.renderCreateRoleButton()}
          {this.renderRoleModal()}
          {this.renderRolesList()}
        </Layout.Content>
      </div>
    );
  }
  public renderRolesList = () => {
    const { t } = this.props;
    return (
      <AccessControl userPermissions={this.props.permissions.roles} allowedPermissions={Permissions.LISTS.ROLES}>
        <Query query={GET_ROLES_SIMPLE}>
          {(state) => {
            const error = state?.error;
            const loading = state.loading;
            const data = state?.data;
            if (error) {
              return <div>Error</div>;
            }
            let roles;
            if (!loading && data && data.roles.length > 0) {
              roles = data.roles.map(({ id, name }) => ({
                id,
                name,
              }));
              return (
                <>
                  {roles.map((item) => {
                    const role = {
                      id: item.id,
                      name: item.name,
                    };

                    return <RolePanel role={role} key={item.id} />;
                  })}
                </>
              );
            }
            if (loading) {
              return (
                <div className="skeleton">
                  <Skeleton
                    loading={loading}
                    active={true}
                    title={{ width: "100%" }}
                    paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }}
                  />
                </div>
              );
            } else {
              return <Empty description={t("emptyDescriptions.ROLES")} />;
            }
          }}
        </Query>
      </AccessControl>
    );
  };
  private hideAddRoleModal = () => {
    this.setState({
      addRoleModalVisibility: false,
    });
  };
  private showAddRoleModal = () => {
    this.setState({
      addRoleModalVisibility: true,
    });
  };
}

export default withTranslation()(Roles);
