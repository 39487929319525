import React from "react";
import moment from "moment";
import { DatePicker, Form } from "antd";
import { TFunction } from "i18next";
import { FormComponentProps } from "antd/lib/form";

const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";
// const DATE_FORMAT = "YYYY-MM-DD";

interface IProps extends FormComponentProps {
  change: (dates: any) => void;
  t: TFunction;
}

const DateRangePicker = ({ change, form, t }) => {
  const { RangePicker } = DatePicker;

  // const [startDate, setStartDate] = useState(moment().format(DATE_FORMAT));
  // const [previousStartDate, setPreviousStartDate] = useState(startDate);

  // const disabledDates = current => {
  //   return (
  //     current < moment(startDate, DATE_FORMAT).subtract(2, "years") ||
  //     current > moment(startDate, DATE_FORMAT).add(2, "years")
  //   );
  // };
  //
  // const changeCalendar = date => {
  //   if (startDate === moment().format(DATE_FORMAT) || startDate === previousStartDate) {
  //     setStartDate(date[0].format(DATE_FORMAT));
  //     setPreviousStartDate(date[0].format(DATE_FORMAT));
  //   }
  // };

  return (
    <Form.Item label={t("reports.DATE_REPORT")} hasFeedback={true}>
      {form.getFieldDecorator("dateRange", {
        initialValue: [moment().startOf("week"), moment().endOf("week")],
        rules: [
          {
            type: "array",
            required: true,
            message: t("reports.MESSAGES.DATE_REQUIRED"),
          },
        ],
      })(
        <RangePicker
          onChange={change}
          format={DISPLAY_DATE_FORMAT}
          // onCalendarChange={changeCalendar}
          // disabledDate={disabledDates}
          style={{ width: "100%" }}
          ranges={{
            Today: [moment(), moment()],
            "This Week": [moment().startOf("week"), moment().endOf("week")],
            "Last Week": [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month"),
            ],
            "This Quarter": [moment().startOf("quarter"), moment().endOf("quarter")],
            "Last Quarter": [
              moment().subtract(1, "quarters").startOf("quarter"),
              moment().subtract(1, "quarters").endOf("quarter"),
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [moment().subtract(1, "years").startOf("year"), moment().subtract(1, "years").endOf("year")],
          }}
        />
      )}
    </Form.Item>
  );
};

// export default DateRangePicker;
export default Form.create<IProps>()(DateRangePicker);
