export const getPariticipationColor = (percentage: number) => {
  const colorThresholds = [
    { threshold: 0, color: "#f7505e" },
    { threshold: 30, color: "#cc5656" },
    { threshold: 40, color: "#f8ba07" },
    { threshold: 50, color: "#cfe161" },
    { threshold: 80, color: "#20c552" },
    { threshold: 100, color: "#20c552" },
  ];

  for (const { threshold, color } of colorThresholds) {
    if (percentage <= threshold) {
      return color;
    }
  }

  // Default case when percentage > 100
  return "#20c552";
};
