import React from "react";
import { Mutation } from "@apollo/react-components";
import { UPDATE_LEAVE_REQUEST_STATUS } from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_LEAVE_REQUEST_DETAILS } from "../../../../graphql/queries/LeaveManagementQueries";
import { Button, notification } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TFunction } from "i18next";

interface IProps extends RouteComponentProps {
  t: TFunction;
  id: number;
  destination: string;
  action: string;
}

const SubmitLeaveRequest: React.FC<IProps> = ({ t, id, action, destination, history }) => {
  return (
    <>
      <Mutation
        mutation={UPDATE_LEAVE_REQUEST_STATUS}
        refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
        onCompleted={() => {
          history.push(destination);
          notification.success({
            message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_SUBMITTED"),
          });
        }}>
        {(updateLeaveRequestStatus, { loading }): any => {
          return (
            <div className="action-button">
              <>
                <Button
                  icon="check-circle"
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    updateLeaveRequestStatus({
                      variables: { id, action },
                    }).then();
                  }}
                  style={{ marginLeft: "5px" }}>
                  {t("buttons.SUBMIT")}
                </Button>
              </>
            </div>
          );
        }}
      </Mutation>
    </>
  );
};

export default withRouter(SubmitLeaveRequest);
