import React from "react";
import { Descriptions, Tag } from "antd";
import moment from "moment";

const PerformanceReviewInformation = ({ t, selectedReview }) => {
  return (
    <Descriptions bordered title="Review info" size="small">
      <Descriptions.Item span={3} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEWEE")}>
        {selectedReview?.Employee}
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER")}>
        {selectedReview?.Manager}
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CREATED_AT")}>
        {moment(selectedReview?.createdAt).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_DATE")}>
        {moment(selectedReview?.expireDate).format("DD/MM/YYYY")}
      </Descriptions.Item>
      <Descriptions.Item span={3} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_STATUS")}>
        <Tag color={selectedReview?.hasExpired ? "red" : "orange"}>
          {selectedReview?.hasExpired ? "Has expired" : "In progress"}
        </Tag>
      </Descriptions.Item>
    </Descriptions>
  );
};
export default PerformanceReviewInformation;
