import gql from "graphql-tag";

export const GET_FINANCES = gql`
  query finances($startDate: Date!, $endDate: Date!, $projects: [Int!], $team: [Int!], $grossProfit: Boolean!) {
    finances(startDate: $startDate, endDate: $endDate, projects: $projects, team: $team, grossProfit: $grossProfit) {
      date
      month
      revenue
      prediction
    }
  }
`;

export const GET_MONTHS_SUMMARY = gql`
  query monthsSummary($date: Date!) {
    monthsSummary(date: $date) {
      month
      percentage
      workedHours
      possibleWorkHours
      billableValue
    }
  }
`;
