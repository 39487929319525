import gql from "graphql-tag";

export const UPDATE_LEAVE_REQUEST_STATUS = gql`
  mutation updateLeaveRequestStatus($id: Int!, $action: String!) {
    updateLeaveRequestStatus(id: $id, action: $action) {
      employeeStatus
      deputyStatus
      managerStatus
    }
  }
`;

export const DELETE_LEAVE_REQUEST = gql`
  mutation deleteLeaveRequest($id: Int!) {
    deleteLeaveRequest(id: $id) {
      id
    }
  }
`;

export const CREATE_LEAVE_REQUEST = gql`
  mutation createLeaveRequest(
    $comment: String
    $leaveType: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $deputyId: Int!
  ) {
    createLeaveRequest(
      comment: $comment
      leaveType: $leaveType
      startDate: $startDate
      endDate: $endDate
      deputyId: $deputyId
    ) {
      id
      leaveRequestType {
        leaveTypeName
        id
      }
      startDate
      endDate
      employeeId
    }
  }
`;

export const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest(
    $id: Int!
    $comment: String
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $deputyId: Int
    $leaveType: Int
    $employeeStatus: String
  ) {
    updateLeaveRequest(
      id: $id
      employeeStatus: $employeeStatus
      comment: $comment
      startDate: $startDate
      endDate: $endDate
      deputyId: $deputyId
      leaveType: $leaveType
    ) {
      id
    }
  }
`;

export const CREATE_LEAVE_TYPE = gql`
  mutation createLeaveType(
    $leaveTypeName: String!
    $holidayLimit: Int!
    $exclude: Boolean!
    $canUploadDocument: Boolean!
  ) {
    createLeaveType(
      leaveTypeName: $leaveTypeName
      holidayLimit: $holidayLimit
      exclude: $exclude
      canUploadDocument: $canUploadDocument
    ) {
      id
    }
  }
`;
export const EDIT_LEAVE_TYPE = gql`
  mutation updateLeaveType(
    $id: Int!
    $leaveTypeName: String!
    $holidayLimit: Int!
    $exclude: Boolean!
    $canUploadDocument: Boolean!
  ) {
    updateLeaveType(
      id: $id
      leaveTypeName: $leaveTypeName
      holidayLimit: $holidayLimit
      exclude: $exclude
      canUploadDocument: $canUploadDocument
    ) {
      id
    }
  }
`;
