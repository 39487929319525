import React, { useState } from "react";
import { Button, notification, Popconfirm } from "antd";
import { Mutation } from "@apollo/react-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { UPDATE_LEAVE_REQUEST_STATUS } from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_DEPUTY_REQUESTS, GET_LEAVE_REQUESTS_BY_ROLE } from "../../../../graphql/queries/LeaveManagementQueries";

interface IProps extends RouteComponentProps, WithTranslation {
  id: number;
  history: any;
  selectedYear: string;
}

const ActionsCompomonent: React.FC<IProps> = (props: IProps) => {
  const [disableDecline, setDisableDecline] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  return (
    <div className="deputy-buttons">
      <ApproveButton
        t={props.t}
        id={props.id}
        disable={disableApprove}
        selectedYear={props.selectedYear}
        changeDeclineDisability={() => setDisableDecline}
        action="confirm"
        history={props.history}
      />
      <DeclineButton
        t={props.t}
        id={props.id}
        selectedYear={props.selectedYear}
        disable={disableDecline}
        changeApproveDisability={() => setDisableApprove}
        action="decline"
        history={props.history}
      />
    </div>
  );
};

const ApproveButton = ({ t, id, action, disable, changeDeclineDisability, history, selectedYear }) => {
  return (
    <>
      <Mutation
        mutation={UPDATE_LEAVE_REQUEST_STATUS}
        refetchQueries={[
          { query: GET_LEAVE_REQUESTS_BY_ROLE, variables: { leaveRequestRole: "deputy", year: selectedYear } },
        ]}
        awaitRefetchQueries={true}
        onCompleted={() => {
          notification.success({
            message: t("leaveRequests.MESSAGES.APPROVED_DEPUTY_REQUEST"),
          });
          history.replace({ pathname: "/dashboard/requests" });
        }}>
        {(updateLeaveRequestStatus, { loading }): any => {
          changeDeclineDisability(loading);
          return (
            <React.Fragment>
              <Button
                icon="like"
                type="primary"
                loading={loading}
                disabled={disable}
                onClick={() => {
                  updateLeaveRequestStatus({
                    variables: { id, action },
                  });
                }}
                className="deputy-button1">
                {t("buttons.APPROVE")}
              </Button>
            </React.Fragment>
          );
        }}
      </Mutation>
    </>
  );
};

const DeclineButton = ({ t, id, disable, action, changeApproveDisability, history, selectedYear }: any) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST_STATUS}
      // refetchQueries={[
      //   { query: GET_LEAVE_REQUESTS_BY_ROLE, variables: { leaveRequestRole: "deputy", year: selectedYear } },
      // ]}
      refetchQueries={[{ query: GET_DEPUTY_REQUESTS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.DECLINED_DEPUTY_REQUEST"),
        });
        history.replace({ pathname: "/dashboard/requests" });
      }}>
      {(updateLeaveRequestStatus, { loading }): any => {
        changeApproveDisability(loading);

        return (
          <Popconfirm
            title={t("popConfirms.TITLE")}
            okText={t("popConfirms.ON_OK")}
            onConfirm={() => {
              updateLeaveRequestStatus({ variables: { id, action } });
            }}>
            <Button loading={loading} type="danger" icon="dislike" className="deputy-button2" disabled={disable}>
              {t("buttons.DECLINE")}
            </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

export default withRouter(withTranslation()(ActionsCompomonent));
