import React from 'react';
import {
  Descriptions,
  Icon,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';

const extendedInfoFromPanel = (t, balanceItem) => {
  return (
    <div>
      <h4>
        {t("appConfiguration.CALENDARS.LABELS.BREAKEDOWN")} {moment(balanceItem?.createdAt).format("YYYY")}
      </h4>
      <Descriptions size="small" bordered>
        <Descriptions.Item
          span={4}
          label={
            <span>
              {t("appConfiguration.CALENDARS.LABELS.TAKEN")}{" "}
              <Tooltip title={t("appConfiguration.CALENDARS.LABELS.TAKEN_INFO")}>
                <Icon type="question-circle" />
              </Tooltip>
            </span>
          }>
          {balanceItem?.takenDays === 1 ? balanceItem?.takenDays + " day" : balanceItem?.takenDays + " days"}
        </Descriptions.Item>
        <Descriptions.Item
          span={4}
          label={
            <span>
              {t("appConfiguration.CALENDARS.LABELS.PENDING_DAYS")}{" "}
              <Tooltip title={t("appConfiguration.CALENDARS.LABELS.PENDING_INFO")}>
                <Icon type="question-circle" />
              </Tooltip>
            </span>
          }>
          {balanceItem?.plannedDaysOff === 1
            ? balanceItem?.plannedDaysOff + " day"
            : balanceItem?.plannedDaysOff + " days"}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h4>
        {t("appConfiguration.CALENDARS.LABELS.TOTAL_DAYS")}{" "}
        <Tag color="blue">
          {balanceItem?.absenceTypes?.[0]?.numberOfDays === 1
            ? balanceItem?.absenceTypes?.[0]?.numberOfDays + " day"
            : balanceItem?.absenceTypes?.[0]?.numberOfDays + " days"}
        </Tag>
      </h4>
    </div>
  );
};
export default extendedInfoFromPanel;
