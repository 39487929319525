export const getPartForOneDay = (isStartDateHalf: boolean, isEndDateHalf: boolean) => {
  if (!isStartDateHalf && !isEndDateHalf) {
    return "Full day";
  } else if (isStartDateHalf && !isEndDateHalf) {
    return "Second half";
  } else if (!isStartDateHalf && isEndDateHalf) {
    return "First half";
  } else {
    return null;
  }
};

export const getPartForFirstDay = (isStartDateHalf: boolean) => {
  if (isStartDateHalf) {
    return "Second half";
  } else {
    return "";
  }
};

export const getPartForSecondDay = (isEndDateHalf: boolean) => {
  if (isEndDateHalf) {
    return "First half";
  } else {
    return "";
  }
};
