import React, { Component } from "react";
import { Button, Modal, Form, Checkbox, Row, Col, notification, Input } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { CREATE_COMPANY } from "../../../graphql/mutations";
import { GET_COMPANIES } from "../../../graphql/queries";
import { withTranslation, WithTranslation } from "react-i18next";
import SelectCountry from "common/Form/SelectCountry";
const CheckboxGroup = Checkbox.Group;
const options = [
  { label: "Time Tracking", value: 1 },
  { label: "Leave Managment", value: 2 },
  { label: "Invoices", value: 3 },
];

type ICreateCompany = FormComponentProps;
interface IProps extends ICreateCompany, WithTranslation {}
class CreateCompany extends Component<IProps> {
  public state = {
    modalIsOpen: false,
    checkedList: [],
  };

  public openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  public closeModal = () => {
    // this.props.form.resetFields();
    this.setState({
      modalIsOpen: false,
    });
  };
  public render() {
    const { t } = this.props;
    return (
      <>
        {this.renderCreateCompanyButton()}
        <Mutation
          mutation={CREATE_COMPANY}
          refetchQueries={[
            {
              query: GET_COMPANIES,
            },
          ]}
          onCompleted={() => {
            this.props.form.resetFields();
            this.closeModal();
            notification.success({
              message: "Company created successfully!",
            });
          }}
          onError={(error) => {
            this.props.form.resetFields();
            this.closeModal();
            notification.error({
              message: error.message.split(":")[1],
            });
          }}>
          {(createCompany, { loading }): any => {
            return (
              <Modal
                visible={this.state.modalIsOpen}
                onCancel={this.closeModal}
                title="Create Company"
                data-cy="create-company-modal"
                cancelButtonProps={{ disabled: loading }}
                okButtonProps={{
                  "aria-label": "ok-button",
                  loading,
                }}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      const { name, firstName, countryId, lastName, password, email, modules } = values;
                      const finalValues = {
                        name,
                        firstName,
                        countryId,
                        lastName,
                        password,
                        email,
                        modules: modules ? modules : [],
                      };
                      createCompany({ variables: finalValues });
                    }
                  });
                }}>
                <Form layout="vertical">
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item label={"Company Name"}>
                        {this.props.form.getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "Company name must be filled",
                            },
                          ],
                        })(<Input placeholder="Your company name" />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={"Country"}>
                        {this.props.form.getFieldDecorator("countryId", {
                          rules: [
                            {
                              required: true,
                              message: "Company country must be filled",
                            },
                          ],
                        })(<SelectCountry t={t} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item label={t("userModal.FIRST_NAME")}>
                        {this.props.form.getFieldDecorator("firstName", {
                          rules: [
                            {
                              required: true,
                              message: t("userModal.messages.FIRST_NAME"),
                            },
                          ],
                        })(<Input placeholder="John" />)}
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label={t("userModal.LAST_NAME")}>
                        {this.props.form.getFieldDecorator("lastName", {
                          rules: [
                            {
                              required: true,
                              message: t("userModal.messages.LAST_NAME"),
                            },
                          ],
                        })(<Input placeholder="Doe" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label={t("userModal.EMAIL")}>
                        {this.props.form.getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: t("userModal.messages.EMAIL"),
                              type: "email",
                            },
                          ],
                        })(<Input placeholder="your@email.com" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label={t("userModal.PASSWORD")}>
                        {this.props.form.getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: t("userModal.messages.PASSWORD"),
                            },
                          ],
                        })(<Input placeholder="Doe" type="password" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Company modules">
                        {this.props.form.getFieldDecorator("modules")(<CheckboxGroup options={options} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            );
          }}
        </Mutation>
      </>
    );
  }

  public renderCreateCompanyButton = () => {
    return (
      <Button type="primary" style={{ marginBottom: 14 }} onClick={() => this.openModal()}>
        Create company
      </Button>
    );
  };

  public onChange = (checkedList) => {
    this.setState({
      checkedList,
    });
  };
}

export default Form.create<ICreateCompany>()(withTranslation()(CreateCompany));
