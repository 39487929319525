import React, { useState } from "react";
import { Form, Button, Input, Col, Row, Alert } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FormComponentProps } from "antd/lib/form";
import { RESET_PASSWORD } from "../../../graphql/mutations";
import { Mutation } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
const ProvideEmail = (props: FormComponentProps & WithTranslation & RouteComponentProps) => {
  const [showErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setSuccessAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { form } = props;
  const { t } = props;
  const handleSubmit = (resetPassword) => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const { email } = values;

        resetPassword({ variables: { email, redirectUrl: props.match.path } });
      }
    });
  };

  return (
    <Mutation
      mutation={RESET_PASSWORD}
      onCompleted={() => {
        setSuccessAlert(true);
        setErrorAlert(false);
        setErrorMessage("");
      }}
      onError={(error) => {
        setSuccessAlert(false);
        setErrorAlert(true);
        setErrorMessage(error.message.split(":")[1]);
      }}>
      {(resetPassword, { loading }) => (
        <Form>
          <Row>
            <Col span={24}>
              <div style={{ width: "80%", margin: "10px 20px 20px 20px" }}>{t("login.MESSAGES.RESET_EMAIL")}</div>
              <Form.Item hasFeedback={true}>
                {form.getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: t("login.MESSAGES.EMAIL"),
                      type: "email",
                    },
                  ],
                })(<Input size="large" placeholder={t("placeholders.LOGIN.EMAIL")} prefix="" />)}
              </Form.Item>
            </Col>
          </Row>
          {showErrorAlert && (
            <Alert
              style={{ margin: "0px 20px 10px 20px" }}
              message={errorMessage}
              // description={t("login.ALERT.EMAIL", { email: form.getFieldValue("email") })}
              type="error"
            />
          )}
          {showSuccessAlert && (
            <Alert
              style={{ margin: "0px 20px 10px 20px" }}
              message="Link sent"
              type="success"
              description={t("login.ALERT.EMAIL", form.getFieldValue("email"))}
            />
          )}
          <div className="loginbutton-container">
            <Button type="primary" onClick={() => handleSubmit(resetPassword)} loading={loading}>
              {t("buttons.SUBMIT")}
            </Button>
          </div>
        </Form>
      )}
    </Mutation>
  );
};

export default Form.create()(withTranslation()(withRouter(ProvideEmail)));
