import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Avatar, Icon, Tag, Tree } from "antd";
import { GET_NEW_USERS } from "graphql/queries";
import { TFunction } from "i18next";
import Search from "antd/lib/input/Search";
import { managerImg, employeeImg, hrImg, contractorImg, financeImg } from "../../../helpers/appImages";

const { TreeNode } = Tree;

interface IProps {
  t: TFunction;
}

const OrganizationChart: React.FC<IProps> = ({ t }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const { loading, error, data } = useQuery(GET_NEW_USERS);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! {error.message}</div>;

  // Group users by manager's id
  const groupedUsers = data.getNewUsers.reduce((acc: any, user: any) => {
    const managerId = user?.employee?.managerId;
    if (managerId && !acc[managerId]) {
      acc[managerId] = {
        manager: {
          firstName: user.employee.manager.firstName,
          lastName: user.employee.manager.lastName,
        },
        users: [],
      };
    }
    if (managerId) {
      const fullName = `${user.firstName} ${user.lastName}`;
      const managerFullName = `${user.employee.manager.firstName} ${user.employee.manager.lastName}`;
      if (fullName.toLowerCase() !== managerFullName.toLowerCase()) {
        acc[managerId].users.push(user);
      }
    }
    return acc;
  }, {});
  const managerIds = Object.keys(groupedUsers);

  const roleToImage = {
    Employee: employeeImg,
    "Human Resource": hrImg,
    Contractor: contractorImg,
    Admin: managerImg,
    Finance: financeImg,
  };

  // Render tree
  return (
    <>
      <Search
        allowClear
        placeholder="Search by first name or last name"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: 300, marginBottom: 16 }}
      />
      <div className="scrollable-div">
        <Tree
          showLine={false}
          selectable={false}
          checkable={false}
          switcherIcon={<Icon type="down-circle" />}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {managerIds.map((managerId: string) => {
            const manager = groupedUsers[managerId].manager;
            const filteredUsers = groupedUsers[managerId].users.filter((user: any) => {
              const fullName = `${user.firstName} ${user.lastName}`;
              return fullName.toLowerCase().includes(searchText.toLowerCase());
            });

            if (
              filteredUsers.length === 0 &&
              !manager.firstName.toLowerCase().includes(searchText.toLowerCase()) &&
              !manager.lastName.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return null; // Skip rendering this manager if no matching users and the manager name doesn't match the search text
            }

            return (
              <TreeNode
                style={{
                  paddingBottom: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                title={
                  <>
                    <Avatar src={managerImg} style={{ backgroundColor: "#f8f0ff" }} />
                    <Tag color="blue" style={{ marginLeft: 8 }}>
                      {manager.firstName} {manager.lastName}
                    </Tag>
                  </>
                }
                key={String(managerId)}>
                {filteredUsers.map((user: any) => (
                  <TreeNode
                    switcherIcon={<Icon type="minus" />}
                    style={{ paddingBottom: 20, paddingTop: 20 }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={roleToImage[user?.roles[0]?.name]} style={{ backgroundColor: "#f0f5ff" }} />
                        <Tag color="purple" style={{ marginLeft: 8 }}>
                          {user.firstName} {user.lastName}
                        </Tag>
                        <Tag color="red" style={{ marginLeft: 8 }}>
                          {user?.roles[0]?.name === "Employee" || user?.roles[0]?.name === "Admin"
                            ? "IT"
                            : user?.roles[0]?.name}
                        </Tag>
                      </div>
                    }
                    key={user.id}
                  />
                ))}
              </TreeNode>
            );
          })}
        </Tree>
      </div>
    </>
  );
};

export default OrganizationChart;
