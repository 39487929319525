import React, { Fragment } from "react";
import { Button, Table, Tag } from "antd";
import moment from "moment";
import { fileTypeImage } from "./Helpers/fileTypeImage";
import { IDocumentType } from "../types";
import { TFunction } from "i18next";
import { categoryColor } from "./Helpers/categoryColor";
import DocumentEdit from "./DocumentEdit";
import DocumentDelete from "./DocumentDelete";
import { documenTableFilter } from "./Helpers/documentFilter";

interface IProps {
  t: TFunction;
  documents: IDocumentType;
  loading: any;
  error: any;
  dataDisplayType: string;
  searchValue: any;
  selectValue: any;
  selectFileTypeValue: any;
  userId: number;
}

const DocumentTable: React.FC<IProps> = ({
  t,
  documents,
  loading,
  error,
  dataDisplayType,
  searchValue,
  selectValue,
  selectFileTypeValue,
  userId,
}) => {
  const columns = [
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.IMAGE"),
      dataIndex: "fileType",
      render: (fileType) => {
        return <img className="image-table" alt="example" src={fileTypeImage(fileType)} />;
      },
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.CREATED_AT"),
      dataIndex: "createdAt",
      render: (createdAt) => {
        return `${moment(createdAt).format("DD/MM/YYYY")}`;
      },
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.FILE_NAME"),
      dataIndex: "fileName",
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.CATEGORY"),
      dataIndex: "category",
      render: (category) => {
        return <Tag color={categoryColor(category)}>{category}</Tag>;
      },
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.FILE_TYPE"),
      dataIndex: "fileType",
      render: (fileType) => {
        return `${fileType.toUpperCase()}`;
      },
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.FILE_SIZE"),
      dataIndex: "fileSize",
      render: (fileSize) => {
        const mbValue = fileSize / (1024 * 1024);
        const roundedMBValue = mbValue.toFixed(2);
        return `${roundedMBValue}/MB`;
      },
    },
    {
      title: t("appConfiguration.DOCUMENTS.LABELS.ACTION"),
      key: "action",
      render: (row) => {
        return (
          <div style={{ display: "flex", gap: 5 }}>
            <Button
              size="small"
              key="1"
              icon="download"
              onClick={() => window.open(row?.filePath as string, "_blank")}
              disabled={!row?.filePath}
            />
            <DocumentEdit document={row} t={t} dataDisplayType={dataDisplayType} userId={userId} />
            <DocumentDelete document={row} t={t} dataDisplayType={dataDisplayType} userId={userId} />
          </div>
        );
      },
    },
  ];

  let documentData;

  if (!loading && documents) {
    documentData = documents;
  }

  const searchedValue = documenTableFilter(documentData, searchValue, selectValue, selectFileTypeValue);

  if (error) {
    return <p>Error</p>;
  }

  return (
    <Fragment>
      <Table size="small" columns={columns} bordered={true} rowKey="id" loading={loading} dataSource={searchedValue} />
    </Fragment>
  );
};

export default DocumentTable;
