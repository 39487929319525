export const managerImg = "https://cdn-icons-png.flaticon.com/128/7484/7484899.png";
export const employeeImg = "https://cdn-icons-png.flaticon.com/128/7484/7484857.png";
export const hrImg = "https://cdn-icons-png.flaticon.com/128/3870/3870481.png";
export const contractorImg = "https://cdn-icons-png.flaticon.com/128/3870/3870373.png";
export const financeImg = "https://cdn-icons-png.flaticon.com/128/3870/3870496.png";
export const absenceImg = "https://cdn-icons-png.flaticon.com/128/2135/2135304.png";
export const absenceActiveImg = "https://cdn-icons-png.flaticon.com/128/2135/2135284.png";
export const anniversaryImg = "https://cdn-icons-png.flaticon.com/128/1223/1223301.png";
export const anniversaryActiveImg = "https://cdn-icons-png.flaticon.com/128/1223/1223355.png";
export const birthdayImg = "https://cdn-icons-png.flaticon.com/128/1544/1544279.png";
export const birthdayActiveImg = "https://cdn-icons-png.flaticon.com/128/1544/1544168.png";
export const holidayImg = "https://cdn-icons-png.flaticon.com/128/2197/2197362.png";
export const holidayActiveImg = "https://cdn-icons-png.flaticon.com/128/2197/2197340.png";
