import React from "react";
import Select, { SelectProps } from "antd/lib/select";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { getRoles } from "__generated__/getRoles";
import { GET_ROLES } from "graphql/queries";
import { getUserData } from "common/AccessControl/Permissions";

interface IProps extends SelectProps {
  roles?: any;
  onChange?: any;
}

const SelectMultipleRole: React.FC<IProps> = ({ roles, onChange }) => {
  const { t } = useTranslation();

  const { loading, data, error } = useQuery<getRoles>(GET_ROLES, { fetchPolicy: "no-cache" });

  const currentUserRole = getUserData();
  const isAdmin = currentUserRole?.roles?.some((role) => role.name === "Admin");

  const rolesData = data?.roles || [];

  const filterRoles = (multipleRoles, val) => {
    return multipleRoles.filter(({ id }) => val.includes(id));
  };
  if (error) {
    return <>{error.graphQLErrors.toString()}</>;
  }

  if (loading) {
    return <p>Loading</p>;
  }

  return (
    <Select
      disabled={!isAdmin}
      mode="multiple"
      showSearch={true}
      optionFilterProp="children"
      loading={loading}
      value={roles.map(({ id }) => id)}
      onChange={(val) => onChange(filterRoles(rolesData, val))}
      style={{ width: "100%" }}
      placeholder={t("placeholders.ADD_ROLE")}>
      {rolesData.map(({ name, id }) => {
        return (
          <Select.Option key={id as number} value={id as number}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
export default SelectMultipleRole;
