import { Button, Modal } from "antd";
import React, { Fragment, useState } from "react";

const PerformanceReviewRules = ({ t }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Fragment>
      <Button icon="exclamation-circle" type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEW_RULES")}
      </Button>
      <Modal title="Review Rules" visible={modalOpen} onCancel={toggleModal} footer={null}>
        <h3> {t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_TITLE")}</h3>
        <ul>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_ONE")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_TWO")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_THREE")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_FOUR")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_FIVE")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_SIX")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_SEVEN")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_EIGHT")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_NINE")}</li>
          <li>{t("appConfiguration.PERFORMANCE_REVIEW.RULES.RULES_TEN")}</li>
        </ul>
      </Modal>
    </Fragment>
  );
};

export default PerformanceReviewRules;
