import React from "react";
import { TFunction } from "i18next";
import { CartesianGrid, LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend } from "recharts";
import { Skeleton } from "antd";
import { Query } from "@apollo/react-components";
import { GET_FINANCES } from "graphql/queries/FinanceQueries";
import moment from "moment";
import { roundTwoDecPlaces } from "helpers/round_number";

interface IProps {
  t: TFunction;
}

const HistoricalChart: React.FC<IProps> = ({ t }) => {
  return (
    <Query
      query={GET_FINANCES}
      variables={{
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
        team: null,
        projects: null,
        grossProfit: false,
      }}>
      {({ loading, error, data }: any) => {
        let mappedFinances: any = [];

        if (!loading && data?.finances) {
          mappedFinances = data.finances.map(({ month, revenue }) => ({
            month: moment(month).format("MMM YYYY"),
            revenue: roundTwoDecPlaces(revenue),
          }));
        }

        if (error) {
          return <p>Error</p>;
        }

        if (loading) {
          return <Skeleton active={true} />;
        }

        return (
          <>
            <ResponsiveContainer height={300}>
              <LineChart data={mappedFinances}>
                <XAxis dataKey="month" />
                <YAxis />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  name={t("analytics.STATISTICS.REVENUE")}
                  stroke="#8884d8"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </>
        );
      }}
    </Query>
  );
};

export default HistoricalChart;
