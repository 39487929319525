import React from "react";
import YearlyUtilizationRate from "./Component/YearlyUtilizationRate";
import MonthlyUtilizationRate from "./Component/MonthlyUtilizationRate";

function View() {
  return (
    <>
      <YearlyUtilizationRate />
      <MonthlyUtilizationRate />
    </>
  );
}

export default View;
