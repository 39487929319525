import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Badge, Button, Col, Collapse, Form, Icon, Layout, Modal, Row, Select, Spin, Tag } from "antd";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_EMPLOYEE_ABSENCE_BALANCE_FOR_PROFILE,
  GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_PROFILE,
} from "graphql/queries/Configurations";
import moment from "moment";
import { absenceColor } from "helpers/approveStatusAndAbsence";
import { absenceHistoryTypes } from "./components/helpers/absenceHistoryTypes";
import extendedInfoFromPanel from "screens/Calendars/components/ExtendedInfoFromPanel";
import AbsenceLogsHistory from "./components/AbsenceLogsHistory";
import CreateAbsence from "./components/CreateAbsence";
import { getUserData } from "../../common/AccessControl/Permissions";

const { Content } = Layout;

const AbsenceBalanceHistory = (userData) => {
  const { t } = useTranslation();
  const userProfileId = userData?.userData?.id;
  const currentUserRole = getUserData();
  const isAdmin = currentUserRole?.roles?.some((role) => role.name === "Admin");

  const {
    data: absenceBalanceData,
    loading: absenceBalanceLoading,
    error: absenceBalanceError,
  } = useQuery(GET_EMPLOYEE_ABSENCE_BALANCE_FOR_PROFILE, {
    variables: {
      id: userProfileId,
    },
  });

  const {
    data: absenceLogsData,
    loading: absenceLogsLoading,
    error: absenceLogsError,
  } = useQuery(GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_PROFILE, {
    variables: {
      id: userProfileId,
    },
  });

  let absenceHistory: absenceHistoryTypes[] = [];

  if (absenceBalanceLoading === false && absenceLogsLoading === false) {
    const absenceBalance = absenceBalanceData?.getEmployeeAbsenceBalanceForProfile;
    const absenceLogs = absenceLogsData?.getAllEmployeeAbsenceRequestsForProfile;

    absenceHistory = absenceBalance?.reduce((acc: absenceHistoryTypes[], balance: absenceHistoryTypes) => {
      const logs = absenceLogs?.filter((log: absenceHistoryTypes) => log.AbsenceTypeId === balance.AbsenceTypeId);
      const mergedData = {
        ...balance,
        logs: logs,
      };
      acc.push(mergedData);
      return acc;
    }, []);
  }

  const groupedHistory: { [key: string]: absenceHistoryTypes[] } = {};

  absenceHistory?.forEach((historyItem: absenceHistoryTypes) => {
    const year = moment(historyItem?.createdAt).format("YYYY");
    if (groupedHistory[year]) {
      groupedHistory[year].push(historyItem);
    } else {
      groupedHistory[year] = [historyItem];
    }
  });

  const [selectedBalance, setSelectedBalance] = useState(null);

  const handleBalanceItemClick = (balanceItem) => {
    setSelectedBalance(balanceItem);
  };

  if (absenceBalanceLoading || absenceLogsLoading)
    return (
      <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }} size="large" />
    );

  if (absenceBalanceError || absenceLogsError) return <p>Error</p>;

  return (
    <>
      <Content className="wrapper">
        {isAdmin ? <CreateAbsence t={t} userProfileId={userProfileId} /> : null}
        <Collapse defaultActiveKey={["1"]}>
          {Object.entries(groupedHistory).map(([year, historyItems]: [string, absenceHistoryTypes[]]) => (
            <Collapse.Panel header={`Absence balance ${year}`} key={year} style={{ marginBottom: 20 }}>
              <Content className="split-screen-history">
                <div className="left-side-history">
                  {historyItems.map((historyItem: absenceHistoryTypes) => {
                    const sortedLogs = historyItem?.logs?.filter((log: any) => log?.approveStatus != false);

                    const numberOfLogs = historyItem?.logs ? sortedLogs.length : 0;
                    return (
                      <a onClick={() => handleBalanceItemClick(historyItem)}>
                        <Collapse key={historyItem?.absenceTypes[0]?.absenceTypeName}>
                          <Collapse.Panel
                            style={{ marginBottom: 10 }}
                            header={historyItem?.absenceTypes[0]?.absenceTypeName}
                            key={year}
                            extra={
                              <span style={{ display: "flex", gap: 20 }}>
                                <Tag color={absenceColor(historyItem?.absenceTypes[0]?.absenceTypeName)}>
                                  {historyItem?.availableDaysOff === 1
                                    ? historyItem?.availableDaysOff + " day left"
                                    : historyItem?.availableDaysOff + " days left"}
                                </Tag>
                                <Badge
                                  style={{ fontSize: 8, padding: 0, lineHeight: "15px", height: 15, minWidth: 15 }}
                                  count={numberOfLogs}>
                                  <Icon style={{ fontSize: 20, color: "#1690ff" }} type="history" />
                                </Badge>
                              </span>
                            }>
                            <div>{extendedInfoFromPanel(t, historyItem)}</div>
                          </Collapse.Panel>
                        </Collapse>
                      </a>
                    );
                  })}
                </div>
                <div className="right-side-history">
                  <span>{selectedBalance && AbsenceLogsHistory(t, selectedBalance)}</span>
                </div>
              </Content>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Content>
    </>
  );
};

export default AbsenceBalanceHistory;
