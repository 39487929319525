import * as React from "react";
import { Modal, Form, Input, Row, Col, notification, Checkbox } from "antd";
import { Mutation } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormComponentProps } from "antd/lib/form";
import { GET_ROLES_SIMPLE } from "../../../../graphql/queries";
import { CREATE_ROLE } from "../../../../graphql/mutations";

interface IProps extends FormComponentProps {
  isOpen: boolean;
  onCancel: () => void;
}

class AddRoleModal extends React.Component<IProps & WithTranslation> {
  public render() {
    const { form, t } = this.props;

    return (
      <Mutation
        mutation={CREATE_ROLE}
        refetchQueries={[{ query: GET_ROLES_SIMPLE }]}
        onError={(error) => {
          // this.props.form.resetFields();
          // this.props.onCancel();
          notification.error({
            message: error.message.split(":")[1],
          });
        }}
        onCompleted={() => {
          notification.success({
            message: t("addRoleModal.MESSAGE"),
          });
          this.props.onCancel();
        }}>
        {(createRole, { loading }) => (
          <Modal
            title={t("addRoleModal.TITLE")}
            visible={this.props.isOpen}
            okButtonProps={{
              loading,
            }}
            onOk={() => {
              this.props.form.validateFields((err, values) => {
                if (!err) {
                  const { name, isInternal } = values;
                  createRole({
                    variables: {
                      name,
                      isInternal,
                    },
                  });
                }
              });
            }}
            onCancel={this.props.onCancel}
            afterClose={() => {
              form.resetFields();
            }}>
            <Form layout="horizontal">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("addRoleModal.LABELS.NAME")}>
                    {form.getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: t("addRoleModal.NAME_REQUIRED"),
                        },
                      ],
                    })(<Input placeholder={t("placeholders.ROLE_NAME")} />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("addRoleModal.LABELS.TYPE")}>
                    {form.getFieldDecorator("isInternal", {
                      valuePropName: "checked",
                      initialValue: true,
                    })(<Checkbox>Internal Role</Checkbox>)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    );
  }
}

export default Form.create<IProps>()(withTranslation()(AddRoleModal));
