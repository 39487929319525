import React from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { DELETE_TAG } from "../../../../../../graphql/mutations";
import { Button, Popconfirm, Form, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { GET_PROJECT_TAGS, GET_TAGS } from "../../../../../../graphql/queries";

interface IProps extends FormComponentProps {
  t: TFunction;
  tag: any;
  projectId: number;
}

const MilestoneEdit: React.FC<IProps> = ({ t, tag, projectId, form }) => {
  return (
    <Mutation
      mutation={DELETE_TAG}
      refetchQueries={[
        {
          query: GET_PROJECT_TAGS,
          variables: { projectId },
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.TAGS.MESSAGES.TAG_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteTag, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteTag({ variables: { id: tag.id } })}>
          <Button type="danger" className="mr-10" loading={loading} icon="delete">
            {t("buttons.DELETE")}
          </Button>
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(MilestoneEdit);
