import React, { useState } from "react";
import { Tag, Icon, Tooltip, Divider, Modal, Descriptions } from "antd";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { getUserData } from "common/AccessControl/Permissions";

const PerformanceReviewManager = ({ selectedReview }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleClick = (item) => {
    if (item?.isPrivate) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
      setModalVisible(true);
    }

    setModalVisible(!item?.isPrivate);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {selectedReview?.managerReviews?.map((reviewItem: any) => {
        return (
          <div className="pill-container-review" key={reviewItem?.ReviewerId}>
            <div className="left-content-review">
              <h4 style={{ marginTop: 5 }}>{reviewItem?.Reviewer}</h4>
              <h5>
                {"Submit date: "}
                <span style={reviewItem?.submittedAt != null ? { color: "green" } : { color: "red" }}>
                  {reviewItem?.submittedAt != null
                    ? moment(reviewItem?.submittedAt).format("DD/MM/YYYY")
                    : t("appConfiguration.PERFORMANCE_REVIEW.NOT_SUBMITTED")}
                </span>
              </h5>
            </div>
            <Divider type="vertical" style={{ height: 50 }} />
            <div className="right-content-review">
              <Tooltip
                title={
                  reviewItem.isPrivate
                    ? t("appConfiguration.PERFORMANCE_REVIEW.PRIVATE_REVIEW")
                    : t("appConfiguration.PERFORMANCE_REVIEW.PUBLIC_REVIEW")
                }>
                <Tag color={reviewItem?.isPrivate ? "red" : "blue"} onClick={() => handleClick(reviewItem)}>
                  <Icon type={reviewItem?.isPrivate ? "eye-invisible" : "eye"} />
                </Tag>
              </Tooltip>
            </div>
            <Divider type="vertical" style={{ height: 50 }} />
            <div className="right-content-review">
              <Tag color={reviewItem?.submittedAt != null ? "green" : "orange"}>
                {reviewItem?.submittedAt != null ? "Submitted" : "Pending"}
              </Tag>
            </div>
          </div>
        );
      })}
      {selectedItem && (
        <Modal
          width={1000}
          style={{ top: 20 }}
          title={`${selectedItem?.Reviewer} answers`}
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}>
          <Descriptions size="small" layout="vertical" bordered>
            {Object.keys(JSON.parse(selectedItem?.template?.questions)).map((questionItem) => (
              <Descriptions.Item
                span={4}
                label={`Q${Number(questionItem) + 1}: ${JSON.parse(selectedItem?.template?.questions)[questionItem]} `}>
                <p style={JSON.parse(selectedItem?.responses)[questionItem] ? { color: "Black" } : {}}>{`${
                  JSON.parse(selectedItem?.responses)[questionItem] || "No answer"
                }`}</p>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Modal>
      )}
    </>
  );
};

export default PerformanceReviewManager;
