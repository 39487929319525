import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { List, Avatar, Spin, Empty, Button, Select, Divider } from "antd";
import moment from "moment";
import { TFunction } from "i18next";
import { GET_COMPANY_BRANCH } from "graphql/queries/Configurations";
import { holidayActiveImg, holidayImg } from "helpers/appImages";
import { getDaysFromToday } from "./helpers/holidayHelper";
import { getUserData } from "common/AccessControl/Permissions";

interface IProps {
  t: TFunction;
}

const HomeHolidays: React.FC<IProps> = ({ t }) => {
  const { data, loading, error } = useQuery(GET_COMPANY_BRANCH);
  const holidayData = data?.getCompanyBranches;

  const currentUser = getUserData();
  const currentUserCountry = currentUser?.employee?.companyBranch?.country;
  const currentUserCity = currentUser?.employee?.companyBranch?.city;

  const [visibleItems, setVisibleItems] = useState(3);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(`${currentUserCountry} ${currentUserCity} `);

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }

  if (!data || !holidayData || holidayData.length === 0) {
    return <Empty />;
  }

  if (error) {
    return <p>Error</p>;
  }

  const today = moment().startOf("day");

  const upcomingHolidays = holidayData
    .filter((holidayItem: any) => holidayItem.holidayTypes?.[0]?.date?.length > 0)
    .map((holidayItem: any) => {
      const holidayDates = holidayItem?.holidayTypes?.[0]?.date;
      const upcomingDates = holidayDates.filter((date: string) => {
        const holiday = moment(date, "YYYY-MM-DD");
        return holiday.isSameOrAfter(today, "day");
      });
      return { ...holidayItem, holidayTypes: [{ date: upcomingDates }] };
    });

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  const { Option } = Select;

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    setVisibleItems(3);
  };

  const filteredHolidays = upcomingHolidays.filter((holidayItem: any) => {
    const country = holidayItem.country.toLowerCase();
    const city = holidayItem.city.toLowerCase();

    if (selectedFilter) {
      const [filterCountry, filterCity] = selectedFilter.toLowerCase().split(" ");
      return country.includes(filterCountry) && city.includes(filterCity);
    }
    return true;
  });

  const renderItem = ({ country, city, date }: any) => {
    const daysFromToday = getDaysFromToday(date);
    const holiday = moment(date, "YYYY-MM-DD");
    let description = `Holiday: ${holiday.format("DD/MM/YYYY")}`;

    if (daysFromToday === 0) {
      description = "Today is holiday";
    } else {
      description += ` (${daysFromToday} days from today)`;
    }

    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape="square" src={daysFromToday === 0 ? holidayActiveImg : holidayImg} />}
          title={`${country} - ${city}`}
          description={description}
        />
      </List.Item>
    );
  };

  return (
    <>
      <Select
        showSearch
        allowClear
        filterOption={(input, option) =>
          (option?.props?.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Filter by country or city"
        style={{ width: "100%", marginBottom: 16 }}
        value={selectedFilter}
        onChange={handleFilterChange}>
        {holidayData.map((holidayItem: any, index: number) => {
          const holidayDates = holidayItem?.holidayTypes?.[0]?.date;
          if (holidayDates && holidayDates.length > 0) {
            return (
              <Option key={index} value={`${holidayItem.country} ${holidayItem.city}`}>
                {`${holidayItem.country} ${holidayItem.city}`}
              </Option>
            );
          }
          return null;
        })}
      </Select>

      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={filteredHolidays
          .slice(0, visibleItems)
          .flatMap((holidayItem: any) =>
            holidayItem.holidayTypes[0].date.map((date: string) => ({ ...holidayItem, date }))
          )}
        renderItem={renderItem}
      />

      {filteredHolidays.length > visibleItems && (
        <Button size="small" onClick={handleLoadMore}>
          Load More
        </Button>
      )}
    </>
  );
};

export default HomeHolidays;
