import React from "react";
import { Table } from "antd";
import { TFunction } from "i18next";
import { formatDate } from "../../../utils/date";
import { Query } from "@apollo/react-components";
import { GET_TIMELOGS_FOR_REPORT } from "graphql/queries";
import { IReportStatistics } from "../../../graphql/queries/Interfaces";
import { secondsToDuration } from "../../../helpers/timeDuration";

interface IProps {
  t: TFunction;
  filters: any;
  stats: IReportStatistics;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const PAGE_SIZE = 30;

const TableReport: React.FC<IProps> = ({ t, filters, setCurrentPage, currentPage, stats }) => {
  const columns = [
    {
      title: t("timelogs.CREATE_TAG.LOG_DATE"),
      dataIndex: "logDate",
      render: (date) => formatDate(date),
    },
    {
      title: t("timelogsTableColumns.REPORTER"),
      dataIndex: "reporter",
    },
    {
      title: t("timelogsTableColumns.DURATION"),
      dataIndex: "duration",
      render: (duration) => secondsToDuration(duration),
    },
    {
      title: t("timelogs.CREATE_TAG.TAG"),
      dataIndex: "tagName",
    },
    {
      title: t("timelogs.CREATE_MODAL.PROJECT"),
      dataIndex: "projectName",
    },
  ];

  return (
    <div>
      <Query
        query={GET_TIMELOGS_FOR_REPORT}
        variables={{ ...filters, limit: PAGE_SIZE }}
        notifyOnNetworkStatusChange={true}>
        {({ loading, error, data, fetchMore }: any) => {
          let dataSource;

          if (!!data && !loading && data.getTimelogsForReport) {
            dataSource = data.getTimelogsForReport;
          }

          if (error) {
            return <p>Error</p>;
          }

          return (
            <Table
              className="mb-15"
              bordered={true}
              size="small"
              dataSource={dataSource}
              loading={loading}
              style={{ whiteSpace: "pre-wrap" }}
              expandedRowRender={(record: { description: string }) => record.description}
              rowKey="id"
              columns={columns}
              onChange={() => {
                document.body.scrollTop = 400; // For Safari
                document.documentElement.scrollTop = 400;
              }}
              pagination={{
                simple: true,
                current: currentPage,
                total: stats.totalTimelogsCount,
                defaultPageSize: PAGE_SIZE,
                onChange: (page) => {
                  setCurrentPage(page);
                  if (!dataSource[PAGE_SIZE * (page - 1)]) {
                    fetchMore({
                      query: GET_TIMELOGS_FOR_REPORT,
                      variables: {
                        ...filters,
                        cursor: dataSource[dataSource.length - 1].logDate,
                        limit: PAGE_SIZE,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        const previousTimelogs = previousResult.getTimelogsForReport;
                        const newTimelogs = fetchMoreResult.getTimelogsForReport;
                        if (JSON.stringify(previousResult) !== JSON.stringify(fetchMoreResult)) {
                          return {
                            getTimelogsForReport: [...previousTimelogs, ...newTimelogs],
                          };
                        } else {
                          return {
                            getTimelogsForReport: [...previousTimelogs],
                          };
                        }
                      },
                    });
                  }
                },
              }}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default TableReport;
