import { Avatar, Button, Input, Layout, Form, Row, Col, Alert, Icon, notification } from "antd";
import { RouteComponentProps } from "react-router-dom";
import * as React from "react";
import Client from "../../../graphql/ApolloClient";
import { FormComponentProps } from "antd/lib/form";
import { NEW_PASSWORD } from "../../../graphql/queries";
import { CHANGE_PASSWORD } from "../../../graphql/mutations";
import { Mutation } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
const { Content } = Layout;

interface IProps extends FormComponentProps {
  onChange: (changedFields) => void;
}

interface IParams {
  token: string;
}

class ResetPasswordFrom extends React.Component<RouteComponentProps<IParams> & IProps & WithTranslation> {
  public state = {
    loading: false,
    expiredToken: false,
    message: "",
    errorAlert: false,
  };

  public componentDidMount() {
    const resetPasswordToken = this.props.match.params.token;

    Client.query({ query: NEW_PASSWORD, variables: { token: resetPasswordToken } }).catch((err) =>
      this.setState({ expiredToken: true })
    );
  }

  public render() {
    const { form, t } = this.props;
    const compareToFirstPassword = (rule, value, callback) => {
      if (value && value !== form.getFieldValue("newPassword")) {
        callback(t("login.VALIDATION.NOT_MATCH_PASSWORD"));
      } else {
        callback();
      }
    };

    const validateNewPasswordLength = (rule, value, callback) => {
      if (value && value.length < 6) {
        callback(t("login.VALIDATION.LOW_LENGTH_PASSWORD"));
      } else {
        callback();
      }
    };

    const handleSubmit = (changePassword) => {
      const token = this.props.match.params.token;
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const { newPassword, confirmPassword } = values;
          changePassword({ variables: { newPassword, confirmPassword, token } });
          this.setState({
            disabled: true,
          });
          this.props.form.resetFields();
        }
      });
    };

    return (
      <Mutation
        mutation={CHANGE_PASSWORD}
        onCompleted={(data) => {
          this.setState({
            message: data.message,
            loading: false,
          });
          this.props.history.push({ pathname: "/login" });
          notification.success({
            duration: 10,
            message: t("login.MESSAGES.PASSWORD_RESET_COMPLETE"),
          });
        }}
        onError={(error) => {
          this.setState({
            message: error.response.data,
            loading: false,
            errorAlert: true,
          });
        }}>
        {(changePassword, { loading }) => (
          <Layout>
            <Content className="loginWrapper">
              <div className="login">
                <Avatar size={100} className="login-avatar">
                  <Icon type="api" />
                </Avatar>
                <div className="other-title">Reset Password</div>
                {!this.state.expiredToken && (
                  <Form>
                    <Row>
                      <Col span={24}>
                        <Form.Item label={t("login.LOGIN_FORM_LABELS.NEW_PASSWORD")} hasFeedback={true}>
                          {form.getFieldDecorator("newPassword", {
                            rules: [
                              {
                                required: true,
                                message: t("login.MESSAGES.PROVIDE_NEW_PASSWORD"),
                              },
                              {
                                validator: validateNewPasswordLength,
                              },
                            ],
                          })(<Input.Password size="large" placeholder={t("login.PLACEHOLDERS.NEW_PASSWORD")} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label={t("login.LOGIN_FORM_LABELS.CONFIRM_PASSWORD")} hasFeedback={true}>
                          {form.getFieldDecorator("confirmPassword", {
                            rules: [
                              {
                                required: true,
                                message: t("login.MESSAGES.CONFIRM_NEW_PASSWORD"),
                              },
                              {
                                validator: compareToFirstPassword,
                              },
                            ],
                          })(
                            <Input.Password
                              size="large"
                              type="password"
                              placeholder={t("login.PLACEHOLDERS.CONFIRM_PASSWORD")}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>

                    {this.state.errorAlert && (
                      <Alert style={{ margin: "0px 20px" }} type="error" message={this.state.message} />
                    )}

                    <Button type="primary" onClick={() => handleSubmit(changePassword)} loading={loading}>
                      {t("buttons.CONFIRM")}
                    </Button>
                  </Form>
                )}
                {this.state.expiredToken && (
                  <Alert style={{ margin: "0px 20px" }} type="error" message={t("login.MESSAGES.EXPIRED_TOKEN")} />
                )}
                <div className="login-form-forgot">
                  <span
                    className="forgot-password-text"
                    onClick={() => this.props.history.replace({ pathname: "/login" })}>
                    {t("login.BACK_TO_LOGIN")}
                  </span>
                </div>
              </div>
            </Content>
          </Layout>
        )}
      </Mutation>
    );
  }
}

export default Form.create()(withTranslation()(ResetPasswordFrom));
