import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import {
  Button,
  Form,
  Col,
  Input,
  Modal,
  InputNumber,
  notification,
  Row,
  Checkbox,
  Select,
  DatePicker,
  Spin,
  Icon,
} from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { GET_ABSENCE_TYPES, GET_COMPANY_BRANCH } from "../../../../../graphql/queries/Configurations";
import TextArea from "antd/lib/input/TextArea";
import { CREATE_ABSENCE_TYPE } from "graphql/mutations";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { absenceTypesEnum } from "./AbsenceTypesEnum";

interface IProps extends FormComponentProps {
  t: TFunction;
}

const { Option } = Select;

const AbsenceTypesCreate: React.FC<IProps> = ({ t, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [expirationDateValue, setExpirationDateValue] = useState("");

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const { loading: loadingBranch, data: branchData } = useQuery(GET_COMPANY_BRANCH, { fetchPolicy: "no-cache" });

  return (
    <Fragment>
      <Button type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.ABSENCE_TYPES.CREATE_ABSENCE_TYPE")}
      </Button>
      <Mutation
        mutation={CREATE_ABSENCE_TYPE}
        refetchQueries={[
          {
            query: GET_ABSENCE_TYPES,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.ABSENCE_TYPE_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createAbsenceType, { loading }) => (
          <Modal
            title={t("appConfiguration.ABSENCE_TYPES.CREATE_ABSENCE_TYPE")}
            width={600}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { absenceTypeName, description, numberOfDays, CompanyBranchId } = values;

                  createAbsenceType({
                    variables: {
                      absenceTypeName,
                      description,
                      numberOfDays,
                      expirationDate: expirationDateValue,
                      CompanyBranchId,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.ABSENCE_TYPE_NAME")}>
                    {form.getFieldDecorator("absenceTypeName", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.NAME_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        allowClear
                        showSearch
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.ABSENCE_TYPE_NAME")}>
                        {absenceTypesEnum.map((absenceItem) => (
                          <Option value={absenceItem} key={absenceItem}>
                            {absenceItem}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.HOLIDAY_TYPES.LABELS.COMPANY_BRANCH")}>
                    {form.getFieldDecorator("CompanyBranchId", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.COMPANY_BRANCH_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        allowClear
                        loading={loadingBranch}
                        placeholder={
                          loadingBranch
                            ? "Loading branch..."
                            : t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.COMPANY_BRANCH")
                        }>
                        {branchData?.getCompanyBranches.map((branchItem) => (
                          <Option key={branchItem.id} value={branchItem.id}>
                            {branchItem.country + " / " + branchItem.city}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.HOLIDAY_LIMIT")}>
                    {form.getFieldDecorator("numberOfDays", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.HOLIDAY_LIMIT_REQUIRED"),
                        },
                      ],
                    })(
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.HOLIDAY_LIMIT")}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.EXPIRATION_DATE")}>
                    {form.getFieldDecorator("expirationDate", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.EXPIRATION_DATE_REQUIRED"),
                        },
                      ],
                    })(
                      <DatePicker
                        allowClear={false}
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => setExpirationDateValue(dateString || "")}
                        disabledDate={(current) => (current ? current < moment().startOf("day") : false)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.ABSENCE_TYPES.LABELS.DESCRIPTION")}>
                    {form.getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                        },
                      ],
                    })(
                      <TextArea
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.DESCRIPTION")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(AbsenceTypesCreate);
