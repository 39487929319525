import * as React from "react";
import { Select } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
  months?: any;
  setMonth: any;
  year?: any;
  month: any;
}

const MonthSelector = (props: IProps) => {
  const handleChangeVal = (value) => {
    props.setMonth(value);
  };
  const { t } = props;

  if (props.year) {
    return props.months ? (
      <>
        {
          <>
            <Select
              allowClear={true}
              showSearch={true}
              optionFilterProp="children"
              style={{ width: "180px", marginLeft: "10px" }}
              placeholder={t("placeholders.CHOOSE_MONTH")}
              value={props.month}
              onChange={handleChangeVal}>
              {props.months.map((item, i) => (
                <Select.Option value={item.monthNumber} key={i}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </>
        }
      </>
    ) : null;
  } else {
    return null;
  }
};

export default withTranslation()(MonthSelector);
