import React, { Fragment, useEffect, useState } from "react";

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TFunction } from "i18next";
import moment from "moment";
import { IUtilization } from "../../../graphql/queries/Interfaces";
import * as _ from "lodash";
import client from "../../../graphql/ApolloClient";
import { GET_MONTH_BENCHMARK } from "../../../graphql/queries";

interface IProps {
  t: TFunction;
  showBenchMark: boolean;
  month: moment.Moment;
  utilization: IUtilization[];
  dataKey: string;
}

const LineChartGraph: React.FC<IProps> = ({ t, month, utilization, dataKey, showBenchMark }) => {
  const [benchMark, setBenchMark] = useState<number | null>(null);

  useEffect(() => {
    client
      .query({ query: GET_MONTH_BENCHMARK, variables: { month } })
      .then((res) => {
        setBenchMark(res.data.getMonthBenchmark.percentage);
      })
      .catch(() => {});
  }, [month]);

  const maxPercentage = () => {
    return _.maxBy(utilization, "percentage").percentage;
  };

  const maxUtilization = () => {
    const maxHours = maxPercentage();

    if (!!benchMark && benchMark >= maxHours) {
      return Math.ceil(benchMark / 25) * 25 + 25;
    }

    if ((!!benchMark && benchMark < maxHours) || (benchMark === null && maxHours > 0)) {
      return Math.ceil(maxHours / 25) * 25 + 25;
    }

    return 100;
  };

  const customTicks = () => {
    const ticks: number[] = [];

    for (let i = 0; i <= maxUtilization(); i += 25) {
      ticks.push(i);
    }

    return ticks.sort((a, b) => a - b);
  };

  const mappedUtilization = utilization.map((item) => ({
    ...item,
    benchMark,
  }));

  return (
    <Fragment>
      <ResponsiveContainer height={300}>
        <LineChart data={mappedUtilization} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey={dataKey} />
          {showBenchMark ? (
            <YAxis type="number" domain={[0, maxUtilization()]} ticks={customTicks()} />
          ) : (
            <YAxis type="number" domain={[0, maxUtilization()]} />
          )}
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip active={true} />
          <Legend />
          <Line
            type="monotone"
            dataKey="percentage"
            stroke="#8884d8"
            name={t("appConfiguration.BENCHMARK.LABELS.PERCENTAGE")}
          />
          {showBenchMark && (
            <Line
              type="monotone"
              dot={false}
              dataKey="benchMark"
              stroke="#82ca9d"
              name={t("appConfiguration.BENCHMARK.TITLE")}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export default LineChartGraph;
