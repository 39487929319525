import React from "react";
import { Mutation } from "@apollo/react-components";
import { DELETE_LEAVE_REQUEST } from "../../../../graphql/mutations/LeaveManagementMutations";
import { Button, notification, Popconfirm } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TFunction } from "i18next";

interface IProps extends RouteComponentProps {
  t: TFunction;
  id: number;
  destination: string;
}

const DeleteLeaveRequest: React.FC<IProps> = ({ t, id, destination, history }) => {
  return (
    <Mutation
      mutation={DELETE_LEAVE_REQUEST}
      // refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
      onCompleted={() => {
        history.push(destination);
        notification.success({
          message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_DELETED"),
        });
      }}>
      {(deleteLeaveRequest, { loading }): any => {
        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                deleteLeaveRequest({ variables: { id } }).then();
              }}>
              <Button loading={loading} type="danger" icon="delete" style={{ marginLeft: "5px" }}>
                {t("buttons.DELETE")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

export default withRouter(DeleteLeaveRequest);
