import React from "react";
import { Statistic, Skeleton } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
interface IProps {
  holidaysLeft: any;
  allowedHolidays: number;
  loading: boolean;
  selectedYear: string;
}
const HolidaysLeft = ({ t, holidaysLeft, allowedHolidays, loading, selectedYear }: IProps & WithTranslation) => {
  return (
    <div className="daysleft-statistic">
      <Skeleton loading={loading} active={true} title={{ width: "145px" }} paragraph={{ rows: 1, width: ["90px"] }}>
        <Statistic
          title={`${t("leaveRequests.HOLIDAYS_LEFT")} ${selectedYear}`}
          value={holidaysLeft}
          suffix={`/ ${allowedHolidays} days`}
          valueStyle={holidaysLeft && holidaysLeft <= 5 ? { color: "red" } : { color: "green" }}
        />
      </Skeleton>
    </div>
  );
};

export default withTranslation()(HolidaysLeft);
