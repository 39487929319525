import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Layout, Timeline } from "antd";
import { useTranslation } from "react-i18next";
import { GET_CONTRACTS_HISTORY_TIMELINE } from "../../../graphql/queries/Contracts";
import { Query } from "@apollo/react-components";
import moment from "moment";
import * as employmentTypes from "constants/employmentType";

interface IProps extends RouteComponentProps<{ id?: string }> {
  userId?: number;
}
const UserTimeline: React.FC<IProps> = ({ match, userId }) => {
  const { t } = useTranslation();

  return (
    <Query
      query={GET_CONTRACTS_HISTORY_TIMELINE}
      variables={{ userId: match.params.id || userId ? Number(match.params.id || userId) : undefined }}>
      {({ loading, error, data }: any) => {
        let contracts;

        if (!loading && data?.employee?.contracts) {
          contracts = data.employee.contracts;
        }

        if (error) {
          return <p>Error</p>;
        }

        if (loading) {
          return <Timeline pending={t("general.LOADING")} />;
        }

        return (
          <Layout.Content className="user-contracts-timeline">
            <Timeline>
              {contracts.map((contract) => {
                const contractExpired = moment().isAfter(moment(contract.endDate));
                const startDate = moment(contract.startDate).format("DD MMM YYYY");
                const endDate = contract.endDate
                  ? moment(contract.endDate).format("DD MMM YYYY")
                  : t("general.PRESENT");

                return (
                  <Timeline.Item key={contract.id} color={contractExpired ? "grey" : "green"}>
                    <p>
                      {t("contracts.form.EMPLOYMENT_TYPE")}: {contract.employmentType}
                    </p>
                    <p>
                      {t("contracts.form.POSITION")}: {contract.position}
                    </p>
                    <p>
                      {t("contracts.form.PERIOD")}: {startDate.concat(" - ", endDate)}
                    </p>
                    {contract.employmentType === employmentTypes.contractor ? (
                      <>
                        <p>
                          {t("contracts.form.HOURLY_RATE")}: {contract.hourlyRate}
                        </p>
                        <p>
                          {t("contracts.form.WORKING_HOURS_PER_MONTH")}: {contract.workingHoursPerMonth}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          {t("contracts.form.SALARY")}: {contract.salary}
                        </p>
                      </>
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Layout.Content>
        );
      }}
    </Query>
  );
};

export default withRouter(UserTimeline);
