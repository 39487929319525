import React, { useState, Fragment } from "react";
import HolidaysLeft from "./holidaysLeft";
import EditButton from "./editLeaveRequest";
import CreateLeaveRequest from "./addLeaveRequest";
import moment from "moment";
import StatusTag from "../../../../common/LeaveRequestTag";
import { Table, Button, Popconfirm, notification, Alert, Statistic, Select, Skeleton, Badge, Tooltip } from "antd";
import { generateKey } from "../../../../utils/listKey";
import { stringToHTML } from "../../../../helpers/draftjs";
import { formatDate } from "../../../../utils/date";
import { getWorkingYears } from "../../../../utils/availableYears";
import { withPermissionsFnc } from "../../../../decorators/permissions";
import { AccessControl } from "../../../../common/AccessControl";
import constants from "../../../../constants";
import { withTranslation, WithTranslation } from "react-i18next";
import { GET_LEAVE_REQUESTS_BY_ROLE } from "../../../../graphql/queries/LeaveManagementQueries";
import { Query, Mutation } from "@apollo/react-components";
import {
  DELETE_LEAVE_REQUEST,
  UPDATE_LEAVE_REQUEST_STATUS,
} from "../../../../graphql/mutations/LeaveManagementMutations";
import { Link } from "react-router-dom";

const ActionsComponent = ({ t, id, data, selectedYear }) => {
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(false);

  return (
    <div className="actions">
      {data.employeeStatus === "inactive" && (
        <div className="leave-request-actions">
          <ConfirmButton
            t={t}
            id={id}
            action={"confirm"}
            disable={disableConfirm}
            changeEditDisability={() => setDisableEdit}
            changeDeleteDisability={() => setDisableDelete}
            selectedYear={selectedYear}
          />
          <DeleteButton
            id={id}
            t={t}
            disable={disableDelete}
            setDisabelDelete={() => setDisableDelete}
            changeConfirmDisability={() => setDisableConfirm}
            changeEditDisability={() => setDisableEdit}
            selectedYear={selectedYear}
          />
          <EditButton data={data} disable={disableEdit} id={id} selectedYear={selectedYear} />
        </div>
      )}

      {data.employeeStatus === "active" &&
        data.deputyStatus !== "Declined" &&
        data.managerStatus !== "Declined" &&
        moment().toDate() < moment(data.startDate).toDate() && (
          <div className="leave-request-actions">
            <CancelButton t={t} id={id} selectedYear={selectedYear} action={"cancel"} />
          </div>
        )}
      {data.employeeStatus === "active" && moment().toDate() > moment(data.startDate).toDate() && (
        <Alert message={t("leaveRequests.ACCRUED")} type="success" showIcon={true} style={{ width: "205px" }} />
      )}
      {data.employeeStatus === "cancel" && (
        <Alert message={t("leaveRequests.WAITING")} type="warning" showIcon={true} style={{ width: "205px" }} />
      )}
    </div>
  );
};

interface IProps extends WithTranslation {
  permissions: any;
}

const MyLeaveRequests = ({ permissions, t }: IProps) => {
  const [selectedYear, setSelectedYear] = useState(JSON.stringify(moment().year()));
  return (
    <Fragment>
      <AccessControl
        userPermissions={permissions.leaveRequests}
        allowedPermissions={constants.permissions.BUTTONS.CREATE_LEAVE_REQUEST}>
        <CreateLeaveRequest selectedYear={selectedYear} setYear={setSelectedYear} />
      </AccessControl>

      <AccessControl
        multipleResourceUserPermissions={{
          employee: permissions.employees,
          leaveRequests: permissions.leaveRequests,
        }}
        multipleResourceAllowedPermissions={{
          employee: constants.permissions.READ_OWN_EMPLOYEE,
          leaveRequests: constants.permissions.READ_OWN_LEAVE_REQUESTS,
        }}>
        <div className="tableWrapper">
          <Query
            query={GET_LEAVE_REQUESTS_BY_ROLE}
            variables={{ leaveRequestRole: "employee", year: selectedYear }}
            fetchPolicy="network-only">
            {({ loading, error, data }: any) => {
              if (error) {
                return <div>Error</div>;
              }
              let myLeaveRequests;
              let daysLeft;
              let allowedDays;
              let contractStartYear;
              let currentYear;
              if (!loading && !!data && data.employee && data.employee.leaveRequests) {
                contractStartYear = moment(data.employee.contracts[0].startDate).year();

                currentYear = moment().year();
                daysLeft = data.employee.holidaysLeft.holidaysLeft;
                allowedDays = data.employee.holidaysLeft.possibleHolidays;
                myLeaveRequests = data.employee.leaveRequests.map(
                  ({
                    id,
                    leaveRequestType: { id: leaveId, leaveTypeName },
                    comment,
                    startDate,
                    endDate,
                    duration,
                    daysUsedFromLastYear,
                    employeeStatus,
                    deputyId,
                    deputyStatus,
                    managerStatus,
                    deputy: {
                      User: { firstName, lastName },
                    },
                    employee: {
                      User: { firstName: employeeName, lastName: employeeLastname },
                    },
                  }) => ({
                    id,
                    leaveTypeName,
                    leaveId,
                    comment,
                    startDate,
                    endDate,
                    daysUsedFromLastYear,
                    duration,
                    employeeStatus,
                    deputyStatus,
                    managerStatus,
                    deputyId,
                    deputy: `${firstName} ${lastName}`,
                    employee: `${employeeName} ${employeeLastname}`,
                  })
                );
              }
              return (
                <>
                  <div style={{ display: "flex" }}>
                    <HolidaysLeft
                      holidaysLeft={daysLeft}
                      allowedHolidays={allowedDays}
                      loading={loading}
                      selectedYear={selectedYear}
                    />
                    <div className="year-filter">
                      <Skeleton
                        loading={loading}
                        active={true}
                        title={{ width: "145px" }}
                        paragraph={{ rows: 1, width: ["90px"] }}>
                        <Statistic
                          title={t("leaveRequests.FILTER_WORKING_YEAR")}
                          formatter={() => (
                            <Select
                              style={{ width: "90px" }}
                              defaultValue={selectedYear}
                              onChange={(val) => {
                                setSelectedYear(val);
                              }}>
                              {getWorkingYears(contractStartYear, currentYear).map((item) => (
                                <Select.Option key={item}>{item}</Select.Option>
                              ))}
                            </Select>
                          )}
                        />
                      </Skeleton>
                    </div>
                  </div>

                  {
                    <AccessControl
                      userPermissions={permissions.leaveRequests}
                      allowedPermissions={constants.permissions.LISTS.READ_OWN_LEAVE_REQUESTS}>
                      <Table
                        data-cy="table"
                        size="small"
                        loading={loading}
                        style={{ whiteSpace: "pre-wrap" }}
                        dataSource={myLeaveRequests}
                        expandedRowRender={(record: { comment: string }) => stringToHTML(record.comment)}
                        rowKey={() => generateKey()}
                        pagination={{ defaultPageSize: 10 }}
                        className="leave-request-table">
                        <Table.Column
                          title={t("leaveRequests.tableColumns.START_DATE")}
                          key="startDate"
                          render={({ startDate }) => formatDate(startDate)}
                        />
                        <Table.Column title="End Date" key="endDate" render={({ endDate }) => formatDate(endDate)} />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.DURATION")}
                          key="duration"
                          align="center"
                          render={({ duration, daysUsedFromLastYear }) => (
                            <div>
                              {duration} {duration > 1 ? "days" : "day"}{" "}
                              <Tooltip placement="rightTop" title={t("leaveRequests.DAYS_TOOLTIP")}>
                                <Badge count={daysUsedFromLastYear} />
                              </Tooltip>
                            </div>
                          )}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.LEAVE_TYPE")}
                          key="leaveType"
                          align="center"
                          render={({ leaveTypeName }) => leaveTypeName}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.DEPUTY")}
                          key="deputy"
                          align="center"
                          render={({ deputy }) => deputy}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.DEPUTY_STATUS")}
                          key="deputyStatus"
                          align="center"
                          render={({ deputyStatus }) => <StatusTag status={deputyStatus} />}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.APPROVAL_STATUS")}
                          key="managerStatus"
                          align="center"
                          render={({ managerStatus }) => <StatusTag status={managerStatus} />}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.ACTIONS")}
                          align="center"
                          key="status"
                          width={250}
                          className="button-actions"
                          render={({
                            employeeStatus,
                            deputyStatus,
                            managerStatus,
                            id,
                            endDate,
                            startDate,
                            comment,
                            deputyId,
                            leaveId,
                          }) => {
                            return (
                              <>
                                <ActionsComponent
                                  t={t}
                                  id={id}
                                  data={{
                                    employeeStatus,
                                    deputyStatus,
                                    managerStatus,
                                    startDate,
                                    endDate,
                                    comment,
                                    deputyId,
                                    leaveId,
                                  }}
                                  selectedYear={selectedYear}
                                />
                              </>
                            );
                          }}
                        />
                        <Table.Column
                          title={t("leaveRequests.tableColumns.DETAILS")}
                          key="details"
                          align="center"
                          render={(record) => {
                            return (
                              <Link
                                to={{
                                  pathname: `/dashboard/leave-request/${record.id}`,
                                }}>
                                <Button
                                  type="default"
                                  icon="dash"
                                  style={{ color: "#1890ff", borderColor: "#1890ff" }}
                                />
                              </Link>
                            );
                          }}
                        />
                      </Table>
                    </AccessControl>
                  }
                </>
              );
            }}
          </Query>
        </div>
      </AccessControl>
    </Fragment>
  );
};

const ConfirmButton = ({ t, id, action, disable, changeEditDisability, changeDeleteDisability, selectedYear }) => {
  return (
    <>
      <Mutation
        mutation={UPDATE_LEAVE_REQUEST_STATUS}
        refetchQueries={[
          { query: GET_LEAVE_REQUESTS_BY_ROLE, variables: { leaveRequestRole: "employee", year: selectedYear } },
        ]}
        awaitRefetchQueries={true}
        onCompleted={() => {
          notification.success({
            message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_SUBMITTED"),
          });
        }}>
        {(updateLeaveRequestStatus, { loading }): any => {
          if (loading) {
            changeEditDisability(loading);
            changeDeleteDisability(loading);
          }
          return (
            <div className="action-button">
              <React.Fragment>
                <Button
                  icon="check-circle"
                  type="primary"
                  loading={loading}
                  disabled={disable}
                  onClick={() => {
                    updateLeaveRequestStatus({
                      variables: { id, action },
                    });
                  }}
                  className="action-button">
                  {t("buttons.SUBMIT")}
                </Button>
              </React.Fragment>
            </div>
          );
        }}
      </Mutation>
    </>
  );
};

const DeleteButton = ({
  t,
  id,
  disable,
  setDisabelDelete,
  changeConfirmDisability,
  changeEditDisability,
  selectedYear,
}) => {
  return (
    <Mutation
      mutation={DELETE_LEAVE_REQUEST}
      refetchQueries={[
        { query: GET_LEAVE_REQUESTS_BY_ROLE, variables: { leaveRequestRole: "employee", year: selectedYear } },
      ]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_DELETED"),
        });
      }}>
      {(deleteLeaveRequest, { loading }): any => {
        if (loading) {
          setDisabelDelete(true);
          changeConfirmDisability(loading);
          changeEditDisability(loading);
        }

        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                deleteLeaveRequest({ variables: { id } });
              }}>
              <Button loading={loading} type="danger" icon="delete" className="action-button" disabled={disable}>
                {t("buttons.DELETE")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

const CancelButton = ({ t, id, action, selectedYear }) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST_STATUS}
      refetchQueries={[
        { query: GET_LEAVE_REQUESTS_BY_ROLE, variables: { leaveRequestRole: "employee", year: selectedYear } },
      ]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.CANCEL_REQUEST"),
        });
      }}>
      {(updateLeaveRequestStatus, { loading }): any => {
        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                updateLeaveRequestStatus({ variables: { id, action } });
              }}>
              <Button loading={loading} icon="close" className="action-button-cancel">
                {t("buttons.CANCEL")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

export default withPermissionsFnc([
  constants.permissionResources.LEAVE_REQUESTS,
  constants.permissionResources.EMPLOYEES,
])(withTranslation()(MyLeaveRequests));
