import React, { useState } from "react";
import { Collapse, Icon } from "antd";
import { Query } from "@apollo/react-components";
import moment from "moment";
import EmployeeHolidaysInfo from "../../../../common/EmployeeHolidayInfo";
import LeaveRequestsTable from "./leaveRequests";
import { GET_LEAVE_REQUESTS_BY_ROLE } from "../../../../graphql/queries/LeaveManagementQueries";

const UserPanels = ({ t, item }) => {
  const [selectedYear, setSelectedYear] = useState(JSON.stringify(moment().year()));
  const genExtra = () => (
    <Icon
      style={{ fontSize: "15pt" }}
      type="idcard"
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );

  const openedPanel = JSON.parse(localStorage.getItem("openedPanel") as string);
  return (
    <Collapse
      expandIconPosition="right"
      style={{ margin: 10 }}
      key={item.userId}
      onChange={(value) => localStorage.setItem("openedPanel", JSON.stringify(value))}
      defaultActiveKey={openedPanel ? openedPanel : undefined}>
      <Collapse.Panel header={item.employee} key={item.userId} extra={genExtra()}>
        <Query
          query={GET_LEAVE_REQUESTS_BY_ROLE}
          variables={{ leaveRequestRole: "employee", year: selectedYear, userId: item.userId }}>
          {(state) => {
            const error = state?.error;
            const loading = state.loading;
            const data = state?.data;
            if (error) {
              return <div>Error</div>;
            }
            let employeeLeaveRequests;
            let allowedYearlyHoliday;
            let holidaysLeft;
            if (!loading && !!data && data.employee && data.employee.leaveRequests) {
              allowedYearlyHoliday = data.employee.holidaysLeft.possibleHolidays;
              holidaysLeft = data.employee.holidaysLeft.holidaysLeft;
              employeeLeaveRequests = data.employee.leaveRequests.map(
                ({
                  id,
                  leaveRequestType: { id: leaveId, leaveTypeName },
                  comment,
                  startDate,
                  endDate,
                  duration,
                  daysUsedFromLastYear,
                  employeeStatus,
                  deputyId,
                  employeeId,
                  managerId,
                  deputyStatus,
                  managerStatus,
                  deputy: {
                    User: { firstName, lastName },
                  },
                  employee: {
                    User: { firstName: employeeName, lastName: employeeLastname },
                  },
                }) => ({
                  id,
                  leaveTypeName,
                  leaveId,
                  comment,
                  startDate,
                  employeeId,
                  managerId,
                  endDate,
                  duration,
                  daysUsedFromLastYear,
                  employeeStatus,
                  deputyStatus,
                  managerStatus,
                  deputyId,
                  deputy: `${firstName} ${lastName}`,
                  employee: `${employeeName} ${employeeLastname}`,
                })
              );
              return (
                <>
                  <EmployeeHolidaysInfo
                    t={t}
                    holidaysLeft={holidaysLeft}
                    allowedHolidays={allowedYearlyHoliday}
                    loading={false}
                    employmentType={item.employmentType}
                    countrySuffix={item.workingCountry}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    contractStart={item.contractStart}
                    employeeId={item.userId}
                  />
                  <LeaveRequestsTable t={t} leaveRequests={employeeLeaveRequests} loading={loading} />{" "}
                </>
              );
            }

            return <LeaveRequestsTable t={t} leaveRequests={[]} loading={loading} />;
          }}
        </Query>
      </Collapse.Panel>
    </Collapse>
  );
};

export default UserPanels;
