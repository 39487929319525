import { Button, Icon, Modal, Tag, Tooltip } from "antd";
import { getUserData } from "common/AccessControl/Permissions";
import React, { useState } from "react";

const PerformanceReviewManagerComment = ({ selectedReview, t }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const currentUser = getUserData();
  const loggedInUser = currentUser?.id;
  const managerCommentIsPrivate = selectedReview?.commentIsPrivate;
  const managerComment = selectedReview?.managerComment;
  const selfReview = selectedReview?.EmployeeId;

  const loggedInUserIsReviewee = loggedInUser === selfReview;

  const employeeAccess = loggedInUserIsReviewee && !managerCommentIsPrivate;
  const adminAndManagerAccess = !loggedInUserIsReviewee;

  const handleShowFullScreen = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <span>
      <span className="manager-title-wrapper">
        <h3 className="manager-title">{t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER_COMMENT")}</h3>
        <Tooltip title={managerCommentIsPrivate ? "Private comment" : "Public comment"}>
          <Tag color={managerCommentIsPrivate ? "red" : "blue"}>
            <Icon type={managerCommentIsPrivate ? "eye-invisible" : "eye"} />{" "}
            {managerCommentIsPrivate ? "Private for reviewee" : "Public for reviewee"}
          </Tag>
        </Tooltip>
        {adminAndManagerAccess || employeeAccess ? (
          <Button style={{ marginLeft: -50 }} size="small" icon="fullscreen" onClick={handleShowFullScreen} />
        ) : null}
      </span>
      <div className="manager-comment">
        {employeeAccess || adminAndManagerAccess ? (
          <p
            style={{ color: managerComment ? "black" : "lightgray" }}
            className={employeeAccess || adminAndManagerAccess ? "" : "private-comment"}>
            {managerComment || t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.MANAGER_COMMENT")}
          </p>
        ) : null}
      </div>

      <Modal visible={modalVisible} onCancel={handleCloseModal} footer={null}>
        <h3 style={{ color: managerComment ? "black" : "lightgray" }}>
          {" "}
          {managerComment || t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.MANAGER_COMMENT")}
        </h3>
      </Modal>
    </span>
  );
};

export default PerformanceReviewManagerComment;
