import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Icon, Input, Row, Spin, Table } from "antd";
import { Query } from "@apollo/react-components";
import AbsenceTypesEdit from "./AbsenceTypesEdit";
import { GET_ABSENCE_TYPES, GET_COMPANY_BRANCH_BY_ID } from "../../../../../graphql/queries/Configurations";
import AbsenceTypesDelete from "./AbsenceTypesDelete";
import moment from "moment";
interface IProps {
  t: TFunction;
}

const AbsenceTypesTable: React.FC<IProps> = ({ t }) => {
  const [filters, setFilters] = useState({});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilters({ ...filters, [dataIndex]: selectedKeys[0] });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setFilters({ ...filters, [dataIndex]: "" });
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a onClick={() => handleReset(clearFilters, dataIndex)}>Reset</a>
          <a onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>Search</a>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (filtered ? <Icon type="file-search" /> : <Icon type="search" />),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: t("appConfiguration.ABSENCE_TYPES.LABELS.ABSENCE_TYPE_NAME"),
      dataIndex: "absenceTypeName",
      ...getColumnSearchProps("absenceTypeName"),
    },
    {
      title: t("appConfiguration.ABSENCE_TYPES.LABELS.DESCRIPTION"),
      dataIndex: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: t("appConfiguration.ABSENCE_TYPES.LABELS.HOLIDAY_LIMIT"),
      dataIndex: "numberOfDays",
      render: (numberOfDays) => <p>{numberOfDays === 1 ? numberOfDays + " day" : numberOfDays + " days"}</p>,
    },
    {
      title: t("appConfiguration.ABSENCE_TYPES.LABELS.EXPIRATION_DATE"),
      dataIndex: "expirationDate",
      render: (expirationDate) => <p>{moment(expirationDate).format("DD/MMM/YYYY")}</p>,
    },
    {
      title: t("appConfiguration.ABSENCE_TYPES.LABELS.COMPANY_BRANCH"),
      dataIndex: "CompanyBranchId",
      render: (CompanyBranchId) => (
        <Query query={GET_COMPANY_BRANCH_BY_ID} variables={{ id: CompanyBranchId }}>
          {({ loading: companyBranchLoading, error, data: companyBranchData }: any) => {
            if (companyBranchLoading) return <Spin size="small" />;

            if (error) return <p>Error</p>;

            const companyBranch = companyBranchData?.getCompanyBranchById;
            return <p>{companyBranch?.country + " / " + companyBranch?.city}</p>;
          }}
        </Query>
      ),
    },
    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row) => {
        return (
          <div style={{ display: "flex" }}>
            <AbsenceTypesDelete t={t} absenceType={row} />
            <AbsenceTypesEdit t={t} absenceType={row} />
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Query query={GET_ABSENCE_TYPES}>
        {({ loading, error, data }: any) => {
          let absenceTypes;

          if (!loading && data?.getAbsenceTypes) {
            absenceTypes = data.getAbsenceTypes.map((absenceItem) => {
              return {
                ...absenceItem,
                createdAt: moment(absenceItem.createdAt).format("DD/MM/YYYY"),
              };
            });
          }

          if (error) {
            return <p>Error</p>;
          }
          return (
            <Table
              size="small"
              columns={columns}
              bordered={true}
              rowKey="id"
              loading={loading}
              dataSource={absenceTypes}
            />
          );
        }}
      </Query>
    </Fragment>
  );
};

export default AbsenceTypesTable;
