import React, { useEffect, useState } from "react";
import { Collapse, Descriptions, Empty, Icon, Spin, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import { GET_EMPLOYEE_ABSENCE_BALANCE } from "graphql/queries/Configurations";
import { absenceColor } from "helpers/approveStatusAndAbsence";
import extendedInfoFromPanel from "./ExtendedInfoFromPanel";

const { Panel } = Collapse;

const CalendarsPanel = () => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_ABSENCE_BALANCE);
  const absenceBalance = data?.getEmployeeAbsenceBalance;

  useEffect(() => {
    refetch();
  }, []);

  if (loading) {
    return (
      <Spin
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}
        size="default"
      />
    );
  }

  if (error) {
    return <p>Error</p>;
  }

  if (!absenceBalance.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div>
      <Collapse>
        {absenceBalance.map((balanceItem) => (
          <Panel
            header={balanceItem.absenceTypes[0]?.absenceTypeName}
            key={balanceItem.id}
            extra={
              <Tag color={absenceColor(balanceItem.absenceTypes[0]?.absenceTypeName)}>
                {balanceItem.availableDaysOff === 1
                  ? balanceItem.availableDaysOff + " day left"
                  : balanceItem.availableDaysOff + " days left"}
              </Tag>
            }>
            <div>{extendedInfoFromPanel(t, balanceItem)}</div>
          </Panel>
        ))}
      </Collapse>
      <br />
    </div>
  );
};

export default CalendarsPanel;
