import pdf from "../../../../assets/fileTypes/pdf.png";
import word from "../../../../assets/fileTypes/word.png";
import exel from "../../../../assets/fileTypes/excel.png";
import txt from "../../../../assets/fileTypes/txt.png";
import image from "../../../../assets/fileTypes/image.png";
import text from "../../../../assets/fileTypes/text.png";

export const fileTypeImage = (fileType: string | undefined | null) => {
  const defaultImageUrl = text; // Change this to the default image URL

  const fileTypeMappings: { [key: string]: string } = {
    pdf: pdf,
    docx: word,
    xlsx: exel,
    txt: txt,
    plain: txt,
    jpg: image,
    jpeg: image,
    png: image,
  };

  if (fileType === undefined || fileType === null) {
    return defaultImageUrl;
  }

  return fileTypeMappings[fileType] || defaultImageUrl;
};
