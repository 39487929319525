import moment from "moment";

const today = moment().startOf("day");

export const getDaysFromToday = (birthdayItem) => {
  const birthday = moment(birthdayItem.birthday, "YYYY-MM-DD");
  const currentYear = moment().year();
  const birthdayThisYear = birthday.set("year", currentYear);
  const daysFromToday = birthdayThisYear.diff(today, "days");
  return daysFromToday;
};
