import React, { useState } from "react";
import { AccessControl } from "../../../../common/AccessControl";
import { useTranslation } from "react-i18next";
import { Button, notification, Spin, Icon, Select } from "antd";
import { Query } from "@apollo/react-components";
import { withPermissionsFnc } from "../../../../decorators/permissions";
import constants from "../../../../constants/permission_resources";
import { GET_EMPLOYEE_FOR_FILTER, EXPORT_LEAVES } from "../../../../graphql/queries/LeaveManagementQueries";
import { exportToPdf } from "../../../../helpers/generateDocument";
import client from "../../../../graphql/ApolloClient";
interface IProps {
  permissions: any;
}

const ExportLeaves: React.FC<IProps> = ({ permissions }) => {
  const [employee, setEmployee] = useState(undefined);
  const [pdfLoading, setPdfLoading] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setPdfLoading(true);
    client
      .query({ query: EXPORT_LEAVES, variables: { employeeId: employee, format: "PDF" } })
      .then((response) => {
        const leaveRequests = response.data.exportLeaveRequests;

        exportToPdf("Leave Requests summary", leaveRequests);

        setPdfLoading(false);
      })
      .catch((error) => {
        setPdfLoading(false);
        notification.error({
          message: error.message.split(":")[1],
        });
      });
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;
  return (
    <div className="calendar-summery-export">
      <AccessControl userPermissions={permissions.employees} allowedPermissions={["read"]}>
        <Query query={GET_EMPLOYEE_FOR_FILTER}>
          {({ data, loading }) => {
            if (!loading && data) {
              const employeeData = data.employees.map(({ User: { firstName, lastName, id } }) => ({
                id,
                fullName: `${firstName} ${lastName}`,
              }));

              return (
                <Select
                  className="select-employees"
                  showSearch={true}
                  value={employee}
                  onChange={(val) => setEmployee(val)}
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder={t("leaveRequests.tableColumns.EMPLOYEE")}>
                  {employeeData.map(({ fullName, id }) => {
                    return (
                      <Select.Option key={id} value={id}>
                        {fullName}
                      </Select.Option>
                    );
                  })}
                </Select>
              );
            } else {
              return (
                <>
                  <Select placeholder={"Loading employees"} className="select-employees">
                    <Select.Option value="">
                      <Spin size={"small"} indicator={antIcon} className="spin" />
                    </Select.Option>
                  </Select>
                </>
              );
            }
          }}
        </Query>
      </AccessControl>
      <Button loading={pdfLoading} onClick={handleClick}>
        {t("leaveRequests.EXPORT_REQUESTS")}
      </Button>
    </div>
  );
};

export default withPermissionsFnc([constants.EMPLOYEES])(ExportLeaves);
