import React from "react";
import { Icon } from "antd";

const TitleSection = (props: {
  bordered?: boolean;
  iconType?: string;
  title: string | React.FC<any>;
  extra?: React.FC<any>;
}) => {
  const { bordered = true, iconType, title = "", extra } = props;
  return (
    <div className={`section-title ${bordered ? "bordered" : ""}`}>
      <Icon type={iconType} style={{ color: "#1890ff", marginRight: "10px" }} />
      {title}
      {extra?.(props)}
    </div>
  );
};

export default TitleSection;
