import gql from 'graphql-tag';

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: Float!) {
    deactivateUser(userId: $id) {
      id
    }
  }
`;

export const UPDATE_USER_FIELDS = gql`
  mutation updateNewUser(
    $userId: Int!
    $roles: [Int!]
    $status: Boolean
    $managerId: Int
    $countryId: Int
    $iban: String
    $address: String
    $phoneNumber: String
    $bankName: String
    $paymentDueDateOffset: Int
    $CompanyBranchId: Int!
    $NUIS: String
  ) {
    updateNewUser(
      userId: $userId
      roles: $roles
      status: $status
      managerId: $managerId
      countryId: $countryId
      iban: $iban
      address: $address
      phoneNumber: $phoneNumber
      bankName: $bankName
      NUIS: $NUIS
      CompanyBranchId: $CompanyBranchId
      paymentDueDateOffset: $paymentDueDateOffset
    ) {
      id
      firstName
      status
      lastName
      email
      createdAt
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $roleId: Int!
    $managerId: Int
    $countryId: Int!
    $emergenceContact: String
    $personalEmail: String
    $contactRelationship: String
    $gender: String
    $birthday: DateTimeISO
    $paymentDueDateOffset: Int
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      roleId: $roleId
      managerId: $managerId
      countryId: $countryId
      emergenceContact: $emergenceContact
      personalEmail: $personalEmail
      contactRelationship: $contactRelationship
      gender: $gender
      birthday: $birthday
      paymentDueDateOffset: $paymentDueDateOffset
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($name: String, $roleId: Int!, $permissions: Permissions, $isInternal: Boolean) {
    updateRole(name: $name, roleId: $roleId, isInternal: $isInternal, permissions: $permissions) {
      id
      companyId
      name
      isInternal
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($name: String!, $isInternal: Boolean!) {
    createRole(name: $name, isInternal: $isInternal) {
      id
      companyId
      name
      isInternal
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $email: String!
    $password: String!
    $countryId: Int!
    $firstName: String!
    $modules: [Int!]!
    $lastName: String!
  ) {
    createCompany(
      name: $name
      email: $email
      modules: $modules
      password: $password
      countryId: $countryId
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      roles
      hasEmployeesUnderManagment
      isAdmin
    }
  }
`;

export const LOGIN_USER_GOOGLE = gql`
  mutation loginWithGoogle($jwt: String!) {
    loginWithGoogle(jwt: $jwt) {
      token
      roles
      hasEmployeesUnderManagment
      isAdmin
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $redirectUrl: String!) {
    resetPassword(email: $email, redirectUrl: $redirectUrl)
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation changePassword($token: String!, $newPassword: String!, $confirmPassword: String!) {
    changePassword(token: $token, newPassword: $newPassword, confirmPassword: $confirmPassword)
  }
`;

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: Int!) {
    deleteInvoice(invoiceId: $id) {
      id
    }
  }
`;

export const EDIT_INVOICE = gql`
  mutation editInvoice($id: Int!, $month: DateTimeISO!) {
    editInvoice(invoiceId: $id, month: $month) {
      id
    }
  }
`;

export const PAID_TRIGGER = gql`
  mutation paidTrigger($id: Int!) {
    updatePaidStatus(id: $id) {
      id
    }
  }
`;

export const APPROVE_INVOICE = gql`
  mutation approveInvoice($id: Int!) {
    approveInvoice(id: $id) {
      id
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice(
    $contractorId: Int!
    $month: DateTimeISO!
    $invoiceData: String!
    $timesheetAttachment: String!
    $otherAttachment: String
  ) {
    createInvoice(
      month: $month
      contractorId: $contractorId
      invoiceData: $invoiceData
      timesheetAttachment: $timesheetAttachment
      otherAttachment: $otherAttachment
    ) {
      id
    }
  }
`;

export const TOGGLE_TIMELOG_REMINDER = gql`
  mutation toggleTimelogReminder($timelogReminder: Boolean) {
    toggleTimelogReminder(timelogReminder: $timelogReminder) {
      timelogReminder
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: Int!
    $name: String
    $description: String
    $start: DateTimeISO
    $end: DateTimeISO
    $budget: Float
    $hourlyRate: Float
    $status: String
    $billable: Boolean
    $clientId: Int
  ) {
    updateProject(
      id: $id
      name: $name
      description: $description
      start: $start
      end: $end
      budget: $budget
      hourlyRate: $hourlyRate
      status: $status
      billable: $billable
      clientId: $clientId
    ) {
      id
      name
      description
      start
      end
      budget
      hourlyRate
      status
      createdAt
      billable
      ClientId
    }
  }
`;

export const ADD_NEW_CLIENT = gql`
  mutation addNewClient($name: String!) {
    createClient(name: $name) {
      name
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $name: String!
    $description: String
    $start: DateTimeISO
    $end: DateTimeISO
    $budget: Float
    $hourlyRate: Float
    $billable: Boolean
    $clientId: Int!
  ) {
    createProject(
      name: $name
      description: $description
      start: $start
      end: $end
      budget: $budget
      hourlyRate: $hourlyRate
      billable: $billable
      clientId: $clientId
    ) {
      id
      name
      description
      start
      end
      budget
      hourlyRate
      status
      createdAt
      billable
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $confirmedPassword: String
    $oldPassword: String
    $iban: String
    $phoneNumber: String
    $notes: String
    $address: String
    $userId: Int
    $bankName: String
    $NUIS: String
    $CompanyBranchId: Int
    $emergenceContact: String
    $personalEmail: String
    $contactRelationship: String
    $gender: String
    $birthday: DateTimeISO
    $paymentDueDateOffset: Int
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      newPassword: $confirmedPassword
      oldPassword: $oldPassword
      userId: $userId
      iban: $iban
      address: $address
      notes: $notes
      phoneNumber: $phoneNumber
      bankName: $bankName
      NUIS: $NUIS
      CompanyBranchId: $CompanyBranchId
      emergenceContact: $emergenceContact
      personalEmail: $personalEmail
      contactRelationship: $contactRelationship
      gender: $gender
      birthday: $birthday
      paymentDueDateOffset: $paymentDueDateOffset
    ) {
      id
      firstName
      lastName
      email
      roles {
        name
      }
      createdAt
    }
  }
`;

export const DELETE_TIMELOG = gql`
  mutation deleteTimelog($id: Int!) {
    deleteTimelog(id: $id) {
      id
    }
  }
`;

export const SYNC_ALL_GOOGLE_CALENDAR = gql`
  mutation syncAllGoogleCalendar($id: Int) {
    syncAllGoogleCalendar(id: $id) {
      confirmed
    }
  }
`;

export const UPDATE_TIMELOG = gql`
  mutation updateTimelog(
    $id: Int!
    $tagId: Int
    $projectId: Int
    $description: String
    $duration: Float
    $logDate: DateTimeISO
  ) {
    updateTimelog(
      id: $id
      tagId: $tagId
      projectId: $projectId
      description: $description
      duration: $duration
      logDate: $logDate
    ) {
      id
      description
      duration
      logDate
      project {
        id
        name
      }
      tag {
        id
        name
      }
    }
  }
`;
export const ASSIGN_EMPLOYEE_TO_PROJECT = gql`
  mutation assignEmployeeToProject($projectId: Int!, $employeeId: Int!, $hourlyRate: Float!) {
    assignEmployeeToProject(projectId: $projectId, employeeId: $employeeId, hourlyRate: $hourlyRate) {
      employee {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ASSIGN_USER_TO_CLIENT = gql`
  mutation assignUserToClient($clientId: Float!, $userId: Float!) {
    assignUserToClient(clientId: $clientId, userId: $userId) {
      id
    }
  }
`;

export const UNASSIGN_EMPLOYEE_FROM_PROJECT = gql`
  mutation unAssignEmployeeFromProject($id: Int!) {
    unAssignEmployeeFromProject(id: $id) {
      id
      employeeId
      projectId
      status
      emailNotification
      createdAt
      updatedAt
      deletedAt
      Employee {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UNASSIGN_USER_FROM_CLIENT = gql`
  mutation unassignUserFromClient($assignmentId: Int!) {
    unAssingUserFromClient(assignmentId: $assignmentId) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_ASSIGNMENT = gql`
  mutation updateEmployeeAssignment($id: Int!, $hourlyRate: Float, $status: String, $emailNotification: Boolean) {
    updateEmployeeAssignment(id: $id, hourlyRate: $hourlyRate, status: $status, emailNotification: $emailNotification) {
      id
      hourlyRate
      status
      emailNotification
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_NEW_TIMELOG = gql`
  mutation createNewTimelog(
    $tagId: Int
    $duration: Int
    $description: String
    $projectId: Int
    $startTime: String
    $endTime: String
    $logDate: DateTimeISO
  ) {
    createNewTimelog(
      tagId: $tagId
      duration: $duration
      description: $description
      projectId: $projectId
      logDate: $logDate
      startTime: $startTime
      endTime: $endTime
    ) {
      id
    }
  }
`;

export const CREATE_BENCHMARK = gql`
  mutation createBenchmark($month: DateTimeISO!, $percentage: Float!) {
    createBenchmark(month: $month, percentage: $percentage) {
      id
      month
      companyId
      updatedBy
      previousPercentage
      percentage
    }
  }
`;

export const UPDATE_BENCHMARK = gql`
  mutation updateBenchmark($id: Int!, $percentage: Float!) {
    updateBenchmark(id: $id, percentage: $percentage) {
      id
      month
      companyId
      updatedBy
      previousPercentage
      percentage
    }
  }
`;

export const createContract = gql`
  mutation createContract(
    $employeeId: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO
    $hourlyRate: Float
    $salary: Float
    $employmentType: String!
    $contractPdf: Upload
    $position: String!
    $workingHoursPerMonth: Int
    $signedBy: Int
  ) {
    createContract(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      salary: $salary
      hourlyRate: $hourlyRate
      contractPdf: $contractPdf
      position: $position
      workingHoursPerMonth: $workingHoursPerMonth
      signedBy: $signedBy
      employmentType: $employmentType
    ) {
      id
      employeeId
      startDate
      endDate
      hourlyRate
      salary
      employmentType
      position
      url
      workingHoursPerMonth
      signedBy
      createdAt
      updatedAt
    }
  }
`;

export const ATTACH_DOCTOR_REPORT = gql`
  mutation attachDoctorReport($leaveRequestId: Int!, $doctorReport: Upload!) {
    attachDoctorReport(leaveRequestId: $leaveRequestId, doctorReport: $doctorReport) {
      id
      doctorReport
    }
  }
`;

export const UPLOAD_CONTRACT = gql`
  mutation uploadContract($contractId: Int!, $contractPdf: String!) {
    uploadSingleContract(contractId: $contractId, contractPdf: $contractPdf) {
      id
      url
    }
  }
`;

export const UPLOAD_INVOICE_DOCUMENT = gql`
  mutation attachInvoiceDocument($invoiceId: Int!, $document: Upload!) {
    uploadInvoiceDocument(invoiceId: $invoiceId, document: $document) {
      id
      documentUrl
    }
  }
`;

export const CLOSE_CONTRACT = gql`
  mutation closeContract($contractId: Int!, $endDate: DateTimeISO) {
    updateContract(contractId: $contractId, endDate: $endDate) {
      id
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($clientId: Int!, $invoiceDaysDue: Int, $active: Boolean) {
    updateClient(clientId: $clientId, invoiceDaysDue: $invoiceDaysDue, active: $active) {
      id
      invoiceDaysDue
      active
    }
  }
`;

export const UPDATE_PREVIEW_INVOICE = gql`
  mutation updatePreviewInvoice($rate: Float!, $projectId: Int!, $employeeId: Int!, $month: DateTimeISO!) {
    updatePreviewInvoice(rate: $rate, projectId: $projectId, employeeId: $employeeId, month: $month) {
      id
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createTag($name: String!, $projectId: Int!) {
    createTag(name: $name, projectId: $projectId) {
      id
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($id: Int!, $projectId: Int!, $name: String!) {
    updateTag(id: $id, projectId: $projectId, name: $name) {
      id
    }
  }
`;

export const CREATE_MILESTONE = gql`
  mutation createMilestone(
    $amount: Int!
    $effort: Int!
    $tagId: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
  ) {
    createMilestone(amount: $amount, effort: $effort, tagId: $tagId, startDate: $startDate, endDate: $endDate) {
      id
      amount
      effort
      tagId
      startDate
      endDate
    }
  }
`;

export const UPDATE_MILESTONE = gql`
  mutation updateMilestone(
    $id: Int!
    $effort: Int
    $amount: Int
    $tagId: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
  ) {
    updateMilestone(
      id: $id
      amount: $amount
      effort: $effort
      tagId: $tagId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      amount
      effort
      tagId
      startDate
      endDate
    }
  }
`;

export const DELETE_MILESTONE = gql`
  mutation deleteMilestone($id: Int!) {
    deleteMilestone(id: $id) {
      id
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($id: Int!) {
    deleteTag(id: $id) {
      id
    }
  }
`;

export const DELETE_BENCHMARK = gql`
  mutation deleteBenchmark($id: Int!) {
    deleteBenchmark(id: $id) {
      id
    }
  }
`;

export const UPDATE_COMPANY_INFORMATION = gql`
  mutation updateCompany(
    $name: String
    $iban: String
    $accountNumber: String
    $logo: Upload
    $nipt: String
    $contactNumber: String
  ) {
    updateCompany(
      name: $name
      iban: $iban
      accountNumber: $accountNumber
      logo: $logo
      nipt: $nipt
      contactNumber: $contactNumber
    ) {
      id
      name
      logo
      accountNumber
      iban
      nipt
      contactNumber
    }
  }
`;

export const CREATE_ABSENCE_TYPE = gql`
  mutation CreateAbsenceType(
    $CompanyBranchId: Int!
    $absenceTypeName: String!
    $description: String!
    $numberOfDays: Int!
    $expirationDate: DateTimeISO!
  ) {
    createAbsenceType(
      CompanyBranchId: $CompanyBranchId
      absenceTypeName: $absenceTypeName
      description: $description
      numberOfDays: $numberOfDays
      expirationDate: $expirationDate
    ) {
      id
      CompanyBranchId
      absenceTypeName
      description
      numberOfDays
      expirationDate
    }
  }
`;

export const UPDATE_ABSENCE_TYPE = gql`
  mutation UpdateAbsenceType(
    $id: Int!
    $CompanyBranchId: Int!
    $absenceTypeName: String!
    $description: String!
    $numberOfDays: Int!
    $expirationDate: DateTimeISO!
  ) {
    updateAbsenceType(
      id: $id
      CompanyBranchId: $CompanyBranchId
      absenceTypeName: $absenceTypeName
      description: $description
      numberOfDays: $numberOfDays
      expirationDate: $expirationDate
    ) {
      id
      CompanyBranchId
      absenceTypeName
      description
      numberOfDays
      expirationDate
    }
  }
`;

export const DELETE_ABSENCE_TYPE = gql`
  mutation DeleteAbsenceType($id: Int!) {
    deleteAbsenceType(id: $id)
  }
`;

export const CREATE_HOLIDAY_TYPE = gql`
  mutation CreateHolidayType($CompanyBranchId: Int!, $date: [DateTimeISO!]!, $description: String!) {
    createHolidayType(CompanyBranchId: $CompanyBranchId, date: $date, description: $description) {
      id
      CompanyBranchId
      date
      description
    }
  }
`;

export const UPDATE_HOLIDAY_TYPE = gql`
  mutation UpdateHolidayType($id: Int!, $CompanyBranchId: Int!, $date: [DateTimeISO!]!, $description: String!) {
    updateHolidayType(id: $id, CompanyBranchId: $CompanyBranchId, date: $date, description: $description) {
      id
      CompanyBranchId
      date
      description
    }
  }
`;

export const DELETE_HOLIDAY_TYPE = gql`
  mutation DeleteHolidayType($id: Int!) {
    deleteHolidayType(id: $id)
  }
`;

export const CREATE_COMPANY_BRANCH = gql`
  mutation CreateNewCompanyBranch(
    $country: String!
    $city: String!
    $iban: String
    $nipt: String
    $accountNumber: String
    $contactNumber: String
    $virtual: Boolean
    $startOfWorkDay: String!
    $endOfWorkDay: String!
    $daysOfWork: [String!]!
  ) {
    createNewCompanyBranch(
      country: $country
      city: $city
      iban: $iban
      nipt: $nipt
      accountNumber: $accountNumber
      contactNumber: $contactNumber
      virtual: $virtual
      startOfWorkDay: $startOfWorkDay
      endOfWorkDay: $endOfWorkDay
      daysOfWork: $daysOfWork
    ) {
      id
      country
      city
      iban
      nipt
      accountNumber
      contactNumber
      virtual
      startOfWorkDay
      endOfWorkDay
      daysOfWork
    }
  }
`;

export const UPDATE_COMPANY_BRANCH = gql`
  mutation UpdateCompanyBranch(
    $id: Int!
    $country: String!
    $city: String!
    $iban: String
    $nipt: String
    $accountNumber: String
    $contactNumber: String
    $virtual: Boolean
    $startOfWorkDay: String!
    $endOfWorkDay: String!
    $daysOfWork: [String!]!
  ) {
    updateCompanyBranch(
      id: $id
      country: $country
      city: $city
      iban: $iban
      nipt: $nipt
      accountNumber: $accountNumber
      contactNumber: $contactNumber
      virtual: $virtual
      startOfWorkDay: $startOfWorkDay
      endOfWorkDay: $endOfWorkDay
      daysOfWork: $daysOfWork
    ) {
      id
      country
      city
      iban
      nipt
      accountNumber
      contactNumber
      virtual
      startOfWorkDay
      endOfWorkDay
      daysOfWork
    }
  }
`;

export const DELETE_COMPANY_BRANCH = gql`
  mutation DeleteCompanyBranch($id: Int!) {
    deleteCompanyBranch(id: $id)
  }
`;

export const CREATE_EMPLOYEE_ABSENCE_REQUEST = gql`
  mutation CreateEmployeeAbsenceRequest(
    $AbsenceTypeId: Int!
    $employeeReplacerId: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $isStartDateHalf: Boolean!
    $isEndDateHalf: Boolean!
    $description: String
  ) {
    createEmployeeAbsenceRequest(
      AbsenceTypeId: $AbsenceTypeId
      employeeReplacerId: $employeeReplacerId
      startDate: $startDate
      endDate: $endDate
      isStartDateHalf: $isStartDateHalf
      isEndDateHalf: $isEndDateHalf
      description: $description
    ) {
      id
      AbsenceTypeId
      employeeReplacerId
      startDate
      endDate
      duration
      isStartDateHalf
      isEndDateHalf
      description
    }
  }
`;

export const CREATE_EMPLOYEE_ABSENCE_BY_ADMIN = gql`
  mutation CreateEmployeeAbsenceByAdmin(
    $employeeId: Int!
    $AbsenceTypeId: Int!
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $isStartDateHalf: Boolean!
    $isEndDateHalf: Boolean!
    $employeeReplacerId: Int!
    $managerId: Int!
    $description: String
  ) {
    createEmployeeAbsenceByAdmin(
      employeeId: $employeeId
      AbsenceTypeId: $AbsenceTypeId
      startDate: $startDate
      endDate: $endDate
      isStartDateHalf: $isStartDateHalf
      isEndDateHalf: $isEndDateHalf
      employeeReplacerId: $employeeReplacerId
      managerId: $managerId
      description: $description
    ) {
      id
      AbsenceTypeId
      EmployeeId
      employeeReplacerId
      managerId
      approvedByReplacer
      approvedByManager
      approveStatus
      startDate
      endDate
      isStartDateHalf
      isEndDateHalf
      duration
      description
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UDPATE_ABSENCE_REQUEST_STATUS = gql`
  mutation UpdateEmployeeAbsenceStatus($id: Int!, $approvedByReplacer: Boolean, $approvedByManager: Boolean) {
    updateEmployeeAbsenceStatus(
      id: $id
      approvedByReplacer: $approvedByReplacer
      approvedByManager: $approvedByManager
    ) {
      id
      approvedByReplacer
      approvedByManager
    }
  }
`;

export const UPDATE_ABSENCE_REQUEST_STATUS = gql`
  mutation UpdateEmployeeAbsenceRequestStatus($id: Int!, $approvedByReplacer: Boolean, $approvedByManager: Boolean) {
    updateEmployeeAbsenceRequestStatus(
      id: $id
      approvedByReplacer: $approvedByReplacer
      approvedByManager: $approvedByManager
    ) {
      id
      approvedByReplacer
      approvedByManager
    }
  }
`;

export const DELETE_ABSENCE_REQUEST = gql`
  mutation DeleteEmployeeAbsenceRequest($id: Int!) {
    deleteEmployeeAbsenceRequest(id: $id)
  }
`;

export const CREATE_REVIEW_CYCLE = gql`
  mutation CreateReviewCycle(
    $EmployeeId: Int!
    $expireDate: DateTimeISO!
    $peerReviewers: [Int!]!
    $managerReviewers: [Int!]!
  ) {
    createReviewCycle(
      EmployeeId: $EmployeeId
      expireDate: $expireDate
      peerReviewers: $peerReviewers
      managerReviewers: $managerReviewers
    ) {
      id
    }
  }
`;

export const CLOSE_REVIEW_CYCLE = gql`
  mutation CloseReviewCycle($ReviewCycleId: Int!, $managerComment: String!, $commentIsPrivate: Boolean!) {
    closeReviewCycle(
      ReviewCycleId: $ReviewCycleId
      managerComment: $managerComment
      commentIsPrivate: $commentIsPrivate
    ) {
      id
    }
  }
`;

export const SUBMIT_REVIEW = gql`
  mutation SubmitReview($ReviewId: Int!, $responses: String!, $status: String!, $isPrivate: Boolean!) {
    submitReview(ReviewId: $ReviewId, responses: $responses, status: $status, isPrivate: $isPrivate) {
      id
    }
  }
`;

export const DELETE_REVIEW_CYCLE = gql`
  mutation DeleteReviewCycle($ReviewCycleId: Int!) {
    deleteReviewCycle(ReviewCycleId: $ReviewCycleId) {
      id
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($EmployeeId: Int!, $category: String!, $file: String!, $fileName: String!) {
    createDocument(EmployeeId: $EmployeeId, category: $category, file: $file, fileName: $fileName) {
      EmployeeId
      fileName
      fileType
      fileSize
      category
      filePath
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($documentId: Int!, $category: String!) {
    updateDocument(documentId: $documentId, category: $category) {
      EmployeeId
      fileName
      fileType
      fileSize
      category
      filePath
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($documentId: Int!) {
    deleteDocument(documentId: $documentId)
  }
`;
