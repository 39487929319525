import React, { useState, useCallback, useMemo, useEffect } from "react";
import MonthlyUtilizationRateTable from "../Component/MonthlyUtilizationRateTable";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Button, Select } from "antd";
import {
  GENERATE_MONTHLY_AND_YEARLY_UTILIZATION_RATE_IN_PDF_FORMAT,
  GET_CALCULATED_UTILIZATION_RATE_FOR_EACH_EMPLOYEE,
} from "graphql/queries";
import { exportToPdf } from "helpers/generateDocument";
import { getMonthDateRange } from "../helpers";

const { Option } = Select;
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const lastYear = currentYear - 1;
const nextYear = currentYear + 1;
const availableYears = [lastYear, currentYear, nextYear];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthlyUtilizationRate = React.memo(() => {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonthOption, setSelectedMonthOption] = useState(currentDate.getMonth());

  const monthlyUtilizationRateParams = {
    startDate: getMonthDateRange(selectedYear, selectedMonthOption + 1).start,
    endDate: getMonthDateRange(selectedYear, selectedMonthOption + 1).end,
  };

  const [getPDFData, { data: pdfData, loading: loadingPDFData }] = useLazyQuery(
    GENERATE_MONTHLY_AND_YEARLY_UTILIZATION_RATE_IN_PDF_FORMAT,
    {
      fetchPolicy: "network-only",
      variables: monthlyUtilizationRateParams,
    }
  );
  const { data, loading, error } = useQuery(GET_CALCULATED_UTILIZATION_RATE_FOR_EACH_EMPLOYEE, {
    variables: monthlyUtilizationRateParams,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!pdfData) return;

    exportToPdf("MonthlyAndYearlyUtilizationRate.pdf", pdfData.generateMonthlyAndYearlyUtilizationRateInPDFFormat);
  }, [pdfData]);

  const generateMonthOptions = useCallback(() => {
    const monthOptions: { value: string; id: number }[] = [];
    for (let i = 0; i < 12; i++) {
      monthOptions.push({ value: months[i], id: i });
    }
    return monthOptions;
  }, []);

  const generatePDFHandler = useCallback(() => {
    getPDFData({
      variables: monthlyUtilizationRateParams,
    });
  }, [monthlyUtilizationRateParams]);

  const monthOptions: { value: string; id: number }[] = useMemo(() => generateMonthOptions(), [generateMonthOptions]);

  return (
    <>
      <div className="monthlyUtilization-container">
        <h1 className="monthlyUtilizationRate-heading">Monthly Utilization Rate</h1>
        <div className="selectOptions-container">
          <div className="selectYear-container">
            <Select className="selectOptions" onChange={setSelectedYear} value={selectedYear} style={{ width: 200 }}>
              {availableYears.map((year) => {
                return (
                  <Option key={year} value={year}>
                    Year: {year}
                  </Option>
                );
              })}
            </Select>
          </div>
          <Select className="selectOptions" onChange={setSelectedMonthOption} value={selectedMonthOption}>
            {monthOptions.map(({ id, value }) => {
              return (
                <Option key={id} value={id}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>

      {error && <p className="paragraph">Something went wrong!</p>}

      {!error && (
        <MonthlyUtilizationRateTable
          selectedYear={selectedYear}
          selectedMonthOption={selectedMonthOption}
          loading={loading}
          data={data}
        />
      )}

      <div className="generatePDF-container">
        <Button loading={loadingPDFData} title="Generate PDF" onClick={generatePDFHandler}>
          {loadingPDFData ? "Generating" : "Generate"} PDF
        </Button>
      </div>
    </>
  );
});

export default MonthlyUtilizationRate;
