import * as React from "react";
import { Layout } from "antd";
import { withPermissions } from "../../decorators/permissions";
import constants from "../../constants/index";
import Home from "./Home";

interface IProps {
  permissions: any;
}

const View: React.FC<IProps> = () => {
  return (
    <Layout.Content className="home-content">
      <Home />
    </Layout.Content>
  );
};

export default withPermissions([constants.permissionResources.HOME])(View);
