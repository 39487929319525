import React, { PureComponent } from "react";
import { Query } from "@apollo/react-components";
import Select, { SelectProps } from "antd/lib/select";
import { Spin, Icon } from "antd";
import { GET_COUNTRIES } from "graphql/queries";
import { TFunction } from "i18next";

interface IProps extends SelectProps {
  t: TFunction;
}

class SelectCountry extends PureComponent<IProps> {
  public render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;
    const { ...rest } = this.props;
    return (
      <div>
        <Query query={GET_COUNTRIES}>
          {(state) => {
            const loading = state.loading;
            const data = state.data;

            if (!loading && data) {
              const countriesData = data.countries;

              return (
                <div>
                  <Select
                    {...rest}
                    showSearch={true}
                    optionFilterProp="children"
                    placeholder={this.props.t("placeholders.SELECT_COUNTRY")}>
                    {countriesData.map(({ fullName, id }) => {
                      return (
                        <Select.Option key={id} value={id}>
                          {fullName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              );
            } else {
              return (
                <>
                  <Select placeholder={this.props.t("placeholders.LOADING_COUNTRIES")} style={{ width: "200px" }}>
                    <Select.Option value="">
                      <Spin size={"small"} indicator={antIcon} className="spin" />
                    </Select.Option>
                  </Select>
                </>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

export default SelectCountry;
