import gql from "graphql-tag";

export const getContracts = gql`
  query getContracts($employeeId: Int) {
    contracts(employeeId: $employeeId) {
      id
      employeeId
      startDate
      endDate
      employee {
        User {
          id
          firstName
          lastName
        }
      }
      hourlyRate
      salary
      employmentType
      position
      url
      workingHoursPerMonth
      signedBy
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTRACTS_HISTORY_TIMELINE = gql`
  query getContractsHistoryTimeline($userId: Float) {
    employee(userId: $userId) {
      contracts {
        id
        employeeId
        startDate
        endDate
        employee {
          User {
            id
            firstName
            lastName
          }
        }
        hourlyRate
        salary
        employmentType
        position
        url
        workingHoursPerMonth
        signedBy
        createdAt
        updatedAt
      }
    }
  }
`;
