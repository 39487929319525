import moment from "moment";

const today = moment().startOf("day");

export const getDaysFromToday = (absenceLogItem) => {
  const anniversary = moment(absenceLogItem.startDate, "YYYY-MM-DD");
  const currentYear = moment().year();
  const anniversaryThisYear = anniversary.set("year", currentYear);
  const daysFromToday = anniversaryThisYear.diff(today, "days");
  return daysFromToday;
};
