import React, { Component, useState } from "react";
import TGetPermissions from "../common/AccessControl/Permissions";
import { Permissions } from "__generated__/globalTypes";

export function withPermissions(resources: string[]) {
  return (TargetComponent: any): any => {
    return class extends Component<any, { permissions: Permissions }> {
      constructor(props) {
        super(props);
        const result = TGetPermissions();
        const permissions = {};
        resources.forEach((resource) => {
          permissions[resource] = result ? result[resource] : undefined;
        });

        this.state = {
          permissions,
        };
      }
      public render() {
        return <TargetComponent permissions={this.state.permissions} {...this.props} />;
      }
    };
  };
}

const getPermissions = (resources: string[]) => {
  const result = TGetPermissions();

  return resources.reduce((acc, resource) => {
    acc[resource] = result ? result[resource] : undefined;
    return acc;
  }, {} as Record<string, string[]>);
};

export function withPermissionsFnc(resources: string[]) {
  return (TargetComponent: any): any => {
    return () => {
      return <TargetComponent permissions={getPermissions(resources)} />;
    };
  };
}

export function usePermissions(resources: string[]) {
  const [permissions] = useState(getPermissions(resources));
  return permissions;
}
