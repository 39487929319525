import React, { useState } from "react";
import { Table, Button, Alert, Empty } from "antd";
import { Link } from "react-router-dom";
import { generateKey } from "../../../../utils/listKey";
import { formatDate } from "../../../../utils/date";
import StatusTag from "../../../../common/LeaveRequestTag";
import * as constants from "../../../../constants/leaveRequestStatus";
import { TFunction } from "i18next";
import moment from "moment";
import { GET_DEPUTY_REQUESTS } from "../../../../graphql/queries/LeaveManagementQueries";
import { Query } from "@apollo/react-components";

interface IDeputyRequests {
  id: number;
  leaveTypeName: string;
  leaveId: number;
  comment: string;
  startDate: Date;
  endDate: Date;
  duration: number;
  employeeStatus: string;
  deputyStatus: string;
  managerStatus: string;
  deputyId: number;
  deputy: string;
  employee: string;
}

interface IProps {
  t: TFunction;
}

const DeputyRequests: React.FC<IProps> = ({ t }) => {
  const [selectedYear, setSelectedYear] = useState(JSON.stringify(moment().year()));

  return (
    <Query query={GET_DEPUTY_REQUESTS}>
      {({ loading, error, data }: any) => {
        if (error) {
          return <div>Error</div>;
        }
        let myLeaveRequests;
        if (!loading && !!data && data.employee && data.employee.deputyRequests) {
          myLeaveRequests = data.employee.deputyRequests.map(
            ({
              id,
              leaveRequestType: { id: leaveId, leaveTypeName },
              comment,
              startDate,
              endDate,
              duration,
              employeeStatus,
              deputyId,
              daysUsedFromLastYear,
              deputyStatus,
              managerStatus,
              deputy: {
                User: { firstName, lastName },
              },
              employee: {
                User: { firstName: employeeName, lastName: employeeLastname },
              },
            }) => ({
              id,
              leaveTypeName,
              leaveId,
              comment,
              startDate,
              endDate,
              duration,
              daysUsedFromLastYear,
              employeeStatus,
              deputyStatus,
              managerStatus,
              deputyId,
              deputy: `${firstName} ${lastName}`,
              employee: `${employeeName} ${employeeLastname}`,
            })
          );
        }
        return (
          <Table
            data-cy="table"
            size="small"
            loading={loading}
            dataSource={myLeaveRequests}
            rowKey={() => generateKey()}
            pagination={{ defaultPageSize: 10 }}
            className="leave-request-table"
            locale={{
              emptyText: (
                <>
                  <Empty description={t("emptyDescriptions.DEPUTY_REQUESTS")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </>
              ),
            }}>
            <Table.Column
              title={t("leaveRequests.tableColumns.EMPLOYEE")}
              key="employee"
              render={({ employee }) => employee}
              width={200}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.START_DATE")}
              key="startDate"
              width={200}
              render={({ startDate }) => formatDate(startDate)}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.END_DATE")}
              key="endDate"
              width={150}
              render={({ endDate }) => formatDate(endDate)}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.DURATION")}
              key="duration"
              align="center"
              width={150}
              render={({ duration }) => `${duration} ${duration > 1 ? "days" : "day"}`}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.DEPUTY_STATUS")}
              key="deputyStatus"
              align="center"
              render={({ deputyStatus }) => <StatusTag status={deputyStatus} />}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.APPROVAL_STATUS")}
              key="managerStatus"
              align="center"
              render={({ managerStatus }) => <StatusTag status={managerStatus} />}
            />
            <Table.Column
              title={t("leaveRequests.tableColumns.ACTIONS")}
              align="center"
              key="status"
              width={250}
              className="button-actions"
              render={(text: any, record: IDeputyRequests) => {
                if (record.employeeStatus !== constants.statusCancel) {
                  return (
                    <div>
                      <Link
                        to={{
                          pathname: `/dashboard/deputy-requests/${record.id}`,
                          state: {
                            leaveRequest: record,
                            selectedYear,
                          },
                        }}>
                        <Button type="primary" icon="profile">
                          {t("buttons.DETAILS")}
                        </Button>
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Alert
                        message={t("leaveRequests.MESSAGES.CANCELED_REQUEST")}
                        type="warning"
                        showIcon={true}
                        style={{ width: "205px" }}
                      />
                    </div>
                  );
                }
              }}
            />
          </Table>
        );
      }}
    </Query>
  );
};

export default DeputyRequests;
