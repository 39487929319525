import gql from "graphql-tag";

export const GET_DEPUTY_REQUESTS = gql`
  query deputyRequests {
    employee {
      deputyRequests {
        id
        comment
        leaveRequestType {
          leaveTypeName
          id
        }
        deputyStatus
        managerStatus
        daysUsedFromLastYear
        employeeStatus
        startDate
        endDate
        managerId
        employeeId
        deputyId
        deputy {
          User {
            firstName
            lastName
          }
        }
        duration
        employee {
          User {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_UPCOMING_LEAVE_REQUESTS = gql`
  query getUpcomingLeaveRequests {
    upcomingRequests {
      id
      comment
      leaveRequestType {
        leaveTypeName
        id
      }
      deputyStatus
      managerStatus
      employeeStatus
      startDate
      endDate
      managerId
      employeeId
      deputyId
      deputy {
        User {
          firstName
          lastName
        }
      }
      duration
      employee {
        User {
          firstName
          lastName
        }
      }
    }
  }
`;
export const GET_LEAVE_REQUESTS_BY_ROLE = gql`
  query getLeaveRequestsByRole(
    $limit: Int
    $cursor: DateTimeISO
    $leaveRequestRole: String!
    $year: DateTimeISO!
    $userId: Float
  ) {
    employee(userId: $userId) {
      holidaysLeft(year: $year) {
        holidaysLeft
        possibleHolidays
      }
      contracts {
        startDate
      }
      leaveRequests(limit: $limit, cursor: $cursor, leaveRequestRole: $leaveRequestRole, year: $year) {
        id
        comment
        leaveRequestType {
          leaveTypeName
          id
        }
        deputyStatus
        managerStatus
        employeeStatus
        startDate
        daysUsedFromLastYear
        endDate
        managerId
        employeeId
        deputyId
        deputy {
          User {
            firstName
            lastName
          }
        }
        duration
        employee {
          User {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_ALL_LEAVE_REQUESTS = gql`
  query leaveRequests($limit: Int, $cursor: DateTimeISO) {
    leaveRequests(limit: $limit, cursor: $cursor) {
      comment
      leaveType
      deputyStatus
      managerStatus
      startDate
      daysUsedFromLastYear
      endDate
      deputy {
        User {
          firstName
          lastName
        }
      }
      duration
      employee {
        User {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_LEAVE_TYPES = gql`
  query leaveTypes {
    leaveTypes {
      id
      leaveTypeName
    }
  }
`;

export const GET_DEPUTIES = gql`
  query deputies($startDate: DateTimeISO, $endDate: DateTimeISO) {
    deputies(startDate: $startDate, endDate: $endDate) {
      userId
      name
    }
  }
`;

export const GET_EMPLOYEES_UNDER_MANAGEMENT = gql`
  query employessUnderManagement {
    employees {
      userId
      managerId
      User {
        id
        firstName
        lastName
        Country {
          fullName
        }
      }
      contracts {
        startDate
        employmentType
      }
    }
  }
`;

export const GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS = gql`
  query calendarSummary {
    simpleEmployees {
      employeeId
      fullName
    }
    calendarSummary {
      id
      leaveRequestType {
        leaveTypeName
        id
      }
      startDate
      endDate
      employeeId
      employeeStatus
      deputyStatus
      managerStatus
    }
  }
`;

export const GET_LEAVE_REQUEST_DETAILS = gql`
  query leaveRequest($id: Float!) {
    leaveRequest(id: $id) {
      id
      comment
      leaveRequestType {
        leaveTypeName
        canUploadDocument
        id
      }
      deputyStatus
      managerStatus
      employeeStatus
      startDate
      endDate
      daysUsedFromLastYear
      managerId
      employeeId
      deputyId
      deputy {
        User {
          firstName
          lastName
        }
      }
      duration
      employee {
        User {
          firstName
          lastName
        }
      }
      doctorReport
    }
  }
`;

export const GET_CANCELED_LEAVE_REQUESTS = gql`
  query canceledLeaveRequests {
    canceledLeaveRequests {
      id
      comment
      leaveRequestType {
        leaveTypeName
        id
      }
      deputyStatus
      managerStatus
      employeeStatus
      startDate
      endDate
      managerId
      employeeId
      deputyId
      deputy {
        User {
          firstName
          lastName
        }
      }
      duration
      employee {
        User {
          firstName
          lastName
        }
      }
    }
  }
`;

// Query for leave requests export pdf filter
export const GET_EMPLOYEE_FOR_FILTER = gql`
  query employeesForFilter {
    employees {
      User {
        id
        firstName
        lastName
      }
    }
  }
`;

export const EXPORT_LEAVES = gql`
  query exportLeaves($employeeId: Int, $format: String!) {
    exportLeaveRequests(employeeId: $employeeId, format: $format)
  }
`;
