import React, { Fragment, useState } from "react";
import { Alert, Button, Descriptions, Modal, Row, Spin, Tag } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { TFunction } from "i18next";
import moment from "moment";

interface IProps extends FormComponentProps {
  t: TFunction;
  branchType: any;
}

const CompanyBrenchesDetail: React.FC<IProps> = ({ t, branchType }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  return (
    <Fragment>
      <Button type="primary" style={{ marginBottom: "16px" }} icon="profile" onClick={toggleModal} />
      <Modal
        title={t("appConfiguration.COMPANY_BRANCHES.DETAIL_COMPANY_BRANCHES")}
        width={600}
        centered={true}
        visible={modalOpen}
        footer={null}
        onCancel={toggleModal}>
        <Descriptions bordered size="small" layout="vertical" title="Main details">
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.COUNTRY")}>
            {branchType.country}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.CITY")}>
            {branchType.city}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.IBAN")}>
            {branchType.iban}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.TAX_ID")}>
            {branchType.nipt}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.ACCOUNT_NUMBER")}>
            {branchType.accountNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.CONTACT_NUMBER")}>
            {branchType.contactNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.CREATED_AT")}>
            {moment(branchType.createdAt).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.UPDATED_AT")}>
            {moment(branchType.updatedAt).format("DD/MM/YYYY")}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          style={{ marginTop: 10 }}
          bordered
          size="small"
          layout="vertical"
          title="Working hours & days details">
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.START_OF_THE_DAY")}>
            {branchType.startOfWorkDay}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.END_OF_THE_DAY")}>
            {branchType.endOfWorkDay}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.OFFICE_TYPE")}>
            <Tag color={branchType.virtual ? "blue" : "purple"}>
              {branchType.virtual
                ? t("appConfiguration.COMPANY_BRANCHES.LABELS.VIRTUAL_OFFICE")
                : t("appConfiguration.COMPANY_BRANCHES.LABELS.PHYSICAL_OFFICE")}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item span={3} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.DAYS_OF_WORK")}>
            {branchType.daysOfWork.map((daysItem) => (
              <Tag color="#108ee9">{daysItem}</Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.HOLIDAY_TYPES")}>
            {branchType.holidayTypes.map((holidayItem) => (
              <Tag>{holidayItem.description}</Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.ABSENCE_TYPES")}>
            {branchType.absenceTypes.map((absenceItem) => (
              <Tag style={{ marginBottom: 5 }}>{absenceItem.absenceTypeName}</Tag>
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </Fragment>
  );
};

export default Form.create<IProps>()(CompanyBrenchesDetail);
