import * as React from "react";
import { Modal, Form, Input, Row, Col, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormComponentProps } from "antd/lib/form";
import { GET_CLIENTS } from "../../../graphql/queries";
import { ADD_NEW_CLIENT } from "../../../graphql/mutations";

interface IFormProps extends FormComponentProps {
  isOpen: boolean;
  onCancel: () => void;
}
interface IProps extends IFormProps, WithTranslation {}
class AddNewClient extends React.Component<IProps> {
  public render() {
    const { form, t } = this.props;

    return (
      <Mutation
        mutation={ADD_NEW_CLIENT}
        refetchQueries={[{ query: GET_CLIENTS }]}
        onError={(error) => {
          this.props.form.resetFields();
          this.props.onCancel();
          notification.error({
            message: error.message.split(":")[1],
          });
        }}
        onCompleted={() => {
          notification.success({
            message: t("projectsTableColumns.addClientModal.CREATED_CLIENT"),
          });
        }}>
        {(addClient, { loading }) => (
          <Modal
            title={t("projectsTableColumns.addClientModal.TITLE")}
            visible={this.props.isOpen}
            okButtonProps={{
              loading,
            }}
            onOk={() => {
              this.props.form.validateFields((err, values) => {
                if (!err) {
                  const { name } = values;
                  addClient({
                    variables: {
                      name,
                    },
                  }).then(() => this.props.onCancel());
                }
              });
            }}
            onCancel={this.props.onCancel}
            afterClose={() => {
              form.resetFields();
            }}>
            <Form layout="horizontal">
              <Row>
                <Col span={10}>
                  <Form.Item label={t("projectsTableColumns.addClientModal.CLIENT_NAME")}>
                    {form.getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: t("projectsTableColumns.messages.CLIENT_NAME"),
                        },
                      ],
                    })(<Input placeholder={t("projectsTableColumns.placeholder.CLIENT_NAME")} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    );
  }
}

export default Form.create<IFormProps>()(withTranslation()(AddNewClient));
