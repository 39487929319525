import React from "react";
import { Button, Descriptions, Icon, Modal, Tabs } from "antd";
import { useState } from "react";
const { TabPane } = Tabs;

const ViewAllReviewAnswers = ({ t, selectedReview }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <div>
      <Button size="small" style={{ marginBottom: 15 }} icon="form" type="primary" onClick={handleClick}>
        {t("appConfiguration.PERFORMANCE_REVIEW.LABELS.VIEW_ALL_REVIEW_ANSWERS")}
      </Button>

      <Modal
        width={1300}
        style={{ top: 20 }}
        title={`All answers`}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}>
        <Tabs>
          {selectedReview?.selfReviews?.map((reviewItem) => (
            <TabPane
              tab={
                <span>
                  <Icon type={reviewItem?.isPrivate ? "eye-invisible" : "eye"} />
                  {reviewItem?.isPrivate ? `(Private) ${reviewItem?.Reviewer}` : `(Public) ${reviewItem?.Reviewer}`}
                </span>
              }
              key={reviewItem?.Reviewer}>
              <Descriptions size="small" layout="vertical" bordered>
                {Object.keys(JSON.parse(reviewItem?.template?.questions)).map((questionItem) => (
                  <Descriptions.Item
                    span={4}
                    label={`Q${Number(questionItem) + 1}: ${
                      JSON.parse(reviewItem?.template?.questions)[questionItem]
                    } `}>
                    <p style={JSON.parse(reviewItem?.responses)[questionItem] ? { color: "Black" } : {}}>{`${
                      JSON.parse(reviewItem?.responses)[questionItem] || "No answer"
                    }`}</p>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </TabPane>
          ))}
          {selectedReview?.peerReviews?.map((reviewItem) => (
            <TabPane
              tab={
                <span>
                  <Icon type={reviewItem?.isPrivate ? "eye-invisible" : "eye"} />
                  {reviewItem?.Reviewer ? `(Private) ${reviewItem?.Reviewer}` : `(Public) ${reviewItem?.Reviewer}`}
                </span>
              }
              key={reviewItem?.Reviewer}>
              <Descriptions size="small" layout="vertical" bordered>
                {Object.keys(JSON.parse(reviewItem?.template?.questions)).map((questionItem) => (
                  <Descriptions.Item
                    span={4}
                    label={`Q${Number(questionItem) + 1}: ${
                      JSON.parse(reviewItem?.template?.questions)[questionItem]
                    } `}>
                    <p style={JSON.parse(reviewItem?.responses)[questionItem] ? { color: "Black" } : {}}>{`${
                      JSON.parse(reviewItem?.responses)[questionItem] || "No answer"
                    }`}</p>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </TabPane>
          ))}
          {selectedReview?.managerReviews?.map((reviewItem) => (
            <TabPane
              tab={
                <span>
                  <Icon type={reviewItem?.isPrivate ? "eye-invisible" : "eye"} />
                  {reviewItem?.Reviewer ? `(Private) ${reviewItem?.Reviewer}` : `(Public) ${reviewItem?.Reviewer}`}
                </span>
              }
              key={reviewItem?.Reviewer}>
              <Descriptions size="small" layout="vertical" bordered>
                {Object.keys(JSON.parse(reviewItem?.template?.questions)).map((questionItem) => (
                  <Descriptions.Item
                    span={4}
                    label={`Q${Number(questionItem) + 1}: ${
                      JSON.parse(reviewItem?.template?.questions)[questionItem]
                    } `}>
                    <p style={JSON.parse(reviewItem?.responses)[questionItem] ? { color: "Black" } : {}}>{`${
                      JSON.parse(reviewItem?.responses)[questionItem] || "No answer"
                    }`}</p>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </TabPane>
          ))}
        </Tabs>
      </Modal>
    </div>
  );
};

export default ViewAllReviewAnswers;
