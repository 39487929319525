import React from "react";
import SectionTitle from "../../../common/SectionTitle/title";
import UserPanels from "./components/userPanels";
import { Empty } from "antd";
import { Skeleton } from "antd";
import { Query } from "@apollo/react-components";
import { GET_EMPLOYEES_UNDER_MANAGEMENT } from "../../../graphql/queries/LeaveManagementQueries";
import { withTranslation, WithTranslation } from "react-i18next";

const EmployeesUnderManagement = (props: WithTranslation) => {
  const { t } = props;
  return (
    <div className="under-management-leave-requests">
      <SectionTitle title={t("sideMenu.LEAVE_MANAGEMENT.MANAGER_REQUESTS")} iconType="eye" />
      <div className="wrapper">
        <Query query={GET_EMPLOYEES_UNDER_MANAGEMENT}>
          {({ loading, error, data }: any) => {
            if (error) {
              return <div>Error</div>;
            }
            let employeeData;
            if (!loading && data && data.employees.length > 0) {
              employeeData = data.employees.map(
                ({
                  userId,
                  User: {
                    firstName,
                    lastName,
                    Country: { fullName },
                  },
                  contracts,
                  managerId,
                }) => ({
                  userId,
                  managerId,
                  employee: `${firstName} ${lastName}`,
                  employmentType: contracts[0]?.employmentType,
                  contractStart: contracts[0]?.startDate,
                  workingCountry: fullName,
                })
              );

              return (
                <>
                  {employeeData.map((item) => {
                    const empInfo = {
                      employee: item.employee,
                      userId: item.userId,
                      holidaysLeft: item.holidaysLeft,
                      allowedYearlyHoliday: item.allowedYearlyHoliday,
                      employmentType: item.employmentType,
                      workingCountry: item.workingCountry,
                      contractStart: item.contractStart,
                    };
                    return <UserPanels t={t} item={empInfo} key={item.userId} />;
                  })}
                </>
              );
            }
            if (loading) {
              return (
                <div className="skeleton">
                  <Skeleton
                    loading={loading}
                    active={true}
                    title={{ width: "100%" }}
                    paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }}
                  />
                </div>
              );
            } else {
              return <Empty description={t("emptyDescriptions.UNDER_MANAGEMENT")} />;
            }
          }}
        </Query>
      </div>
    </div>
  );
};

export default withTranslation()(EmployeesUnderManagement);
