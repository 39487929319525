import React, { Component } from "react";
import { Button, Form, notification, Modal } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation, WithTranslation } from "react-i18next";
import TimelogForm from "./TimelogForm";
import { UPDATE_TIMELOG } from "graphql/mutations";
import { IProjectTag } from "../../Interfaces/projectTags";
import client from "../../../../graphql/ApolloClient";
import { GET_PROJECT_TAGS } from "../../../../graphql/queries";

interface IProps extends FormComponentProps {
  id: number;
  refetch?: any;
  duration: number;
  description: string;
  logDate: Date;
  disable: any;
  projectId: number;
  project: string;
  tagId: number;
  tagName: string;
  userId: number;
}

interface IState {
  modalIsOpen: boolean;
  projectTags: IProjectTag[];
}

class EditTimelog extends Component<IProps & WithTranslation, IState> {
  public state = {
    modalIsOpen: false,
    projectTags: [],
  };

  getProjectTags = (projectId: number) => {
    client.query({ query: GET_PROJECT_TAGS, variables: { projectId } }).then((res) => {
      this.setState({
        projectTags: res.data.projectTags,
      });
    });
  };

  componentDidMount() {
    this.getProjectTags(this.props.projectId);
  }

  public toggleModal = () => {
    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  public handleCancel = () => {
    this.toggleModal();
    this.props.form.resetFields();
  };

  public handleProjectChange = (projectId: number) => {
    this.getProjectTags(projectId);
  };

  public render() {
    const { t } = this.props;
    return (
      <>
        <Button icon="edit" onClick={this.toggleModal} disabled={this.props.disable} />
        <Mutation
          mutation={UPDATE_TIMELOG}
          refetchQueries={this.props.refetch ?? []}
          onCompleted={() => {
            this.props.form.resetFields();
            notification.success({
              message: t("timelogs.MESSAGES.TIMELOG_EDITED"),
            });
          }}
          onError={(error) => {
            notification.error({
              message: error.message.split(":")[1],
            });
          }}>
          {(updateTimelog, { loading }) => (
            <>
              <Modal
                title={t("timelogs.CREATE_MODAL.EDIT_TIMELOG")}
                width={600}
                maskClosable={false}
                className={"timelogModal"}
                visible={this.state.modalIsOpen}
                onCancel={this.handleCancel}
                okButtonProps={{
                  "aria-details": "ok-button-manager",
                  loading,
                }}
                cancelButtonProps={{
                  disabled: loading,
                }}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      let { projectId, tag, description, startTime, endTime, logDate } = values;

                      tag = tag.filter((item) => item)[0];
                      description = description.filter((item) => item)[0];
                      startTime = startTime.filter((item) => item)[0];
                      endTime = endTime.filter((item) => item)[0];

                      const duration = endTime
                        .seconds(0)
                        .milliseconds(0)
                        .diff(startTime.seconds(0).milliseconds(0), "minutes", true);

                      const result = {
                        tagId: tag?.key ?? null,
                        duration: duration === 0 ? 60 : duration * 60,
                        description,
                        projectId: projectId?.key ?? null,
                        logDate: logDate.set({ hour: startTime.hours(), minute: startTime.minutes(), second: 0 }),
                      };

                      updateTimelog({
                        variables: { id: this.props.id, ...result },
                      });

                      this.toggleModal();
                    }
                  });
                }}>
                <TimelogForm
                  t={t}
                  form={this.props.form}
                  projectId={this.props.projectId}
                  projectName={this.props.project}
                  tagId={this.props.tagId}
                  tagName={this.props.tagName}
                  description={this.props.description}
                  duration={this.props.duration}
                  startTime={this.props.logDate}
                  userId={this.props.userId}
                  projectChange={this.handleProjectChange}
                  tags={this.state.projectTags}
                  isEditing={true}
                />
              </Modal>
            </>
          )}
        </Mutation>
      </>
    );
  }
}

export default Form.create<IProps>()(withTranslation()(EditTimelog));
