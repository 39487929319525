import * as React from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import GeneralSettingsItems from "./GeneralSettingsItems";

interface IProps {}

const GeneralSettings: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleSection title={t("sideMenu.GENERAL_SETTINGS")} iconType="setting" />
      <GeneralSettingsItems />
    </>
  );
};

export default GeneralSettings;
