import React, { useState } from "react";
import { Button, Col, DatePicker, Divider, Form, Icon, Input, Popconfirm, Row, Select, TimePicker } from "antd";
import SelectProject from "./SelectProject";
import moment from "moment";
import { TFunction } from "i18next";
import { IProjectTimelog } from "../../Interfaces/tag";
import { IProjectTag } from "../../Interfaces/projectTags";
import {
  durationToMinutes,
  formatDuration,
  minutesToDuration,
  secondsToDuration,
} from "../../../../helpers/timeDuration";
import { draftJSToRawData } from "../../../../helpers/draftjs";
// import { ReactElement } from "react-slick/node_modules/@types/react";

interface IDuration {
  valid: boolean;
  minutes: number;
  duration: string;
}

interface IProps {
  t: TFunction;
  form: any;
  projectId?: number;
  projectName?: string;
  tagId?: number;
  tagName?: string;
  description?: string;
  duration?: number;
  startTime?: Date;
  userId?: number;
  isEditing: boolean;
  projectChange: (projectId: number) => void;
  tags: IProjectTag[];
}

const TIME_PICKER_FORMAT = "HH:mm";
const DATE_PICKER_FORMAT = "DD-MM-YYYY";

const TimelogForm: React.FC<IProps> = ({
  t,
  form,
  projectId,
  projectName,
  tagId,
  tagName,
  description,
  duration,
  startTime,
  userId,
  projectChange,
  isEditing,
  tags,
}) => {
  const [startingTime, setStartingTime] = useState<moment.Moment>(
    !!startTime ? moment.utc(startTime).local() : moment()
  );

  const remove = (k) => {
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    keys.splice(k - 4, 5);

    // can use data-binding to set
    form.setFieldsValue({
      keys,
    });
  };

  const add = () => {
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // const newArr = [keys[0], keys[1], keys[2], keys[3], keys[4]];
    const newArr = keys.slice(0, 5);
    const nextKeys = keys.concat(newArr);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const selectProject = (project: IProjectTimelog) => {
    projectChange(project.id);
    form.setFieldsValue({
      tag: undefined,
    });
  };

  const durationValidator = (rule, value, callback) => {
    if (!value) {
      callback();
    } else {
      if (!formatDuration(value).valid) {
        callback([new Error(t("timelogs.MESSAGES.WRONG_FORMAT"))]);
      } else {
        callback();
      }
    }
  };

  const durationFocusOut = (index) => {
    const timespan = form.getFieldsValue(["duration"]).duration;
    let start = form.getFieldsValue(["startTime"]).startTime;
    const prevLogEnd = form.getFieldsValue(["endTime"]).endTime[index - 3];

    start = start[index + 1];

    const validateDuration: IDuration = formatDuration(timespan[index]);

    const durationInput = `duration[${index}]`;
    const startTimeInput = `startTime[${index + 1}]`;
    const endTimeInput = `endTime[${index + 2}]`;

    const calcEndTime = !!prevLogEnd ? prevLogEnd : start;
    const startCustom = !!prevLogEnd ? moment(prevLogEnd) : moment(start);

    form.setFieldsValue({
      [durationInput]: validateDuration.duration,
      [startTimeInput]: !!prevLogEnd ? moment(prevLogEnd) : moment(start),
      [endTimeInput]: !!startCustom
        ? startCustom.seconds(0).milliseconds(0).add(validateDuration.minutes, "minutes")
        : moment().seconds(0).milliseconds(0).add(validateDuration.minutes, "minutes"),
    });

    setStartingTime(!!start ? moment(start) : moment());
  };

  const handleEndTimeChange = (end: moment.Moment, elementName, index) => {
    let start = form.getFieldsValue(["startTime"]).startTime;

    start = start[index - 1];

    if (start && end) {
      if (end.isBefore(start)) {
        const startTime = `startTime[${index - 1}]`;
        const duration = `duration[${index - 2}]`;

        form.setFieldsValue({
          [startTime]: end,
          [duration]: "",
        });

        setStartingTime(end);
      } else {
        const duration = `duration[${index - 2}]`;

        const timeDifference = end.seconds(0).milliseconds(0).diff(start.seconds(0).milliseconds(0), "minutes", true);

        form.setFieldsValue({
          [duration]: timeDifference < 60 ? `${timeDifference}m` : minutesToDuration(timeDifference),
        });
      }
    } else if (!start && end) {
      const startTime = `startTime[${index - 1}]`;
      const duration = `duration[${index - 2}]`;

      form.setFieldsValue({
        [startTime]: end,
        [duration]: "",
      });

      setStartingTime(end);
    }
  };

  const handleStartTimeChange = (start: moment.Moment, index) => {
    let timespan = form.getFieldsValue(["duration"]).duration;
    const end = form.getFieldsValue(["endTime"]).endTime;

    if (timespan[index - 1] == "") {
      timespan = "1h";
    } else {
      timespan = timespan[index - 1];
    }

    if (start && end) {
      if (start.isAfter(end)) {
        const endTime = `endTime[${index + 1}]`;
        const duration = `duration[${index - 1}]`;

        form.setFieldsValue({
          [endTime]: start,
          [duration]: "",
        });
      } else {
        let tempEndTime: moment.Moment | null;

        if (
          startingTime &&
          startingTime.date() === start.date() &&
          startingTime.hour() === start.hour() &&
          startingTime.minutes() !== start.minutes()
        ) {
          tempEndTime = moment(end).seconds(0).milliseconds(0).minutes(start.minutes());
        } else {
          tempEndTime = moment(start).seconds(0).milliseconds(0).add(durationToMinutes(timespan[0]), "minutes");
        }

        const endTime = `endTime[${index + 1}]`;

        form.setFieldsValue({
          [endTime]: tempEndTime,
        });
      }
    } else if (start && !end) {
      const endTime = `endTime[${index + 1}]`;
      const duration = `duration[${index - 1}]`;

      form.setFieldsValue({
        [endTime]: start,
        [duration]: "",
      });
    }

    setStartingTime(start);
  };

  const { getFieldDecorator, getFieldValue } = form;

  getFieldDecorator("keys", {
    initialValue: [
      {
        name: "tag",
        type: "select",
        initialValue: !!tagId && !!tagName ? { key: tagId, label: tagName } : undefined,
        rules: [{ required: false, message: t("timelogs.MESSAGES.TAG_REQUIRED") }],
        placeholder: t("timelogs.CREATE_TAG.SELECT_TAG"),
      },
      {
        name: "duration",
        type: "input",
        initialValue: !!duration ? secondsToDuration(duration) : "",
        rules: [{ validator: durationValidator }],
        placeholder: t("timelogs.CREATE_TAG.DURATION"),
      },
      {
        name: "startTime",
        type: "timepickerStart",
        initialValue: !!startTime ? moment.utc(startTime).local() : moment("09:00", TIME_PICKER_FORMAT),
        rules: [
          {
            required: true,
            message: t("timelogs.MESSAGES.START_TIME_REQUIRED"),
          },
        ],
        placeholder: t("timelogs.CREATE_TAG.START_TIME"),
      },
      {
        name: "endTime",
        type: "timepickerEnd",
        initialValue:
          !!startTime && duration
            ? moment.utc(startTime).local().add(duration, "seconds")
            : moment("09:00", TIME_PICKER_FORMAT),
        rules: [
          {
            required: true,
            message: t("timelogs.MESSAGES.END_TIME_REQUIRED"),
          },
        ],
        placeholder: t("timelogs.CREATE_TAG.END_TIME"),
      },
      {
        name: "description",
        type: "textarea",
        initialValue: draftJSToRawData(description),
        rules: [
          {
            required: true,
            message: t("timelogs.MESSAGES.DESCRIPTION_REQUIRED"),
          },
        ],
        placeholder: t("timelogs.CREATE_TAG.DESCRIPTION"),
      },
    ],
  });
  const keys = getFieldValue("keys");

  const filterSelectInputHandler = (inputValue: string, optionValue) =>
    optionValue.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  const formItems = keys.map((item, index) => {
    if (item.type === "select") {
      return (
        <Col span={8} key={index}>
          <Form.Item label={item.placeholder} key={item}>
            {getFieldDecorator(`${item.name}[${index}]`, {
              rules: item.rules,
              initialValue: item.initialValue,
            })(
              <Select
                filterOption={(inputValue: string, option: React.ReactElement) =>
                  filterSelectInputHandler(inputValue, option.props.children)
                }
                showSearch={tags.length > 1}
                allowClear={true}
                labelInValue={true}
                placeholder={item.placeholder}>
                {tags.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      );
    }

    if (item.type === "input") {
      return (
        <Col span={4} key={index}>
          <Form.Item label={item.placeholder} hasFeedback={true} key={index}>
            {form.getFieldDecorator(`${item.name}[${index}]`, {
              initialValue: item.initialValue,
              rules: item.rules,
            })(<Input className="is-fullwidth" placeholder="0h" onBlur={() => durationFocusOut(index)} />)}
          </Form.Item>
        </Col>
      );
    }

    if (item.type === "timepickerStart")
      return (
        <Col span={6} key={index}>
          <Form.Item label={item.placeholder} hasFeedback={true} key={index}>
            {form.getFieldDecorator(`${item.name}[${index}]`, {
              initialValue: item.initialValue,
              rules: item.rules,
            })(
              <TimePicker
                className="is-fullwidth"
                format={TIME_PICKER_FORMAT}
                onChange={(e) => handleStartTimeChange(e, index)}
              />
            )}
          </Form.Item>
        </Col>
      );

    if (item.type === "timepickerEnd") {
      return (
        <Col span={6} key={index}>
          <Form.Item label={item.placeholder} hasFeedback={true} key={index}>
            {form.getFieldDecorator(`${item.name}[${index}]`, {
              initialValue: item.initialValue,
              rules: item.rules,
            })(
              <TimePicker
                className="is-fullwidth"
                format={TIME_PICKER_FORMAT}
                onChange={(e) => handleEndTimeChange(e, `${item.name}[${index}]`, index)}
              />
            )}
          </Form.Item>
        </Col>
      );
    }

    return (
      <Col span={24} key={index}>
        <Form.Item label={item.placeholder} key={index}>
          {form.getFieldDecorator(`${item.name}[${index}]`, {
            initialValue: draftJSToRawData(description),
            rules: [
              {
                required: true,
                message: t("timelogs.MESSAGES.DESCRIPTION_REQUIRED"),
              },
            ],
          })(<Input.TextArea autoSize={true} className={"customTextField"} />)}
          {keys.length > 5 ? (
            <div className={"newRowDiv"}>
              <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => remove(index)} />
              <Divider className={"customDivider"} />
            </div>
          ) : null}
        </Form.Item>
      </Col>
    );
  });

  return (
    <Form>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("timelogs.CREATE_MODAL.PROJECT")}>
            {form.getFieldDecorator("projectId", {
              initialValue: !!projectId && !!projectName ? { key: projectId, label: projectName } : undefined,
              rules: [
                {
                  required: true,
                  message: t("timelogs.MESSAGES.PROJECT_REQUIRED"),
                },
              ],
            })(<SelectProject userId={userId} t={t} selectProject={selectProject} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("timelogs.CREATE_MODAL.LOG_DATE")} hasFeedback={true}>
            {form.getFieldDecorator("logDate", {
              initialValue: !!startTime ? moment.utc(startTime).local() : moment(),
              rules: [
                {
                  type: "object",
                  required: true,
                  message: t("timelogs.MESSAGES.DATE_REQUIRED"),
                },
              ],
            })(
              <DatePicker
                format={DATE_PICKER_FORMAT}
                data-cy="log-date"
                // id="log-date"
                className="is-fullwidth"
                // onChange={changeDateHandler}
              />
            )}
          </Form.Item>
        </Col>

        {/* <Divider />*/}

        {formItems}
      </Row>
      {!isEditing ? (
        <Form.Item>
          <Button type="dashed" onClick={add} className={"addRowBtn"}>
            <Icon className="dynamic-delete-button" type="plus-circle-o" onClick={add} /> New entry
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default TimelogForm;
