import React from "react";
import ActionsComponent from "./actions";
import { Link, RouteComponentProps } from "react-router-dom";
import { List, Avatar, Icon } from "antd";
import { formatDateForDeputy } from "../../../../utils/date";
import { stringToHTML } from "../../../../helpers/draftjs";
import constants from "../../../../constants";
import { withTranslation, WithTranslation } from "react-i18next";
import TitleSection from "../../../../common/SectionTitle/title";
import { leaveRequestStatus } from "../../../../helpers/leaveRequestStatus";
const LeaveRequestDetails = (props: RouteComponentProps & WithTranslation) => {
  const { state }: any = props.location;
  const { leaveRequest, selectedYear }: any = state;
  const {
    id,
    startDate,
    endDate,
    employee,
    comment,
    leaveTypeName,
    duration,
    employeeStatus,
    deputyStatus,
    managerStatus,
  } = leaveRequest;
  const { t } = props;
  const data = [
    {
      title: t("projectsTableColumns.STATUS"),
      value: leaveRequestStatus(employeeStatus, deputyStatus, managerStatus, t).message,
      icon: "form",
    },
    { title: t("leaveRequests.tableColumns.EMPLOYEE"), value: employee, icon: "user" },
    { title: t("leaveRequests.tableColumns.START_DATE"), value: formatDateForDeputy(startDate), icon: "calendar" },
    { title: t("leaveRequests.tableColumns.END_DATE"), value: formatDateForDeputy(endDate), icon: "carry-out" },
    {
      title: t("leaveRequests.tableColumns.DURATION"),
      value: `${duration} ${duration > 1 ? "days" : "day"}`,
      icon: "dashboard",
    },
    { title: t("leaveRequests.tableColumns.COMMENT"), value: stringToHTML(comment), icon: "notification" },
    { title: t("leaveRequests.tableColumns.LEAVE_TYPE"), value: leaveTypeName, icon: "gold" },
  ];
  return (
    <div className="leave-request-details">
      <TitleSection title={t("sideMenu.LEAVE_MANAGEMENT.DEPUTY_REQUESTS")} iconType="audit" />
      <div className="wrapper">
        <div className="header-wrapper">
          <div>
            <Link to="/dashboard/requests">
              <Icon type="left" style={{ marginRight: "10px", color: "black", fontSize: "16px" }} />
              <span className="customer-header-style">{t("sideMenu.LEAVE_MANAGEMENT.DEPUTY_REQUESTS")}</span>
            </Link>
            <span>
              {state && state.leaveRequest && ` / ${state.leaveRequest.employee}'s `}
              {t("sideMenu.LEAVE_MANAGEMENT.DEPUTY_REQUESTS")}
            </span>
          </div>
          {deputyStatus === constants.leaveRequestStatus.statusPending && (
            <div className="deputy-actions">
              <ActionsComponent id={id} selectedYear={selectedYear} />
            </div>
          )}
        </div>
        <List
          itemLayout="vertical"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar icon={item.icon} style={{ backgroundColor: "#001529" }} />}
                title={item.title}
                style={{ whiteSpace: "pre-wrap" }}
                description={item.value}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default withTranslation()(LeaveRequestDetails);
