import { useState, useEffect } from "react";
import moment, { Moment } from "moment";

const useTimeOffPolicy = (startDate: Moment, daysDifference: number): [boolean, boolean] => {
  const [firstRule, setFirstRule] = useState<boolean>(false);
  const [secondRule, setSecondRule] = useState<boolean>(false);

  useEffect(() => {
    const businessDiff = (startDate: Moment, endDate: Moment): number => {
      let currentDate = moment(startDate);
      const diff = moment(endDate).diff(startDate, "days");
      let businessDays = 0;

      for (let i = 0; i <= diff; i++) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          businessDays++;
        }
        currentDate.add(1, "days");
      }

      return businessDays;
    };

    const timeOffPolicy = (startDate: Moment, daysDifference: number): void => {
      const today = moment();
      const diff = businessDiff(today, startDate); // Calculates the difference in business days

      if (daysDifference <= 5 && diff < daysDifference * 2) {
        setSecondRule(false);
        setFirstRule(true);
      } else if (daysDifference > 5 && diff < 20) {
        setFirstRule(false);
        setSecondRule(true);
      } else {
        setFirstRule(false);
        setSecondRule(false);
      }
    };

    timeOffPolicy(startDate, daysDifference);
  }, [startDate]);

  return [firstRule, secondRule];
};

export default useTimeOffPolicy;
