import React, { useState } from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { UPDATE_TAG } from "../../../../../../graphql/mutations";
import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { GET_PROJECT_TAGS } from "../../../../../../graphql/queries";

interface IProps extends FormComponentProps {
  t: TFunction;
  tag: any;
  projectId: number;
}

const TagEdit: React.FC<IProps> = ({ t, tag, form, projectId }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [projects, setProjects] = useState<any>([]);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  return (
    <>
      <span>
        <Button icon="edit" onClick={toggleModal}>
          {t("buttons.EDIT")}
        </Button>
      </span>
      <Mutation
        mutation={UPDATE_TAG}
        refetchQueries={[
          {
            query: GET_PROJECT_TAGS,
            variables: { projectId },
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.TAGS.MESSAGES.TAG_UPDATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(editTag, { loading }) => (
          <Modal
            title={t("appConfiguration.TAGS.EDIT_TAG")}
            width={600}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const {
                    name,
                    project: { key },
                  } = values;

                  editTag({
                    variables: {
                      id: tag.id,
                      name,
                      projectId: key,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("timelogs.CREATE_TAG.PROJECT")}>
                    {form.getFieldDecorator("project", {
                      initialValue: { key: tag?.project?.id, label: tag?.project?.name },
                      rules: [{ required: true, message: t("appConfiguration.TAGS.MESSAGES.PROJECT_REQUIRED") }],
                    })(
                      <Select
                        disabled={true}
                        optionFilterProp="children"
                        showSearch={true}
                        labelInValue={true}
                        placeholder={t("timelogs.CREATE_TAG.PROJECT")}>
                        {projects.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("addRoleModal.LABELS.NAME")}>
                    {form.getFieldDecorator("name", {
                      initialValue: tag.name,
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.TAGS.MESSAGES.NAME_REQUIRED"),
                        },
                      ],
                    })(<Input placeholder={t("addRoleModal.LABELS.NAME")} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(TagEdit);
