export const checkPermissions = (
  userPermissions: string[] | undefined,
  allowedPermissions: string[],
  allPermissionsNeeded: boolean,
  multipleResourceUserPermissions: any,
  multipleResourceAllowedPermissions: any
) => {
  if (!multipleResourceUserPermissions) {
    if (allowedPermissions.length === 0) {
      return true;
    }

    if (!allPermissionsNeeded) {
      return userPermissions && userPermissions.some((permission) => allowedPermissions.includes(permission));
    } else {
      return userPermissions && allowedPermissions.every((permission) => userPermissions.includes(permission));
    }
  } else {
    return checkMultiplePermissions(
      multipleResourceUserPermissions,
      multipleResourceAllowedPermissions,
      allPermissionsNeeded
    );
  }
};

export const checkMultiplePermissions = (
  userPermissions: any,
  allowedPermissions: any,
  allPermissionsNeeded: boolean
): boolean => {
  const keys = Object.keys(allowedPermissions);
  if (allPermissionsNeeded) {
    for (let i = 0; i < keys.length; i++) {
      const thisPermissions = allowedPermissions[keys[i]];
      const thisUserPermissions = userPermissions[keys[i]];
      if (!thisPermissions.every((p) => thisUserPermissions.includes(p))) {
        return false;
      }
    }
  } else {
    for (let i = 0; i < keys.length; i++) {
      const thisPermissions = allowedPermissions[keys[i]];
      const thisUserPermissions = userPermissions[keys[i]];
      if (!thisPermissions.every((p) => thisUserPermissions.includes(p))) {
        return false;
      }
    }
  }
  return true;
};

export const AccessControl = ({
  userPermissions,
  allowedPermissions,
  allPermissionsNeeded,
  multipleResourceUserPermissions,
  multipleResourceAllowedPermissions,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(
    userPermissions,
    allowedPermissions,
    allPermissionsNeeded,
    multipleResourceUserPermissions,
    multipleResourceAllowedPermissions
  );

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

export const isInternalUser = () => {
  const roles = JSON.parse(localStorage.getItem("roles") as string);

  if (roles.some(({ isInternal }) => isInternal)) {
    return true;
  }
  return false;
};

export const isAllowed = (
  permissions: null | string[] | undefined,
  allowedPermissions: string[],
  allPermissionsNeeded = false
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  if (!allPermissionsNeeded) {
    return permissions && permissions.some((permission) => allowedPermissions.includes(permission));
  } else {
    return permissions && permissions.every((permission) => allowedPermissions.includes(permission));
  }
};
AccessControl.defaultProps = {
  userPermissions: [],
  allowedPermissions: [],
  allPermissionsNeeded: false,
  multipleResourceUserPermissions: null,
  multipleResourceAllowedPermissions: null,
  renderNoAccess: () => null,
};
