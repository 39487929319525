import React from "react";
import { useTranslation } from "react-i18next";
import Search from "antd/lib/input/Search";
import { Icon, Radio, Select, Tooltip } from "antd";
import DocumentUpload from "./DocumentUpload";
import { IUser } from "screens/Profile/Interfaces";

const { Option } = Select;

interface IProps {
  selectFileTypeValue: any;
  setSelectFileTypeValue: any;
  uniqueFileTypes: any;
  uniqueCategories: any;
  selectValue: any;
  setSelectValue: any;
  setSearchValue: any;
  dataDisplayType: string;
  handleDisplayTypeChange: any;
  userData: IUser;
  userId: number;
}
const DocumentActions: React.FC<IProps> = ({
  selectFileTypeValue,
  setSelectFileTypeValue,
  uniqueFileTypes,
  uniqueCategories,
  selectValue,
  setSelectValue,
  setSearchValue,
  userData,
  dataDisplayType,
  handleDisplayTypeChange,
  userId,
}) => {
  const { t } = useTranslation();

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const handleSelectChange = (value) => {
    setSelectValue(value);
  };
  const handleSelectFileTypeChange = (value) => {
    setSelectFileTypeValue(value);
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ marginBottom: "40px" }}>
        <DocumentUpload userData={userData} t={t} userId={userId} />
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
        <div style={{ marginBottom: "40px", marginLeft: "20px" }}>
          <Radio.Group size="default" value={dataDisplayType} onChange={(e) => handleDisplayTypeChange(e.target.value)}>
            <Tooltip placement="topLeft" title={"Card display"}>
              <Radio.Button value="card">
                <Icon type="appstore" />
              </Radio.Button>
            </Tooltip>
            <Tooltip placement="topLeft" title={"Table display"}>
              <Radio.Button value="table">
                <Icon type="table" />
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </div>
      </div>
      {dataDisplayType === "table" && (
        <div style={{ flex: "0 0 5%", marginRight: 20 }}>
          <Select
            showSearch
            allowClear
            style={{ width: 150 }}
            placeholder="Select category"
            optionFilterProp="children"
            onChange={handleSelectChange}
            value={selectValue}>
            {uniqueCategories?.map((category: string) => (
              <Option value={category}>{category}</Option>
            ))}
          </Select>
        </div>
      )}
      <div style={{ flex: "0 0 5%", marginRight: 20 }}>
        <Select
          showSearch
          allowClear
          style={{ width: 150 }}
          placeholder="Select file type"
          optionFilterProp="children"
          onChange={handleSelectFileTypeChange}
          value={selectFileTypeValue}>
          {uniqueFileTypes?.map((fileType: string) => (
            <Option value={fileType}>{fileType.toUpperCase()}</Option>
          ))}
        </Select>
      </div>
      <div style={{ flex: "0 0 30%" }}>
        <Search allowClear enterButton onSearch={handleSearchChange} placeholder="Search document by file name" />
      </div>
    </div>
  );
};

export default DocumentActions;
