export const formatDuration = (duration) => {
  if (!!duration) {
    const hoursIndex = duration.search("h");
    const minutesIndex = duration.search("m");

    // Format: 1h40m, 1h 40m, 1h 40min
    if (hoursIndex > -1 && minutesIndex > -1) {
      const rawHours = parseFloat(duration.split("h")[0]);
      const rawMinutes = parseFloat(duration.slice(hoursIndex + 1, minutesIndex));

      if (!isNaN(rawHours) && !isNaN(rawMinutes)) {
        const minutes = rawHours * 60 + rawMinutes;

        // Format: 1h 70m convert to 2h 10min
        // Format: 1h 120m convert to 3h
        return {
          valid: true,
          minutes,
          duration: minutesToDuration(minutes),
        };
      }
    }
    // Format 1h, 1.5h
    else if (hoursIndex > -1 && minutesIndex === -1) {
      const hours = parseFloat(duration.split("h")[0]);

      if (!isNaN(hours)) {
        const minutes = hours * 60;

        return {
          valid: true,
          minutes,
          duration: minutesToDuration(minutes),
        };
      }
    }
    // Format 30m, 100 min convert to 1h 40m
    else if (hoursIndex === -1 && minutesIndex > -1) {
      const minutes = parseFloat(duration.slice(hoursIndex + 1, minutesIndex));

      if (!isNaN(minutes)) {
        return {
          valid: true,
          minutes,
          duration: minutesToDuration(minutes),
        };
      }
    }
    // Format 1 convert to 1h, 1.5 convert to 1h 30m
    else if (hoursIndex === -1 && minutesIndex === -1) {
      const hours = parseFloat(duration);

      if (!isNaN(hours)) {
        const minutes = hours * 60;

        return {
          valid: true,
          minutes,
          duration: minutesToDuration(minutes),
        };
      }
    }
  }

  return {
    valid: false,
    minutes: 0,
    duration: "",
  };
};

export const minutesToDuration = (minutes: number) => {
  if (minutes < 60) {
    return `${Math.floor(minutes)}m`;
  }

  return minutes % 60 === 0 ? `${minutes / 60}h` : `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
};

export const timeInvoiceFormatter = (minutes: number) => {
  if (minutes < 60) {
    return `${Math.floor(minutes)}`;
  }

  return minutes % 60 === 0 ? `${minutes / 60}` : `${Math.floor(minutes / 60)} ${minutes % 60}`;
};

export const secondsToHours = (seconds: number) => {
  return Math.floor(seconds / 3600);
};

export const secondsToDuration = (seconds: number) => {
  if (seconds < 60) {
    return "1m";
  }

  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)}m`;
  }

  return seconds % 3600 === 0
    ? `${seconds / 3600}h`
    : `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m`;
};

export const durationToMinutes = (duration: string) => {
  const hoursIndex = duration.search("h");
  const minutesIndex = duration.search("m");

  if (hoursIndex > -1 && minutesIndex > -1) {
    const hours = parseInt(duration.split("h")[0], 10);
    const minutes = parseInt(duration.substring(hoursIndex + 1, minutesIndex).trim(), 10);

    if (!isNaN(hours) && !isNaN(minutes)) {
      return hours * 60 + minutes;
    }
  } else if (hoursIndex > -1 && minutesIndex === -1) {
    const hours = parseInt(duration.split("h")[0], 10);

    if (!isNaN(hours)) {
      return hours * 60;
    }
  } else if (hoursIndex === -1 && minutesIndex > -1) {
    const minutes = parseInt(duration.split("m")[0], 10);

    if (!isNaN(minutes)) {
      return minutes;
    }
  }

  return 0;
};
