import React from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { DEACTIVATE_USER } from "../../../graphql/mutations";
import { Button, Popconfirm, notification } from "antd";

interface IProps {
  t: TFunction;
  userId: number;
  refetch: any;
}

const DeactivateUser = ({ t, userId, refetch }: IProps) => {
  return (
    <Mutation
      mutation={DEACTIVATE_USER}
      refetchQueries={refetch}
      update={() => {
        notification.success({
          message: t("userManagement.messages.DEACTIVATED_USER"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deactivateUser, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => {
            deactivateUser({ variables: { id: userId } });
          }}>
          <Button type="danger" className="mr-10" style={{ marginLeft: "10px" }} loading={loading} icon="delete" />
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default DeactivateUser;
