/* eslint-disable max-len */
export default {
  translation: {
    dashboard: {},
    disclaimer: {
      FIRST_RULE: `Please note that the ${"2x Rule"} applies to absence requests with a duration of up to 5 business days. According to this rule, you are required to submit your absence request a number of business days in advance equal to twice the duration of your requested absence.`,
      SECOND_RULE: `Disclaimer: Please note that the ${"1 Month Rule"} applies to absence requests exceeding 5 business days. You must submit your request at least one month in advance. Failure to comply may result in the request being declined by the manager. Make sure to notify your manager in advance for any exceptional circumstances or urgent requests.`,
    },
    sideMenu: {
      HOME: "Home",
      ABSENCES: "Absences",
      CALENDARS: "Calendars",
      ORGANIZATION: "Organization",
      GENERAL_SETTINGS: "General Settings",
      PERFORMANCE_REVIEW: "Performance Review",
      DASHBOARD: "Dashboard",
      PROJECTS: "Projects",
      ASSIGNED_PROJECTS: "Assigned Projects",
      CLIENT_PRODUCTS: "Clients",
      TIMELOGS: "Time logs",
      USERS: "Users",
      INVOICES: "Invoices",
      REPORTS: "Reports",
      ANALYTICS: {
        UTILIZATION: "Utilization",
        FINANCE_OVERVIEW: "Finance Overview",
        UTILIZATION_RATE: "Utilization Rate",
      },
      CONTRACTS: "Contracts",
      PROFILE: "Profile",
      COMPANIES: "Companies",
      MANAGMENT: {
        ROLES: "Roles & Permissions",
        MODULES_CONFIGURATION: "Office Settings",
      },
      LEAVE_MANAGEMENT: {
        REQUESTS: "Requests",
        LEAVE_REQUESTS: "My Leave Requests",
        DEPUTY_REQUESTS: "Deputy Requests",
        MANAGER_REQUESTS: "Under Management",
        CALENDAR_VIEW: "Calendar Summary",
        CANCELED_REQUESTS: "Canceled Requests",
        UPCOMING_REQUESTS: "Upcoming Requests",
      },
    },
    addRoleModal: {
      TITLE: "Add new Role",
      MESSAGE: "Role successfully created",
      NAME_REQUIRED: "Role name is required",
      LABELS: {
        NAME: "Name",
        PROJECT: "Project",
        TYPE: "Type",
      },
    },
    userManagement: {
      messages: {
        DEACTIVATED_USER: "User is successfully deactivated",
      },
    },
    invoicesTableColumns: {
      OWNER_NAME: "Owner Name",
      EMPLOYEE: "Employee",
      INVOICE_ID: "Invoice ID",
      CLIENT_NAME: "Client Name",
      MONTH: "Month",
      DUE: "Due Date",
      VALUE: "Total Value",
      PAYMENT_STATUS: "Payment Status",
      APPROVAL_STATUS: "Approval Status",
      DOCUMENT_URL: "Invoice Document",
      ACTIONS: "Actions",
      CREATED: "Created",
    },
    permissionsTableColumns: {
      RESOURCE: "Resource",
      VIEW: "View",
      CREATE: "Create",
      UPDATE: "Update",
      DELETE: "Delete",
      DOWNLOAD_INVOICE_DOC: "Download Document",
    },
    popConfirms: {
      TITLE: "Are you sure?",
      ON_OK: "Sure",
    },
    emptyDescriptions: {
      ROLES: "No Roles Created Yet",
      INVOICES: "No Invoices created yet",
      EMPLOYEE_INVOICE: "No employee to be invoiced",
      CLIENTS: "No clients created yet",
      REPORTS: "No clients to view reports created",
      DEPUTY_REQUESTS: "No upcoming deputy request at this moment.",
      CANCELED_REQUESTS: "No canceled requests at this moment.",
      UPCOMING_REQUESTS: "No upcoming leave requests at this moment.",
      UNDER_MANAGEMENT: "No Employees Under Management",
      MILESTONE_INVOICE: "No milestones completed during this month",
      NO_INVOICE_DETAILS: "No Invoice Details",
      NO_TRANSACTION_DETAILS: "No Transaction Details",
    },
    createInvoiceModal: {
      TITLE: "Create Invoice",
      EDIT_RATE: "Edit Rate",
      GENERATE_INVOICE: "Generate Invoice",
      INVOICE_ASSIGNMENT_TIMELOGS: "Invoice Assignment Time logs",
      LABELS: {
        MONTH: "Invoice month",
        CLIENT: "Client",
        DUE: "Due Date",
        DISCOUNT: "Discount Percentage",
      },
      MESSAGES: {
        MONTH: "Please provide invoice month",
        CLIENT: "Please provide client",
        UPDATED_RATE: "Rate successfully updated",
        BILLABLE_HOURS: "Billable hours",
        INVOICE_ITEMS: "Invoice items",
        TIMELOGS_BREAKDOWN: "Time logs Breakdown",
        INVOICE_GENERATED: "Invoice successfully generated",
        INVOICE_UPDATED: "Invoice successfully updated",
        MILESTONES: "Milestones",
      },
    },
    contracts: {
      form: {
        PERIOD: "Period",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        POSITION: "Position",
        WORKING_HOURS_PER_MONTH: "Working hours per month",
        HOURLY_RATE: "Hourly Rate",
        SALARY: "Salary",
        EMPLOYEE: "Employee",
        EMPLOYMENT_TYPE: "Employment type",
        SIGNED_BY: "Signed By",
        ADD_CONTRACT: "Add contract",
        EDIT_CONTRACT: "Edit contract",
        EXPORT_PDF: "Export Contract",
        UPLOAD_PDF: "Upload Contract",
        EXPIRE: "Expire status",
        required: {
          START_DATE: "Start date is required and must be after the last contract end date",
          END_DATE: "End date is required and must be after the last contract start date",
          EMPLOYEE: "You have to select employee in order to create a contract!",
          EMPLOYMENT_TYPE: "Employment Type is required!",
          POSITION: "Position is required when creating a contract",
          SALARY: "Salary is required when employment type is Employee",
          WORKING_HOURS_PER_MONTH: "Working h/month is required when employment type is Contractor",
          HOURLY_RATE: "Hourly rate is required when employment type is Contractor",
        },
        DOWNLOAD: "Download",
        VIEW_DETAILS: "View Details",
      },
      messages: {
        CONTRACT_STOPPED: "Contract was successfuly stopped",
      },
      modalNames: {
        STOP_CONTRACT: "End {{name}}'s Contract",
        STOP: "Stop Contract",
      },
    },
    reports: {
      NAME: "Name",
      MONTH_REPORT: "Month Report",
      DATE_REPORT: "Report Date",
      CLIENTS_REPORT: "Clients",
      PROJECTS_REPORT: "Projects",
      TEAM_REPORT: "Team",
      TAGS_REPORT: "Tags",
      APPLY_FILTER_REPORT: "APPLY",
      GENERATE_REPORT: "Generate Report",
      CSV: "CSV",
      PDF: "PDF",
      PLACEHOLDERS: {
        SELECT_CLIENTS: "Select clients",
        SELECT_PROJECTS: "Select projects",
        SELECT_MEMBERS: "Select members",
        SELECT_TAGS: "Select tags",
        SELECT_HOURS: "Select hours",
      },
      MESSAGES: {
        DATE_REQUIRED: "Please enter Report Date",
      },
    },
    invoiceDetails: {
      STATISTICS: {
        LIFE_EARNINGS: "Life Earnings",
        YTD_EARNINGS: "YTD Earnings",
        LAST_MONTH_EARNINGS: "Last Month",
      },
      TABLE: {
        EMPLOYEE: "Employee",
        PROJECT: "Project",
        TOTAL_HOURS: "Total Hours",
        RATE: "Rate",
        AMOUNT: "Amount",
        TOTAL_AMOUNT: "Total Amount",
        ACTIONS: "Actions",
      },
    },
    buttons: {
      UPLOAD: "Upload",
      CREATE_ROLE: "Create Role",
      SAVE_CHANGES: "Save Changes",
      CREATE_INVOICE: "Create Invoice",
      EDIT_INVOICE: "Edit Invoice",
      DETAILS: "Details",
      DELETE: "Delete",
      MARK_PAID: "Pay",
      MARK_UNPAID: "Un-pay",
      INVOICE_APPROVE: "Approve",
      INVOICE_DISAPPROVE: "Disapprove",
      VIEW_WORK_LOGS: "View Work Logs",
      LOGIN: "Login",
      EDIT: "Edit",
      CREATE: "Create",
      SUBMIT: "Submit",
      UPDATE: "Update",
      CANCEL: "Cancel",
      CONFIRM: "Confirm",
      APPROVE: "Approve",
      DECLINE: "Decline",
      SEARCH: "Search",
    },
    messages: {
      DOCUMENT_UPLOADED: "Document successfully uploaded",
      DOCUMENT_UPLOADED_ERROR: "Error uploading the document",
      PERMISSIONS_SAVED: "Permissions succesfully saved",
      INVOICE_DELETED: "Invoice successfully deleted",
      INVOICE_DELETED_ERROR: "Cannot Delete Approved Invoice",
      INVOICE_CREATED: "Invoice successfully generated",
      INVOICE_UPDATED: "Invoice Status updated",
      INVOICE_APPROVED: "Invoice Approved By Manager",
      INVOICE_APPROVED_NOT_ALLOWED: "Cannot approve/disapprove invoice. Operation not allowed!",
      CONTRACT_CREATED: "Contract successfully created",
    },
    placeholders: {
      ROLE: "Role",
      LOADING_ROLES: "Loading Roles...",
      CHOOSE_MONTH: "Choose Month",
      ADD_ROLE: "Add / Remove roles",
      LOADING_MANAGERS: "Loading Managers...",
      SELECT_MANAGER: "Manager",
      SELECT_DEPUTY: "Select a Deputy",
      LOADING_DEPUTIES: "Loading Deputies ...",
      SELECT_LEAVE_TYPE: "Select a Leave Type",
      LOADING_LEAVE_TYPES: "Loading Leave Types",
      SELECT_PROJECT: "Select a Project",
      SELECT_COUNTRY: "Country",
      LOADING_COUNTRIES: "Loading Countries...",
      ROLE_NAME: "Enter role name",
      SELECT_CLIENT: "Select a Client",
      SELECT_EMPLOYEE: "Select Employee",
      LOADING_CLIENTS: "Loading Clients...",
      LOADING_EMPLOYEES: "Loading Employees...",
      LOADING_PROJECTS: "Loading Projects",
      NO_PROJECT_ASSIGNMENTS: "No project assignments",
      LOGIN: {
        PASSWORD: "Password goes here..",
        EMAIL: "you@email.com",
      },
    },
    employees: {
      HOLIDAYS_LEFT: "Holidays Left in",
      CONTRACT_START: "Contract Start",
      EMPLOYMENT_TYPE: "Employment Type",
      WORKING_COUNTRY: "Working Country",
      FILTER_BY_WORKING_YEAR: "Filter By Working Year",
    },
    leaveRequests: {
      LABELS: {
        START_DATE: "Start Date:",
        END_DATE: "End Date:",
        LEAVE_TYPE: "Leave Type",
        DEPUTY: "Deputy",
        COMMENT: "Comment",
      },
      LEAVE_REQUESTS: "Leave Requests",
      HOLIDAYS_LEFT: "Holidays Left in",
      ADD_LEAVE_REQUEST: "Add Leave Request",
      FILTER_WORKING_YEAR: "Filter by working year",
      DAYS_TOOLTIP: "Days used from last year",
      ACCRUED: "Accrued or ongoing leave.",
      WAITING: "Waiting for cancellation.",
      EDIT_MODAL_TITLE: "Edit Leave Request",
      EXPORT_REQUESTS: "Export Requests",
      MESSAGES: {
        LEAVE_REQUEST_DELETED: "Leave request successfully deleted",
        LEAVE_REQUEST_SUBMITTED: "Leave request successfully submitted",
        CANCEL_REQUEST: "Cancel request successfully sent to manager",
        LEAVE_REQUEST_UPDATED: "Leave request successfully updated!",
        DEPUTY_REQUIRED: "Please select a deputy!",
        LEAVE_TYPE_REQUIRED: "Please select a leave type!",
        END_DATE_REQUIRED: "Please provide a end date!",
        START_DATE_REQUIRED: "Please provide a start date!",
        LEAVE_REQUEST_CREATED: "Congrats your leave request has been added successfully!",
        CANCELED_REQUEST: "Employee has asked for cancellation.",
        DECLINED_DEPUTY_REQUEST: "Deputy request was successfully declined!",
        APPROVED_DEPUTY_REQUEST: "Deputy request was successfully approved!",
        DELETED_LEAVE_REQUEST: "Leave request successfully deleted!",
        DECLINED_CANCEL_REQUEST: "attempt to cancel his/her leave request was declined!",
        WAITING_CANCELATION: "Waiting for cancellation.",
        LEAVE_REQUEST_APPROVED: "Leave request successfully approved!",
        LEAVE_REQUEST_DECLINED: "Leave request successfully declined!",
        DOCTOR_REPORT: "Doctor Report",
        AWAITING_EMPLOYEE_CONFIRMATION: "Awaiting employee confirmation",
        AWAITING_DEPUTY_CONFIRMATION: "Awaiting deputy confirmation",
        AWAITING_MANAGER_CONFIRMATION: "Awaiting manager confirmation",
        REQUEST_APPROVED_MANAGER_DEPUTY: "Leave request approved by deputy and manager",
        REQUEST_CANCELLED_DECLINED: "Leave request has been cancelled by employee. Awaiting confirmation",
      },
      tableColumns: {
        START_DATE: "Start Date",
        END_DATE: "End Date",
        DURATION: "Duration",
        QTY: "Quantity",
        LEAVE_TYPE: "Leave Type",
        DEPUTY: "Deputy",
        DEPUTY_STATUS: "Deputy Status",
        APPROVAL_STATUS: "Approval Status",
        ACTIONS: "Actions",
        EMPLOYEE: "Employee",
        DETAILS: "Details",
        COMMENT: "Comment",
      },
      CALENDAR_SUMMARY: {
        RESOURCES: "Resources",
        WEEK: "Week",
        MONTH: "Month",
        YEAR: "Year",
      },
    },
    login: {
      TROUBLE_LOGIN: "Trouble logging in?",
      FORGOT_PASSWORD: "Forgot Password?",
      BACK_TO_LOGIN: "Back to Login",
      VALIDATION: {
        NOT_MATCH_PASSWORD: "Two passwords that you entered are inconsistent!",
        LOW_LENGTH_PASSWORD: "Password should be at least 6 characters!",
      },
      LOGIN_FORM_LABELS: {
        EMAIL: "Your email:",
        PASSWORD: "Your password:",
        NEW_PASSWORD: "Your new password:",
        CONFIRM_PASSWORD: "Confirm new password:",
      },
      PLACEHOLDERS: {
        NEW_PASSWORD: "Enter new password",
        CONFIRM_PASSWORD: "Confirm new password",
      },
      MESSAGES: {
        EMAIL: "Please write your email!",
        PASSWORD: "Please insert your password",
        RESET_EMAIL: "Enter your email and we will provide you with a link to reset your password.",
        PASSWORD_RESET_COMPLETE: "Password successfully reset!",
        PROVIDE_NEW_PASSWORD: "Please write your new password!",
        CONFIRM_NEW_PASSWORD: "Please confirm your new password!",
        EXPIRED_TOKEN: "Reset password link has expired.",
      },
      ALERT: {
        EMAIL: "We sent an email to {{email}} with a link to get back into your account.",
      },
    },
    analytics: {
      CHART: "Data for 3 months of revenue and billable hours",
      STATISTICS: {
        BILLED_HOURS: "Billable Hours",
        BILLED: "Billable",
        NON_BILLED_HOURS: "Non-billable hours",
        NON_BILLED: "Non-billable",
        HOURS: "Hours",
        REVENUE: "Revenue",
        PREDICTION: "Prediction",
      },
      TIMELINES: {
        TODAY: "Today",
        THIS_WEEK: "This Week",
        THIS_MONTH: "This Month",
        LAST_MONTH: "Last Month",
        SECONDARY_MONTH: "The month before last month",
      },
      UTILIZATION: {
        DATE: "Date",
        METRIC: "Metric",
        SELECT_METRIC: "Select Metric",
      },
      REVENUE: {
        TITLE: "Revenue",
        SELECT_REVENUE: "Select Revenue",
        GROSS: "Gross",
        NET: "Net",
      },
      FINANCE_OVERVIEW: {
        HISTORICAL_DATA: "Historical Data",
        // HISTORICAL_DATA_MESSAGE: "Revenue data from January until last month",
        MONTHLY_DATA: "Monthly Data",
        // MONTHLY_DATA_MESSAGE: "Revenue data and prediction for current month",
        PREDICTION_DATA: "Prediction Data",
        // PREDICTION_DATA_MESSAGE: "Revenue prediction for next six months",
        GENERAL_OVERVIEW: "General Overview",
      },
    },
    usersTableColumns: {
      NAME: "Name",
      PROFESSION: "Profession",
      PROJECTS_ASSIGNED: "Projects Assigned",
      CREATE_PROJECT: "Create Project",
      EMAIL: "Email",
      ROLE: "Role",
      ROLES: "Roles",
      ACTIONS: "Actions",
      buttons: {
        CREATE_USER: "Create a user",
      },
    },
    projectsTableColumns: {
      CLIENT: "Client",
      NAME: "Name",
      PROJECT: "Project",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      DEVELOPERS: "Developers",
      STATUS: "Status",
      ACTIONS: "Actions",
      BILLABLE: "Billable",
      EDIT: "Edit",
      CREATE: "Create Project",
      DETAILS: "Details",
      EMPLOYEES: "Employees",
      PROJECT_DETAILS: "Project Details",
      MANAGE_EMPLOYEES: "Manage Employees",
      ASSIGN_EMPLOYEE: {
        ADD_EMPLOYEE: "Add an Employee",
        TITLE: "Assign Employee to Project",

        MESSAGES: {
          ASSIGNED_EMPLOYEE: "You have successfully assigned an employee",
          EMPLOYEE_REQUIRED: "Please select an employee",
          RATE_REQUIRED: "Please enter hourly rate",
        },
      },
      UN_ASSIGN_CLIENT: {
        CLIENT_UNASSIGNED: "You have successfully unassigned a user from ",
        UN_ASSIGN: "UnAssign",
      },
      manageProjectModal: {
        TITLE: "Manage Project",
        PROJECT_NAME: "Project Name",
        DESCRIPTION: "Description",
      },
      addClientModal: {
        ADD_NEW_CLIENT_BUTTON: "Add New Client",
        TITLE: "Add New Client",
        CONFIGURATION: "Configuration",
        CLIENT_NAME: "Client Name",
        DESCRIPTION: "Description",
        CREATED_CLIENT: "Client successfully created",
      },
      messages: {
        CREATED_USER: "New user successfully registered",
        DESCRIPTION: "Project description is required",
        PROJECT_NAME: "Project name is required",
        CLIENT_NAME: "Client name is required",
        UNASSIGN: "Employee uccessfully unassigned",
        EDIT: "Employee assignment successfully updated",
        PROJECT_CREATED: "Project successfully created",
        PROJECT_UPDATED: "Project successfully updated",
        INVOICE_DUE_DAYS_REQUIRED: "Invoice due days is required",
      },
      placeholder: {
        PROJECT_NAME: "Project name",
        CLIENT_NAME: "Client name",
        DESCRIPTION: "Project description",
        DATE: "Select date",
        EMPLOYEE: "Select employee",
        HOURLY_RATE: "Hourly rate",
        INVOICE_DUE_DAYS: "Invoice due days",
        ACTIVE_STATUS: "Active Client",
      },
      users: {
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        HOURLY_RATE: "Hourly rate",
        ACTIONS: "Actions",
        SEARCH_USER_NAME: "Search by user name",
      },
      buttons: {
        EDIT: "Edit",
        UNASSIGN: "Unassign",
        ADD: {
          EMPLOYEE: "Add a Employee",
          CLIENT: "Add a Client",
        },
      },
    },
    clientUsers: {
      ASSIGN_CLIENT_USER: "Assign Client User",
      ASSIGN_CLIENT: "Assign Client",
      tableColumns: {
        FIRSTNAME: "First name",
        LASTNAME: "Last name",
        ACTIONS: "Actions",
      },
      MESSAGES: {
        ASSIGNED_USER: "You successfully assigned a client user to",
        SELECT_USER: "Please select a client user!",
      },
    },
    analyticsModal: {
      UTILIZATION: "Percent Utilization",
      LOGGED_HOURS: "Logged Hours",
      AVILABLE: "Available Hours",
      TITLE: "User Utilization",
    },
    utilization: {
      SHOW_WORKING_DAYS: "Show only working days",
    },
    timelogs: {
      EDIT_MODAL: {
        TITLE: "Edit Timelog",
      },
      CREATE_TAG: {
        TAG_CREATED: "Congrats you just added a tag",
        TITLE: "Add Tag",
        TAG: "Tag",
        TAGS: "Tags",
        LOG_DATE: "Log Date",
        HOURS: "Hours",
        NEW_TAG: "New Tag",
        SELECT_TAG: "Select a Tag",
        DESCRIPTION: "Description",
        ADD_LOG: "Add Log",
        PROJECT: "Project",
        DURATION: "Duration",
        START_TIME: "Start time",
        END_TIME: "End time",
      },
      TIMELOGS_REMINDER: "Time logs reminder email",
      MESSAGES: {
        TIMELOG_CREATED: "Time log successfully confirmed",
        TIMELOG_DELETED: "Time log successfully deleted",
        SYNC_CALENDAR: "Calendar has synced on google",
        SYNC_CALENDAR_TITLE: "Sync to google calendar",
        TIMELOG_EDITED: "Time log successfully edited",
        STATUS_REQUIRED: "Please select a State",
        DATE_REQUIRED: "Please enter log date",
        DURATION_REQUIRED: "Please enter duration",
        WRONG_FORMAT: "Incorrect format",
        START_TIME_REQUIRED: "Please enter start time",
        END_TIME_REQUIRED: "Please enter end time",
        TAG_REQUIRED: "Please select a tag",
        TOTAL_HOURS_REQUIRED: "Please enter total hours",
        DESCRIPTION_REQUIRED: "You have to provide a description",
        PROJECT_REQUIRED: "Please select a Project",
        HOURS: "Please enter a number between 0.1 and {{hours}}",
        OFF_TIMELOGS_REMINDER: "Time log reminder successfully turned off!",
        ON_TIMELOGS_REMINDER:
          "Time log reminder successfully turned on. You will be notified every business day at 7:00 PM to post you time logs!",
        NO_HOURS: "No more hours for selected date",
      },
      CREATE_MODAL: {
        TIMELOG_CREATED: "Time log successfully created",
        TITLE: "Log Time",
        CREATE_INVOICE: "Create Invoice",
        OK: "Ok",
        PROJECT: "Project",
        LOG_DATE: "Date",
        HOURS: "Hours",
        NEW_TIMELOG: "New time log",
        NEW_INVOICE: "New Invoice",
        EDIT_TIMELOG: "Edit time log",
        STATUS: "Status",
        LOG_ANOTHER: "Log Another",
      },
    },
    timelogsTableColumns: {
      TAG: "Tag",
      PROJECT: "Project",
      HOURS: "Hours",
      DESCRIPTION: "Description",
      LOGDATE: "Log Date",
      STATUS: "Status",
      REPORTER: "Reporter",
      ACTIONS: "Actions",
      DURATION: "Duration",
    },
    contractsTableColumns: {
      EMPLOYEE: "Employee",
      START_DATE: "Start Date",
      END_DATE: "End date",
      EMPLOYMENT_TYPE: "Employment Type",
      POSITION: "Position",
      SALARY_OR_HOURLY_RATE: "Salary/Hourly Rate",
      WORKKING_HOURS_PER_MONTH: "h/Month",
      SIGNED_BY: "Signed by",
      MORE: "More",
      DURATION: "Duration",
      CREATE_CONTRACT: "Create Contract",
      CONTRACT: "Contract",
    },
    reportsTableColumns: {
      TAG: "Tag Name",
      INSIGHTS: "Insights",
      USER_REPORT_SENTENCE: "You are viewing <strong>{{employee}}</strong>'s logs for <strong>{{project}}</strong>.",
      TIMELOGS_NUMBER: "Number of time logs:",
      TOTAL_HOURS: "Total Hours: {{hours}}",
      NUMBER_OF_TIME_LOGS: "Time Logs: {{timelogs}}",
      MONTH_SUMMARY: {
        TIMELOGS_BY_PROJECTS: "Time logs by Projects",
        TOTALHOURS_BY_PROJECTS: "Total hours by Projects",
        TOTALHOURS_BY_DEVELOPERS: "Total hours by Developers",
      },
      LOG_DATE: "Log date",
      DAILY_HOURS: "Daily hours",
    },
    userProfile: {
      PROFILE: "'s profile",
      FIRST_NAME: "Your first name:",
      LAST_NAME: "Your last name:",
      EMERGENCY_CONTACT: "Emergence contact:",
      CONTACT_RELATIONSHIP: "Contact relationship:",
      BIRTHDAY: "Birthday:",
      GENDER: "Gender:",
      PERSONAL_EMAIL: "Personal email:",
      EMAIL: "Your email:",
      ROLE: "Your roles:",
      CONTRACT: "Contract",
      COMPANY_BRANCH: "Company Branch:",
      ACCOUNT_CREATED_AT: "Your account is created at:",
      CHANGE_FIRSTNAME: "Change your first name:",
      CHANGE_LASTNAME: "Change your last name:",
      CHANGE_EMAIL: "Change your email:",
      CHANGE_PASSWORD: "Change your passowrd:",
      CONFIRM_PASSWORD: "Confirm your new password",
      OLD_PASSWORD: "Enter old password:",
      EDIT_PROFILE: "Edit profile",
      PERSONAL_NOTES: "Personal notes",
      SAVE_PROFILE: "Save profile",
      PROFILE_SETTINGS: "Profile Settings",
      PRIVACY_SETTINGS: "Privacy Settings ",
      ACCOUNT_CENTER: "Account Center",
      PROFILE_PANEL: "Profile",
      PREFERENCES: "Preferences",
      NOTES: "Notes",
      PHONE_NUMBER: "Phone number:",
      ADDRESS: "Address:",
      IBAN: "IBAN:",
      BANK_NAME: "Bank name:",
      NUIS: "NUIS:",
      ROLES: "Roles",
      SAVE: "Save",
      HISTORY: "History",
      PAYMENT_DUE_DATE_OFFSET: "Payment due date offset",
      VALIDATION_MESSAGES: {
        FIRST_NAME_REQURIED: "First name is required!",
        LASTNAME_REQUIRED: "Last name is required!",
        EMAIL_REQUIRED: "Email is required!",
        PASSWORD_REQURIED: "Password is required!",
        CONFIRM_PASSWORD: "Please confirm your new password!",
        ROLES: "Role is required",
        EMERGENCY_CONTACT: "Emergence contact is required",
        CONTACT_RELATIONSHIP: "Contact relationship is required",
        BIRTHDAY: "Birthday is required",
        GENDER: "Gender is required",
        PERSONAL_EMAIL: "Personal email is required",
      },
      PLACEHOLDERS: {
        LASTNAME: "Your last name",
        FIRSTNAME: "Your first name",
        EMAIL: "Your email",
        OLD_PASSWORD: "Old password",
        NEW_PASSWORD: "Your new password",
        CONFIRM_NEW_PASSWORD: "Confirm new password",
      },
      MESSAGES: {
        UPDATED_PROFILE: "Profile info successfully updated",
        UPDATED_PASSWORD: "Password successfully updated",
        UPDATED_NOTES: "Notes successfully updated",
      },
    },
    userModal: {
      MODAL_TITLE: "Add user",
      CHOOSE: "Choose user role:",
      CLIENT: "Client",
      EMPLOYEE: "Employee",
      FIRST_NAME: "First name:",
      LAST_NAME: "Last name:",
      EMAIL: "Email",
      PASSWORD: "Password",
      TEMPORARY_PASSWORD: "Temporary Password",
      EMPLOYMENT: "Employment Type",
      HOURLY_RATE: "Hourly Rate",
      COUNTRY: "Country",
      MANAGER: "Manager",
      CONTRACT_START: "Contract Start",
      CONTRACT_END: "Contract End",
      ALLOWED_YEARLY_HOLIDAY: "Allowed Holidays",
      WORKING_HOURS: "Working Hours",
      STATUS: "Status",
      USER_ROLES: "User Roles",

      EMERGENCY_CONTACT: "Emergence Contact",
      CONTACT_RELATIONSHIP: "Contact Relationship",
      BIRTHDAY: "Birthday",
      GENDER: "Gender",
      PERSONAL_EMAIL: "Personal Email",
      EMPLOYMENT_TYPE: "Emplyment Type",
      HIRE_DATE: "Hire Date",
      CONTRACT_ENDS: "Contract Ends",
      REFERRED: "Referred",
      HOURS_ALLOCATED_FOR_MONTH: "Hours Allocated For Month",
      MONTHLY_PAYMENT: "Monthly Payment",
      CURRENCY_OF_PAYMENT: "Currency Of Payment",
      BIC: "BIC / Swift",
      DOCUMENTS: "Documents",
      PAYMENT_DUE_DATE_OFFSET: 'Payment due date offset',
      IS_CONTRACTOR: 'Is Contractor ?',
      ENTER_VALUE_IN_DAYS: 'Enter value in days',
      messages: {
        CREATED_USER: "User successfully created",
        ROLE: "Please choose a user role!",
        EMPLOYMENT_TYPE: "Please select a Employment Type!",
        HOURLY_RATE: "Please select Employee Hourly Rate!",
        FIRST_NAME: "Please enter your first name!",
        LAST_NAME: "Please enter your last name!",
        EMAIL: "Please write your email!",
        ALLOWED_YEARLY_HOLIDAY: "Please write allowed yearly holidays",
        PASSWORD: "Password is required!",
        WORKING_HOURS: "Working hours are required!",
        COUNTRY: "Country is required",
        ASSING_ROLES: "Assign roles for this user",
        MANAGER: "Manager is required",
        CONTRACT_START: "Please select contract start",
        CONTRACT_END: "Please select contract end",
        UPDATED_USER: "User successfully updated",

        EMERGENCY_CONTACT: "Emergence contact is required",
        CONTACT_RELATIONSHIP: "Contact relationship is required",
        BIRTHDAY: "Birthday is required",
        GENDER: "Gender is required",
        PERSONAL_EMAIL: "Personal email is required",
        FULL_TIME_EMPLOYEE: "Full time employee is required",
        HIRE_DATE: "Hire date is required",
        CONTRACT_ENDS: "Contract ends is required",
        REFERRED: "Referred is required",
        HOURS_ALLOCATED_FOR_MONTH: "Hours allocated for month is required",
        MONTHLY_PAYMENT: "Monthly payment is required",
        CURRENCY_OF_PAYMENT: "Currency of payment is required",
        BIC: "BIC is required",
        DOCUMENTS: "Documents is required",
        PAYMENT_DUE_DATE_OFFSET: 'Payment due date offset is required'
      },
    },
    general: {
      buttons: {
        BACK: "Back",
      },
      PRESENT: "Present",
      LOADING: "Loading",
      ERROR: "Error",
      CALCULATING: "Calculating...",
    },
    clients: {
      TABS: {
        CLIENT_USERS: "Client Users",
        PROJECTS: "Projects",
        ACTIVE_PROJECTS: "Active Projects",
        INACTIVE_PROJECTS: "Inactive Projects",
        ACTIVE_CLIENTS: "Active Clients",
        INACTIVE_CLIENTS: "Inactive Clients",
      },
      DUE_DAYS: "Due days successfully updated",
      CLIENT_UPDATED: "Client updated successfully!",
    },
    appConfiguration: {
      COMPANY_INFO: {
        TITLE: "Company Information",
        MESSAGES: {
          COMPANY_UPDATED: "Company information successfully updated",
        },
        LABELS: {
          NAME: "Name",
          LOGO: "Logo",
          IBAN: "IBAN",
          ACCOUNT_NUMBER: "Account Number",
          CONTACT_NUMBER: "Contact Number",
          TAX_ID: "Tax ID",
        },
      },
      BENCHMARK: {
        TITLE: "Benchmark",
        CREATE_BENCHMARK: "Create benchmark",
        ADD_BENCHMARK: "Add benchmark",
        EDIT_BENCHMARK: "Edit benchmark",
        BILLABLE_HOURS: "Billable hours",
        NON_BILLABLE_HOURS: "Non-billable hours",
        LABELS: {
          MONTH: "Month",
          PERCENTAGE: "Percentage",
          UPDATED_BY: "Updated By",
          PREVIOUS_PERCENTAGE: "Previous Percentage",
          ACTION: "Action",
        },
        MESSAGES: {
          MONTH: "Please select a month",
          PERCENTAGE: "Please enter a number greater than 0",
          BENCHMARK_DELETED: "Benchmark successfully deleted",
          BENCHMARK_CREATED: "Benchmark successfully created",
          BENCHMARK_UPDATED: "Benchmark successfully updated",
          NO_BENCHMARK: "No benchmark for {{month}}",
          NO_DATA: "No data for selected period",
        },
      },
      TAGS: {
        CREATE_TAG: "Create tag",
        EDIT_TAG: "Edit tag",
        MESSAGES: {
          TAG_CREATED: "Tag successfully created",
          TAG_UPDATED: "Tag successfully updated",
          TAG_DELETED: "Tag successfully deleted",
          NAME_REQUIRED: "Name is required",
          PROJECT_REQUIRED: "Project is required",
        },
      },
      MILESTONES: {
        TITLE: "Milestones",
        CREATE_MILESTONE: "Create milestone",
        EDIT_MILESTONE: "Edit milestone",
        LABELS: {
          AMOUNT: "Amount",
          EFFORT: "Effort",
          START_DATE: "Start Date",
          END_DATE: "End Date",
          TAG: "Tag",
        },
        MESSAGES: {
          MILESTONE_CREATED: "Milestone successfully created",
          MILESTONE_UPDATED: "Milestone successfully updated",
          MILESTONE_DELETED: "Milestone successfully deleted",
          EFFORT_REQUIRED: "Effort is required",
          PRICE_REQUIRED: "Price is required",
          START_DATE: "Start date is required",
          END_DATE: "End date is required",
          TAG: "Tag is required",
        },
      },
      LEAVE_TYPES: {
        TITLE: "Leave Types",
        EXCLUDED: "Excluded",
        OPTIONS: {
          EXCLUDE: "Exclude from yearly holidays",
          UPLOAD_REPORT: "Can Upload Report",
        },
        NOT_EXCLUDED: "Not Excluded",
        EDIT_LEAVE_TYPE: "Edit leave type",
        CREATE_LEAVE_TYPE: "Create leave type",
        LABELS: {
          HOLIDAY_LIMIT: "Maximum duration in days",
          NAME: "Name",
          TYPE: "Type",
        },
        PLACE_HOLDERS: {
          HOLIDAY_LIMIT: "Holidays limit",
          NAME: "Name",
        },
        MESSAGES: {
          NAME_REQUIRED: "Name is required",
          HOLIDAY_LIMIT_REQUIRED: "Holiday limit is required",
          LEAVE_TYPE_UPDATED: "Leave type successfully updated",
          LEAVE_TYPE_CREATED: "Leave type successfully created",
        },
      },
      ABSENCE_TYPES: {
        TITLE: "Absence Types",
        EDIT_ABSENCE_TYPE: "Edit leave type",
        CREATE_ABSENCE_TYPE: "Create absence type",
        LABELS: {
          ID: "Id",
          ABSENCE_TYPE_NAME: "Absence type name",
          DESCRIPTION: "Description",
          HOLIDAY_LIMIT: "Absence days",
          CREATED_AT: "Created at",
          COMPANY_BRANCH: "Company branch",
          EXPIRATION_DATE: "Expiration date",
        },
        PLACE_HOLDERS: {
          ABSENCE_TYPE_NAME: "Medical leave",
          DESCRIPTION: "Reason for absence",
          HOLIDAY_LIMIT: "20",
        },
        MESSAGES: {
          NAME_REQUIRED: "Name is required",
          EXPIRATION_DATE_REQUIRED: "Expiration date is required",
          HOLIDAY_LIMIT_REQUIRED: "Absence limit is required",
          ABSENCE_TYPE_UPDATED: "Absence type successfully updated",
          ABSENCE_TYPE_CREATED: "Absence type successfully created",
          ABSENCE_TYPE_DELETED: "Absence type successfully deleted",
        },
      },
      HOLIDAY_TYPES: {
        TITLE: "Holiday Types",
        EDIT_HOLIDAY_TYPE: "Edit holiday type",
        CREATE_HOLIDAY_TYPE: "Create holiday type",
        LABELS: {
          ID: "Id",
          COMPANY_BRANCH: "Company Branch",
          DESCRIPTION: "Description",
          HOLIDAYS: "Holidays",
          CREATED_AT: "Created at",
        },
        PLACE_HOLDERS: {
          DESCRIPTION: "Softup Albania Holidays",
          COMPANY_BRANCH: "Select a company branch",
          HOLIDAYS: "Holidays",
        },
        MESSAGES: {
          DATE_REQUIRED: "Select at least one date",
          DESCRIPTION_REQUIRED: "Description is required",
          COMPANY_BRANCH_REQUIRED: "Company branch is required",
          HOLIDAY_REQUIRED: "Holiday is required",
          HOLIDAY_DATES_REQUIRED: "Holiday dates are required",
          HOLIDAY_TYPE_UPDATED: "Holiday type successfully updated",
          HOLIDAY_TYPE_CREATED: "Holiday type successfully created",
          HOLIDAY_TYPE_DELETED: "Holiday type successfully deleted",
        },
      },
      COMPANY_BRANCHES: {
        TITLE: "Company Branches",
        EDIT_COMPANY_BRANCH: "Edit company branch",
        CREATE_COMPANY_BRANCH: "Create company branch",
        DETAIL_COMPANY_BRANCHES: "Company branch details",
        LABELS: {
          ID: "Id",
          COUNTRY: "Country",
          CITY: "City",
          IBAN: "Iban",
          TAX_ID: "Tax ID",
          ACCOUNT_NUMBER: "Account number",
          CONTACT_NUMBER: "Contact number",
          DURATION: "Duration",
          START_OF_THE_DAY: "Start of work days",
          END_OF_THE_DAY: "End of work days",
          DAYS_OF_WORK: "Days of work",
          HOLIDAY_TYPES: "Holiday type",
          ABSENCE_TYPES: "Absence types",
          OFFICE_TYPE: "Office type",
          VIRTUAL_OFFICE: "Virtual office",
          PHYSICAL_OFFICE: "Physical office",
          CREATED_AT: "Created at",
          UPDATED_AT: "Updated at",
        },
        PLACE_HOLDERS: {
          COUNTRY: "Select a country",
          CITY: "Select a city",
          IBAN: "K312*********",
          TAX_ID: "34B3*********",
          DURATION: "8",
          ACCOUNT_NUMBER: "123456789",
          CONTACT_NUMBER: "+355692121223",
          HOLIDAY_TYPE: "Select a holiday type",
        },
        MESSAGES: {
          COUNTRY_REQUIRED: "Country is required",
          CITY_REQUIRED: "City is required",
          IBAN_REQUIRED: "IBAN is required",
          TAX_ID_REQUIRED: "Tax ID is required",
          ACCOUNT_NUMBER_REQUIRED: "Account number is required",
          CONTACT_NUMBER_REQUIRED: "Contact number is required",
          START_OF_THE_DAY_REQUIRED: "Start of work days is required",
          END_OF_THE_DAY_REQUIRED: "End of work days is required",
          DAYS_OF_WORK_REQUIRED: "Select at least one day of work",
          HOLIDAY_TYPE_REQUIRED: "Holiday type is required",
          COMPANY_BRANCH_UPDATED: "Company branch successfully updated",
          COMPANY_BRANCH_CREATED: "Company branch successfully created",
          COMPANY_BRANCH_DELETED: "Company branch successfully deleted",
        },
      },
      ORGANIZATION: {
        TITLE: "Organization",
        EMPLOYEE_LIST: "Employee list",
        EMPLOYEE_DETAILS: "Employee details",
        ORG_CHART: "Org chart",
        DETAILS: "Details",
        DETAIL_ORGANIZATION: "Organization details",
        MANAGER: "Manager",
        LABELS: {
          FIRST_NAME: "First name",
          LAST_NAME: "Last name",
          EMAIL: "Email",
          ROLE: "Role",
          MANAGER: "Manager",
          PHONE_NUMBER: "Phone number",
          ADDRESS: "Address",
          USER_SINCE: "User since",
          COMPANY_BRANCH: "Company branch",
        },
        PLACE_HOLDERS: {
          COUNTRY: "Select a country",
          CITY: "Select a city",
          IBAN: "K312*********",
          TAX_ID: "34B3*********",
          DURATION: "8",
          ACCOUNT_NUMBER: "123456789",
          CONTACT_NUMBER: "+355692121223",
          HOLIDAY_TYPE: "Select a holiday type",
        },
        MESSAGES: {
          COUNTRY_REQUIRED: "Country is required",
          CITY_REQUIRED: "City is required",
          IBAN_REQUIRED: "IBAN is required",
          TAX_ID_REQUIRED: "Tax ID is required",
          ACCOUNT_NUMBER_REQUIRED: "Account number is required",
          CONTACT_NUMBER_REQUIRED: "Contact number is required",
          START_OF_THE_DAY_REQUIRED: "Start of work days is required",
          END_OF_THE_DAY_REQUIRED: "End of work days is required",
          DAYS_OF_WORK_REQUIRED: "Select at least one day of work",
          HOLIDAY_TYPE_REQUIRED: "Holiday type is required",
          COMPANY_BRANCH_UPDATED: "Company branch successfully updated",
          COMPANY_BRANCH_CREATED: "Company branch successfully created",
          COMPANY_BRANCH_DELETED: "Company branch successfully deleted",
        },
      },
      CALENDARS: {
        TITLE: "Absence calendar",
        CREATE_ABSENCE_REQUEST: "Request absence",
        TITLE_PANEL: "Personal absence panel",
        EMPLOYEE_DETAILS: "Employee details",
        ABSENCE_REQUEST_DETAILS: "Absence request details",
        ORG_CHART: "Org chart",
        DETAILS: "Details",
        DETAIL_ORGANIZATION: "Organization details",
        MANAGER: "Manager",
        LABELS: {
          BREAKEDOWN: "Breakdown for",
          TAKEN: "Taken days",
          TAKEN_INFO: "Approved requested days ",
          PENDING_INFO: "Pending requested days ",
          AVAILABLE_DAYS_OFF: "Available days off",
          PENDING_DAYS: "Pending days",
          TOTAL_DAYS: "Total days:",
          WHAT_TYPE_OF_ABSENCE: "What type of absence?",
          FROM_TO: "From – To",
          COMMENT: "Comment",
          FIRST_DAY_DURATION: "First day duration",
          LAST_DAY_DURATION: "Last day duration",
          ONE_DAY_DURATION: "One day duration",
          DURATION: "Duration",
          SUBSTITUTE: "Substitute",
          SUBSTITUTE_STATUS: "Substitute approve status",
          MANAGER: "Manager",
          MANAGER_STATUS: "Manager approve status",
          RETURN_DATE: "Return date",
          REQUEST_DATE: "Request date",
          DELETED_AT: "Deleted at",
          ABSENCE_DURATION: "Absence duration",
        },
        PLACE_HOLDERS: {},
        MESSAGES: {
          COUNTRY_REQUIRED: "Country is required",
          CITY_REQUIRED: "City is required",
          IBAN_REQUIRED: "IBAN is required",
          TAX_ID_REQUIRED: "Tax ID is required",
          ACCOUNT_NUMBER_REQUIRED: "Account number is required",
          CONTACT_NUMBER_REQUIRED: "Contact number is required",
          START_OF_THE_DAY_REQUIRED: "Start of work days is required",
          END_OF_THE_DAY_REQUIRED: "End of work days is required",
          DAYS_OF_WORK_REQUIRED: "Select at least one day of work",
          HOLIDAY_TYPE_REQUIRED: "Holiday type is required",
          COMPANY_BRANCH_UPDATED: "Company branch successfully updated",
          CALENDARS_CREATED: "Absence request successfully sent",
          ABSENCE_REQUEST_APPROVE: "Absence request successfully approved",
          ABSENCE_REQUEST_NOT_APPROVE: "Absence request successfully rejected",
          ABSENCE_LOG_DELETED: "Absence requestsuccessfully deleted",
        },
      },
      ABSENCE_LOGS: {
        TITLE: "Absence logs",
        EDIT_HOLIDAY_TYPE: "Edit holiday type",
        CREATE_HOLIDAY_TYPE: "Create holiday type",
        LABELS: {
          ID: "Id",
          USER_NAME: "Employee",
          ABSENCE_NAME: "Company branch",
          FIRST_DAY_ABSENCE: "First day",
          LAST_DAY_ABSENCE: "Last day",
          DURATION: "Duration",
          RETURN_DAY: "Return day",
          MANAGER_STATUS: "Manager",
          REPLACER_STATUS: "Substitute",
          APPROVE_STATUS: "Approve status",
          COMMENT: "Comment",
          CREATED_AT: "Created at",
          ABSENCE_TYPE: "Absence type",
        },
        PLACE_HOLDERS: {
          DESCRIPTION: "Softup Albania Holidays",
          COMPANY_BRANCH: "Select a company branch",
          HOLIDAYS: "Holidays",
        },
        MESSAGES: {
          DATE_REQUIRED: "Select at least one date",
          DESCRIPTION_REQUIRED: "Description is required",
          COMPANY_BRANCH_REQUIRED: "Company branch is required",
          HOLIDAY_REQUIRED: "Holiday is required",
          HOLIDAY_DATES_REQUIRED: "Holiday dates are required",
          HOLIDAY_TYPE_UPDATED: "Holiday type successfully updated",
          HOLIDAY_TYPE_CREATED: "Holiday type successfully created",
          HOLIDAY_TYPE_DELETED: "Holiday type successfully deleted",
        },
      },
      PUBLIC_PROFILE: {
        TITLE: "Public profile",
        LABELS: {
          FIRST_NAME: "First name:",
          LAST_NAME: "Last name:",
          EMAIL: "Email:",
          ROLE: "Role:",
          MANAGER: "Manager:",
          PHONE_NUMBER: "Phone number:",
          ADDRESS: "Address:",
          USER_SINCE: "User since:",
          COMPANY_BRANCH: "Company branch:",
          BIRTHDAY: "Birthday:",
          GENDER: "Gender:",
          WORK_EMAIL: "Work email:",
        },
      },
      PERFORMANCE_REVIEW: {
        TITLE: "Performance Review (Admin)",
        REVIEW_CYCLES: "Review Cycles",
        PERFORMANCE_REVIEW_ADMIN: "Performance Review (Admin)",
        PERFORMANCE_REVIEW_MANAGER: "Performance Review (Manager)",
        PERFORMANCE_REVIEW_PERSONAL: "Performance Review (Personal)",
        REVIEW_REQUESTS: "Review Requests",
        SELF_REVIEW_REQUESTS: "Self review",
        PEER_REVIEW_REQUESTS: "Peer review",
        MANAGER_REVIEW_REQUESTS: "Manager review",
        NOT_SUBMITTED: "Not submitted",
        PRIVATE_REVIEW: "Private review",
        PUBLIC_REVIEW: "Public review",
        PRIVATE_REVIEW_FOR_MANAGER: "Private review for manager",
        CLOSED_BY_MANAGER: "Review cycle is closed by manager",
        NOT_CLOSED_YET: "Review cycle is still opened by manager",
        LEAVE_YOUR_REVIEW_FOR: "Leave your review for",
        LEAVE_YOUR_ANSWER_FOR_QUESTION: "Leave your answer for question",
        LABELS: {
          REVIEWEE: "Reviewee",
          REVIEW_STATS: "Review stats",
          EMPLOYEE_REVIEW: "The employee to be reviewed",
          PEER_REVIWERS: "Peer reviwers",
          MANAGER_REVIEW: "Manager review",
          REVIEW_RULES: "Review rules",
          READ_REVIEW_RULES: "Please, read the following rules before writing your review!",
          MANAGER_COMMENT: "Manager comment",
          MANAGER_COMMENT_PRIVATE: "Manager comment view status",
          LEAVE_YOUR_REVIEW: "Leave your review",
          CLOSE_THIS_REVIEW_CYCLE: "Close this review cycle",
          REVIEW_CYCLE: "Review cycle",
          CREATE_REVIEW_CYCLE: "Create review cycle",
          FIRST_NAME: "First name",
          LAST_NAME: "Last name",
          MANAGER: "Manager",
          EXPIRE_DATE: "Expiration date",
          REVIEW_STATUS: "Review status",
          PARTICIPATION: "Participation",
          EXPIRE_STATUS: "Expiration status",
          CLOSED_AT: "Closed at",
          CLOSED_STATUS: "Closed status",
          CREATED_AT: "Created at",
          VIEW_ALL_REVIEW_ANSWERS: "View all review answers",
          ANSWER_PRIVATE_INFO: "If you switch to 'Private', Reviewee can't read your answers!",
        },
        RULES: {
          RULES_TITLE: "Please read the following rules before writing your review:",
          RULES_ONE:
            "Take the time to write a thoughtful and detailed review. Your colleague is counting on you to provide feedback that is specific and helpful.",
          RULES_TWO:
            "Be honest, but also constructive. If there are areas where your colleague could improve, be sure to address them, but also provide suggestions for how they can improve.",
          RULES_THREE:
            "Use specific examples to illustrate your points. This will help your colleague understand exactly what you are referring to and make it easier for them to improve.",
          RULES_FOUR:
            "Focus on behavior and performance, not personality. Stick to the facts and avoid making personal attacks or judgments.",
          RULES_FIVE:
            "Give credit where credit is due. If your colleague has done an outstanding job in a particular area, be sure to highlight their accomplishments.",
          RULES_SIX: "Avoid generalizations.",
          RULES_SEVEN:
            "Be professional and respectful in your tone. Remember that this is a professional review, and your colleague deserves to be treated with respect.",
          RULES_EIGHT:
            "Provide feedback that is actionable. Your colleague should be able to take the feedback you provide and use it to improve their performance.",
          RULES_NINE: "Use the form provided and answer all the questions thoughtfully.",
          RULES_TEN: "Proofread your review before submitting it.",
        },
        PLACE_HOLDERS: {
          MANAGER_COMMENT:
            "Manager's comment regarding this performance review will be displayed here once the review cycle has been closed!",
          MANAGER_COMMENT_ON_CLOSE: "Leave a detailed comment about this performance review!",
          SELECT_EMPLOYEE: "Select an employee",
          PEER_REVIEWERS: "Select peer reviewers",
          MANAGER_REVIEW: "Select manager review",
          EXPIRE_DATE: "Expiration date",
        },
        MESSAGES: {
          DATE_REQUIRED: "Select at least one date",
          SUBMIT_CONFIRM: "Are you sure you want to submit your answers?",
          SUBMIT_PERMANENT: "This action is permanent!",
          LEAVE_YOUR_COMMENT: "Leave your comment before closing this cycle",
          REVIEWEE_REQUIRED: "Reviwee is required",
          EXPIRE_DATE_REQUIRED: "Expiration date is required",
          PEER_REVIEWERS_REQUIRED: "Peer reviwers is required",
          MANAGER_REVIEW_REQUIRED: "Manager reviwe is required",
          REVIEW_SUMBIT: "You answers successfully saved",
          REVIEW_DRAFT: "You answars successfully drafted",
          PERFORMANCE_REVIEW_SUMBIT: "Review successfully submitted",
          PERFORMANCE_REVIEW_CLOSED: "Review successfully closed",
          PERFORMANCE_REVIEW_SAVE_DRAFT: "Review successfully drafted",
          PERFORMANCE_REVIEW_CYCLE_CREATED: "Review cycle successfully created",
          PERFORMANCE_REVIEW_CYCLE_DELETED: "Review cycle successfully deleted",
          PERFORMANCE_REVIEW_MANAGER_COMMENT: "Review comment successfully saved",
        },
      },
      DOCUMENTS: {
        TITLE: "Documents",
        UPLOAD: "Upload document",
        EDIT: "Edit",
        ALLOWED_FILE_TYPE: "Allowed file type: pdf, docx, xlsx, txt, jpg, jpeg, png",
        LABELS: {
          ID: "Id",
          CATEGORY: "Category",
          FILE_NAME: "File name",
          FILE_TYPE: " File type",
          FILE_SIZE: " File size",
          CREATED_AT: "Created at",
          UPDATED_AT: "Modified at",
          ACTION: "Action",
          IMAGE: "Image",
          FILE: "Upload file",
        },
        PLACE_HOLDERS: {
          FILE_NAME: "Write your new filename",
        },
        MESSAGES: {
          CATEGORY_REQUIRED: "Category is required",
          FILE_REQUIRED: "File is required",
          DOCUMENT_MODIFIED: "Document successfully modified",
          DOCUMENT_CREATED: "Document successfully uploaded",
          DOCUMENT_DELETED: "Document successfully deleted",
        },
      },
    },

    invoices: {
      MONTH: "Month",
      TIMESHEET: "Timesheet",
      OTHER_ATTACHMENT: "Other Attachment",
      MESSAGES: {
        INVOICE_ITEMS_REQUIRED: "Please add invoice items",
        MONTH_REQUIRED: "Month is required",
        TIMESHEET_REQUIRED: "Timesheet is required",
      },
      INVOICE_ITEMS_TABLE: {
        INVOICE_ITEMS: "Invoice Items",
        NEW_ITEM: "New Item",
      },
      CREATE_INVOICE: {
        PROJECT: "Project",
        DESCRIPTION: "Description",
        HOURS: "Hours",
        HOURLY_RATE: "Hourly Rate",
        ADD_ITEM: "Add Item",
        EDIT_ITEM: "Edit Item",
        MESSAGES: {
          PROJECT_REQUIRED: "Project is required",
          HOURS_REQUIRED: "Hours are required",
          HOURLY_RATE_REQUIRED: "Hourly rate is required",
          MISSING_PAYMENT_DUE_DATE_OFFSET: "Payment due date offset was not provided"
        },
      },
    },
  },
};
