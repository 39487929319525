import moment from "moment";

export function getMonthDateRange(year, month) {
  const startDate = moment([year, month - 1]);
  const endDate = moment(startDate).endOf("month");

  return { start: startDate, end: endDate };
}

export const getQueryParams = (year) => ({
  startDate: new Date(`${year}-01-01`),
  endDate: new Date(`${year}-12-31`),
});
