import * as yup from "yup";
import moment from "moment";
import { TFunction } from "i18next";

export default (t: TFunction) =>
  yup.object({
    employeeId: yup.number().integer().required(t("contracts.form.required.EMPLOYEE")),
    startDate: yup
      .date()
      .when("endDate", (endDate, schema) => {
        return schema.test({
          test: (startDate) => (endDate ? moment(startDate).isBefore(endDate) : !!startDate),
        });
      })
      .required(t("contracts.form.required.START_DATE")),
    endDate: yup.date().nullable(),
    salary: yup.number().when("employmentType", {
      is: "Employee",
      then: yup.number().required(t("contracts.form.required.SALARY")),
      otherwise: yup.number().nullable(),
    }),
    employmentType: yup
      .string()
      .oneOf(["Contractor", "Employee"])
      .required(t("contracts.form.required.EMPLOYMENT_TYPE")),
    workingHoursPerMonth: yup.number().when("employmentType", {
      is: "Contractor",
      then: yup.number().required(t("contracts.form.required.WORKING_HOURS_PER_MONTH")),
      otherwise: yup.number().nullable(),
    }),
    hourlyRate: yup.number().when("employmentType", {
      is: "Contractor",
      then: yup.number().required(t("contracts.form.required.HOURLY_RATE")),
      otherwise: yup.number().nullable(),
    }),
    signedBy: yup.number().integer().nullable(),
    position: yup.string().required(t("contracts.form.required.POSITION")),
  });
