import * as React from 'react';
import {
  notification,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Radio,
  Row,
  Select,
  Switch,
} from 'antd';
import {
  Mutation,
  Query,
} from '@apollo/react-components';
import {
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import { AccessControl } from 'common/AccessControl';
import Permissions from 'constants/viewPermissions';
import SelectMultipleRoles from 'screens/Users/components/SelectMultipleRoles';
import SelectManager from 'screens/Users/components/SelectManager';
import SelectCountry from 'common/Form/SelectCountry';
import { GET_USER_PROFILE } from 'graphql/queries';
import { GET_COMPANY_BRANCH } from 'graphql/queries/Configurations';
import moment, { Moment } from 'moment';

import { getUserData } from '../../common/AccessControl/Permissions';
import {
  UPDATE_USER,
  UPDATE_USER_FIELDS,
} from '../../graphql/mutations';
import {
  mergeRolesPermissions,
  renderEmployeeFields,
} from '../../helpers/permissions';
import { IProps } from './Interfaces';

interface IState {
  rolesSelected: any;
  companyBranchValues: any;
  selectedGenderValue: any;
  selectedBirthdayValue: Moment | null;
}

const { Option } = Select;

class RenderForm extends React.Component<IProps & WithTranslation, IState> {
  constructor(props) {
    super(props);
    this.state = {
      rolesSelected: this.props.data.roles,
      companyBranchValues: this.props.data.employee?.CompanyBranchId,
      selectedGenderValue: this.props.data.gender,
      selectedBirthdayValue: this.props.data.selectedBirthdayValue
        ? moment(this.props.data.selectedBirthdayValue)
        : null,
    };
  }
  public render() {
    const { t, form, variables } = this.props;
    const isEmployee = renderEmployeeFields(
      mergeRolesPermissions(this.state.rolesSelected.map(({ permissions }) => permissions))
    );

    const isLoggedInUser = !variables.userId || variables.userId === getUserData().id;
    const UpdateUserMutation = this.props.permissions?.users?.some((permission) =>
      Permissions.BUTTONS.UPDATE_USER.includes(permission)
    )
      ? UPDATE_USER_FIELDS
      : UPDATE_USER;

    const hasRoleContractor = this.state.rolesSelected.find(({ name }) => name === 'Contractor');

    return (
      <div style={isLoggedInUser ? { width: "48%" } : { width: "100%" }} className="profile-info form-wrapper">
        <Col offset={0}>
          <label style={{ fontSize: 20 }} className="label">
            {t("userProfile.PROFILE_SETTINGS")} <Icon type="user" style={{ color: "#1890ff", marginLeft: "5px" }} />
          </label>
          <Divider />
        </Col>

        <Form className="form">
          <Layout.Content>
            <Row gutter={16}>
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.CHANGE_FIRSTNAME")}>
                    {form.getFieldDecorator("firstName", {
                      rules: [
                        {
                          required: true,
                          message: t("userProfile.VALIDATION_MESSAGES.FIRST_NAME_REQURIED"),
                        },
                      ],
                      initialValue: this.props.data.firstName ? this.props.data.firstName : undefined,
                    })(<Input placeholder={t("userProfile.FIRSTNAME")} />)}
                  </Form.Item>
                </Col>
              ) : null}
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.CHANGE_LASTNAME")}>
                    {form.getFieldDecorator("lastName", {
                      rules: [
                        {
                          required: true,
                          message: t("userProfile.VALIDATION_MESSAGES.LASTNAME_REQUIRED"),
                        },
                      ],
                      initialValue: this.props.data.lastName ? this.props.data.lastName : undefined,
                    })(<Input placeholder={t("userProfile.LASTNAME")} />)}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.CHANGE_EMAIL")}>
                    {form.getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: t("userProfile.VALIDATION_MESSAGES.EMAIL_REQUIRED"),
                          type: "email",
                        },
                      ],
                      initialValue: this.props.data.email ? this.props.data.email : undefined,
                    })(<Input placeholder={t("userProfile.EMAIL")} />)}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Divider />
            <Row gutter={16}>
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.EMERGENCY_CONTACT")}>
                    {form.getFieldDecorator("emergenceContact", {
                      rules: [
                        {
                          required: false,
                          message: t("userProfile.VALIDATION_MESSAGES.EMERGENCY_CONTACT"),
                        },
                      ],
                      initialValue: this.props.data.emergenceContact ? this.props.data.emergenceContact : undefined,
                    })(<Input placeholder={"+35567********"} />)}
                  </Form.Item>
                </Col>
              ) : null}
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.CONTACT_RELATIONSHIP")}>
                    {form.getFieldDecorator("contactRelationship", {
                      rules: [
                        {
                          required: false,
                          message: t("userProfile.VALIDATION_MESSAGES.CONTACT_RELATIONSHIP"),
                        },
                      ],
                      initialValue: this.props.data.contactRelationship
                        ? this.props.data.contactRelationship
                        : undefined,
                    })(<Input placeholder={"John"} />)}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.PERSONAL_EMAIL")}>
                    {form.getFieldDecorator("personalEmail", {
                      rules: [
                        {
                          required: false,
                          message: t("userProfile.VALIDATION_MESSAGES.PERSONAL_EMAIL"),
                        },
                      ],
                      initialValue: this.props.data.personalEmail ? this.props.data.personalEmail : undefined,
                    })(<Input placeholder={"personal@email.com"} />)}
                  </Form.Item>
                </Col>
              ) : null}
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.GENDER")}>
                    {form.getFieldDecorator("gender", {
                      rules: [
                        {
                          required: false,
                          message: t("userProfile.VALIDATION_MESSAGES.GENDER"),
                        },
                      ],
                      initialValue: this.props.data.gender ? this.props.data.gender : undefined,
                    })(
                      <Radio.Group
                        defaultValue={this.state.selectedGenderValue}
                        onChange={(e) =>
                          this.setState({
                            selectedGenderValue: e.target.value,
                          })
                        }>
                        <Radio.Button value="Male">Male</Radio.Button>
                        <Radio.Button value="Female">Female</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              {isEmployee ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.IBAN")}>
                    {form.getFieldDecorator("iban", {
                      rules: [
                        {
                          type: "string",
                        },
                      ],
                      initialValue: this.props.data.employee?.iban,
                    })(<Input placeholder={t("userProfile.IBAN")} />)}
                  </Form.Item>
                </Col>
              ) : null}
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.BIRTHDAY")}>
                    {form.getFieldDecorator("birthday", {
                      initialValue: this.props.data.birthday ? moment(this.props.data.birthday) : null,
                    })(
                      <DatePicker
                        defaultValue={this.state.selectedBirthdayValue}
                        onChange={(date) =>
                          this.setState({
                            selectedBirthdayValue: date,
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row gutter={16}>
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.BANK_NAME")}>
                    {form.getFieldDecorator("bankName", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      initialValue: this.props.data.employee?.bankName,
                    })(<Input placeholder={t("userProfile.BANK_NAME")} />)}
                  </Form.Item>
                </Col>
              ) : null}
              {isLoggedInUser ? (
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.NUIS")}>
                    {form.getFieldDecorator("NUIS", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      initialValue: this.props.data.employee?.NUIS,
                    })(<Input placeholder={t("userProfile.NUIS")} />)}
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            {isEmployee ? (
              <Row gutter={16}>
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.PHONE_NUMBER")}>
                    {form.getFieldDecorator("phoneNumber", {
                      rules: [
                        {
                          message: t("userProfile.VALIDATION_MESSAGES.PHONE_NUMBER"),
                          type: "string",
                        },
                      ],
                      initialValue: this.props.data.employee?.phoneNumber,
                    })(<Input placeholder={t("userProfile.PHONE_NUMBER")} />)}
                  </Form.Item>
                </Col>
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.ADDRESS")}>
                    {form.getFieldDecorator("address", {
                      rules: [
                        {
                          message: t("userProfile.VALIDATION_MESSAGES.ADDRESS"),
                          type: "string",
                        },
                      ],
                      initialValue: this.props.data.employee?.address,
                    })(<Input placeholder={t("userProfile.ADDRESS")} />)}
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Divider />
            <Row gutter={16}>
              {isEmployee ? (
                <AccessControl
                  userPermissions={this.props.permissions.users || []}
                  allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
                  <Col span={12} className="input-col">
                    <Form.Item label={t("userModal.MANAGER")}>
                      {form.getFieldDecorator("manager", {
                        rules: [
                          {
                            required: true,
                            message: t("userModal.messages.MANAGER"),
                          },
                        ],
                        initialValue: this.props.data.employee?.managerId,
                      })(<SelectManager t={t} />)}
                    </Form.Item>
                  </Col>
                </AccessControl>
              ) : null}
              {isEmployee ? (
                <AccessControl
                  userPermissions={this.props.permissions.users || []}
                  allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
                  <Col span={12}>
                    <Form.Item label={t("appConfiguration.HOLIDAY_TYPES.LABELS.COMPANY_BRANCH")}>
                      {form.getFieldDecorator("companyBranch", {
                        rules: [
                          {
                            required: false,
                            message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.COMPANY_BRANCH_REQUIRED"),
                          },
                        ],
                        initialValue: this.props.data.employee?.CompanyBranchId,
                      })(
                        <Query query={GET_COMPANY_BRANCH}>
                          {({ loading: loadingBranch, data: branchData, error }: any) => {
                            if (error) {
                              return <p>Error</p>;
                            }
                            return (
                              <Select
                                showSearch
                                loading={loadingBranch}
                                disabled={loadingBranch}
                                defaultValue={this.state.companyBranchValues}
                                onChange={(value) => this.setState({ companyBranchValues: value })}
                                placeholder={
                                  loadingBranch
                                    ? "Loading branches..."
                                    : t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.COMPANY_BRANCH")
                                }>
                                {branchData?.getCompanyBranches.map((branchItem) => (
                                  <Option key={branchItem.id} value={branchItem.id}>
                                    {branchItem.country + " / " + branchItem.city}
                                  </Option>
                                ))}
                              </Select>
                            );
                          }}
                        </Query>
                      )}
                    </Form.Item>
                  </Col>
                </AccessControl>
              ) : null}
              <AccessControl
                userPermissions={this.props.permissions.users || []}
                allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
                <Col span={12} className="input-col">
                  <Form.Item label={t("userModal.COUNTRY")}>
                    {form.getFieldDecorator("country", {
                      rules: [
                        {
                          required: true,
                          message: t("userModal.messages.MANAGER"),
                        },
                      ],
                      initialValue: this.props.data.countryId,
                    })(<SelectCountry t={t} />)}
                  </Form.Item>
                </Col>
              </AccessControl>
              {
                isEmployee && hasRoleContractor && (
                  <AccessControl
                    userPermissions={this.props.permissions.users || []}
                    allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
                    <Col span={12} className="input-col">
                      <Form.Item label={t("userModal.PAYMENT_DUE_DATE_OFFSET")}>
                        {this.props.form.getFieldDecorator("paymentDueDateOffset", {
                          rules: [
                            {
                              required: true,
                              message: t("userModal.messages.PAYMENT_DUE_DATE_OFFSET"),
                              type: "number",
                              min: 0,
                            },
                          ],
                          initialValue: this.props.data.paymentDueDateOffset
                        })(<InputNumber style={{ width: '100%' }} placeholder={t('userModal.ENTER_VALUE_IN_DAYS')} />)}
                      </Form.Item>
                    </Col>
                  </AccessControl>
                )
              }
            </Row>
            <AccessControl
              userPermissions={this.props.permissions.users || []}
              allowedPermissions={Permissions.BUTTONS.UPDATE_USER}>
              <Row gutter={16}>
                <Col span={12} className="input-col">
                  <Form.Item label={t("userProfile.ROLES")}>
                    {form.getFieldDecorator("roles", {
                      rules: [
                        {
                          required: true,
                          message: t("userProfile.VALIDATION_MESSAGES.ROLES"),
                          type: "array",
                        },
                      ],
                      initialValue: this.state.rolesSelected.map(({ id }) => id),
                    })(
                      <SelectMultipleRoles
                        onChange={this.onChangeHandler}
                        roles={this.state.rolesSelected.map(({ id, name }) => ({
                          id,
                          name,
                        }))}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12} className="input-col">
                  <Form.Item label={t("userModal.STATUS")} className="fr">
                    {form.getFieldDecorator("status", {
                      valuePropName: "checked",
                      rules: [{ type: "boolean" }],
                      initialValue: this.props.data.status === "active",
                    })(<Switch checkedChildren="Active" unCheckedChildren="No active" />)}
                  </Form.Item>
                </Col>
              </Row>
            </AccessControl>

            <Row>
              <Col span={15} offset={0}>
                <Form.Item>
                  <Mutation
                    mutation={UpdateUserMutation}
                    refetchQueries={[
                      {
                        query: GET_USER_PROFILE,
                        variables: { userId: variables?.userId },
                      },
                    ]}
                    onCompleted={() => {
                      notification.success({
                        message: t("userProfile.MESSAGES.UPDATED_PROFILE"),
                      });
                    }}
                    onError={(error) => {
                      notification.error({
                        message: error.message.split(":")[1],
                      });
                    }}>
                    {(updateUser, { loading }) => {
                      return (
                        <Button
                          type="primary"
                          icon="save"
                          loading={loading}
                          onClick={() => {
                            form.validateFields((err, variables) => {
                              const {
                                firstName,
                                lastName,
                                NUIS,
                                address,
                                phoneNumber,
                                bankName,
                                emergenceContact,
                                contactRelationship,
                                personalEmail,
                                iban,
                                status,
                                paymentDueDateOffset,
                                country: countryId,
                                manager: managerId,
                              } = variables;

                              if (!err) {
                                updateUser({
                                  variables: {
                                    CompanyBranchId: this.state.companyBranchValues,
                                    birthday: this.state.selectedBirthdayValue,
                                    gender: this.state.selectedGenderValue,
                                    firstName,
                                    lastName,
                                    status,
                                    NUIS,
                                    address,
                                    phoneNumber,
                                    emergenceContact,
                                    contactRelationship,
                                    personalEmail,
                                    bankName,
                                    iban,
                                    paymentDueDateOffset: paymentDueDateOffset || null,
                                    countryId,
                                    managerId,
                                    roles: this.state.rolesSelected.map(({ id }) => id),
                                    userId: this.props.data.id,
                                  },
                                });
                              }
                            });
                          }}>
                          {t("userProfile.SAVE")}
                        </Button>
                      );
                    }}
                  </Mutation>
                </Form.Item>
              </Col>
            </Row>
          </Layout.Content>
        </Form>
      </div>
    );
  }
  private onChangeHandler = (value) => {
    this.setState({
      rolesSelected: value,
    });
  };
}

const ProfileSettings = Form.create<IProps>()(withTranslation()(RenderForm));

export default ProfileSettings;
