import React from "react";
import { GET_INVOICE_ANALYTICS } from "../../../graphql/queries";
import { Query } from "@apollo/react-components";
import { withLastLocation } from "react-router-last-location";
import { Skeleton, Statistic, Row, Col, Card } from "antd";
import { withTranslation } from "react-i18next";

const InvoiceAnalytics = (props: any) => {
  const { t } = props;
  return (
    <Query query={GET_INVOICE_ANALYTICS}>
      {({ loading, error, data }: any) => {
        if (error) {
          return <div>Error</div>;
        }
        if (loading) {
          return (
            <div className="skeleton">
              <Skeleton
                loading={loading}
                active={true}
                title={{ width: "100%" }}
                paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }}
              />
            </div>
          );
        }

        let earnings;
        if (!loading && data && data.getEarnings) {
          earnings = data.getEarnings;
        }

        return (
          <div style={{ marginTop: "20px" }}>
            <Row gutter={16}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={t("invoiceDetails.STATISTICS.LIFE_EARNINGS")}
                    value={earnings.lifeEarnings + " €"}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic title={t("invoiceDetails.STATISTICS.YTD_EARNINGS")} value={earnings.ytdEarnings + " €"} />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={t("invoiceDetails.STATISTICS.LAST_MONTH_EARNINGS")}
                    value={earnings.lastMonthEarnings + " €"}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        );
      }}
    </Query>
  );
};

export default withTranslation()(withLastLocation(InvoiceAnalytics));
