import React from "react";
import { Table, Button, Alert, Tooltip, Badge } from "antd";
import { generateKey } from "../../../../utils/listKey";
import { formatDateForDeputy } from "../../../../utils/date";
import StatusTag from "../../../../common/LeaveRequestTag";
import LeaveTypeEmoji from "../../../../common/LeaveTypeEmojis";
import { Link } from "react-router-dom";
import * as constants from "../../../../constants/leaveRequestStatus";

const LeaveRequestsTable = ({ t, leaveRequests, loading }) => {
  return (
    <div style={{ margin: "20px 0" }} className="tableWrapper">
      <Table dataSource={leaveRequests} rowKey={() => generateKey()} pagination={false} size="small" loading={loading}>
        <Table.Column
          title=""
          key="leaveTypeName"
          align="center"
          render={({ leaveTypeName }) => {
            return <LeaveTypeEmoji leaveTypeName={leaveTypeName} />;
          }}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.LEAVE_TYPE")}
          key="leaveTypeName"
          align="center"
          render={({ leaveTypeName }) => leaveTypeName}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.START_DATE")}
          key="startDate"
          align="center"
          render={({ startDate }) => formatDateForDeputy(startDate)}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.END_DATE")}
          key="endDate"
          align="center"
          render={({ endDate }) => formatDateForDeputy(endDate)}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.DURATION")}
          key="duration"
          align="center"
          render={({ duration, daysUsedFromLastYear }) => (
            <div>
              {duration} {duration > 1 ? "days" : "day"}{" "}
              <Tooltip placement="rightTop" title="Days used from last year">
                <Badge count={daysUsedFromLastYear} />
              </Tooltip>
            </div>
          )}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.DEPUTY")}
          align="center"
          key="deputy"
          render={({ deputy }) => deputy}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.DEPUTY_STATUS")}
          key="deputyStatus"
          align="center"
          render={({ deputyStatus }) => <StatusTag status={deputyStatus} />}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.APPROVAL_STATUS")}
          key="managerStatus"
          align="center"
          render={({ managerStatus }) => <StatusTag status={managerStatus} />}
        />
        <Table.Column
          title={t("leaveRequests.tableColumns.DETAILS")}
          key="details"
          align="center"
          render={(record) => {
            if (record.employeeStatus !== constants.statusCancel) {
              return (
                <Link
                  to={{
                    pathname: `/dashboard/leave-request/${record.id}`,
                  }}>
                  <Button type="primary" icon="profile">
                    {t("buttons.DETAILS")}
                  </Button>
                </Link>
              );
            } else {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Alert
                    message={t("leaveRequests.MESSAGES.WAITING_CANCELATION")}
                    type="warning"
                    showIcon={true}
                    style={{ width: "205px" }}
                  />
                </div>
              );
            }
          }}
        />
      </Table>
    </div>
  );
};

export default LeaveRequestsTable;
