import * as React from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Layout } from "antd";
import CalendarsView from "./components/CalendarsView";
import CalendarsPanel from "./components/CalendarsPanel";
import CalendarsAbsenceLogs from "./components/CalendarsAbsenceLogs";
import CalendarsCreateAbsence from "./components/CalendarsCreateAbsence";

const { Content } = Layout;

const Calendars = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TitleSection title={t("sideMenu.CALENDARS")} iconType="calendar" />
      <Content className="split-screen">
        <div className="left-side">
          <span style={{ display: "flex" }}>
            <h4>Absence calendar</h4>
            <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Public"}</h6>
          </span>
          <CalendarsView />
        </div>
        <div className="right-side">
          <CalendarsCreateAbsence t={t} />
          <span style={{ display: "flex" }}>
            <h4>Absence panel</h4>
            <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Private"}</h6>
          </span>
          <CalendarsPanel />
          <span style={{ display: "flex" }}>
            <h4>Requested absence</h4>
            <br />
            <h6 style={{ color: "#1990FF", marginLeft: 2 }}>{"Private"}</h6>
          </span>
          <CalendarsAbsenceLogs />
        </div>
      </Content>
    </div>
  );
};

export default Calendars;
