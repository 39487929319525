import React from "react";
import { Mutation } from "@apollo/react-components";
import { Button, Col, Form, Modal, Row, Switch, notification } from "antd";
import { CLOSE_REVIEW_CYCLE } from "graphql/mutations";
import { GET_REVIEW_CYCLE } from "graphql/queries/Configurations";
import { TFunction } from "i18next";
import { Fragment, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { reviewCyclesType } from "screens/PerformanceReview/types";

interface IProps extends FormComponentProps {
  t: TFunction;
  selectedReview: reviewCyclesType;
}

const PerformanceReviewClose: React.FC<IProps> = ({ t, selectedReview, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [privateValue, setPrivateValue] = useState<boolean>(true);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  return (
    <Fragment>
      <Button style={{ marginBottom: 15 }} icon="close-circle" type="danger" onClick={toggleModal}>
        {t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSE_THIS_REVIEW_CYCLE")}
      </Button>
      <Mutation
        mutation={CLOSE_REVIEW_CYCLE}
        refetchQueries={[
          {
            query: GET_REVIEW_CYCLE,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.PERFORMANCE_REVIEW_CLOSED"),
          });
          toggleModal();
          window.location.reload();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(closeReviewCycle, { loading }) => (
          <Modal
            title={t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.LEAVE_YOUR_COMMENT")}
            width={500}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { managerComment } = values;

                  closeReviewCycle({
                    variables: {
                      ReviewCycleId: selectedReview?.id,
                      managerComment,
                      commentIsPrivate: privateValue,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER_COMMENT")}>
                    {form.getFieldDecorator("managerComment", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                        },
                      ],
                    })(
                      <TextArea
                        rows={10}
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.MANAGER_COMMENT_ON_CLOSE")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER_COMMENT_PRIVATE")}>
                    {form.getFieldDecorator("commentIsPrivate", {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    })(
                      <Switch
                        checkedChildren="Private for reviewee"
                        unCheckedChildren="Public for reviewee"
                        defaultChecked
                        onChange={setPrivateValue}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};
export default Form.create<IProps>()(PerformanceReviewClose);
