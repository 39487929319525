import React, { useState, Fragment } from 'react';
import { Mutation } from '@apollo/react-components';
import {
  notification,
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Modal,
  Row,
  Select,
} from 'antd';
import { CREATE_EMPLOYEE_ABSENCE_REQUEST } from 'graphql/mutations';
import {
  GET_COMPANY_BRANCH_BY_ID,
  GET_EMPLOYEE_ABSENCE_BALANCE,
  GET_EMPLOYEE_ABSENCE_REQUESTS,
} from 'graphql/queries/Configurations';
import { TFunction } from 'i18next';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { useQuery } from '@apollo/react-hooks';
import { GET_NEW_USERS } from 'graphql/queries';
import { getUserData } from 'common/AccessControl/Permissions';

import useTimeOffPolicy from './helpers/requestTimeOffPolicy';

interface IProps extends FormComponentProps {
  t: TFunction;
}
const { RangePicker } = DatePicker;
const { Option } = Select;
const CalendarsCreateAbsence: React.FC<IProps> = ({ t, form }) => {
  const currentUser = getUserData();

  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
  const [startDateStatus, setStartDateStatus] = useState<boolean>();
  const [endDateStatus, setEndDateStatus] = useState<boolean>();

  const { data: emplyeeAbsenceData, loading: emplyeeAbsenceLoading } = useQuery(GET_EMPLOYEE_ABSENCE_BALANCE);
  const { data: userData, loading: userLoading } = useQuery(GET_NEW_USERS);
  const { data: emplyeeBranchData } = useQuery(GET_COMPANY_BRANCH_BY_ID, {
    variables: {
      id: currentUser?.employee?.CompanyBranchId,
    },
  });
  const daysDifference = selectedDateRange?.endDate?.diff(selectedDateRange?.startDate, "days") + 1;
  const managerFirstName = currentUser?.employee?.manager?.firstName;
  const managerLastName = currentUser?.employee?.manager?.lastName;
  const currentUserFirstName = currentUser?.firstName;
  const currentUserLastName = currentUser?.lastName;
  const holidaysArray = emplyeeBranchData?.getCompanyBranchById?.holidayTypes?.[0]?.date;

  const [firstRule, secondRule] = useTimeOffPolicy(selectedDateRange?.startDate, daysDifference);

  const handleDateChange = (dates) => {
    const startDate = dates[0];
    const endDate = dates[1];
    setSelectedDateRange({ startDate, endDate });
  };

  const disabledDate = (current) => {
    // Disable weekends (Saturday and Sunday)
    if (current.day() === 0 || current.day() === 6) {
      return true;
    }
    // Disable holidays from holidaysArray
    if (holidaysArray) {
      const dateString = current.format("YYYY-MM-DD");
      if (holidaysArray.some((holiday) => holiday.startsWith(dateString))) {
        return true;
      }
    }
    return false;
  };

  const oneDayDuration = (values) => {
    const statusMap = {
      allDay: { isStartDateHalf: false, isEndDateHalf: false },
      firstPart: { isStartDateHalf: true, isEndDateHalf: false },
      secondPart: { isStartDateHalf: false, isEndDateHalf: true },
    };
    if (statusMap.hasOwnProperty(values)) {
      const { isStartDateHalf, isEndDateHalf } = statusMap[values];
      setStartDateStatus(isStartDateHalf);
      setEndDateStatus(isEndDateHalf);
    }
  };

  const startDayDuration = (values) => {
    const statusMap = {
      allDay: { isStartDateHalf: false },
      secondPart: { isStartDateHalf: true },
    };
    if (statusMap.hasOwnProperty(values)) {
      const { isStartDateHalf } = statusMap[values];
      setStartDateStatus(isStartDateHalf);
    }
  };

  const endDayDuration = (values) => {
    const statusMap = {
      allDay: { isEndDateHalf: false },
      firstPart: { isEndDateHalf: true },
    };
    if (statusMap.hasOwnProperty(values)) {
      const { isEndDateHalf } = statusMap[values];
      setEndDateStatus(isEndDateHalf);
    }
  };

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  return (
    <Fragment>
      <Button icon="tag" size="large" block type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.CALENDARS.CREATE_ABSENCE_REQUEST")}
      </Button>
      <Mutation
        mutation={CREATE_EMPLOYEE_ABSENCE_REQUEST}
        refetchQueries={[
          {
            query: GET_EMPLOYEE_ABSENCE_REQUESTS,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.CALENDARS.MESSAGES.CALENDARS_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createEmployeeAbsenceLog, { loading }) => (
          <Modal
            title={t("appConfiguration.CALENDARS.CREATE_ABSENCE_REQUEST")}
            width={500}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { AbsenceTypeId, employeeReplacerId, description } = values;

                  createEmployeeAbsenceLog({
                    variables: {
                      AbsenceTypeId,
                      employeeReplacerId,
                      startDate: moment(selectedDateRange?.startDate).startOf("day").utcOffset(0, true).format(),
                      endDate: moment(selectedDateRange?.endDate).startOf("day").utcOffset(0, true).format(),
                      isStartDateHalf: startDateStatus,
                      isEndDateHalf: endDateStatus,
                      description,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
              setSelectedDateRange(null);
              setStartDateStatus(undefined);
              setEndDateStatus(undefined);
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.CALENDARS.LABELS.WHAT_TYPE_OF_ABSENCE")}>
                    {form.getFieldDecorator("AbsenceTypeId", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.NAME_REQUIRED"),
                        },
                      ],
                    })(
                      <Select allowClear loading={emplyeeAbsenceLoading}>
                        {emplyeeAbsenceData?.getEmployeeAbsenceBalance
                          .filter((absenceItem) => absenceItem?.availableDaysOff > 0)
                          .map((absenceItem) => (
                            <Option value={absenceItem?.AbsenceTypeId} key={absenceItem?.AbsenceTypeId}>
                              {absenceItem?.absenceTypes?.[0]?.absenceTypeName}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback={true}
                    label={
                      <span style={{ display: "flex", gap: 5, flexDirection: "row" }}>
                        {`${t("appConfiguration.CALENDARS.LABELS.FROM_TO")} `}
                        <a
                          target="_blank"
                          href="https://www.notion.so/softup/Request-Time-Off-c09cd45df99c4b57826b9532b46d50c9?pvs=4"
                          style={{ display: "flex", gap: 3, flexDirection: "row", marginTop: 3 }}>
                          <Icon style={{ color: "orange", fontSize: 10, marginTop: 2 }} type="warning" />
                          <h6 style={{ color: "#1690ff" }}>{"Request Time Off Policy"}</h6>
                        </a>
                      </span>
                    }>
                    {form.getFieldDecorator("datesOfAbsence", {
                      rules: [{ required: true, message: "Please select days" }],
                    })(<RangePicker disabledDate={disabledDate} onChange={handleDateChange} />)}
                  </Form.Item>
                </Col>
              </Row>
              {daysDifference > 0 && (
                <Row gutter={16}>
                  {daysDifference === 1 ? (
                    <Col span={12}>
                      <Form.Item hasFeedback={true} label={t("appConfiguration.CALENDARS.LABELS.DURATION")}>
                        {form.getFieldDecorator("duration", {
                          rules: [
                            {
                              required: true,
                              message: "Please select duration",
                            },
                          ],
                        })(
                          <Select onChange={oneDayDuration}>
                            <Option value={"allDay"}>All day</Option>
                            <Option value={"firstPart"}>First part</Option>
                            <Option value={"secondPart"}>Second part</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  ) : (
                    <>
                      <Col span={12}>
                        <Form.Item hasFeedback={true} label={t("appConfiguration.CALENDARS.LABELS.FIRST_DAY_DURATION")}>
                          {form.getFieldDecorator("firstDayDuration", {
                            rules: [
                              {
                                required: true,
                                message: "Please select first day duration",
                              },
                            ],
                          })(
                            <Select onChange={startDayDuration}>
                              <Option value={"allDay"}>All day</Option>
                              <Option value={"secondPart"}>Second part</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item hasFeedback={true} label={t("appConfiguration.CALENDARS.LABELS.LAST_DAY_DURATION")}>
                          {form.getFieldDecorator("secondDayDuration", {
                            rules: [
                              {
                                required: true,
                                message: "Please select last day duration",
                              },
                            ],
                          })(
                            <Select onChange={endDayDuration}>
                              <Option value={"allDay"}>All day</Option>
                              <Option value={"firstPart"}>First part</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback={true}
                    label={
                      <span style={{ display: "flex", flexDirection: "row", gap: 5, width: "100%" }}>
                        {`${t("appConfiguration.CALENDARS.LABELS.SUBSTITUTE")} `}
                        <span style={{ display: "flex", gap: 3, marginTop: 3 }}>
                          <Icon style={{ marginTop: 2, fontSize: 10 }} type="info-circle" theme="twoTone" />
                          <h6>{`Your manager is: ${managerFirstName} ${managerLastName}`}</h6>
                        </span>{" "}
                      </span>
                    }>
                    {form.getFieldDecorator("employeeReplacerId", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.NAME_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        allowClear
                        showSearch
                        loading={userLoading}
                        filterOption={(input, option) =>
                          (option?.props?.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {userData?.getNewUsers
                          .filter((userItem) => {
                            const isManager =
                              userItem.firstName == managerFirstName && userItem.lastName == managerLastName;

                            const isCurrentUser =
                              userItem.firstName == currentUserFirstName && userItem.lastName == currentUserLastName;

                            return !isManager && !isCurrentUser;
                          })
                          .map((userItem) => (
                            <Option value={userItem.id} key={userItem.id}>
                              {userItem.firstName + " " + userItem.lastName}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.CALENDARS.LABELS.COMMENT")}>
                    {form.getFieldDecorator("description", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                        },
                      ],
                    })(
                      <TextArea
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.DESCRIPTION")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {firstRule ? (
                <Alert
                  message="Disclaimer:"
                  description={
                    <span style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                      {`${t("disclaimer.FIRST_RULE")} `}
                      <a
                        target="_blank"
                        href="https://www.notion.so/softup/Request-Time-Off-c09cd45df99c4b57826b9532b46d50c9?pvs=4"
                        style={{ display: "flex", gap: 3, flexDirection: "row", marginTop: 3 }}>
                        <h5 style={{ color: "#1690ff" }}>{"Request Time Off Policy"}</h5>
                      </a>
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              ) : secondRule ? (
                <Alert
                  message="Disclaimer:"
                  description={
                    <span style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                      {`${t("disclaimer.SECOND_RULE")} `}
                      <a
                        target="_blank"
                        href="https://www.notion.so/softup/Request-Time-Off-c09cd45df99c4b57826b9532b46d50c9?pvs=4"
                        style={{ display: "flex", gap: 3, flexDirection: "row", marginTop: 3 }}>
                        <h5 style={{ color: "#1690ff" }}>{"Request Time Off Policy"}</h5>
                      </a>
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              ) : null}
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};
export default Form.create<IProps>()(CalendarsCreateAbsence);
