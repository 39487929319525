import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IUtilization } from "../../../graphql/queries/Interfaces";
import { TFunction } from "i18next";
import * as _ from "lodash";

interface IProps {
  utilization: IUtilization[];
  t: TFunction;
  dataKey: string;
}

const BarChartGraph: React.FC<IProps> = ({ utilization, t, dataKey }) => {
  const maxHours = Math.round(_.maxBy(utilization, "totalHours").totalHours * 1.2);

  return (
    <ResponsiveContainer height={300}>
      <BarChart data={utilization} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey} />
        {maxHours > 0 ? <YAxis type="number" domain={[0, maxHours]} /> : <YAxis />}
        <Tooltip />
        <Legend />
        <Bar dataKey="workedHours" stackId="a" fill="#8884d8" name={t("appConfiguration.BENCHMARK.BILLABLE_HOURS")} />
        <Bar
          dataKey="nonWorkedHours"
          stackId="a"
          fill="#82ca9d"
          name={t("appConfiguration.BENCHMARK.NON_BILLABLE_HOURS")}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartGraph;
