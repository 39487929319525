import moment from "moment";
import { absenceType } from "../HomeTypes";

const today = moment().startOf("day");

export const getDaysFromToday = (absenceLogItem: absenceType) => {
  const startDate = moment(absenceLogItem?.startDate);
  const daysFromToday = startDate.diff(today, "days");
  return daysFromToday;
};

export const isTodayInVacation = (absenceLogItem: absenceType) => {
  const startDate = moment(absenceLogItem?.startDate);
  const endDate = moment(absenceLogItem?.endDate);
  return today.isBetween(startDate, endDate, "days", "[]");
};

export const getDaysUntilReturn = (absenceLogItem: absenceType) => {
  const endDate = moment(absenceLogItem?.endDate);
  const daysUntilReturn = endDate.diff(today, "days");
  return daysUntilReturn;
};
