import React, { useState } from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { Button, Upload, message, Icon, Input, Form, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { GET_COMPANY_INFORMATION } from "graphql/queries";
import { UPDATE_COMPANY_INFORMATION } from "../../../../../graphql/mutations";
import { ICompany } from "../Interfaces";
interface IProps extends FormComponentProps {
  t: TFunction;
  company: ICompany;
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  return false;
}
const CompanyForm: React.FC<IProps> = ({
  t,
  company: { name, iban, nipt, accountNumber, logo, contactNumber },
  form: { getFieldDecorator, validateFields },
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(logo);

  const handleSubmit = (updateCompany) => {
    validateFields((err, values) => {
      if (!err) {
        const { name, iban, logo, accountNumber, nipt, contactNumber } = values;
        updateCompany({
          variables: {
            logo: logo.file,
            name,
            iban,
            accountNumber,
            nipt,
            contactNumber,
          },
        });
      }
    });
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">{t("buttons.UPLOAD")}</div>
    </div>
  );

  const handleChange = (info) => {
    setImageUrl(null);
    if (info.fileList.length > 1) {
      info.fileList.shift();
    }
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
  };
  return (
    <>
      <Mutation
        mutation={UPDATE_COMPANY_INFORMATION}
        refetchQueries={[
          {
            query: GET_COMPANY_INFORMATION,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.COMPANY_INFO.MESSAGES.COMPANY_UPDATED"),
          });
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(updateCompany, { loading }) => (
          <Form>
            <Form.Item label={t("appConfiguration.COMPANY_INFO.LABELS.LOGO")}>
              {getFieldDecorator("logo", {
                initialValue: logo,
                valuePropName: "file",
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Upload
                  name="avatar"
                  listType="picture-card"
                  multiple={false}
                  className="avatar-uploader"
                  showUploadList={true}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {imageUrl ? <img src={imageUrl} alt="Logo" style={{ width: "100%" }} /> : uploadButton}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label={t("appConfiguration.COMPANY_INFO.LABELS.NAME")}>
              {getFieldDecorator("name", {
                initialValue: name,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Iban">
              {getFieldDecorator("iban", {
                initialValue: iban,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t("appConfiguration.COMPANY_INFO.LABELS.ACCOUNT_NUMBER")}>
              {getFieldDecorator("accountNumber", {
                initialValue: accountNumber,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t("appConfiguration.COMPANY_INFO.LABELS.TAX_ID")}>
              {getFieldDecorator("nipt", {
                initialValue: nipt,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t("appConfiguration.COMPANY_INFO.LABELS.CONTACT_NUMBER")}>
              {getFieldDecorator("contactNumber", {
                initialValue: contactNumber,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item>
              <Button loading={loading} onClick={() => handleSubmit(updateCompany)} type="primary" htmlType="submit">
                {t("buttons.SAVE_CHANGES")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(CompanyForm);
