export const getStatusColor = (approveStatus: boolean | null): string => {
  if (approveStatus === null) {
    return "#ff9800";
  } else if (approveStatus) {
    return "#08a451";
  } else {
    return "#e91a2b";
  }
};

export const getStatusIcon = (approveStatus: boolean | null): string => {
  if (approveStatus === null) {
    return "exclamation-circle";
  } else if (approveStatus) {
    return "check-circle";
  } else {
    return "close-circle";
  }
};

export const getStatusText = (approveStatus: boolean | null): string => {
  if (approveStatus === null) {
    return "Pending";
  } else if (approveStatus) {
    return "Approved";
  } else {
    return "Not Approved";
  }
};

export const getDayDuration = (dayDuration: number | null): string => {
  if (dayDuration === 1) {
    return "Full day";
  } else if (dayDuration === 0) {
    return "First part of the day";
  } else {
    return "Second part of the day";
  }
};

export const absenceColor = (absenceName: string): any => {
  if (absenceName === "Paid vacations") {
    return "#08a451";
  } else if (absenceName === "Medical leave") {
    return "#e91a2b";
  } else if (absenceName === "Marriage leave") {
    return "#42a1e2";
  } else if (absenceName === "Maternity leave") {
    return "#693599";
  } else if (absenceName === "Bereavement leave") {
    return "#6e330c";
  }
};
