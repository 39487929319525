import React, { useState } from "react";
import { TFunction } from "i18next";
import { CartesianGrid, LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend } from "recharts";
import { FormComponentProps } from "antd/lib/form";
import { InputNumber, Form, Skeleton, Radio } from "antd";
import { GET_MONTHS_SUMMARY } from "graphql/queries/FinanceQueries";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { roundTwoDecPlaces } from "helpers/round_number";

interface IProps extends FormComponentProps {
  t: TFunction;
}

interface IPredictionFilters {
  utilization: number | null;
  growth: number | null;
  date: string;
}

const UTILIZATION = "utilization";
const GROWTH = "growth";

const PredictionChart: React.FC<IProps> = ({ t, form }) => {
  const [filters, setFilters] = useState<IPredictionFilters>({
    utilization: null,
    growth: null,
    date: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
  });

  const [predictionType, setPredictionType] = useState<string>(UTILIZATION);

  const utilizationChange = () => {
    const value = form.getFieldsValue(["utilization"]).utilization;
    const utilization = value ?? 0;

    setFilters({ ...filters, utilization });
  };

  const growthChange = () => {
    const value = form.getFieldsValue(["growth"]).growth;
    const growth = value ?? 0;

    setFilters({ ...filters, growth });
  };

  const buttonGroupChange = (e) => {
    setPredictionType(e.target.value);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Radio.Group defaultValue={UTILIZATION} buttonStyle="solid" onChange={buttonGroupChange}>
          <Radio.Button value={UTILIZATION}>Utilization</Radio.Button>
          <Radio.Button value={GROWTH}>Growth</Radio.Button>
        </Radio.Group>

        <Form layout="inline">
          {predictionType === UTILIZATION && (
            <Form.Item label="Utilization" style={{ marginRight: "5px" }}>
              {form.getFieldDecorator("utilization", {
                initialValue: filters.utilization,
              })(
                <InputNumber
                  size="small"
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") ?? ""}
                  onBlur={() => utilizationChange()}
                />
              )}
            </Form.Item>
          )}

          {predictionType === GROWTH && (
            <Form.Item label="Growth" style={{ marginRight: "5px" }}>
              {form.getFieldDecorator("growth", {
                initialValue: filters.growth,
              })(
                <InputNumber
                  size="small"
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") ?? ""}
                  onBlur={() => growthChange()}
                />
              )}
            </Form.Item>
          )}
        </Form>
      </div>

      <Query query={GET_MONTHS_SUMMARY} variables={{ date: filters.date }}>
        {({ loading, error, data }: any) => {
          const dataSource: any = [];

          if (!loading && data?.monthsSummary) {
            const { month, billableValue, percentage, workedHours } = data.monthsSummary[0];

            const utilization = filters.utilization
              ? filters.utilization >= 0
                ? 1 + filters.utilization / 100
                : 1 - Math.abs(filters.utilization) / 100
              : 1;

            const utilizationPercentage = utilization * 100 - 100;

            const growth = filters.growth
              ? filters.growth >= 0
                ? 1 + filters.growth / 100
                : 1 - Math.abs(filters.growth) / 100
              : 1;

            for (let i = 0; i < 6; i++) {
              if (dataSource.length === 0) {
                dataSource.push({
                  month: moment().format("MMM YYYY"),
                  // workedHours: roundTwoDecPlaces(workedHours * utilization),
                  percentage:
                    predictionType === UTILIZATION
                      ? roundTwoDecPlaces(
                          percentage + utilizationPercentage > 0 ? percentage + utilizationPercentage : 0 // If percentage + utilization is less than 0 set percentage to 0
                        )
                      : roundTwoDecPlaces(percentage),
                  billableValue:
                    predictionType === UTILIZATION
                      ? percentage + utilizationPercentage <= 0
                        ? 0
                        : roundTwoDecPlaces(((percentage + utilizationPercentage) * billableValue) / percentage)
                      : roundTwoDecPlaces(billableValue * growth),
                });
              } else {
                dataSource.push({
                  month: moment(month)
                    .add(i + 1, "months")
                    .format("MMM YYYY"),
                  // workedHours: roundTwoDecPlaces(dataSource[i - 1].workedHours * utilization),
                  percentage:
                    predictionType === UTILIZATION
                      ? dataSource[i - 1].percentage === 0
                        ? 0
                        : roundTwoDecPlaces(dataSource[i - 1].percentage + utilizationPercentage)
                      : roundTwoDecPlaces(dataSource[i - 1].percentage),
                  billableValue:
                    predictionType === UTILIZATION
                      ? dataSource[i - 1].percentage === 0
                        ? 0
                        : roundTwoDecPlaces(
                            ((dataSource[i - 1].percentage + utilizationPercentage) * dataSource[i - 1].billableValue) /
                              dataSource[i - 1].percentage
                          )
                      : roundTwoDecPlaces(dataSource[i - 1].billableValue * growth),
                });
              }
            }
          }

          if (error) {
            return <p>Error</p>;
          }

          if (loading) {
            return <Skeleton active={true} />;
          }

          return (
            <>
              <ResponsiveContainer height={300}>
                <LineChart data={dataSource}>
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="billableValue"
                    name={t("analytics.STATISTICS.REVENUE")}
                    stroke="#8884d8"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          );
        }}
      </Query>
    </>
  );
};

export default Form.create<IProps>()(PredictionChart);
