import React, { useState } from "react";
import StatusTag from "../../../common/LeaveRequestTag";
import { Table, Button, Popconfirm, notification, Empty } from "antd";
import { generateKey } from "../../../utils/listKey";
import { stringToHTML } from "../../../helpers/draftjs";
import { formatDate } from "../../../utils/date";
import constants from "../../../constants";
import { TFunction } from "i18next";
import { GET_CANCELED_LEAVE_REQUESTS } from "../../../graphql/queries/LeaveManagementQueries";
import { Query, Mutation } from "@apollo/react-components";
import { DELETE_LEAVE_REQUEST, UPDATE_LEAVE_REQUEST } from "../../../graphql/mutations/LeaveManagementMutations";

interface IProps {
  t: TFunction;
}

const ActionsCompomonent = ({ t, id, data }) => {
  const [disableEdit, setDisableEdit] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  return (
    <div className="actions">
      <div className="leave-request-actions">
        <ApproveButton t={t} id={id} disable={disableDelete} changeEditDisability={() => setDisableEdit} />
        <DeclineButton
          id={id}
          t={t}
          disable={disableEdit}
          changeDeleteDisability={() => setDisableDelete}
          employeeName={data.employee}
        />
      </div>
    </div>
  );
};

const CancelledRequests: React.FC<IProps> = ({ t }) => {
  return (
    <Query query={GET_CANCELED_LEAVE_REQUESTS}>
      {({ loading, error, data }: any) => {
        if (error) {
          return <div>Error</div>;
        }
        let canceledLeaveRequests;
        if (!loading && data && data.canceledLeaveRequests) {
          canceledLeaveRequests = data.canceledLeaveRequests.map(
            ({
              id,
              leaveRequestType: { id: leaveId, leaveTypeName },
              comment,
              startDate,
              endDate,
              duration,
              employeeStatus,
              deputyId,
              deputyStatus,
              managerStatus,
              deputy: {
                User: { firstName, lastName },
              },
              employee: {
                User: { firstName: employeeName, lastName: employeeLastname },
              },
            }) => ({
              id,
              leaveTypeName,
              leaveId,
              comment,
              startDate,
              endDate,
              duration,
              employeeStatus,
              deputyStatus,
              managerStatus,
              deputyId,
              deputy: `${firstName} ${lastName}`,
              employee: `${employeeName} ${employeeLastname}`,
            })
          );
        }
        return (
          <>
            {
              <Table
                data-cy="table"
                size="small"
                loading={loading}
                dataSource={canceledLeaveRequests}
                expandedRowRender={(record: { comment: string }) => stringToHTML(record.comment)}
                rowKey={() => generateKey()}
                pagination={{ defaultPageSize: 10 }}
                className="leave-request-table"
                locale={{
                  emptyText: (
                    <>
                      <Empty
                        description={t("emptyDescriptions.CANCELED_REQUESTS")}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </>
                  ),
                }}>
                <Table.Column
                  title={t("leaveRequests.tableColumns.EMPLOYEE")}
                  key="employee"
                  render={({ employee }) => employee}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.START_DATE")}
                  key="startDate"
                  render={({ startDate }) => formatDate(startDate)}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.END_DATE")}
                  key="endDate"
                  render={({ endDate }) => formatDate(endDate)}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.LEAVE_TYPE")}
                  key="leaveType"
                  align="center"
                  render={({ leaveTypeName }) => leaveTypeName}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.DEPUTY")}
                  key="deputy"
                  align="center"
                  render={({ deputy }) => deputy}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.DEPUTY_STATUS")}
                  key="deputyStatus"
                  align="center"
                  render={({ deputyStatus }) => <StatusTag status={deputyStatus} />}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.APPROVAL_STATUS")}
                  key="managerStatus"
                  align="center"
                  render={({ managerStatus }) => <StatusTag status={managerStatus} />}
                />
                <Table.Column
                  title={t("leaveRequests.tableColumns.ACTIONS")}
                  align="center"
                  key="status"
                  width={250}
                  className="button-actions"
                  render={({ employeeStatus, id, endDate, startDate, comment, deputyId, leaveId, employee }) => {
                    return (
                      <>
                        <>
                          <ActionsCompomonent
                            t={t}
                            id={id}
                            data={{ employeeStatus, startDate, endDate, comment, deputyId, leaveId, employee }}
                          />
                        </>
                      </>
                    );
                  }}
                />
              </Table>
            }
          </>
        );
      }}
    </Query>
  );
};

const ApproveButton = ({ t, id, disable, changeEditDisability }) => {
  return (
    <Mutation
      mutation={DELETE_LEAVE_REQUEST}
      refetchQueries={[{ query: GET_CANCELED_LEAVE_REQUESTS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.DELETED_LEAVE_REQUEST"),
        });
      }}>
      {(deleteLeaveRequest, { loading }): any => {
        if (loading) {
          changeEditDisability(loading);
        }

        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                deleteLeaveRequest({ variables: { id } });
              }}>
              <Button loading={loading} type="primary" icon="like" className="action-button" disabled={disable}>
                {t("buttons.APPROVE")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

const DeclineButton = ({ t, id, disable, changeDeleteDisability, employeeName }) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST}
      refetchQueries={[{ query: GET_CANCELED_LEAVE_REQUESTS }]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: `${employeeName}'s ${t("leaveRequests.MESSAGES.DECLINED_CANCEL_REQUEST")}`,
        });
      }}>
      {(updateLeaveRequest, { loading }): any => {
        if (loading) {
          changeDeleteDisability(loading);
        }

        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                updateLeaveRequest({ variables: { id, employeeStatus: constants.leaveRequestStatus.statusActive } });
              }}>
              <Button loading={loading} type="danger" icon="dislike" className="action-button" disabled={disable}>
                {t("buttons.DECLINE")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

export default CancelledRequests;
