import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { Table } from "antd";

import { GET_PROJECT_EMPLOYEE_ASSIGNMENTS } from "../../../../../graphql/queries";
import { withTranslation, WithTranslation } from "react-i18next";
import AssignEmployee from "./components/AssignEmployee";
import UnAssignButton from "./components/UnAssignButton";
import EditAssignmentButton from "./components/EditAssignmentButton";

interface IProps extends WithTranslation {
  projectId: number;
}
class Employees extends Component<IProps> {
  public refetch = [
    {
      query: GET_PROJECT_EMPLOYEE_ASSIGNMENTS,
      variables: { id: this.props.projectId },
    },
  ];

  public render() {
    const { t } = this.props;
    return (
      <>
        <AssignEmployee projectId={this.props.projectId} />
        <Query query={GET_PROJECT_EMPLOYEE_ASSIGNMENTS} variables={{ id: this.props.projectId }}>
          {(state) => {
            const loading = state?.loading;
            const data = state?.data;
            const error = state?.error;
            let dataSource;

            if (!!data && !loading && data.Project.employeeAssignments) {
              dataSource = data.Project.employeeAssignments.reverse().map(
                ({
                  id,
                  employee: {
                    User: { id: employeeId, firstName, lastName },
                  },
                  hourlyRate,
                }) => ({
                  id,
                  employeeId,
                  firstName,
                  lastName,
                  key: id,
                  hourlyRate,
                })
              );
            }

            if (error) {
              return <p>Error</p>;
            }

            return (
              <Table size="small" loading={loading} dataSource={dataSource}>
                <Table.Column
                  title={t("projectsTableColumns.users.FIRST_NAME")}
                  dataIndex="firstName"
                  key="firstName"
                  width="100px"
                />
                <Table.Column
                  title={t("projectsTableColumns.users.LAST_NAME")}
                  dataIndex="lastName"
                  key="lastName"
                  width="100px"
                />
                <Table.Column
                  title={t("projectsTableColumns.users.HOURLY_RATE")}
                  dataIndex="hourlyRate"
                  key="hourlyRate"
                  width="50px"
                  align="center"
                />
                <Table.Column
                  title={t("projectsTableColumns.users.ACTIONS")}
                  key="id"
                  width="100px"
                  align="center"
                  render={({ id, hourlyRate }) => (
                    <>
                      <UnAssignButton id={id} refetch={this.refetch} projectId={this.props.projectId} />
                      <EditAssignmentButton data={{ id, hourlyRate }} />
                    </>
                  )}
                />
              </Table>
            );
          }}
        </Query>
      </>
    );
  }
}

export default withTranslation()(Employees);
