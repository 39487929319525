import React, { Fragment, useState } from "react";
import { Alert, Button, Descriptions, Modal, Tag } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { TFunction } from "i18next";
import moment from "moment";
import { absenceType } from "screens/Home/components/HomeTypes";
import { getStatusColor, getStatusText } from "helpers/approveStatusAndAbsence";
import useTimeOffPolicyHome from "screens/Home/components/helpers/requestTimeOffPolicyHome";

interface IProps extends FormComponentProps {
  t: TFunction;
  absenceLogItem: absenceType;
  buttonTheme: boolean;
}

const CalendarsAbsenceLogDetails: React.FC<IProps> = ({ t, absenceLogItem, buttonTheme }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const returnDate = (returnDate) => {
    const returnDateIsNotSameDay = returnDate.add(1, "day").format("DD/MM/YYYY dddd");
    return returnDateIsNotSameDay;
  };

  const returnDateInSameDay = (returnDate) => {
    const returnDateInSameDay = returnDate.format("DD/MM/YYYY dddd");
    return returnDateInSameDay;
  };

  const currentURL = window.location.href;
  // Extract the profile from the current URL
  const segments = currentURL.split("/");
  const profile = segments[segments.length - 1];

  const firstDayOfAbsence = absenceLogItem?.startDate;
  const selectedDatesOfAbsenceLength = absenceLogItem?.datesOfAbsence.length;
  const selectedDatesCreatedAt = absenceLogItem?.createdAt;

  const [firstRule, secondRule] = useTimeOffPolicyHome(
    firstDayOfAbsence,
    selectedDatesOfAbsenceLength,
    selectedDatesCreatedAt
  );

  const generateLabelOneDayDuration = (absenceLogItem) => {
    let descriptionText;
    if (absenceLogItem && absenceLogItem.duration != 1) {
      if (absenceLogItem.isStartDateHalf) {
        descriptionText = (
          <>
            ({absenceLogItem.duration} days)
            <br />
            First half
          </>
        );
      } else if (absenceLogItem.isEndDateHalf) {
        descriptionText = (
          <>
            ({absenceLogItem.duration} days)
            <br />
            Second half
          </>
        );
      } else {
        descriptionText = `(${absenceLogItem.duration} days)`;
      }
    } else {
      descriptionText = "1 day";
    }
    return descriptionText;
  };

  return (
    <Fragment>
      {buttonTheme && (
        <Button style={{ color: "blue" }} key="profile" onClick={toggleModal}>
          Details
        </Button>
      )}
      {!buttonTheme && (
        <Button className="button-delete" type="primary" size="small" icon="info-circle" onClick={toggleModal}>
          {profile === "profile" ? "Details" : ""}
        </Button>
      )}
      <Modal
        title={t("appConfiguration.CALENDARS.ABSENCE_REQUEST_DETAILS")}
        width={600}
        centered={true}
        visible={modalOpen}
        footer={null}
        onCancel={toggleModal}>
        <Descriptions bordered size="small" layout="vertical">
          {absenceLogItem?.datesOfAbsence.length > 1 && (
            <>
              <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.FIRST_DAY_DURATION")}>
                {moment(absenceLogItem?.startDate).format("DD/MM/YYYY")}
                <br />
                {absenceLogItem?.isStartDateHalf ? "Second part of the day" : "Full day"}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.LAST_DAY_DURATION")}>
                {moment(absenceLogItem?.endDate).format("DD/MM/YYYY")}
                <br />
                {absenceLogItem?.isEndDateHalf ? "First part of the day" : "Full day"}
              </Descriptions.Item>
            </>
          )}
          {absenceLogItem?.datesOfAbsence.length === 1 && (
            <Descriptions.Item span={3} label={t("appConfiguration.CALENDARS.LABELS.ONE_DAY_DURATION")}>
              {moment(absenceLogItem?.startDate).format("DD/MM/YYYY")}
              <br />
              {generateLabelOneDayDuration(absenceLogItem)}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.RETURN_DATE")}>
            {absenceLogItem?.duration == 0
              ? returnDateInSameDay(moment(absenceLogItem?.endDate))
              : returnDate(moment(absenceLogItem?.endDate))}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.REQUEST_DATE")}>
            {moment(absenceLogItem?.createdAt).format("DD/MM/YYYY HH:mm dddd")}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.MANAGER_STATUS")}>
            {<Tag color="blue">{absenceLogItem?.manager?.firstName + " " + absenceLogItem?.manager?.lastName}</Tag>}
            {
              <Tag color={getStatusColor(absenceLogItem?.approvedByManager)}>
                {getStatusText(absenceLogItem?.approvedByManager)}
              </Tag>
            }
          </Descriptions.Item>
          <Descriptions.Item span={2} label={t("appConfiguration.CALENDARS.LABELS.SUBSTITUTE_STATUS")}>
            {<Tag color="blue">{absenceLogItem?.replacer?.firstName + " " + absenceLogItem?.replacer?.lastName}</Tag>}
            {
              <Tag color={getStatusColor(absenceLogItem?.approvedByReplacer)}>
                {getStatusText(absenceLogItem?.approvedByReplacer)}
              </Tag>
            }
          </Descriptions.Item>
          <Descriptions.Item span={4} label={t("appConfiguration.CALENDARS.LABELS.COMMENT")}>
            {absenceLogItem?.description ? absenceLogItem?.description : "------"}
          </Descriptions.Item>
          {absenceLogItem?.deletedAt !== null && (
            <Descriptions.Item span={4} label={t("appConfiguration.CALENDARS.LABELS.DELETED_AT")}>
              {absenceLogItem?.deletedAt ? moment(absenceLogItem?.deletedAt).format("DD/MM/YYYY HH:mm dddd") : "------"}
            </Descriptions.Item>
          )}
          {firstRule ? (
            <Descriptions.Item span={4} label={"Disclaimer"}>
              <Alert
                message=""
                description={
                  <span style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                    {`${t("disclaimer.FIRST_RULE")} `}
                    <a
                      target="_blank"
                      href="https://www.notion.so/softup/Request-Time-Off-c09cd45df99c4b57826b9532b46d50c9?pvs=4"
                      style={{ display: "flex", gap: 3, flexDirection: "row", marginTop: 3 }}>
                      <h5 style={{ color: "#1690ff" }}>{"Request Time Off Policy"}</h5>
                    </a>
                  </span>
                }
                type="warning"
                showIcon
              />
            </Descriptions.Item>
          ) : secondRule ? (
            <Descriptions.Item span={4} label={"Disclaimer"}>
              <Alert
                message=""
                description={
                  <span style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                    {`${t("disclaimer.SECOND_RULE")} `}
                    <a
                      target="_blank"
                      href="https://www.notion.so/softup/Request-Time-Off-c09cd45df99c4b57826b9532b46d50c9?pvs=4"
                      style={{ display: "flex", gap: 3, flexDirection: "row", marginTop: 3 }}>
                      <h5 style={{ color: "#1690ff" }}>{"Request Time Off Policy"}</h5>
                    </a>
                  </span>
                }
                type="warning"
                showIcon
              />
            </Descriptions.Item>
          ) : null}
        </Descriptions>
      </Modal>
    </Fragment>
  );
};

export default Form.create<IProps>()(CalendarsAbsenceLogDetails);
