import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik, FormikProps } from "formik";
import {
  Table,
  Layout,
  Button,
  Tooltip,
  Form,
  InputNumber,
  Select,
  Input,
  DatePicker,
  Tag,
  Popover,
  Modal,
  Col,
  Row,
  notification,
  Icon,
} from "antd";
import * as _ from "lodash";
import moment, { Moment } from "moment";
import { TFunction } from "i18next";
import { TableProps } from "antd/lib/table";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { QueryResult } from "@apollo/react-common";

import { useHandleData } from "components/useHandleData";
import { getEmployees } from "graphql/queries";
import {
  getEmployees as TGetEmployees,
  getEmployees_employees as IEmployee,
  getEmployees_employees_User as IUser,
} from "__generated__/getEmployees";

import { getContracts } from "graphql/queries/Contracts";
import { getContracts as TGetContracts, getContracts_contracts as IContract } from "__generated__/getContracts";
import { createContract } from "graphql/mutations";
import TitleSection from "common/SectionTitle/title";
import { createContractVariables, createContract as TCreateContract } from "__generated__/createContract";

import Validation from "./Validation";
import { Link } from "react-router-dom";
import { withPermissionsFnc } from "../../decorators/permissions";
import constants from "../../constants";
import { AccessControl } from "common/AccessControl";
import ActionsComponent from "./components/contractActions";
const formatDate = (date = moment()) => moment(date).format("DD-MM-yyyy");
interface IColumn extends IContract {
  user?: IUser;
}

interface ContractsTableProps extends TableProps<IColumn> {
  t: TFunction;
}

const getLatestContractEndDate = (contracts, state) => {
  const employeeContracts = contracts.filter(({ employeeId }) => employeeId === state.employeeId);
  const latestEndDate = _.max(
    employeeContracts.filter(({ endDate }) => !_.isNull(endDate)).map(({ endDate }) => endDate)
  );

  const latestStartDate = _.max(employeeContracts.map(({ startDate }) => startDate));

  if (moment(latestStartDate) > moment(latestEndDate)) {
    return latestStartDate;
  }
  return latestEndDate;
};

interface IContractsFormProps {
  t: TFunction;
  refetch: any;
  contracts: IContract[];
  employees: IEmployee[];
  employmentTypes: string[];
  contract: IContractState | null;
  setContract: (contract: IContractState | null) => void;
}

const emptyContract: () => IContractState = () => ({
  employeeId: undefined,
  startDate: undefined,
  endDate: null,
  hourlyRate: undefined,
  salary: null,
  employmentType: undefined,
  position: "",
  contractPdf: undefined,
  workingHoursPerMonth: null,
  signedBy: undefined,
});

const FormFields: (t: TFunction) => IFormField[] = (t) => [
  {
    field: "employeeId",
    type: "Select",
    optionsKey: "employees",
    mapOptionText: ["User.firstName", "User.lastName"],
    key: "userId",
    label: t("contracts.form.EMPLOYEE"),
    onChangeEffect: (state, relatedFormData) => {
      const previousContractEndDate = getLatestContractEndDate(relatedFormData.contracts, state);
      return {
        ...state,
        startDate: previousContractEndDate
          ? moment(previousContractEndDate).add("days", 1).format("YYYY-MM-DD")
          : undefined,
        endDate: previousContractEndDate
          ? moment(previousContractEndDate).add("days", 2).format("YYYY-MM-DD")
          : undefined,
      };
    },
  },
  {
    field: "employmentType",
    type: "Select",
    optionsKey: "employmentTypes",
    label: t("contracts.form.EMPLOYMENT_TYPE"),
    key: "",
    onChangeEffect: (state) => ({
      ...state,
      workingHoursPerMonth: undefined,
      hourlyRate: undefined,
      salary: undefined,
    }),
  },
  {
    field: "startDate",
    type: "DatePicker",
    label: t("contracts.form.START_DATE"),
    disableDateFn: (currentDate, state, formRelatedData) => {
      if (state.startDate) {
        const latestContractEndDate = getLatestContractEndDate(formRelatedData.contracts, state);
        return currentDate
          ? (latestContractEndDate ? currentDate <= moment(latestContractEndDate) : true) &&
              currentDate <= moment(state.startDate)
          : false;
      } else if (state.endDate) {
        return currentDate
          ? currentDate >= moment(state.endDate).set("days", moment(state.endDate).get("days") - 1)
          : false;
      }
      return false;
    },
    renderIf: (state) => typeof state.employeeId !== "undefined",
  },
  {
    field: "endDate",
    type: "DatePicker",
    label: t("contracts.form.END_DATE"),
    disableDateFn: (currentDate, state) => {
      return currentDate ? currentDate < moment(moment(state?.["startDate"]).add("days", 1)) : false;
    },
    renderIf: (state) => typeof state.employeeId !== "undefined",
  },
  {
    field: "position",
    type: "TextInput",
    label: t("contracts.form.POSITION"),
  },
  {
    field: "signedBy",
    type: "Select",
    optionsKey: "employees",
    mapOptionText: ["User.firstName", "User.lastName"],
    key: "userId",
    label: t("contracts.form.SIGNED_BY"),
  },
  {
    field: "workingHoursPerMonth",
    type: "InputNumber",
    label: t("contracts.form.WORKING_HOURS_PER_MONTH"),
  },
  {
    field: "hourlyRate",
    type: "InputNumber",
    renderIf: (state) => ["Contractor"].includes(state.employmentType),
    label: t("contracts.form.HOURLY_RATE"),
  },
  {
    field: "salary",
    type: "InputNumber",
    renderIf: (state) => ["Employee"].includes(state.employmentType),
    label: t("contracts.form.SALARY"),
  },
];

interface IFormField {
  field: string;
  type: string;
  disableDateFn?: (val: Moment | null, state, formRelatedData) => boolean;
  optionsKey?: string;
  renderIf?: (state) => boolean;
  onChangeEffect?: (state, relatedFormData?: any) => any;
  mapOptionText?: string[];
  key?: string;
  label: string;
}

const FormFieldsByType = {
  InputNumber: (props: ICommonInputProps) => (
    <InputNumber
      placeholder={props.item.label}
      key={props.item.field}
      value={props.formikProps.values[props.item.field]}
      onChange={(v) => props.formikProps.setFieldValue(props.item.field, v)}
    />
  ),
  TextInput: (props: ICommonInputProps) => (
    <Input
      placeholder={props.item.label}
      key={props.item.field}
      value={props.formikProps.values[props.item.field]}
      onChange={(v) => {
        props.formikProps.setFieldValue(props.item.field, v.currentTarget.value);
      }}
    />
  ),
  Select: (props: ICommonInputProps) => (
    <Select
      key={props.item.field}
      placeholder={props.item.label}
      value={props.formikProps.values[props.item.field]}
      onChange={(v) => {
        if (props.item.onChangeEffect) {
          props.formikProps.setValues(
            props.item.onChangeEffect({ ...props.formikProps.values, [props.item.field]: v }, props.formProps)
          );
        } else {
          props.formikProps.setFieldValue(props.item.field, v);
        }
      }}>
      {(props?.formProps[props.item.optionsKey as string] ?? []).map((item) => {
        const identifier = props.item.key === "" ? item : _.get(item, props.item.key, "");
        return (
          <Select.Option value={identifier} key={identifier}>
            {props.item.mapOptionText
              ?.map((key) => _.get(item, key, ""))
              .toString()
              .replace(",", " ") ?? item}
          </Select.Option>
        );
      })}
    </Select>
  ),
  DatePicker: (props: ICommonInputProps) => (
    <DatePicker
      placeholder={props.item.label}
      key={props.item.field}
      value={props.formikProps.values[props.item.field] ? moment(props.formikProps.values[props.item.field]) : null}
      onChange={(v) => props.formikProps.setFieldValue(props.item.field, v?.format("YYYY-MM-DD"))}
      disabledDate={(val) => props.item?.disableDateFn?.(val, props.formikProps.values, props.formProps) ?? true}
      showToday={false}
    />
  ),
};

interface ICommonInputProps {
  item: IFormField;
  formProps: IContractsFormProps;
  formikProps: FormikProps<IContractState>;
  index: number;
}

const renderBasedOnType = ({ item, formProps, formikProps, index }: ICommonInputProps) =>
  FormFieldsByType[item.type] ? (
    <Col key={item.field} span={11} offset={index % 2 === 1 ? 2 : 0}>
      <Form.Item
        label={item.label}
        help={formikProps.errors[item.field]}
        validateStatus={formikProps.errors[item.field] ? "error" : undefined}>
        {FormFieldsByType[item.type]({ item, formikProps, formProps })}
      </Form.Item>
    </Col>
  ) : null;

const ContractsForm: React.FC<IContractsFormProps> = (props) => {
  const [createContractMutation, { loading, error }] = useMutation<TCreateContract, createContractVariables>(
    createContract,
    {
      refetchQueries: props.refetch,
    }
  );
  const createOrEdit =
    props.contract && !props.contract.id
      ? props.t("contracts.form.ADD_CONTRACT")
      : props.t("contracts.form.EDIT_CONTRACT");
  return (
    <Modal
      title={createOrEdit}
      visible={!!props.contract}
      onCancel={() => props.setContract(null)}
      footer={null}
      className="contracts-form">
      <Formik<IContractState>
        initialValues={props.contract as IContractState}
        validationSchema={Validation(props.t)}
        onSubmit={(values, form) => {
          createContractMutation({ variables: values as createContractVariables }).then(() => {
            props.setContract(null);
            notification.success({
              message: props.t("messages.CONTRACT_CREATED"),
            });
            form.resetForm({ values: emptyContract() });
          });
        }}>
        {(formikProps) => {
          const fieldsToRender = FormFields(props.t).filter((item) => item?.renderIf?.(formikProps.values) ?? true);
          return (
            <>
              {_.chunk(fieldsToRender, 2).map((items, i) => (
                <Row key={i}>
                  {items.map((item, index) => renderBasedOnType({ formikProps, item, formProps: props, index }))}
                </Row>
              ))}

              <Form.Item
                help={error?.graphQLErrors?.[0]?.message.toString()}
                validateStatus={error?.message.toString() ? "error" : undefined}>
                <Button
                  type="primary"
                  size="large"
                  className="submit-button"
                  block
                  loading={loading}
                  onClick={() => {
                    formikProps.submitForm();
                  }}>
                  {createOrEdit}
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

interface IContractState extends Omit<createContractVariables, "employeeId" | "employmentType"> {
  employmentType?: "Contractor" | "Employee";
  employeeId?: number;
  id?: number;
  url?: string;
}

const Contracts: React.FC<any> = (props: any) => {
  const [contract, setContract] = useState<IContractState | null>(null);
  const contractsResult = useQuery<TGetContracts>(getContracts);
  const employeesResult = useQuery<TGetEmployees>(getEmployees);
  const { t } = useTranslation();
  const [filters, setFilters] = useState<string>("");

  const handleSearch = (selectedKeys, confirm) => {
    confirm(() => {
      setFilters(selectedKeys[0]);
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setFilters("");
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <a onClick={() => handleReset(clearFilters)}>Reset</a>
            <a onClick={() => handleSearch(selectedKeys, confirm)}>Search</a>
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => (filtered ? <Icon type="file-search" /> : <Icon type="search" />),
    onFilter: (value, record) => {
      const fullName = `${record[dataIndex]?.User?.firstName} ${record[dataIndex]?.User?.lastName}`;
      return fullName.toLowerCase().includes(value.toLowerCase());
    },
  });

  // Here are the columns for the table
  const columns: (t: TFunction) => ColumnProps<IColumn>[] = (t: TFunction) => [
    {
      title: "Employee",
      dataIndex: "employeeId",
      key: "employeeId",
      ...getColumnSearchProps("employee"),
      render: (id, item) => (
        <Link
          to={{
            pathname: `/dashboard/${item.employeeId}/profile`,
            state: {
              redirectName: "Contracts",
            },
          }}>{`${item.user?.firstName} ${item.user?.lastName}`}</Link>
      ),
    },

    {
      title: t("contractsTableColumns.START_DATE"),
      dataIndex: "startDate",
      key: "startDate",
      render: (date, item) => <p className="pre-wrap">{`${formatDate(item?.startDate)}`}</p>,
      align: "center",
    },
    {
      title: t("contractsTableColumns.END_DATE"),
      dataIndex: "endDate",
      key: "endDate",
      render: (date, item) => <p className="pre-wrap">{`${!item.endDate ? "Present" : formatDate(item?.endDate)}`}</p>,
      align: "center",
    },
    {
      title: t("contractsTableColumns.EMPLOYMENT_TYPE"),
      dataIndex: "employmentType",
      key: "employmentType",
      render: (employmentType, item) => (
        <Tag color={employmentType === "Employee" ? "cyan" : "geekblue"}>{employmentType}</Tag>
      ),
      align: "center",
    },
    {
      title: t("contractsTableColumns.POSITION"),
      dataIndex: "employmentType",
      key: "employmentType",
      render: (employmentType, item) => <Tag className="position-tag">{item.position ?? "-"}</Tag>,
      align: "center",
    },
    {
      title: t("contractsTableColumns.SALARY_OR_HOURLY_RATE"),
      dataIndex: "salary",
      render: (salary, item) =>
        `${salary !== null ? `${salary}€` : `${item.hourlyRate ? `${item.hourlyRate}€/h` : "-"}`}`,
      align: "center",
      key: "salary",
    },
    {
      title: t("contractsTableColumns.WORKKING_HOURS_PER_MONTH"),
      dataIndex: "workingHoursPerMonth",
      key: "createdAt",
      render: (workingHPerMonth, item) => item.workingHoursPerMonth ?? "-",
      align: "center",
    },
    {
      title: t("contractsTableColumns.CONTRACT"),
      dataIndex: "__typename",
      key: "startedAt",
      ellipsis: true,
      render: (typename, item) => (
        <Tooltip title="Export contract">
          <Button size="small" key="1" onClick={() => window.open(item?.url as string, "_blank")} disabled={!item?.url}>
            <Icon type="file-pdf" />
            {"Export"}
          </Button>
        </Tooltip>
      ),
      align: "right",
    },
    {
      title: t("contractsTableColumns.MORE"),
      dataIndex: "__typename",
      key: "startedAt",
      ellipsis: true,
      render: (typename, item) => <ActionsComponent item={item} />,
      align: "right",
    },
  ];

  // here is the Contracts Table
  const ContractsTable = (props: ContractsTableProps) => (
    <Table
      {...props}
      size="small"
      columns={columns(props.t)}
      pagination={{ pageSize: 20 }}
      scroll={{ x: 800 }}
      rowKey="id"
      bordered={true}
      indentSize={10}
    />
  );

  return (
    <>
      <Layout.Content className="contracts-content">
        <TitleSection
          title={t("sideMenu.CONTRACTS")}
          iconType="file-protect"
          extra={() => (
            <AccessControl userPermissions={props.permissions.contracts} allowedPermissions={"create"}>
              <Button
                icon="file-add"
                size="default"
                type="primary"
                className="add-contract"
                onClick={() => {
                  setContract(emptyContract());
                }}>
                {t("contractsTableColumns.CREATE_CONTRACT")}
              </Button>
            </AccessControl>
          )}
        />

        <div className="wrapper">
          {useHandleData(
            {
              ...contractsResult,
              loading: contractsResult.loading || employeesResult.loading,
              error: contractsResult.error || employeesResult.error,
            } as QueryResult<TGetContracts, Record<string, any>>,
            {
              loadingComponent: () => <ContractsTable loading t={t} />,
            }
          )((data) => (
            <ContractsTable
              t={t}
              dataSource={(data?.contracts || []).map((contractItem) => ({
                ...contractItem,
                permissions: props.permissions,
                user: contractItem.employee?.User,
              }))}
            />
          ))}
        </div>
      </Layout.Content>
      <ContractsForm
        refetch={[{ query: getContracts }]}
        t={t}
        contract={contract}
        setContract={setContract}
        contracts={contractsResult.data?.contracts || []}
        employees={employeesResult.data?.employees || []}
        employmentTypes={["Contractor", "Employee"]}
      />
    </>
  );
};

export default withPermissionsFnc([constants.permissionResources.CONTRACTS])(Contracts);
