import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Row,
  Upload,
} from 'antd';
import moment from 'moment';
import { TFunction } from 'i18next';
import Text from 'antd/lib/typography/Text';
import { calculateDueDate } from 'utils/date';
import { Query } from '@apollo/react-components';
import { GET_USER_PROFILE } from 'graphql/queries';

import InvoiceItemsTable from './invoiceItemsTable';

interface IProps {
  t: TFunction;
  form: any;
  isEditing?: boolean;
  userId: number;
  month?: moment.Moment;
  timesheetAttachment?: string;
  otherAttachment?: string;
  fetchInvoiceItems?: (invoiceItems: string[]) => void;
  setHasPaymentDueDateOffset: (hasOffset: boolean) => void;
}

const CreateInvoiceForm: React.FC<IProps> = ({
  t,
  form,
  userId,
  isEditing,
  month,
  timesheetAttachment,
  otherAttachment,
  fetchInvoiceItems,
  setHasPaymentDueDateOffset,
}) => {
  const { getFieldDecorator, getFieldValue } = form;
  const [totalPrice, setTotalPrice] = useState("");

  const disabledDate = (current) => {
    return current && current > moment().endOf("month");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    if (e.fileList.length > 1) e.fileList.shift();
    return e && e.fileList;
  };

  const getTotalPrice = (price: string) => {
    setTotalPrice(price);
  };

  getFieldDecorator("keys", {
    initialValue: [
      {
        name: "month",
        type: "timePickerMonth",
        initialValue: !!month ? moment.utc(month).local() : moment(),
        rules: [
          {
            required: true,
            message: t("invoices.MESSAGES.MONTH_REQUIRED"),
          },
        ],
        placeholder: t("invoices.MONTH"),
      },
      {
        name: "timesheetAttachment",
        type: "attachment",
        initialValue: !!timesheetAttachment ? timesheetAttachment : null,
        rules: [
          {
            required: true,
            message: t("invoices.MESSAGES.TIMESHEET_REQUIRED"),
          },
        ],
        placeholder: t("invoices.TIMESHEET"),
      },
      {
        name: "otherAttachment",
        type: "attachment",
        initialValue: !!otherAttachment ? otherAttachment : null,
        rules: [
          {
            required: false,
          },
        ],
        placeholder: t("invoices.OTHER_ATTACHMENT"),
      },
    ],
  });

  const keys = getFieldValue("keys");

  const formItems = keys.map((item: any, index) => {
    if (item.type === "timePickerMonth")
      return (
        <Row type="flex" justify="space-between">
          <Col span={6} key={index}>
            <Form.Item label={item.placeholder} hasFeedback key={index}>
              {form.getFieldDecorator(`${item.name}[${index}]`, {
                initialValue: item.initialValue,
                rules: item.rules,
              })(<DatePicker.MonthPicker format="MMMM YY" disabledDate={disabledDate} className="is-fullwidth" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            {totalPrice && (
              <>
                <Row type="flex" justify="end">
                  <Text>Total</Text>
                </Row>
                <Row type="flex" justify="end">
                  <Text style={{ fontSize: "30px", fontWeight: "bold" }}>{totalPrice}</Text>
                </Row>
              </>
            )}
          </Col>
        </Row>
      );
    else if (item.type === "attachment")
      return (
        <Row>
          <Col span={6} key={index}>
            <Form.Item label={item.placeholder} key={index}>
              {form.getFieldDecorator(`${item.name}[${index}]`, {
                initialValue: item.initialValue,
                rules: item.rules,
                getValueFromEvent: normFile,
              })(
                <Upload beforeUpload={() => false}>
                  <Button>
                    <Icon type="upload" /> {t("buttons.UPLOAD")}
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
      );
    return;
  });

  return (
    <Form>
      <Row gutter={16}>
        <InvoiceItemsTable
          t={t}
          userId={userId}
          isEditing={isEditing}
          fetchInvoiceItems={fetchInvoiceItems}
          getTotalPrice={getTotalPrice}
        />
        <Divider />
        {formItems}
        <Row>
          <Row style={{ marginTop: "15px" }}>
            <Text>Due Date:</Text>
          </Row>
          <Query query={GET_USER_PROFILE} fetchPolicy="cache-and-network" variables={{ userId }}>
            {({ data }) => {
              const paymentDueDateOffset = data?.User?.paymentDueDateOffset;

              setHasPaymentDueDateOffset(paymentDueDateOffset === null ? false : true);

              return (
                <Row>
                  <Text style={{ fontSize: "20px", fontWeight: "bolder" }}>
                    {moment(calculateDueDate(paymentDueDateOffset || 0)).format("DD MMMM YYYY")}
                  </Text>
                  {
                    paymentDueDateOffset === null && (
                      <Alert
                        message={t("invoices.CREATE_INVOICE.MESSAGES.MISSING_PAYMENT_DUE_DATE_OFFSET")}
                        type="warning"
                        showIcon
                      />
                    )
                  }
                </Row>
              )
            }}
          </Query>
        </Row>
      </Row>
    </Form>
  );
};

export default CreateInvoiceForm;
