import React, { Component } from "react";
import { Mutation } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Modal, Form, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { ASSIGN_USER_TO_CLIENT } from "../../../../../graphql/mutations";
import { GET_PROJECT_ASSIGNABLE_CLIENTS, GET_CLIENT_ASSIGNMENT } from "../../../../../graphql/queries";

import SelectClient from "./components/SelectClient";
interface IFormProps extends FormComponentProps {
  clientId: number;
  clientName: string;
}
interface IProps extends IFormProps, WithTranslation {}
class AssignClient extends Component<IProps> {
  public state = {
    modalIsOpen: false,
  };

  public openModal = () =>
    this.setState({
      modalIsOpen: true,
    });

  public closeModal = () =>
    this.setState({
      modalIsOpen: false,
    });

  public onChange = ({ key, value }) =>
    this.props.form.setFieldsValue({
      [key]: value,
    });

  public clearForm = () => this.props.form.resetFields();

  public render() {
    const { t } = this.props;
    return (
      <Mutation
        mutation={ASSIGN_USER_TO_CLIENT}
        refetchQueries={() => {
          return [
            {
              query: GET_CLIENT_ASSIGNMENT,
              variables: { userId: this.props.clientId },
            },
            {
              query: GET_PROJECT_ASSIGNABLE_CLIENTS,
              variables: { clientId: this.props.clientId },
            },
          ];
        }}
        update={(cache, response) => {
          this.clearForm();
          this.closeModal();
          notification.success({
            message: `${t("clientUsers.MESSAGES.ASSIGNED_USER")} ${this.props.clientName}!`,
          });
        }}>
        {(assignClient) => {
          return (
            <>
              <Button type="primary" onClick={this.openModal} style={{ marginBottom: "15px" }}>
                {t("clientUsers.ASSIGN_CLIENT_USER")}
              </Button>
              <Modal
                title={t("clientUsers.ASSIGN_CLIENT")}
                visible={this.state.modalIsOpen}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      assignClient({
                        variables: {
                          clientId: this.props.clientId,
                          ...values,
                        },
                      });
                    }
                  });
                }}
                okButtonProps={{
                  "aria-label": "ok-button",
                }}
                onCancel={this.closeModal}>
                <Form>
                  <Form.Item>
                    {this.props.form.getFieldDecorator("userId", {
                      rules: [
                        {
                          required: true,
                          message: t("clientUsers.MESSAGES.SELECT_USER"),
                        },
                      ],
                    })(
                      <SelectClient
                        projectId={this.props.clientId}
                        onChange={(value) => this.onChange({ key: "userId", value })}
                      />
                    )}
                  </Form.Item>
                </Form>
              </Modal>
            </>
          );
        }}
      </Mutation>
    );
  }
}

export default Form.create<IFormProps>()(withTranslation()(AssignClient));
