import React from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Layout, Tabs } from "antd";
import AbsencesTable from "./components/AbsencesLogsTable";

interface IProps {}
const { Content } = Layout;
const { TabPane } = Tabs;

const Absences: React.FC<IProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleSection title={t("sideMenu.ABSENCES")} iconType="bars" />
      <Content className="wrapper">
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("appConfiguration.ABSENCE_LOGS.TITLE")} key="1">
            <AbsencesTable t={t} />
          </TabPane>
        </Tabs>
      </Content>
    </>
  );
};

export default Absences;
