import React, { Fragment } from "react";
import { TFunction } from "i18next";
import { Table } from "antd";
import { Query } from "@apollo/react-components";
import { GET_PROJECT_TAGS } from "../../../../../../graphql/queries";
import TagEdit from "./TagEdit";
import TagDelete from "./TagDelete";

interface IProps {
  t: TFunction;
  projectId: number;
}

const TagsTable: React.FC<IProps> = ({ t, projectId }) => {
  const columns = [
    {
      title: t("timelogs.CREATE_TAG.TAG"),
      dataIndex: "name",
    },
    {
      title: t("timelogs.CREATE_TAG.PROJECT"),
      dataIndex: "project.name",
    },
    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row) => (
        <>
          <TagDelete t={t} tag={row} projectId={projectId} />
          <TagEdit t={t} tag={row} projectId={projectId} />
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Query query={GET_PROJECT_TAGS} variables={{ projectId }}>
        {({ loading, error, data }: any) => {
          let tags;
          if (!loading && data?.projectTags) {
            tags = data.projectTags;
          }

          if (error) {
            return <p>Error</p>;
          }

          return <Table columns={columns} bordered={true} rowKey="id" loading={loading} dataSource={tags} />;
        }}
      </Query>
    </Fragment>
  );
};

export default TagsTable;
