import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { List, Avatar, Spin, Empty, Button } from "antd";
import moment from "moment";
import { TFunction } from "i18next";
import { GET_NEW_USERS } from "graphql/queries";
import { birthdayActiveImg, birthdayImg } from "helpers/appImages";
import { getDaysFromToday } from "./helpers/birthdayHelper";
import { log } from "console";

interface IProps {
  t: TFunction;
}

const HomeBirthdays: React.FC<IProps> = ({ t }) => {
  const { data, loading, error } = useQuery(GET_NEW_USERS);
  const userDatas = data?.getNewUsers;
  const [visibleItems, setVisibleItems] = useState(3);

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }
  if (!data || !userDatas || userDatas.length === 0) {
    return <Empty />;
  }

  if (error) {
    return <p>Error</p>;
  }

  // Filter out birthdays with NaN values
  const validBirthdays = userDatas.filter((birthdayItem: any) => !isNaN(Date.parse(birthdayItem.birthday)));
  const today = moment().startOf("day");

  // Get upcoming birthdays for this year
  const upcomingBirthdays = validBirthdays.filter((birthdayItem: any) => {
    const birthday = moment(birthdayItem.birthday, "YYYY-MM-DD");
    const currentYear = moment().year();
    const birthdayThisYear = birthday.set("year", currentYear);
    return birthdayThisYear.isSameOrAfter(moment(), "day");
  });

  upcomingBirthdays.sort((a, b) => {
    const currentYear = moment().year();
    const currentBirthday = moment(a.birthday).set("year", currentYear);
    const nextBirthday = moment(b.birthday).set("year", currentYear);
    return currentBirthday.diff(nextBirthday);
  });

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  return (
    <>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={upcomingBirthdays.slice(0, visibleItems)}
        renderItem={(birthdayItem: any) => {
          const currentYear = moment().year();
          const birthday = moment(birthdayItem.birthday, "YYYY-MM-DD");
          const yearsOfCelebration = moment().year() - birthday.year();
          const daysFromToday = getDaysFromToday(birthdayItem);

          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar shape="square" src={birthday.isSame(today, "day") ? birthdayActiveImg : birthdayImg} />}
                title={`${birthdayItem.firstName} ${birthdayItem.lastName}`}
                description={`Birthday: ${birthday.set("year", currentYear).format("DD/MM/YYYY")} ${
                  birthday.isSame(today, "day")
                    ? `(🥳 Happy Birthday ${birthdayItem.firstName})  Celebrating ${yearsOfCelebration} years`
                    : `(${daysFromToday} days from today)`
                }`}
              />
            </List.Item>
          );
        }}
      />
      {upcomingBirthdays.length > visibleItems && (
        <Button size="small" onClick={handleLoadMore}>
          Load More
        </Button>
      )}
    </>
  );
};

export default HomeBirthdays;
