import React from "react";
import { Button, Popconfirm, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { TFunction } from "i18next";
import { DELETE_COMPANY_BRANCH } from "graphql/mutations";
import { GET_COMPANY_BRANCH } from "graphql/queries/Configurations";

interface IProps extends FormComponentProps {
  t: TFunction;
  branchType: any;
}

const CompanyBranchesDelete: React.FC<IProps> = ({ t, branchType }) => {
  return (
    <Mutation
      mutation={DELETE_COMPANY_BRANCH}
      refetchQueries={[
        {
          query: GET_COMPANY_BRANCH,
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.COMPANY_BRANCH_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteCompanyBranch, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteCompanyBranch({ variables: { id: branchType.id } })}>
          <Button type="danger" className="mr-10" loading={loading} icon="delete" />
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(CompanyBranchesDelete);
