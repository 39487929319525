import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Button, Form, Col, Modal, notification, Row, DatePicker, Tag, Select } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { GET_COMPANY_BRANCH, GET_HOLIDAY_TYPES } from "../../../../../graphql/queries/Configurations";
import TextArea from "antd/lib/input/TextArea";
import { CREATE_HOLIDAY_TYPE } from "graphql/mutations";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";

interface IProps extends FormComponentProps {
  t: TFunction;
}
const { Option } = Select;

const HolidayTypesCreate: React.FC<IProps> = ({ t, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const [selectedHolidays, setSelectedHolidays] = useState<string[]>([]);

  const handleDateSelection = (date) => {
    if (!selectedHolidays.includes(date)) {
      setSelectedHolidays([...selectedHolidays, moment(date).format()]);
    }
  };

  const handleTagClose = (index) => {
    const newSelectedHolidays = selectedHolidays.filter((_, i) => i !== index);
    setSelectedHolidays(newSelectedHolidays);
  };
  const { loading: loadingBranch, data } = useQuery(GET_COMPANY_BRANCH, { fetchPolicy: "no-cache" });

  return (
    <Fragment>
      <Button type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.HOLIDAY_TYPES.CREATE_HOLIDAY_TYPE")}
      </Button>
      <Mutation
        mutation={CREATE_HOLIDAY_TYPE}
        refetchQueries={[
          {
            query: GET_HOLIDAY_TYPES,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.HOLIDAY_TYPE_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createHolidayType, { loading }) => (
          <Modal
            title={t("appConfiguration.HOLIDAY_TYPES.CREATE_HOLIDAY_TYPE")}
            width={600}
            centered={true}
            okButtonProps={{
              loading: loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { date, description, CompanyBranchId } = values;

                  createHolidayType({
                    variables: {
                      CompanyBranchId,
                      description,
                      date: selectedHolidays,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
              setSelectedHolidays([]);
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.HOLIDAY_TYPES.LABELS.HOLIDAYS")}>
                    {form.getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          validator: (rule, value) => {
                            if (!value || value.length === 0) {
                              return Promise.reject(t("appConfiguration.HOLIDAY_TYPES.MESSAGES.DATE_REQUIRED"));
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ],
                    })(<DatePicker allowClear={false} style={{ width: "100%" }} onChange={handleDateSelection} />)}
                  </Form.Item>
                </Col>
                <Col span={17}>
                  {selectedHolidays.map((date, index) => (
                    <Tag
                      color="#108ee9"
                      closable
                      onClose={() => handleTagClose(index)}
                      style={{ marginBottom: 10 }}
                      key={moment(date).format("DD-MMMM-YYYY")}>
                      {moment(date).format("DD-MMMM-YYYY")}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.HOLIDAY_TYPES.LABELS.COMPANY_BRANCH")}>
                    {form.getFieldDecorator("CompanyBranchId", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.COMPANY_BRANCH_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        allowClear
                        loading={loadingBranch}
                        placeholder={
                          loadingBranch
                            ? "Loading branch..."
                            : t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.COMPANY_BRANCH")
                        }>
                        {data?.getCompanyBranches.map((branchItem) => (
                          <Option key={branchItem.id} value={branchItem.id}>
                            {branchItem.country + " / " + branchItem.city}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.HOLIDAY_TYPES.LABELS.DESCRIPTION")}>
                    {form.getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.DESCRIPTION_REQUIRED"),
                        },
                      ],
                    })(<TextArea placeholder={t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.DESCRIPTION")} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(HolidayTypesCreate);
