import React, { useState } from "react";
import { Col, DatePicker, Form, Input, Modal, notification, Row } from "antd";
import { TFunction } from "i18next";
import moment from "moment";
import { FormComponentProps } from "antd/lib/form";
import SelectLeaveType from "../../Components/MyLeaveRequests/selectLeaveType";
import SelectDeputy from "../../Components/MyLeaveRequests/selectDeputy";
import { Mutation } from "@apollo/react-components";
import { CREATE_LEAVE_REQUEST } from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS } from "../../../../graphql/queries/LeaveManagementQueries";

interface IProps extends FormComponentProps {
  visible: boolean;
  t: TFunction;
  toggle: () => void;
  resourceId: number | null;
  startDate: moment.Moment;
  endDate: moment.Moment;
  newRecord: () => void;
}

const CreateLeaveRequest: React.FC<IProps> = ({
  t,
  form,
  visible,
  toggle,
  resourceId,
  startDate,
  endDate,
  newRecord,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(moment());

  const handleCancel = () => {
    form.resetFields();
    toggle();
  };

  const disabledStartDate = (current) => {
    return (
      current < moment().endOf("day").subtract(1, "day") ||
      current.format("dddd") === "Saturday" ||
      current.format("dddd") === "Sunday"
    );
  };

  const disabledEndDate = (current) => {
    return (
      current < selectedStartDate.endOf("day") ||
      (selectedStartDate.year() === moment().year() ? current > moment().endOf("year") : null) ||
      current.format("dddd") === "Saturday" ||
      current.format("dddd") === "Sunday"
    );
  };

  return (
    <>
      <Mutation
        mutation={CREATE_LEAVE_REQUEST}
        refetchQueries={[
          {
            query: GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS,
          },
        ]}
        onCompleted={() => {
          form.resetFields();
          newRecord();
          toggle();
          notification.success({
            message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_CREATED"),
          });
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createLeaveRequest, { loading }): any => {
          return (
            <Modal
              visible={visible}
              onCancel={handleCancel}
              title={t("leaveRequests.ADD_LEAVE_REQUEST")}
              cancelButtonProps={{ disabled: loading }}
              okButtonProps={{
                "aria-label": "ok-button",
                loading,
              }}
              onOk={() => {
                form.validateFields((err, values) => {
                  if (!err) {
                    const { startDate, endDate, deputyId, comment, leaveType } = values;
                    const finalValues = {
                      startDate: startDate.format("YYYY-MM-DD"),
                      endDate: endDate.format("YYYY-MM-DD"),
                      deputyId,
                      leaveType,
                      comment,
                    };
                    createLeaveRequest({ variables: finalValues });
                  }
                });
              }}>
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={t("leaveRequests.LABELS.START_DATE")} hasFeedback={true}>
                      {form.getFieldDecorator("startDate", {
                        initialValue: moment(startDate),
                        rules: [
                          {
                            type: "object",
                            required: true,
                            message: t("leaveRequests.MESSAGES.START_DATE_REQUIRED"),
                          },
                        ],
                      })(
                        <DatePicker
                          format="DD-MM-YYYY"
                          disabledDate={disabledStartDate}
                          onChange={(val) => {
                            setSelectedStartDate(val ?? moment());
                            form.setFieldsValue({ endDate: val });
                            form.setFieldsValue({
                              deputyId: undefined,
                            });
                          }}
                          data-cy="start-date"
                          id="start-date"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("leaveRequests.LABELS.END_DATE")} hasFeedback={true}>
                      {form.getFieldDecorator("endDate", {
                        initialValue: moment(endDate),
                        rules: [
                          {
                            type: "object",
                            required: true,
                            message: t("leaveRequests.MESSAGES.END_DATE_REQUIRED"),
                          },
                        ],
                      })(
                        <DatePicker
                          disabled={!form.getFieldValue("startDate")}
                          format="DD-MM-YYYY"
                          data-cy="end-date"
                          id="end-date"
                          style={{ width: "100%" }}
                          disabledDate={disabledEndDate}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={t("leaveRequests.LABELS.LEAVE_TYPE")}>
                      {form.getFieldDecorator("leaveType", {
                        rules: [
                          {
                            required: true,
                            message: t("leaveRequests.MESSAGES.LEAVE_TYPE_REQUIRED"),
                          },
                        ],
                      })(<SelectLeaveType t={t} />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("leaveRequests.LABELS.DEPUTY")}>
                      {form.getFieldDecorator("deputyId", {
                        rules: [
                          {
                            required: true,
                            message: t("leaveRequests.MESSAGES.DEPUTY_REQUIRED"),
                          },
                        ],
                      })(
                        <SelectDeputy
                          startDate={form.getFieldValue("startDate")}
                          endDate={form.getFieldValue("endDate")}
                          t={t}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label={t("leaveRequests.LABELS.COMMENT")}>
                  {form.getFieldDecorator("comment", {})(<Input.TextArea autoSize={true} />)}
                </Form.Item>
              </Form>
            </Modal>
          );
        }}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(CreateLeaveRequest);
