import React from "react";
import { Button, Empty, Table } from "antd";
import { generateKey } from "../../../utils/listKey";
import { stringToHTML } from "../../../helpers/draftjs";
import { formatDate } from "../../../utils/date";
import StatusTag from "../../../common/LeaveRequestTag";
import { Link } from "react-router-dom";
import { TFunction } from "i18next";
import { GET_UPCOMING_LEAVE_REQUESTS } from "../../../graphql/queries/LeaveManagementQueries";
import { Query } from "@apollo/react-components";

interface IProps {
  t: TFunction;
}

const UpcomingRequests: React.FC<IProps> = ({ t }) => {
  return (
    <div className="tableWrapper">
      <Query query={GET_UPCOMING_LEAVE_REQUESTS}>
        {({ loading, error, data }: any) => {
          if (error) {
            return <div>Error</div>;
          }
          let myLeaveRequests;

          if (!loading && !!data && data.upcomingRequests.length) {
            myLeaveRequests = data.upcomingRequests.map(
              ({
                id,
                leaveRequestType: { id: leaveId, leaveTypeName },
                comment,
                startDate,
                endDate,
                duration,
                employeeStatus,
                deputyId,
                deputyStatus,
                managerStatus,
                deputy: {
                  User: { firstName, lastName },
                },
                employee: {
                  User: { firstName: employeeName, lastName: employeeLastname },
                },
              }) => ({
                id,
                leaveTypeName,
                leaveId,
                comment,
                startDate,
                endDate,
                duration,
                employeeStatus,
                deputyStatus,
                managerStatus,
                deputyId,
                deputy: `${firstName} ${lastName}`,
                employee: `${employeeName} ${employeeLastname}`,
              })
            );
          }
          return (
            <>
              {
                <Table
                  data-cy="table"
                  rowKey={() => generateKey()}
                  size="small"
                  loading={loading}
                  dataSource={myLeaveRequests}
                  expandedRowRender={(record: { comment: string }) => stringToHTML(record.comment)}
                  pagination={{ defaultPageSize: 50 }}
                  className="leave-request-table"
                  locale={{
                    emptyText: (
                      <>
                        <Empty
                          description={t("emptyDescriptions.UPCOMING_REQUESTS")}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      </>
                    ),
                  }}>
                  <Table.Column
                    title={t("leaveRequests.tableColumns.EMPLOYEE")}
                    key="employee"
                    render={({ employee }) => employee}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.START_DATE")}
                    key="startDate"
                    align="center"
                    render={({ startDate }) => formatDate(startDate)}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.END_DATE")}
                    key="endDate"
                    align="center"
                    render={({ endDate }) => formatDate(endDate)}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.DEPUTY")}
                    key="deputy"
                    align="center"
                    render={({ deputy }) => deputy}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.DEPUTY_STATUS")}
                    key="deputyStatus"
                    align="center"
                    render={({ deputyStatus }) => <StatusTag status={deputyStatus} />}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.APPROVAL_STATUS")}
                    key="managerStatus"
                    align="center"
                    render={({ managerStatus }) => <StatusTag status={managerStatus} />}
                  />
                  <Table.Column
                    title={t("leaveRequests.tableColumns.DETAILS")}
                    align="center"
                    key="status"
                    width={250}
                    className="button-actions"
                    render={({ id }) => {
                      return (
                        <>
                          <Link
                            to={{
                              pathname: `/dashboard/leave-request/${id}`,
                            }}>
                            <Button type="primary">{t("buttons.DETAILS")}</Button>
                          </Link>
                        </>
                      );
                    }}
                  />
                </Table>
              }
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default UpcomingRequests;
