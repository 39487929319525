import React, { useState } from "react";
import { Col, DatePicker, Form, Input, Modal, Row } from "antd";
import moment from "moment";
import SelectLeaveType from "./selectLeaveType";
import SelectDeputy from "./selectDeputy";
import { TFunction } from "i18next";
import { FormComponentProps } from "antd/es/form";

interface IProps extends FormComponentProps {
  t: TFunction;
  loading: boolean;
  visible: boolean;
  data: any;
  closeModal: () => void;
  updateLeaveRequest: any;
}

const EditLeaveRequestModal: React.FC<IProps> = ({
  t,
  loading,
  form,
  visible,
  data,
  closeModal,
  updateLeaveRequest,
}) => {
  const { id, startDate, endDate, leaveId, deputyId, comment } = data;
  const [selectedStartDate, setSelectedStartDate] = useState(moment());

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    return current && current < selectedStartDate.endOf("day");
  };

  return (
    <>
      <Modal
        title={t("leaveRequests.EDIT_MODAL_TITLE")}
        visible={visible}
        onCancel={() => {
          form.resetFields();
          closeModal();
        }}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{
          "aria-details": "update-timelog",
          loading,
        }}
        onOk={() => {
          form.validateFields((err, values) => {
            if (!err) {
              const finalValues = {
                startDate: values.startDate.format("YYYY-MM-DD"),
                endDate: values.endDate.format("YYYY-MM-DD"),
                deputyId: values.deputyId,
                leaveType: values.leaveType,
                comment: values.comment,
              };

              updateLeaveRequest({
                variables: { id, ...finalValues },
              });
            }
          });
        }}>
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t("leaveRequests.LABELS.START_DATE")} hasFeedback={true}>
                {form.getFieldDecorator("startDate", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: t("leaveRequests.MESSAGES.START_DATE_REQUIRED"),
                    },
                  ],
                  initialValue: moment(startDate),
                })(
                  <DatePicker
                    format="DD-MM-YYYY"
                    disabledDate={disabledStartDate}
                    onChange={(val) => {
                      setSelectedStartDate(val ?? moment());
                      form.setFieldsValue({ endDate: val });
                      form.setFieldsValue({ deputyId: undefined });
                    }}
                    data-cy="start-date"
                    id="start-date"
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("leaveRequests.LABELS.END_DATE")} hasFeedback={true}>
                {form.getFieldDecorator("endDate", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: t("leaveRequests.MESSAGES.END_DATE_REQUIRED"),
                    },
                  ],
                  initialValue: moment(endDate),
                })(
                  <DatePicker
                    disabled={!form.getFieldValue("startDate")}
                    format="DD-MM-YYYY"
                    data-cy="end-date"
                    id="end-date"
                    style={{ width: "100%" }}
                    disabledDate={disabledEndDate}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t("leaveRequests.LABELS.LEAVE_TYPE")}>
                {form.getFieldDecorator("leaveType", {
                  rules: [
                    {
                      required: true,
                      message: t("leaveRequests.MESSAGES.LEAVE_TYPE_REQUIRED"),
                    },
                  ],
                  initialValue: leaveId,
                })(<SelectLeaveType t={t} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("leaveRequests.LABELS.DEPUTY")}>
                {form.getFieldDecorator("deputyId", {
                  rules: [
                    {
                      required: true,
                      message: t("leaveRequests.MESSAGES.DEPUTY_REQUIRED"),
                    },
                  ],
                  initialValue: deputyId,
                })(
                  <SelectDeputy
                    startDate={form.getFieldValue("startDate")}
                    endDate={form.getFieldValue("endDate")}
                    t={t}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={t("leaveRequests.LABELS.COMMENT")}>
            {form.getFieldDecorator("comment", {
              initialValue: comment,
            })(<Input.TextArea autoSize={true} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditLeaveRequestModal;
