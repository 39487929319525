import * as React from "react";
import Routes from "./router";
import { withRouter } from "react-router-dom";
import { setRoute } from "./expiredRoute";
import { writePermissions, writeUserData } from "./common/AccessControl/Permissions";
import { Spin } from "antd";

interface IState {
  loading: boolean;
}

class App extends React.Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  public componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!!token) {
        await writePermissions();
        await writeUserData();

        setRoute(this.props.history);

        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.props.history.push("/login");
      localStorage.setItem("token", "");
      this.setState({
        loading: false,
      });
    }
  };

  public render() {
    return (
      <div className="App">
        {this.state.loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Spin size="large" tip="Loading..." />
          </div>
        ) : (
          <Routes />
        )}
      </div>
    );
  }
}

export default withRouter(App);
