import React, { Fragment, useState } from "react";
import { Button, Col, Icon, Modal, Popconfirm, Row, Switch, Tooltip, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { TFunction } from "i18next";
import { reviewCyclesType } from "screens/PerformanceReview/types";
import { Mutation } from "@apollo/react-components";
import { GET_REVIEW_REQUESTS } from "graphql/queries/Configurations";
import TextArea from "antd/lib/input/TextArea";
import { SUBMIT_REVIEW } from "graphql/mutations";

interface IProps extends FormComponentProps {
  t: TFunction;
  selectedReview: reviewCyclesType;
}

const LeaveReviewManager: React.FC<IProps> = ({ t, selectedReview, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [privateValue, setPrivateValue] = useState<boolean>(true);
  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const questionsJson = selectedReview?.managerReviews?.[0]?.template?.questions;
  const responsesJson = selectedReview?.managerReviews?.[0]?.responses;
  const initialResponses = responsesJson ? JSON.parse(responsesJson) : {};

  const reviewerId = selectedReview?.managerReviews?.[0]?.id;
  const isDraft = "draft";
  const isNotDraft = "submit";

  return (
    <Fragment>
      <Button
        disabled={
          selectedReview?.hasExpired ||
          selectedReview?.closedAt != null ||
          selectedReview?.managerReviews?.[0]?.submittedAt != null
        }
        size="small"
        type="primary"
        icon="reconciliation"
        onClick={toggleModal}>
        {t("appConfiguration.PERFORMANCE_REVIEW.LABELS.LEAVE_YOUR_REVIEW")}
      </Button>
      <Mutation
        mutation={SUBMIT_REVIEW}
        refetchQueries={[
          {
            query: GET_REVIEW_REQUESTS,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.REVIEW_SUMBIT"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(submitReview) => (
          <Modal
            title={`${t("appConfiguration.PERFORMANCE_REVIEW.LEAVE_YOUR_REVIEW_FOR")} ${selectedReview?.Employee}`}
            width={900}
            centered={true}
            visible={modalOpen}
            okText={"Submit"}
            footer={[
              <div key="footer" className="sticky-footer">
                <Button key="cancel" onClick={toggleModal}>
                  Cancel
                </Button>
                <span>
                  <Switch
                    key="switch"
                    checkedChildren={`Private for ${selectedReview?.Employee}`}
                    unCheckedChildren={`Public for ${selectedReview?.Employee}`}
                    defaultChecked
                    onChange={setPrivateValue}
                  />
                  <Tooltip title={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.ANSWER_PRIVATE_INFO")}>
                    <Icon style={{ marginBottom: -10, marginLeft: 5, fontSize: 15 }} type="question-circle" />
                  </Tooltip>
                </span>
                <Button
                  key="draft"
                  style={{ backgroundColor: "orange", color: "white" }}
                  onClick={() =>
                    form.validateFields((err, values) => {
                      if (!err) {
                        const newResponses = Object.keys(values).reduce((acc, questionKey) => {
                          acc[questionKey] = values[questionKey];
                          return acc;
                        }, {});

                        submitReview({
                          variables: {
                            ReviewId: reviewerId,
                            isPrivate: privateValue,
                            status: isDraft,
                            responses: JSON.stringify(newResponses),
                          },
                        });
                      }
                    })
                  }>
                  Save as draft
                </Button>
                <Popconfirm
                  title={
                    <span>
                      {`${t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.SUBMIT_CONFIRM")}`}
                      <br />
                      {`${t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.SUBMIT_PERMANENT")}`}
                    </span>
                  }
                  onConfirm={() =>
                    form.validateFields((err, values) => {
                      if (!err) {
                        const newResponses = Object.keys(values).reduce((acc, questionKey) => {
                          acc[questionKey] = values[questionKey];
                          return acc;
                        }, {});

                        submitReview({
                          variables: {
                            ReviewId: reviewerId,
                            isPrivate: privateValue,
                            status: isNotDraft,
                            responses: JSON.stringify(newResponses),
                          },
                        });
                      }
                    })
                  }
                  okText="Yes"
                  cancelText="No">
                  <Button key="submit" type="primary">
                    Submit
                  </Button>
                </Popconfirm>
              </div>,
            ]}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  {Object.keys(JSON.parse(questionsJson)).map((questionKey) => (
                    <Form.Item label={`Q${Number(questionKey) + 1}: ${JSON.parse(questionsJson)[questionKey]} `}>
                      {form.getFieldDecorator(`${Number(questionKey)}`, {
                        rules: [
                          {
                            required: false,
                            type: "string",
                          },
                        ],
                        initialValue: initialResponses[questionKey] || "", // Set the initial value based on responsesJson
                      })(
                        <TextArea
                          rows={8}
                          placeholder={`${t("appConfiguration.PERFORMANCE_REVIEW.LEAVE_YOUR_ANSWER_FOR_QUESTION")} ${
                            Number(questionKey) + 1
                          }`}
                        />
                      )}
                    </Form.Item>
                  ))}
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(LeaveReviewManager);
