import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Select, Table, Tag, Tooltip } from "antd";
import { Query } from "@apollo/react-components";
import moment from "moment";
import { GET_EMPLOYEE_ABSENCE_ABSENCE_FOR_ADMIN } from "graphql/queries/Configurations";
import { getPartForFirstDay, getPartForOneDay, getPartForSecondDay } from "./helpers/absenceHelpers";
import { getStatusColor, getStatusText } from "helpers/approveStatusAndAbsence";
import { useQuery } from "@apollo/react-hooks";
import { absenceLogsHistoryType } from "screens/AbsenceBalanceHistory/components/helpers/absenceHistoryTypes";

interface IProps {
  t: TFunction;
}
const { Option } = Select;

const AbsencesTable: React.FC<IProps> = ({ t }) => {
  const [year, setYear] = useState(moment().format("YYYY")); // Initialize with current year

  const generateYearOptions = () => {
    const currentYear = parseInt(moment().format("YYYY"));
    const previousYears = Array.from(
      { length: 50 }, // Adjust the number of previous years as needed
      (_, index) => (currentYear - index - 1).toString()
    );
    return previousYears.map((yearOption) => (
      <Option key={yearOption} value={yearOption}>
        {yearOption}
      </Option>
    ));
  };

  const { data } = useQuery(GET_EMPLOYEE_ABSENCE_ABSENCE_FOR_ADMIN);

  const userOfRequestOptions = Array.from(
    new Set<string>(
      data?.getAllEmployeesAbsenceRequestsForAdmin?.map(
        (item) => item?.employee?.firstName + " " + item?.employee?.lastName
      )
    )
  );

  const userOfRequestFilterOptions = userOfRequestOptions.map((option) => ({
    text: option,
    value: option,
  }));
  const onFilter = (value, record) => {
    const fullName = record.employee.firstName + " " + record.employee.lastName;
    return fullName.toLowerCase().includes(value.toLowerCase());
  };

  const expandedRowRender = (record) => <p style={{ margin: 0, fontSize: 15 }}>{record.description}</p>;

  const columns = [
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.CREATED_AT"),
      dataIndex: "createdAt",
      render: (createdAt) => <p>{moment(createdAt).format("DD/MM/YYYY")}</p>,
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.ABSENCE_TYPE"),
      dataIndex: "absenceType",
      render: (absenceType) => <p>{absenceType?.absenceTypeName}</p>,
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.USER_NAME"),
      dataIndex: "employee",
      render: (employee) => <p>{`${employee?.firstName} ${employee?.lastName}`}</p>,
      filters: userOfRequestFilterOptions,
      onFilter: onFilter,
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.FIRST_DAY_ABSENCE"),
      dataIndex: "firstDay",
      render: (firstDay) => (
        <span>
          <p style={{ marginBottom: -2 }}>{`${firstDay[0]}`}</p>
          <p style={{ marginBottom: -4 }}>{`${firstDay[1]}`}</p>
        </span>
      ),
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.LAST_DAY_ABSENCE"),
      dataIndex: "secondDay",
      render: (secondDay) => (
        <span>
          <p style={{ marginBottom: -2 }}>{`${secondDay[0]}`}</p>
          <p style={{ marginBottom: -4 }}>{`${secondDay[1]}`}</p>
        </span>
      ),
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.DURATION"),
      dataIndex: "duration",
      render: (duration) => <p>{`${duration !== 1 ? duration + " days" : duration + " day"}`}</p>,
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.MANAGER_STATUS"),
      dataIndex: "managerNameAndStatus",
      render: (managerNameAndStatus) => (
        <Tooltip title={getStatusText(managerNameAndStatus[1])}>
          <p style={{ color: `${getStatusColor(managerNameAndStatus[1])}` }}>{managerNameAndStatus[0]}</p>
        </Tooltip>
      ),
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.REPLACER_STATUS"),
      dataIndex: "replacerNameAndStatus",
      render: (replacerNameAndStatus) => (
        <Tooltip title={getStatusText(replacerNameAndStatus[1])}>
          <p style={{ color: `${getStatusColor(replacerNameAndStatus[1])}` }}>{replacerNameAndStatus[0]}</p>
        </Tooltip>
      ),
    },
    {
      title: t("appConfiguration.ABSENCE_LOGS.LABELS.APPROVE_STATUS"),
      dataIndex: "approveStatus",
      render: (approveStatus) => <Tag color={getStatusColor(approveStatus)}>{getStatusText(approveStatus)}</Tag>,
    },
  ];

  return (
    <Fragment>
      <Select value={year} onChange={(value) => setYear(value)} style={{ marginBottom: 16, width: 100 }}>
        <Select.Option value={moment().format("YYYY")}>{moment().format("YYYY")}</Select.Option>
        {generateYearOptions()}
      </Select>
      <Query query={GET_EMPLOYEE_ABSENCE_ABSENCE_FOR_ADMIN}>
        {({ loading, error, data }: any) => {
          let absenceLogTypes: any = [];

          if (!loading && data?.getAllEmployeesAbsenceRequestsForAdmin) {
            absenceLogTypes = data.getAllEmployeesAbsenceRequestsForAdmin
              .filter((log: absenceLogsHistoryType) => moment(log.createdAt).format("YYYY") === year)
              .map((log: absenceLogsHistoryType) => {
                return {
                  ...log,
                  managerNameAndStatus: [`${log?.manager.firstName} ${log?.manager.lastName}`, log?.approvedByManager],
                  replacerNameAndStatus: [
                    `${log?.replacer.firstName} ${log?.replacer.lastName}`,
                    log?.approvedByReplacer,
                  ],

                  firstDay: [
                    moment(log?.startDate).format("DD/MM/YYYY"),
                    `${
                      log?.datesOfAbsence.length <= 0
                        ? getPartForOneDay(log?.isStartDateHalf, log?.isEndDateHalf)
                        : getPartForFirstDay(log?.isStartDateHalf)
                    }`,
                  ],
                  secondDay: [
                    moment(log?.endDate).format("DD/MM/YYYY"),
                    `${
                      log?.datesOfAbsence.length <= 0
                        ? getPartForOneDay(log?.isStartDateHalf, log?.isEndDateHalf)
                        : getPartForSecondDay(log?.isEndDateHalf)
                    }`,
                  ],
                };
              });
            // Apply year filter
            if (year) {
              absenceLogTypes = absenceLogTypes.filter((log: any) => moment(log?.createdAt).format("YYYY") === year);
            }
          }

          if (error) {
            return <p>Error</p>;
          }

          return (
            <Table
              pagination={{ pageSize: 20 }}
              size="small"
              columns={columns}
              bordered={true}
              rowKey="id"
              loading={loading}
              dataSource={absenceLogTypes}
              expandedRowRender={expandedRowRender}
            />
          );
        }}
      </Query>
    </Fragment>
  );
};

export default AbsencesTable;
