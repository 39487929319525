import React, { Component } from "react";
import { Table } from "antd";
import TitleSection from "../../common/SectionTitle/title";
import { generateKey } from "../../utils/listKey";
import { withApollo, WithApolloClient } from "@apollo/react-hoc";
import { Query } from "@apollo/react-components";
import { GET_COMPANIES } from "../../graphql/queries";
import { withTranslation, WithTranslation } from "react-i18next";
import { renderDate } from "../../utils/date";
import CreateCompany from "./components/addCompany";
type IProps = WithTranslation;
class View extends Component<WithApolloClient<IProps>> {
  public render() {
    return (
      <div className="companies">
        <TitleSection title={"Companies"} iconType="reconciliation" />
        <div className="wrapper">
          <CreateCompany />
          <Query query={GET_COMPANIES}>
            {(state) => {
              const loading = state?.loading;
              const data = state?.data;
              const error = state?.error;
              let companies;
              if (!loading && !!data && data.companies) {
                companies = data.companies.map(({ id, name, createdAt }) => ({
                  id,
                  name,
                  createdAt,
                }));
              }
              if (error) {
                return <div>ERROR</div>;
              }

              return (
                <>
                  {
                    <Table
                      bordered={false}
                      size="small"
                      dataSource={companies}
                      loading={loading}
                      rowKey={() => generateKey()}
                      pagination={{ defaultPageSize: 50 }}>
                      <Table.Column title={"Company id"} key="id" render={({ id }) => id} />
                      <Table.Column title={"Company Name"} key="companyName" render={({ name }) => name} />
                      <Table.Column
                        title={"Created Date"}
                        key="createdAt"
                        // width={150}
                        render={({ createdAt }) => renderDate(createdAt)}
                      />
                    </Table>
                  }
                </>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
  public handleChange = (filters) => {
    this.setState({
      filteredInfo: filters,
    });
  };
}

export default withTranslation()(withApollo<WithTranslation>(View));
