export const calculateSubmittedPercentage = (rowData) => {
  let totalReviews = 0;
  let submittedReviewsCount = 0;
  const selfReviews = rowData?.selfReviews;
  const peerReviews = rowData?.peerReviews;
  const managerReviews = rowData?.managerReviews;

  if (rowData && selfReviews && peerReviews && managerReviews) {
    // Calculate the lengths
    const selfReviewsLength = selfReviews.length;
    const peerReviewsLength = peerReviews.length;
    const managerReviewsLength = managerReviews.length;

    // Calculate the total number of reviews
    totalReviews = selfReviewsLength + peerReviewsLength + managerReviewsLength;

    // Calculate the submitted reviews count
    const allReviews = [...selfReviews, ...peerReviews, ...managerReviews];
    submittedReviewsCount = allReviews.reduce((count, review) => count + (review.submittedAt !== null ? 1 : 0), 0);
  }

  // Calculate the percentage of submitted reviews
  const submittedPercentage = (submittedReviewsCount / totalReviews) * 100;

  return submittedPercentage.toFixed(2);
};
