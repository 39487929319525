import * as React from "react";
import { Form, Row, Input, Col, Button, notification, Icon, Divider } from "antd";
import { Mutation } from "@apollo/react-components";
import { UPDATE_USER } from "../../graphql/mutations";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormComponentProps } from "antd/lib/form";

const renderPasswordForm = (props: WithTranslation & FormComponentProps) => {
  const { form } = props;
  const { t } = props;
  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback(t("login.VALIDATION.NOT_MATCH_PASSWORD"));
    } else {
      callback();
    }
  };
  return (
    <div className="password-form">
      <Col offset={0}>
        <label style={{ fontSize: 20 }} className="label">
          {t("userProfile.PRIVACY_SETTINGS")} <Icon type="lock" style={{ color: "#1890ff", marginLeft: "5px" }} />
        </label>
        <Divider />
      </Col>
      <Form className="form">
        <Row>
          <Col span={15} offset={0} className="input-col">
            <Form.Item label={t("userProfile.OLD_PASSWORD")}>
              {form.getFieldDecorator("oldPassword", {
                rules: [
                  {
                    required: true,
                    message: t("userProfile.VALIDATION_MESSAGES.CONFIRM_PASSWORD"),
                    min: 6,
                    max: 15,
                  },
                ],
              })(<Input type="password" placeholder={t("userProfile.PLACEHOLDERS.OLD_PASSWORD")} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15} offset={0} className="input-col">
            <Form.Item label={t("userProfile.CHANGE_PASSWORD")} hasFeedback={true}>
              {form.getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: t("userProfile.VALIDATION_MESSAGES.PASSWORD_REQURIED"),
                    min: 6,
                    max: 15,
                  },
                ],
              })(<Input.Password placeholder={t("userProfile.PLACEHOLDERS.NEW_PASSWORD")} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15} offset={0} className="input-col">
            <Form.Item label={t("userProfile.CONFIRM_PASSWORD")} hasFeedback={true}>
              {form.getFieldDecorator("confirmedPassword", {
                rules: [
                  {
                    required: true,
                    message: t("userProfile.VALIDATION_MESSAGES.CONFIRM_PASSWORD"),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input.Password placeholder={t("userProfile.PLACEHOLDERS.CONFIRM_NEW_PASSWORD")} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={0} className="submit-col">
            <Form.Item>
              <Mutation
                mutation={UPDATE_USER}
                onCompleted={() => {
                  notification.success({
                    message: t("userProfile.MESSAGES.UPDATED_PASSWORD"),
                  });
                }}
                onError={() => {
                  notification.error({
                    message: "Old password does not match!",
                  });
                }}>
                {(updateUser, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      icon="save"
                      loading={loading}
                      onClick={() => {
                        form.validateFields((err, values) => {
                          if (!err) {
                            const variables = values;
                            updateUser({
                              variables,
                            });
                          }
                        });
                      }}>
                      {t("userProfile.SAVE")}
                    </Button>
                  );
                }}
              </Mutation>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(withTranslation()(renderPasswordForm));
