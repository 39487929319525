import React, { Component } from "react";
import { Button, Form, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { withTranslation, WithTranslation } from "react-i18next";
import { UPDATE_LEAVE_REQUEST } from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_LEAVE_REQUESTS_BY_ROLE } from "../../../../graphql/queries/LeaveManagementQueries";
import EditLeaveRequestModal from "./editLeaveRequestModal";

interface IProps extends FormComponentProps {
  id: number;
  disable: any;
  selectedYear: any;
  data: {
    startDate: Date;
    endDate: Date;
    comment: string;
    deputyId: number;
    leaveId: number;
  };
}

class EditLeaveRequest extends Component<IProps & WithTranslation> {
  public state = {
    modalIsOpen: false,
  };

  public openModal = () =>
    this.setState({
      modalIsOpen: true,
    });

  public closeModal = () => this.setState({ modalIsOpen: false });

  public render() {
    const { t } = this.props;
    return (
      <div className="action-button">
        <Button
          icon="edit"
          onClick={this.openModal}
          disabled={this.props.disable}
          data-cy="edit"
          className="action-button"
          htmlType="reset"
          type="dashed">
          {t("buttons.EDIT")}
        </Button>

        <Mutation
          mutation={UPDATE_LEAVE_REQUEST}
          refetchQueries={[
            {
              query: GET_LEAVE_REQUESTS_BY_ROLE,
              variables: { leaveRequestRole: "employee", year: this.props.selectedYear },
            },
          ]}
          onCompleted={() => {
            this.closeModal();
            notification.success({
              message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_UPDATED"),
            });
          }}
          onError={(err) => {
            this.props.form.resetFields();
            this.closeModal();
            notification.error({
              message: err.message.split(":")[1],
            });
          }}>
          {(updateLeaveRequest, { loading }) => (
            <>
              <EditLeaveRequestModal
                t={t}
                loading={loading}
                visible={this.state.modalIsOpen}
                data={{ ...this.props.data, id: this.props.id }}
                closeModal={this.closeModal}
                updateLeaveRequest={updateLeaveRequest}
                form={this.props.form}
              />
            </>
          )}
        </Mutation>
      </div>
    );
  }
}

export default Form.create<IProps>()(withTranslation()(EditLeaveRequest));
