import { Table } from "antd";
import React from "react";

interface IProps {
  loading: boolean;
  data: any;
  selectedYear: number;
  selectedMonthOption: number;
}

function MonthlyUtilizationRateTable(props: IProps) {
  const { loading, data } = props;

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Non Billed Hours",
      dataIndex: "nonBilledHours",
      key: "nonBilledHours",
    },
    {
      title: "Billed Hours",
      dataIndex: "billedHours",
      key: "billedHours",
    },
    {
      title: "Hours Available",
      dataIndex: "availableHours",
      key: "availableHours",
    },
    {
      title: "Utilization Rate",
      dataIndex: "utilizationRate",
      key: "utilizationRate",
    },
  ];

  return (
    <div className={"monthlyUtilizationTable"}>
      <Table
        loading={loading || !data}
        columns={columns}
        dataSource={loading || !data ? [] : data.getCalculatedUtilizationRateForEachEmployee}
        pagination={false}
      />
    </div>
  );
}

export default React.memo(MonthlyUtilizationRateTable);
