import React from "react";
import { Divider, List, Tag } from "antd";
import { absenceLogsHistoryType } from "./helpers/absenceHistoryTypes";
import moment from "moment";
import { getStatusColor, getStatusText } from "helpers/approveStatusAndAbsence";
import CalendarsAbsenceLogDetails from "screens/Calendars/components/CalendarsAbsenceLogDetails";

const AbsenceLogsHistory = (t, selectedBalance) => {
  // Filter logs with deletedAt null and sort the remaining logs based on createdAt in ascending order
  const sortedLogs = selectedBalance.logs.sort(
    (a: absenceLogsHistoryType, b: absenceLogsHistoryType) =>
      moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
  );

  return (
    <div>
      <h4>{`${selectedBalance?.absenceTypes?.[0]?.absenceTypeName} logs history`}</h4>
      <List
        dataSource={sortedLogs}
        renderItem={(log: absenceLogsHistoryType) => {
          const duration = log?.duration;
          const startDate = log?.startDate;
          const endDate = log?.endDate;

          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Tag
                    style={{ marginTop: 10 }}
                    color={log?.deletedAt != null ? "red" : log?.approveStatus === true ? "#e91a2b" : "#ff9800"}>
                    {log?.deletedAt != null
                      ? `${duration > 1 ? duration + " days" : duration + " day"}`
                      : log?.approveStatus === true
                      ? `- ${duration > 1 ? duration + " days" : duration + " day"}`
                      : `${duration > 1 ? duration + " days" : duration + " day"}`}
                  </Tag>
                }
                title={
                  <span>
                    {moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY")} {"   "}
                    {
                      <Tag color={log?.deletedAt != null ? "red" : getStatusColor(log?.approveStatus)}>
                        {log?.deletedAt != null ? `Deleted` : getStatusText(log?.approveStatus)}
                      </Tag>
                    }
                  </span>
                }
                description={<span>{`Requested at: ${moment(log?.createdAt).format("DD/MM/YYYY HH:mm dddd")}`}</span>}
              />
              <Divider type="vertical" style={{ height: 50 }} />
              <div className="button-delete-log-container">
                <CalendarsAbsenceLogDetails t={t} absenceLogItem={log} buttonTheme={false} />
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default AbsenceLogsHistory;
