import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Spin, Badge } from "antd";
import { TFunction } from "i18next";
import { GET_REVIEW_TOTAL_REQUESTS } from "graphql/queries/Configurations";
import { Link } from "react-router-dom";
import { getUserData } from "common/AccessControl/Permissions";

interface IProps {
  t: TFunction;
}

const HomeReviewTotalRequests: React.FC<IProps> = ({ t }) => {
  const { data, loading, error } = useQuery(GET_REVIEW_TOTAL_REQUESTS);

  const selfReview = data?.getTotalReviewRequests?.totalSelfReviews;
  const peerReview = data?.getTotalReviewRequests?.totalPeerReviews;
  const managerReview = data?.getTotalReviewRequests?.totalManagerReviews;

  const currentUserRole = getUserData();
  const isAdmin = currentUserRole?.roles?.some((role) => role.name === "Admin");

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }

  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      <div style={{ display: "flex", gap: 30, marginTop: 10, marginBottom: -10 }}>
        <Link
          style={isAdmin ? { opacity: 0.6, cursor: "not-allowed" } : {}}
          to={isAdmin ? "#" : `performance-review/${1}`}>
          <Badge
            style={{ marginRight: -5, fontSize: 8, padding: 0, lineHeight: "15px", height: 15, minWidth: 15 }}
            showZero
            count={selfReview}>
            <p>{t("appConfiguration.PERFORMANCE_REVIEW.SELF_REVIEW_REQUESTS")}</p>
          </Badge>
        </Link>
        <Link to={`performance-review/${2}`}>
          <Badge
            style={{ marginRight: -5, fontSize: 8, padding: 0, lineHeight: "15px", height: 15, minWidth: 15 }}
            showZero
            count={peerReview}>
            <p>{t("appConfiguration.PERFORMANCE_REVIEW.PEER_REVIEW_REQUESTS")}</p>
          </Badge>
        </Link>
        <Link to={`performance-review/${3}`}>
          <Badge
            style={{ marginRight: -5, fontSize: 8, padding: 0, lineHeight: "15px", height: 15, minWidth: 15 }}
            showZero
            count={managerReview}>
            <p>{t("appConfiguration.PERFORMANCE_REVIEW.MANAGER_REVIEW_REQUESTS")}</p>
          </Badge>
        </Link>
      </div>
    </>
  );
};

export default HomeReviewTotalRequests;
