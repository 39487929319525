import * as React from "react";
import { Card, Col, Icon, Row } from "antd";
import { managementMenu, officeSettingsMenu } from "screens/Dashboard/SideMenu/MenuItems";
import { Link } from "react-router-dom";
interface IProps {}

const GeneralSettingsItems: React.FC<IProps> = () => {
  return (
    <div style={{ padding: "30px" }}>
      <Row gutter={10}>
        {officeSettingsMenu.map((officeItem) => (
          <Col span={6}>
            <Card
              style={{ height: "17rem" }}
              title={officeItem.languageKey}
              extra={<Icon type={officeItem.iconName} style={{ fontSize: "25px" }} />}
              bordered={false}>
              <Link to={officeItem.link}>
                <Icon type={officeItem.iconName} style={{ paddingRight: "5px" }} /> {officeItem.languageKey}
              </Link>
            </Card>
          </Col>
        ))}
        <Col span={6}>
          <Card
            title={managementMenu[0].languageKey}
            extra={<Icon type={managementMenu[0].iconName} style={{ fontSize: "25px", paddingRight: "8px" }} />}
            bordered={false}>
            {managementMenu[0].item.map((manageItem) => (
              <Col style={{ paddingBottom: "20px" }}>
                <Link key={manageItem.keyItem} to={manageItem.link}>
                  <Icon type={manageItem.iconName} style={{ paddingRight: "5px" }} /> {manageItem.languageKey}
                </Link>
              </Col>
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default GeneralSettingsItems;
