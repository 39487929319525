import * as React from "react";
import { Button, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { withApollo, WithApolloClient } from "@apollo/react-hoc";
import { GET_ROLE_PERMISSIONS } from "../../../../graphql/queries";
import { UPDATE_ROLE } from "../../../../graphql/mutations";
import { formatPermissions, removeTypeName } from "../../../../helpers/permissions";
import PermissionsTable from "./permissionsTable";
import { AccessControl } from "common/AccessControl";
import { withPermissions } from "decorators/permissions";
import constants from "../../../../constants/index";
import Permissions from "../../../../constants/viewPermissions";
import { getNewPermissions } from "../../../../helpers/permissions";
import { withTranslation, WithTranslation } from "react-i18next";
interface IProps extends WithTranslation {
  roleId: number;
  permissions?: any;
}

interface IState {
  permissions: any;
  loading: boolean;
  disableSaveChanges: boolean;
}
@withPermissions([constants.permissionResources.ROLES])
class RoleCollapedView extends React.Component<WithApolloClient<IProps>, IState> {
  constructor(props) {
    super(props);
    this.state = {
      permissions: {} as any,
      loading: true,
      disableSaveChanges: true,
    };
  }
  public componentDidMount = () => {
    this.props.client.query({ query: GET_ROLE_PERMISSIONS, variables: { id: this.props.roleId } }).then((response) =>
      this.setState({
        loading: false,
        permissions: removeTypeName(response.data.role.permissions),
      })
    );
  };
  public render() {
    return (
      <div>
        <PermissionsTable
          onChange={(val, resource, type) => this.onChange(val, resource, type)}
          permissions={formatPermissions(this.state.permissions)}
          loading={this.state.loading}
        />
        {this.renderSaveChanges(this.props.roleId)}
      </div>
    );
  }
  public renderSaveChanges = (roleId) => {
    return (
      <AccessControl
        userPermissions={this.props.permissions.roles}
        allowedPermissions={Permissions.BUTTONS.UPDATE_ROLE_PERMISSIONS}>
        {this.renderSaveChangesButton(roleId)}
      </AccessControl>
    );
  };
  public onChange = (value, resource, type) => {
    const newPermissions = getNewPermissions(this.state.permissions, resource, type, value);

    if (this.state.disableSaveChanges) {
      this.setState({
        disableSaveChanges: false,
        permissions: newPermissions,
      });
    }
  };
  public renderSaveChangesButton = (roleId) => {
    const { t } = this.props;
    return (
      <Mutation
        mutation={UPDATE_ROLE}
        refetchQueries={[{ query: GET_ROLE_PERMISSIONS, variables: { id: roleId } }]}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}
        onCompleted={() => {
          notification.success({
            message: t("messages.PERMISSIONS_SAVED"),
          });
          this.setState({
            disableSaveChanges: true,
          });
        }}>
        {(updateRole, { loading }) => (
          <div className="save-permissions">
            <Button
              type="primary"
              disabled={this.state.disableSaveChanges}
              loading={loading}
              onClick={() => this.onClick(updateRole)}
              className="save-button">
              {t("buttons.SAVE_CHANGES")}
            </Button>
          </div>
        )}
      </Mutation>
    );
  };
  public onClick = (updateRole) => {
    updateRole({
      variables: {
        permissions: this.state.permissions,
        roleId: this.props.roleId,
      },
    });
  };
}

export default withTranslation()(withApollo<IProps>(RoleCollapedView));
