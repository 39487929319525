import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Button, Form, Col, Input, Modal, notification, Row, Checkbox, Select, TimePicker, Switch } from "antd";
import { Mutation } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { GET_COMPANY_BRANCH } from "../../../../../graphql/queries/Configurations";
import { SelectValue } from "antd/lib/select";
import countriesAndCities from "../../../../../constants/countries.json";
import moment from "moment";
import { CREATE_COMPANY_BRANCH } from "graphql/mutations";

interface IProps extends FormComponentProps {
  t: TFunction;
}

const CompanyBranchesCreate: React.FC<IProps> = ({ t, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const CheckboxGroup = Checkbox.Group;
  const { Option } = Select;
  const plainOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const [selectedCountry, setSelectedCountry] = useState<SelectValue>();
  const [selectedCity, setSelectedCity] = useState<SelectValue>();
  const [filteredCities, setFilteredCities] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [startOfWorkDayValue, setStartOfWorkDayValue] = useState<moment.Moment>(moment("09:00"));
  const [endOfWorkDayValue, setEndOfWorkDayValue] = useState<moment.Moment>(moment("17:00"));
  const [officeType, setOfficeType] = useState(false);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    const cities = countriesAndCities.find((country) => country.country === value)?.cities || [];
    setFilteredCities(cities);
    if (selectedCity) {
      setSelectedCity("");
    }
  };
  const countriesSortedAlphabetically = countriesAndCities.map((country) => country.country).sort();

  const onChange = (list) => {
    setCheckedList(list);
    if (list) {
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
    }
  };

  const onCheckAllChange = (e) => {
    const newCheckedList = e.target.checked ? plainOptions : [];
    setCheckedList(newCheckedList);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Fragment>
      <Button type="primary" style={{ marginBottom: "16px" }} onClick={toggleModal}>
        {t("appConfiguration.COMPANY_BRANCHES.CREATE_COMPANY_BRANCH")}
      </Button>
      <Mutation
        mutation={CREATE_COMPANY_BRANCH}
        refetchQueries={[
          {
            query: GET_COMPANY_BRANCH,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.COMPANY_BRANCH_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createLeaveType, { loading }) => (
          <Modal
            title={t("appConfiguration.COMPANY_BRANCHES.CREATE_COMPANY_BRANCH")}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { country, city, iban, nipt, accountNumber, contactNumber } = values;

                  createLeaveType({
                    variables: {
                      country,
                      city,
                      iban,
                      nipt,
                      accountNumber,
                      contactNumber,
                      startOfWorkDay: startOfWorkDayValue.format("HH:mm"),
                      endOfWorkDay: endOfWorkDayValue.format("HH:mm"),
                      daysOfWork: checkedList,
                      virtual: officeType,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
              setFilteredCities([]);
              setCheckAll(false);
              setCheckedList([]);
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={30}>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.COUNTRY")}>
                    {form.getFieldDecorator("country", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.COUNTRY_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        allowClear
                        placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.COUNTRY")}
                        onChange={handleCountryChange}
                        style={{ width: 200 }}
                        value={selectedCountry}>
                        {countriesSortedAlphabetically.map((country) => (
                          <Option key={country} value={country}>
                            {country}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.CITY")}>
                    {form.getFieldDecorator("city", {
                      rules: [
                        {
                          required: true,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.CITY_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        allowClear
                        placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.CITY")}
                        onChange={(value) => setSelectedCity(value)}
                        value={selectedCity}
                        style={{ width: 200 }}>
                        {filteredCities.map((city) => (
                          <Option key={city} value={city}>
                            {city}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.IBAN")}>
                    {form.getFieldDecorator("iban", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.IBAN_REQUIRED"),
                        },
                      ],
                    })(<Input allowClear placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.IBAN")} />)}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.TAX_ID")}>
                    {form.getFieldDecorator("nipt", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.TAX_ID_REQUIRED"),
                        },
                      ],
                    })(<Input allowClear placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.TAX_ID")} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.ACCOUNT_NUMBER")}>
                    {form.getFieldDecorator("accountNumber", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.ACCOUNT_NUMBER_REQUIRED"),
                        },
                      ],
                    })(
                      <Input
                        allowClear
                        placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.ACCOUNT_NUMBER")}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.CONTACT_NUMBER")}>
                    {form.getFieldDecorator("contactNumber", {
                      rules: [
                        {
                          required: false,
                          type: "string",
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.CONTACT_NUMBER_REQUIRED"),
                        },
                      ],
                    })(
                      <Input
                        allowClear
                        placeholder={t("appConfiguration.COMPANY_BRANCHES.PLACE_HOLDERS.CONTACT_NUMBER")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginBottom: 10 }}>
                <Form.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.DAYS_OF_WORK")}>
                  {form.getFieldDecorator("daysOfWork", {
                    rules: [
                      {
                        required: true,
                        message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.DAYS_OF_WORK_REQUIRED"),
                      },
                    ],
                  })(
                    <div>
                      <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          Check all
                        </Checkbox>
                      </div>
                      <br />
                      <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                    </div>
                  )}
                </Form.Item>
              </Row>
              <Row gutter={10}>
                <Col span={7}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.START_OF_THE_DAY")}>
                    {form.getFieldDecorator("startOfWorkDay", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.START_OF_THE_DAY_REQUIRED"),
                        },
                      ],
                    })(<TimePicker allowClear format={"HH:mm"} onChange={setStartOfWorkDayValue} />)}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.COMPANY_BRANCHES.LABELS.END_OF_THE_DAY")}>
                    {form.getFieldDecorator("endOfWorkDay", {
                      rules: [
                        {
                          required: true,
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.END_OF_THE_DAY_REQUIRED"),
                        },
                      ],
                    })(<TimePicker allowClear format={"HH:mm"} onChange={setEndOfWorkDayValue} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7}>
                  <Form.Item label={t("appConfiguration.COMPANY_BRANCHES.LABELS.OFFICE_TYPE")}>
                    {form.getFieldDecorator("virtual", {
                      rules: [
                        {
                          required: false,
                          message: t("appConfiguration.COMPANY_BRANCHES.MESSAGES.END_OF_THE_DAY_REQUIRED"),
                        },
                      ],
                    })(
                      <Switch
                        onChange={setOfficeType}
                        checkedChildren={t("appConfiguration.COMPANY_BRANCHES.LABELS.VIRTUAL_OFFICE")}
                        unCheckedChildren={t("appConfiguration.COMPANY_BRANCHES.LABELS.PHYSICAL_OFFICE")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};

export default Form.create<IProps>()(CompanyBranchesCreate);
