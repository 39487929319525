import React, { useState } from "react";
import "antd/dist/antd.css";
import { Col, Form, Row, Select, Skeleton, Radio, Button, notification } from "antd";
import { TFunction } from "i18next";
import { FormComponentProps } from "antd/lib/form";
import { GET_REPORT_FILTERS, EXPORT_TIMELOGS_REPORT } from "graphql/queries";
import { Query } from "@apollo/react-components";
import DateRangePicker from "../../../common/Form/DateRangePicker";
import client from "../../../graphql/ApolloClient";
import { exportToCsv, exportToPdf } from "../../../helpers/generateDocument";

interface IProps extends FormComponentProps {
  t: TFunction;
  change: any;
  dateChange: any;
  hoursChange: any;
  filters: any;
}

const DATE_FORMAT = "YYYY-MM-DD";

const FilterReport: React.FC<IProps> = ({ t, form, change, dateChange, hoursChange, filters }) => {
  const { Option } = Select;

  const [documentType, setDocumentType] = useState<string>("pdf");
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const changeHandler = (dates) => {
    if (dates.length > 0) {
      dateChange(dates[0].format(DATE_FORMAT), dates[1].format(DATE_FORMAT));
    }
  };

  const changeHours = (type) => {
    let hours;

    if (!!type) {
      hours = type === "true";
    }

    hoursChange(hours);
  };

  const handleDocumentChange = (e) => {
    setDocumentType(e.target.value);
  };

  const generateReport = () => {
    setReportLoading(true);
    client
      .query({ query: EXPORT_TIMELOGS_REPORT, variables: { ...filters, reportType: documentType.toUpperCase() } })
      .then((response) => {
        const timelogs = response.data.exportReport;

        if (documentType === "csv") {
          exportToCsv("TimeLogs.csv", timelogs);
        } else {
          exportToPdf("TimeLogs.pdf", timelogs);
        }

        setReportLoading(false);
      })
      .catch((error) => {
        setReportLoading(false);
        notification.error({
          message: error.message.split(":")[1],
        });
      });
  };

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Query query={GET_REPORT_FILTERS}>
            {(state) => {
              state.refetch();
              const error = state?.error;
              const loading = state.loading;
              const data = state?.data;
              let filterData;

              if (!!data && !loading && data.getReportFilters) {
                filterData = data.getReportFilters;
              }

              if (loading) {
                return (
                  <div className="mlr8">
                    <Skeleton active={true} />
                  </div>
                );
              }

              if (error) {
                return <p>Error</p>;
              }

              return (
                <>
                  <Row gutter={16}>
                    <Col span={6}>
                      <DateRangePicker change={changeHandler} t={t} />
                    </Col>
                    {filterData && !!filterData.clients && (
                      <Col span={6}>
                        <Form.Item label={t("reports.CLIENTS_REPORT")} hasFeedback={true}>
                          {form.getFieldDecorator("reportClients")(
                            <Select
                              mode="multiple"
                              onChange={(e) => change("clients", e)}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              placeholder={t("reports.PLACEHOLDERS.SELECT_CLIENTS")}>
                              {filterData.clients.map((cl) => {
                                return <Option key={cl.id}>{cl.name}</Option>;
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {filterData && !!filterData.projects && (
                      <Col span={6}>
                        <Form.Item label={t("reports.PROJECTS_REPORT")} hasFeedback={true}>
                          {form.getFieldDecorator("reportProjects")(
                            <Select
                              mode="multiple"
                              onChange={(e) => change("projects", e)}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              placeholder={t("reports.PLACEHOLDERS.SELECT_PROJECTS")}>
                              {filterData.projects.map((project) => {
                                return <Option key={project.id}>{project.name}</Option>;
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {filterData && filterData.team && (
                      <Col span={6}>
                        <Form.Item label={t("reports.TEAM_REPORT")} hasFeedback={true}>
                          {form.getFieldDecorator("reportMembers")(
                            <Select
                              mode="multiple"
                              onChange={(e) => change("team", e)}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              placeholder={t("reports.PLACEHOLDERS.SELECT_MEMBERS")}>
                              {filterData.team.map((member) => {
                                return <Option key={member.id}>{member.name}</Option>;
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {/* </Row>*/}
                    {/* <Row gutter={16}>*/}
                    {filterData && !!filterData.tags && (
                      <Col span={6}>
                        <Form.Item label={t("reports.TAGS_REPORT")} hasFeedback={true}>
                          {form.getFieldDecorator("reportTags")(
                            <Select
                              mode="multiple"
                              onChange={(e) => change("tags", e)}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              placeholder={t("reports.PLACEHOLDERS.SELECT_TAGS")}>
                              {filterData.tags.map((tag) => {
                                return <Option key={tag.id}>{tag.name}</Option>;
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={6}>
                      <Form.Item label={t("analytics.STATISTICS.HOURS")} hasFeedback={true}>
                        {form.getFieldDecorator("reportHours")(
                          <Select
                            onChange={changeHours}
                            style={{ width: "100%" }}
                            allowClear={true}
                            placeholder={t("analytics.STATISTICS.HOURS")}>
                            <Option key="true">{t("analytics.STATISTICS.BILLED")}</Option>
                            <Option key="false">{t("analytics.STATISTICS.NON_BILLED")}</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      className={
                        filterData && !!filterData.clients && !!filterData.tags
                          ? "generateReportManager"
                          : "generateReportEmployee"
                      }>
                      <Radio.Group value={documentType} onChange={handleDocumentChange} className="mt4">
                        <Radio value="pdf">{t("reports.PDF")}</Radio>
                        <Radio value="csv">{t("reports.CSV")}</Radio>
                      </Radio.Group>
                      <Button onClick={generateReport} type="primary" loading={reportLoading}>
                        {t("reports.GENERATE_REPORT")}
                      </Button>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Query>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create<IProps>()(FilterReport);
