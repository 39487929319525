import React, { Fragment, useState } from "react";
import { Button, Select, Table, Tag, Tooltip } from "antd";
import PerformanceReviewCycleDetails from "../PerformanceReviewCycle/PerformanceReviewCycleDetails";
import { useTranslation } from "react-i18next";
import { Query } from "@apollo/react-components";
import { GET_REVIEW_REQUESTS } from "../../../../graphql/queries/Configurations/index";
import moment from "moment";
import { reviewCyclesType } from "../../types";
import LeaveReviewSelf from "./LeaveReviewFrom/LeaveReviewSelf";
import { getUserData } from "common/AccessControl/Permissions";

const SelfReviewRequests = () => {
  const { t } = useTranslation();
  const loggedInUser = getUserData();

  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [year, setYear] = useState(moment().format("YYYY"));

  const generateYearOptions = () => {
    const currentYear = parseInt(moment().format("YYYY"));
    const previousYears = Array.from(
      { length: 50 }, // Adjust the number of previous years as needed
      (_, index) => (currentYear - index - 1).toString()
    );
    return previousYears.map((yearOption) => (
      <Select.Option key={yearOption} value={yearOption}>
        {yearOption}
      </Select.Option>
    ));
  };

  const handleRowClick = (rowKey: number, rowData: any) => {
    setSelectedRowKey(rowKey);
    setSelectedRowData(rowData);
  };

  const columns = [
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CREATED_AT"),
      dataIndex: "createdAt",
      render: (createdAt) => <span>{moment(createdAt).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEWEE"),
      dataIndex: "Employee",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER"),
      dataIndex: "Manager",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_DATE"),
      dataIndex: "expireDate",
      render: (expireDate) => <span>{moment(expireDate).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_STATUS"),
      dataIndex: "hasExpired",
      render: (hasExpired) => (
        <Tag color={hasExpired ? "red" : "orange"}>{hasExpired ? "Has expired" : "In progress"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_AT"),
      dataIndex: "closedAt",
      render: (closedAt) => <span>{closedAt ? moment(closedAt).format("DD/MM/YYYY") : "----------"}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_STATUS"),
      dataIndex: "closedAt",
      render: (closedAt) => (
        <Tag color={closedAt != null ? "red" : "green"}>{closedAt != null ? "Closed" : "Opened"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEW_STATUS"),
      dataIndex: "selfReviews",
      key: "selfReviews",
      render: (selfReviews) => {
        const filteredReviews = selfReviews.filter((review) => review.ReviewerId === loggedInUser?.id);
        return (
          <>
            {filteredReviews.map((review, index) => (
              <Tag key={index} color={review.submittedAt ? "green" : "red"}>
                {review.submittedAt ? "Submitted" : "Not submitted"}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row: reviewCyclesType) => (
        <div style={{ display: "flex", gap: 10 }}>
          <Tooltip title="View details">
            <Button size="small" icon="unordered-list" onClick={() => handleRowClick(row?.id, row)} />
          </Tooltip>
          <LeaveReviewSelf selectedReview={row} t={t} />
        </div>
      ),
    },
  ];

  const handleBackButtonClick = () => {
    setSelectedRowKey(null);
  };

  return (
    <Fragment>
      <Query query={GET_REVIEW_REQUESTS}>
        {({ loading, error, data }: any) => {
          let reviewRequests: reviewCyclesType[] = [];

          if (!loading && data?.getReviewRequests) {
            reviewRequests = data?.getReviewRequests?.filter((request) => request?.EmployeeId === loggedInUser?.id);
          }
          let filteredReviewCycles = reviewRequests;

          if (year) {
            filteredReviewCycles = reviewRequests.filter(
              (item: any) => moment(item?.createdAt).format("YYYY") === year
            );
          }

          if (error) {
            return <p>Error</p>;
          }

          return (
            <div style={{ marginTop: 10 }}>
              {!selectedRowKey && (
                <>
                  <Select value={year} onChange={(value) => setYear(value)} style={{ marginBottom: 16, width: 100 }}>
                    <Select.Option value={moment().format("YYYY")}>{moment().format("YYYY")}</Select.Option>
                    {generateYearOptions()}
                  </Select>
                  <Table loading={loading} size="middle" dataSource={filteredReviewCycles} columns={columns} />
                </>
              )}
              {selectedRowKey && (
                <>
                  <Button style={{ marginBottom: 15 }} icon="left" type="default" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <PerformanceReviewCycleDetails selectedReview={selectedRowData} t={t} />
                </>
              )}
            </div>
          );
        }}
      </Query>
    </Fragment>
  );
};

export default SelfReviewRequests;
