import * as React from "react";
import { Redirect, Route } from "react-router";

const privateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      // tslint:disable-next-line:jsx-no-lambda
      render={(props) => {
        if (!isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default privateRoute;
