import React from "react";
import { Button, Popconfirm, notification } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { TFunction } from "i18next";
import { DELETE_ABSENCE_REQUEST } from "graphql/mutations";
import { GET_EMPLOYEE_ABSENCE_REQUEST } from "graphql/queries/Configurations";
import { absenceType } from "screens/Home/components/HomeTypes";

interface IProps extends FormComponentProps {
  t: TFunction;
  absenceLogItem: absenceType;
}

const CalendarsAbsenceLogDelete: React.FC<IProps> = ({ t, absenceLogItem }) => {
  return (
    <Mutation
      mutation={DELETE_ABSENCE_REQUEST}
      refetchQueries={[
        {
          query: GET_EMPLOYEE_ABSENCE_REQUEST,
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.CALENDARS.MESSAGES.ABSENCE_LOG_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteEmployeeAbsenceLog, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteEmployeeAbsenceLog({ variables: { id: absenceLogItem.id } })}>
          <Button className="button-delete" type="danger" size="small" icon="delete" />{" "}
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default Form.create<IProps>()(CalendarsAbsenceLogDelete);
