import React from "react";
import TitleSection from "../../../../common/SectionTitle/title";
import ActionsComponent from "./actions";
import { Link, RouteComponentProps } from "react-router-dom";
import { Avatar, Button, Icon, List, notification, Popconfirm, Skeleton } from "antd";
import { Mutation, Query } from "@apollo/react-components";
import { GET_LEAVE_REQUEST_DETAILS } from "../../../../graphql/queries/LeaveManagementQueries";
import { formatDateForDeputy } from "../../../../utils/date";
import { stringToHTML } from "../../../../helpers/draftjs";
import constants from "../../../../constants";
import { withLastLocation, WithLastLocationProps } from "react-router-last-location";
import moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";
import UploadFile from "./UploadFile";
import EditLeaveRequest from "../../CalendarSummary/components/EditLeaveRequest";
import DeleteLeaveRequest from "../../CalendarSummary/components/DeleteLeaveRequest";
import SubmitLeaveRequest from "../../CalendarSummary/components/SubmitLeaveRequest";
import { getUserData } from "../../../../common/AccessControl/Permissions";
import { IMinifiedUser } from "../../../../graphql/queries/Interfaces";
import { leaveRequestStatus } from "../../../../helpers/leaveRequestStatus";
import { UPDATE_LEAVE_REQUEST_STATUS } from "../../../../graphql/mutations/LeaveManagementMutations";

interface ILeaveRequestRouterProp {
  leaveRequestId: string;
}

interface ILeaveRequestDetailList {
  icon: any;
  value: any;
  title: any;
  pathname: any;
}

const LeaveRequestDetails = (
  props: RouteComponentProps<ILeaveRequestRouterProp> &
    ILeaveRequestDetailList &
    WithLastLocationProps &
    WithTranslation
) => {
  const { t } = props;

  const loggedUser: IMinifiedUser = getUserData();

  const setHeader = (title: string) => {
    let header: string;

    if (title.split("/")[2] === "requests" && title.split("/")[3] === undefined) {
      return t("sideMenu.LEAVE_MANAGEMENT.REQUESTS");
    }

    switch (title.split("/")[3]) {
      case "under-management":
        header = t("sideMenu.LEAVE_MANAGEMENT.MANAGER_REQUESTS");
        break;
      default:
        header = t("sideMenu.LEAVE_MANAGEMENT.CALENDAR_VIEW");
        break;
    }

    return header;
  };

  const getPreviousLocation = () => {
    return props?.lastLocation?.pathname ?? "/dashboard/requests/calendar-summary";
  };

  return (
    <div className="leave-request-details">
      <TitleSection title={t("leaveRequests.LEAVE_REQUESTS")} iconType="contacts" />
      <div className="wrapper">
        <Query query={GET_LEAVE_REQUEST_DETAILS} variables={{ id: parseFloat(props.match.params.leaveRequestId) }}>
          {(state) => {
            const loading = state.loading;
            const data = state.data;
            let dataForList;
            let dataNeededForActions;
            let leaveRequest;
            let editLeaveRequestData;

            if (loading) {
              return <Skeleton active={true} />;
            }

            if (!loading && data && data.leaveRequest) {
              const {
                id,
                leaveRequestType: { id: leaveId, leaveTypeName, canUploadDocument },
                comment,
                startDate,
                endDate,
                duration,
                employeeStatus,
                deputyId,
                daysUsedFromLastYear,
                employeeId,
                managerId,
                deputyStatus,
                managerStatus,
                deputy: {
                  User: { firstName, lastName },
                },
                employee: {
                  User: { firstName: employeeName, lastName: employeeLastname },
                },
                doctorReport,
              } = data.leaveRequest;

              leaveRequest = {
                id,
                leaveTypeName,
                canUploadDocument,
                leaveId,
                comment,
                startDate,
                employeeId,
                daysUsedFromLastYear,
                managerId,
                endDate,
                duration,
                employeeStatus,
                deputyStatus,
                managerStatus,
                deputyId,
                deputy: `${firstName} ${lastName}`,
                employee: `${employeeName} ${employeeLastname}`,
                doctorReport,
              };

              dataForList = [
                {
                  title: t("projectsTableColumns.STATUS"),
                  value: leaveRequestStatus(employeeStatus, deputyStatus, managerStatus, t).message,
                  icon: "form",
                },
                { title: t("leaveRequests.tableColumns.EMPLOYEE"), value: leaveRequest.employee, icon: "user" },
                { title: t("leaveRequests.tableColumns.DEPUTY"), value: leaveRequest.deputy, icon: "team" },
                {
                  title: t("leaveRequests.tableColumns.START_DATE"),
                  value: formatDateForDeputy(startDate),
                  icon: "calendar",
                },
                {
                  title: t("leaveRequests.tableColumns.END_DATE"),
                  value: formatDateForDeputy(endDate),
                  icon: "carry-out",
                },
                {
                  title: t("leaveRequests.tableColumns.DURATION"),
                  value: `${duration} ${duration > 1 ? "days" : "day"}`,
                  icon: "dashboard",
                },
                {
                  title: t("leaveRequests.DAYS_TOOLTIP"),
                  value: `${daysUsedFromLastYear} ${daysUsedFromLastYear > 1 ? "days" : "day"}`,
                  icon: "backward",
                },
                { title: t("leaveRequests.tableColumns.COMMENT"), value: stringToHTML(comment), icon: "notification" },
                { title: t("leaveRequests.tableColumns.LEAVE_TYPE"), value: leaveRequest.leaveTypeName, icon: "gold" },
              ];

              if (canUploadDocument) {
                dataForList.push({
                  title: t("leaveRequests.MESSAGES.DOCTOR_REPORT"),
                  value: doctorReport ? (
                    <a href={doctorReport} download={true}>
                      {t("contracts.form.DOWNLOAD")}
                    </a>
                  ) : (
                    <UploadFile id={id} />
                  ),
                  icon: "medicine-box",
                });
              }

              dataNeededForActions = {
                leaveRequestId: id,
                employeeStatus,
                deputyStatus,
                managerStatus,
                employee: leaveRequest.employee,
                employeeId,
                managerId,
                deputyId,
                selectedYear: moment(startDate).year().toString(),
              };

              editLeaveRequestData = {
                id,
                startDate,
                endDate,
                leaveId,
                deputyId,
                comment,
              };
            }

            const location = getPreviousLocation();

            return (
              <>
                <div className="header-wrapper">
                  <div>
                    <Link to={location}>
                      <Icon type="left" style={{ marginRight: "10px", color: "black", fontSize: "16px" }} />
                      <span className="customer-header-style">{setHeader(location)}</span>
                    </Link>
                    <span>
                      {data && leaveRequest && ` / ${leaveRequest.employee}'s `}
                      {t("leaveRequests.LEAVE_REQUESTS")}
                    </span>
                  </div>
                  {leaveRequest.employeeStatus !== constants.leaveRequestStatus.statusInactive &&
                    loggedUser.id !== leaveRequest.employeeId && (
                      <div className="deputy-actions">
                        <ActionsComponent t={t} data={dataNeededForActions} loggedUserId={loggedUser.id} />
                      </div>
                    )}
                  {leaveRequest.employeeStatus === constants.leaveRequestStatus.statusInactive &&
                    loggedUser.id === leaveRequest.employeeId && (
                      <div className="deputy-actions">
                        <SubmitLeaveRequest
                          t={t}
                          id={leaveRequest.id}
                          action={"confirm"}
                          destination={getPreviousLocation()}
                        />
                        <DeleteLeaveRequest t={t} id={leaveRequest.id} destination={getPreviousLocation()} />
                        <EditLeaveRequest t={t} data={editLeaveRequestData} />
                      </div>
                    )}
                  {leaveRequest.employeeStatus === constants.leaveRequestStatus.statusActive &&
                    loggedUser.id === leaveRequest.employeeId &&
                    moment(leaveRequest.startDate).isAfter(moment()) && (
                      <div className="leave-request-actions">
                        <CancelLeaveRequest t={t} id={leaveRequest.id} action={"cancel"} history={props.history} />
                      </div>
                    )}
                </div>

                <List
                  itemLayout="vertical"
                  dataSource={loading ? [1, 2, 3, 4, 5, 6] : dataForList}
                  renderItem={(item: ILeaveRequestDetailList) => (
                    <List.Item key={item.title}>
                      <Skeleton
                        loading={loading}
                        active={true}
                        avatar={true}
                        title={{ width: "10vw" }}
                        paragraph={{ rows: 1, width: ["20vw"] }}>
                        <List.Item.Meta
                          avatar={<Avatar icon={item.icon} style={{ backgroundColor: "#001529" }} />}
                          title={item.title}
                          description={item.value}
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

const CancelLeaveRequest = ({ t, id, action, history }) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST_STATUS}
      refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.CANCEL_REQUEST"),
        });

        history.goBack();
      }}>
      {(updateLeaveRequestStatus, { loading }): any => {
        return (
          <div className="action-button">
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                updateLeaveRequestStatus({ variables: { id, action } });
              }}>
              <Button loading={loading} icon="close" className="action-button-cancel">
                {t("buttons.CANCEL")}
              </Button>
            </Popconfirm>
          </div>
        );
      }}
    </Mutation>
  );
};

export default withTranslation()(withLastLocation(LeaveRequestDetails));
