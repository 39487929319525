import React from "react";
import { Query } from "@apollo/react-components";
import { GET_REPORT_GRAPH } from "../../../graphql/queries";
import { TFunction } from "i18next";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IReportGraph, IReportStatistics } from "../../../graphql/queries/Interfaces";
import moment from "moment";
import { Skeleton } from "antd";
import SummaryReport from "./SummaryReport";
import * as _ from "lodash";
import { roundTwoDecPlaces } from "../../../helpers/round_number";

interface IProps {
  t: TFunction;
  filters: any;
  stats: IReportStatistics;
}

const AnalyticsReport: React.FC<IProps> = ({ t, filters, stats }) => {
  const setDatakey = (data: IReportGraph[]) => {
    if (!!data[0].date) {
      return "date";
    }

    if (!!data[0].week) {
      return "week";
    }

    return "month";
  };

  return (
    <div>
      <Query query={GET_REPORT_GRAPH} variables={filters}>
        {(state) => {
          const loading = state?.loading;
          const error = state?.error;
          const data = state?.data;
          if (loading) {
            return (
              <div style={{ marginBottom: "30px" }}>
                <Skeleton active={true} />
              </div>
            );
          }

          if (error) {
            return <p>Error</p>;
          }

          const formattedData: IReportGraph[] = data.getReport.map((item) => ({
            ...item,
            totalHours: item.totalHours,
            totalBillableHours: roundTwoDecPlaces(item.totalBillableHours),
            totalNonBillableHours: roundTwoDecPlaces(item.totalHours - item.totalBillableHours),
            week: !!item.week ? `Week ${item.week.split("-")[1]}` : null,
            date: !!item.date ? moment(item.date).format("DD MMMM") : null,
            month: !!item.month ? moment(`${item.month}-01`).format("MMM YYYY") : null,
          }));

          const maxHours = Math.round(_.maxBy(formattedData, "totalHours").totalHours * 1.2);

          return formattedData.length > 0 ? (
            <div style={{ marginBottom: "30px" }}>
              <SummaryReport t={t} stats={stats} />

              <ResponsiveContainer height={300}>
                <BarChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={setDatakey(formattedData)} />
                  {maxHours > 0 ? <YAxis type="number" domain={[0, maxHours]} /> : <YAxis />}
                  <Tooltip />
                  <Legend />
                  {filters.billable === undefined || filters.billable ? (
                    <Bar
                      dataKey="totalBillableHours"
                      stackId="a"
                      fill="#8884d8"
                      name={t("analytics.STATISTICS.BILLED_HOURS")}
                    />
                  ) : null}
                  {filters.billable === undefined || !filters.billable ? (
                    <Bar
                      dataKey="totalNonBillableHours"
                      stackId="a"
                      fill="#82ca9d"
                      name={t("analytics.STATISTICS.NON_BILLED_HOURS")}
                    />
                  ) : null}
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : null;
        }}
      </Query>
    </div>
  );
};

export default AnalyticsReport;
