import React, { useState } from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { Button, Col, Form, Modal, notification, Row, DatePicker, Tag, Select, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  GET_COMPANY_BRANCH,
  GET_COMPANY_BRANCH_BY_ID,
  GET_HOLIDAY_TYPES,
} from "../../../../../graphql/queries/Configurations";
import TextArea from "antd/lib/input/TextArea";
import { UPDATE_HOLIDAY_TYPE } from "graphql/mutations";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";

interface IProps extends FormComponentProps {
  t: TFunction;
  holidayType: any;
}
const { Option } = Select;

const HolidayTypesEdit: React.FC<IProps> = ({ t, holidayType, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const [selectedHolidaysForUpdate, setSelectedHolidaysForUpdate] = useState<string[]>(holidayType.date);

  const handleDateSelection = (date) => {
    if (!selectedHolidaysForUpdate.includes(date)) {
      setSelectedHolidaysForUpdate([...selectedHolidaysForUpdate, moment(date).format()]);
    }
  };

  const handleTagClose = (index) => {
    setSelectedHolidaysForUpdate(selectedHolidaysForUpdate.filter((_, i) => i !== index));
  };

  const { loading: loadingHolidays, data: allBranchData } = useQuery(GET_COMPANY_BRANCH, { fetchPolicy: "no-cache" });

  const { data: oneBranchData } = useQuery(GET_COMPANY_BRANCH_BY_ID, {
    variables: { id: holidayType.CompanyBranchId },
  });

  const initialValueCompanyBranchCountry = oneBranchData?.getCompanyBranchById?.country
    ? oneBranchData?.getCompanyBranchById?.country
    : "loading..";
  const initialValueCompanyBranchCity = oneBranchData?.getCompanyBranchById?.city
    ? oneBranchData?.getCompanyBranchById?.city
    : "loading..";

  return (
    <>
      <Button icon="edit" onClick={toggleModal} />
      <Mutation
        mutation={UPDATE_HOLIDAY_TYPE}
        refetchQueries={[
          {
            query: GET_HOLIDAY_TYPES,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.HOLIDAY_TYPE_UPDATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(updateAbsenceType, { loading }) => (
          <Modal
            title={t("appConfiguration.HOLIDAY_TYPES.EDIT_HOLIDAY_TYPE")}
            width={600}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { date, CompanyBranchId, description } = values;

                  updateAbsenceType({
                    variables: {
                      id: holidayType.id,
                      CompanyBranchId,
                      description,
                      date: selectedHolidaysForUpdate,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item label={t("appConfiguration.HOLIDAY_TYPES.LABELS.HOLIDAYS")}>
                    <DatePicker allowClear={false} style={{ width: "100%" }} onChange={handleDateSelection} />
                  </Form.Item>
                </Col>
                <Col span={17}>
                  {selectedHolidaysForUpdate.map((date, index) => (
                    <Tag
                      color="#108ee9"
                      closable
                      onClose={() => handleTagClose(index)}
                      style={{ marginBottom: 10 }}
                      key={moment(date).format("DD-MMMM-YYYY")}>
                      {moment(date).format("DD-MMMM-YYYY")}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.HOLIDAY_TYPES.LABELS.COMPANY_BRANCH")}>
                    {form.getFieldDecorator("CompanyBranchId", {
                      rules: [
                        {
                          required: false,
                          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.COMPANY_BRANCH_REQUIRED"),
                        },
                      ],
                      initialValue: oneBranchData?.getCompanyBranchById.id,
                    })(
                      <Select
                        showSearch
                        allowClear
                        value={initialValueCompanyBranchCountry + " / " + initialValueCompanyBranchCity}
                        defaultValue={initialValueCompanyBranchCountry + " / " + initialValueCompanyBranchCity}
                        loading={loadingHolidays}
                        placeholder={t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.COMPANY_BRANCH")}>
                        {allBranchData?.getCompanyBranches.map((branchItem) => (
                          <Option key={branchItem.id} value={branchItem.id}>
                            {branchItem.country + " / " + branchItem.city}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.HOLIDAY_TYPES.LABELS.DESCRIPTION")}>
                    {form.getFieldDecorator("description", {
                      initialValue: holidayType.description,
                      rules: [
                        {
                          required: false,
                          type: "string",
                        },
                      ],
                    })(
                      <TextArea
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.DESCRIPTION")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(HolidayTypesEdit);
