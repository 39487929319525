import * as React from "react";
import { Layout } from "antd";
import { withPermissions } from "../../decorators/permissions";
import constants from "../../constants/index";
import Documents from "./Documents";
import { IUser } from "screens/Profile/Interfaces";

interface IProps {
  permissions: any;
  userData: IUser;
}

const View: React.FC<IProps> = ({ userData }) => {
  return (
    <Layout.Content className="document-content">
      <Documents userData={userData} />
    </Layout.Content>
  );
};

export default withPermissions([constants.permissionResources.DOCUMENTS])(View);
