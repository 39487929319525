import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Spin, Button, notification, Tooltip, Divider, Empty } from "antd";
import Meta from "antd/lib/card/Meta";
import { GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME } from "graphql/queries/Configurations";
import { TFunction } from "i18next";
import { UPDATE_ABSENCE_REQUEST_STATUS } from "graphql/mutations";
import { getUserData } from "common/AccessControl/Permissions";
import { absenceType } from "./HomeTypes";
import moment from "moment";
import { getStatusColor, getStatusText } from "helpers/approveStatusAndAbsence";
import CalendarsAbsenceLogDetails from "screens/Calendars/components/CalendarsAbsenceLogDetails";

interface IProps {
  t: TFunction;
}

const HomeRequestForAbsence: React.FC<IProps> = ({ t }) => {
  const currentUser = getUserData();
  const currentUserId = currentUser?.id;

  const { data: absenceLogData, loading, error, refetch } = useQuery(GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME);

  const emplyeeAbsenceLogsData: absenceType[] = absenceLogData?.getEmployeeAbsenceRequestForHomeScreen;

  const [updateEmployeeAbsenceRequestStatus] = useMutation(UPDATE_ABSENCE_REQUEST_STATUS);

  const approveRequestHendler = (absenceRequestId: number, isReplacer: boolean, isManager: boolean) => {
    if (isReplacer) {
      updateEmployeeAbsenceRequestStatus({
        variables: {
          approvedByReplacer: true,
          id: absenceRequestId,
        },
        refetchQueries: [{ query: GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME }],
      }).then(() => {
        notification.success({
          message: t("appConfiguration.CALENDARS.MESSAGES.ABSENCE_REQUEST_APPROVE"),
        });
      });
    }
    if (isManager) {
      updateEmployeeAbsenceRequestStatus({
        variables: {
          approvedByManager: true,
          id: absenceRequestId,
        },
        refetchQueries: [{ query: GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME }],
      }).then(() => {
        notification.success({
          message: t("appConfiguration.CALENDARS.MESSAGES.ABSENCE_REQUEST_APPROVE"),
        });
      });
    }
  };

  const notApproveRequestHendler = (absenceRequestId: number, isReplacer: boolean, isManager: boolean) => {
    if (isReplacer) {
      updateEmployeeAbsenceRequestStatus({
        variables: {
          approvedByReplacer: false,
          id: absenceRequestId,
        },
        refetchQueries: [{ query: GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME }],
      }).then(() => {
        notification.success({
          message: t("appConfiguration.CALENDARS.MESSAGES.ABSENCE_REQUEST_NOT_APPROVE"),
        });
      });
    }
    if (isManager) {
      updateEmployeeAbsenceRequestStatus({
        variables: {
          approvedByManager: false,
          id: absenceRequestId,
        },
        refetchQueries: [{ query: GET_EMPLOYEE_ABSENCE_REQUESTS_FOR_HOME }],
      }).then(() => {
        notification.success({
          message: t("appConfiguration.CALENDARS.MESSAGES.ABSENCE_REQUEST_NOT_APPROVE"),
        });
      });
    }
  };

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }

  if (!absenceLogData || !absenceLogData?.getEmployeeAbsenceRequestForHomeScreen) {
    return <Empty />;
  }

  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      {emplyeeAbsenceLogsData?.map((absenceLogItem: absenceType) => {
        const absenceRequestId = absenceLogItem?.id;
        const isManager = absenceLogItem?.managerId === currentUserId;
        const isReplacer = absenceLogItem?.employeeReplacerId === currentUserId;
        const totalDaysOfAbsence = absenceLogItem?.duration;
        const firstDayOfAbsence = absenceLogItem?.startDate;
        const lastDayOfAbsence = absenceLogItem?.endDate;
        const notApprovedByReplacer = absenceLogItem?.approvedByReplacer != true;

        return (
          <Card
            loading={loading}
            key={absenceLogItem.AbsenceTypeId}
            style={{ width: "100%", marginTop: 16 }}
            className={"absence-card"}
            actions={[
              <Tooltip title={isManager && notApprovedByReplacer ? "Wait for substitute status" : "Approve"}>
                <Button
                  disabled={isManager && notApprovedByReplacer}
                  style={{ color: "green" }}
                  key="check-circle"
                  onClick={() => approveRequestHendler(absenceRequestId, isReplacer, isManager)}>
                  Approve
                </Button>
              </Tooltip>,
              <Tooltip title={"Decline"}>
                <Button
                  style={{ color: "red" }}
                  key="close-circle"
                  onClick={() => notApproveRequestHendler(absenceRequestId, isReplacer, isManager)}>
                  Reject
                </Button>
              </Tooltip>,
              <Tooltip title={"Absence details"}>
                <CalendarsAbsenceLogDetails t={t} absenceLogItem={absenceLogItem} buttonTheme={true} />
              </Tooltip>,
            ]}>
            <Meta title={absenceLogItem?.employee?.firstName + " " + absenceLogItem?.employee?.lastName} />
            <>
              <span>
                <p style={{ marginTop: 5, marginBottom: 0 }}>
                  {absenceLogItem?.absenceType?.absenceTypeName +
                    " " +
                    `(${totalDaysOfAbsence != 1 ? totalDaysOfAbsence + " days" : totalDaysOfAbsence + " day"})`}
                </p>
                {firstDayOfAbsence != lastDayOfAbsence ? (
                  <p style={{ marginBottom: -15 }}>
                    {moment(firstDayOfAbsence).format("DD/MM/YYYY") +
                      " - " +
                      moment(lastDayOfAbsence).format("DD/MM/YYYY")}
                  </p>
                ) : (
                  <p style={{ marginBottom: -15 }}>{moment(firstDayOfAbsence).format("DD/MM/YYYY dddd")}</p>
                )}
              </span>
              <div className="approve-status-home">
                <h5>{"Manager: "}</h5>
                <Tooltip title={getStatusText(absenceLogItem?.approvedByManager)}>
                  <h5
                    style={{
                      paddingLeft: 5,
                      color: `${getStatusColor(absenceLogItem?.approvedByManager)}`,
                    }}>
                    {absenceLogItem?.manager?.firstName + " " + absenceLogItem?.manager?.lastName}
                  </h5>
                </Tooltip>
                <Divider style={{ marginTop: 2 }} type="vertical" />
                <h5>{"Substitute: "}</h5>
                <Tooltip title={getStatusText(absenceLogItem?.approvedByReplacer)}>
                  <h5
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      color: `${getStatusColor(absenceLogItem?.approvedByReplacer)}`,
                    }}>
                    {absenceLogItem?.replacer?.firstName + " " + absenceLogItem?.replacer?.lastName}
                  </h5>
                </Tooltip>
              </div>
            </>
          </Card>
        );
      })}
    </>
  );
};

export default HomeRequestForAbsence;
