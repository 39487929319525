import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { GET_PROJECT_ASSIGNABLE_EMPLOYEES } from "../../../../../../../../graphql/queries";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends SelectProps, WithTranslation {
  projectId: number;
}

class SelectEmployee extends Component<IProps> {
  public render() {
    const { t } = this.props;
    const { projectId, ...rest } = this.props;
    return (
      <Query query={GET_PROJECT_ASSIGNABLE_EMPLOYEES} fetchPolicy="network-only" variables={{ id: projectId }}>
        {(state) => {
          const loading = state?.loading;
          const data = state?.data;
          let assignableEmployees;
          if (!loading && !!data) {
            assignableEmployees = data.Project.assignableEmployees;
          }

          return (
            <>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder={t("projectsTableColumns.placeholder.EMPLOYEE")}
                  {...rest}>
                  {!!assignableEmployees &&
                    assignableEmployees.map((employee) => (
                      <Select.Option key={employee.User.id} value={employee.User.id}>
                        {`${employee.User.firstName} ${employee.User.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

export default withTranslation()(SelectEmployee);
