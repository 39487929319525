import React from "react";
import {
  CartesianGrid,
  ComposedChart,
  LineChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { TFunction } from "i18next";
import { IFinances } from "../../../graphql/queries/Interfaces";

interface IProps {
  t: TFunction;
  data: IFinances[];
  showPrediction: boolean;
}

const LineChartGraph = ({ data, t }) => {
  return (
    <ResponsiveContainer height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis dataKey="revenue" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="revenue"
          name={t("analytics.STATISTICS.REVENUE")}
          stroke="#8884d8"
          strokeWidth="2"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ComposedChartGraph = ({ data, t }) => {
  return (
    // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    <ResponsiveContainer height={300}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="revenue"
          name={t("analytics.STATISTICS.REVENUE")}
          stroke="#82ca9d"
          fill="#82ca9d"
          strokeWidth="2"
        />
        <Line
          type="monotone"
          dataKey="prediction"
          name={t("analytics.STATISTICS.PREDICTION")}
          stroke="#8884d8"
          strokeWidth="2"
          strokeDasharray="5 5"
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const Chart: React.FC<IProps> = ({ data, showPrediction, t }) => {
  return <>{showPrediction ? <ComposedChartGraph data={data} t={t} /> : <LineChartGraph data={data} t={t} />}</>;
};

export default Chart;
