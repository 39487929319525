import React from "react";
import { Layout, Skeleton } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";
import TitleSection from "../../../common/SectionTitle/title";
import withDragDropContext from "./withDnDContext";
import ExportLeaves from "./components/ExportLeaves";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
import { GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS } from "../../../graphql/queries/LeaveManagementQueries";
import client from "../../../graphql/ApolloClient";
import { TFunction } from "i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CreateLeaveRequest from "./components/CreateLeaveRequest";
import { getUserData } from "../../../common/AccessControl/Permissions";
import { IMinifiedUser } from "../../../graphql/queries/Interfaces";
import { leaveRequestStatus } from "../../../helpers/leaveRequestStatus";

const initializeCalendar = (resources, events, t: TFunction) => {
  const schedulerData = new SchedulerData(moment(), ViewTypes.Week);
  schedulerData.localeMoment.locale("en");
  schedulerData.setResources(resources);
  schedulerData.setEvents(events);
  schedulerData.config.schedulerWidth = window.screen.width - 336;
  schedulerData.config.resourceName = t("leaveRequests.CALENDAR_SUMMARY.RESOURCES");
  schedulerData.config.yearCellWidth = 40;
  schedulerData.config.movable = false;
  // schedulerData.config.eventItemPopoverEnabled = false;
  schedulerData.config.views = [
    {
      viewName: t("leaveRequests.CALENDAR_SUMMARY.WEEK"),
      viewType: ViewTypes.Week,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: t("leaveRequests.CALENDAR_SUMMARY.MONTH"),
      viewType: ViewTypes.Month,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: t("leaveRequests.CALENDAR_SUMMARY.YEAR"),
      viewType: ViewTypes.Year,
      showAgenda: false,
      isEventPerspective: false,
    },
  ];

  return schedulerData;
};

interface IState {
  viewModel: any;
  events: any;
  resources: any;
  visible: boolean;
  resourceId: number | null;
  startDate: moment.Moment;
  endDate: moment.Moment;
  newRecordFlag: boolean;
  loading: boolean;
  user: IMinifiedUser;
}

class CalendarSummary extends React.Component<WithTranslation & RouteComponentProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      viewModel: initializeCalendar([], [], this.props.t),
      events: [],
      resources: [],
      visible: false,
      resourceId: null,
      startDate: moment(),
      endDate: moment(),
      newRecordFlag: false,
      loading: false,
      user: getUserData(),
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate = () => {
    if (this.state.newRecordFlag) {
      this.getData();

      this.checkForNewItem();
    }
  };

  checkForNewItem = () => {
    this.setState((prevState) => ({
      ...prevState,
      newRecordFlag: !prevState.newRecordFlag,
    }));
  };

  render() {
    const { t } = this.props;
    const { viewModel } = this.state;

    return (
      <>
        <TitleSection
          title={t("sideMenu.LEAVE_MANAGEMENT.CALENDAR_VIEW")}
          iconType="schedule"
          extra={() => <ExportLeaves />}
        />

        <Layout.Content className="wrapper">
          {this.state.loading ? (
            <Skeleton active={true} />
          ) : (
            <>
              <Scheduler
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                newEvent={this.newEvent}
              />

              <CreateLeaveRequest
                t={t}
                visible={this.state.visible}
                toggle={this.toggleModal}
                resourceId={this.state.resourceId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                newRecord={this.checkForNewItem}
              />
            </>
          )}
        </Layout.Content>
      </>
    );
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      visible: !prevState.visible,
    }));
  };

  toggleLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: !prevState.loading,
    }));
  };

  getData = () => {
    this.toggleLoading();
    client.query({ query: GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS, fetchPolicy: "network-only" }).then((res) => {
      const summaryData = res?.data?.calendarSummary;
      const employees = res?.data?.simpleEmployees;

      const eventsSummary = summaryData.map(
        ({
          id,
          startDate,
          endDate,
          employeeId,
          employeeStatus,
          deputyStatus,
          managerStatus,
          leaveRequestType: { leaveTypeName: leaveRequestType },
        }) => ({
          id,
          start: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
          end: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
          resourceId: employeeId,
          title: leaveRequestType,
          bgColor: leaveRequestStatus(employeeStatus, deputyStatus, managerStatus, this.props.t).color,
          showPopover: true,
        })
      );

      const resourcesSummary = employees.map(({ employeeId, fullName }) => ({
        id: employeeId,
        name: fullName,
      }));

      this.state.viewModel.setEvents(eventsSummary);
      this.state.viewModel.setResources(resourcesSummary);

      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        events: eventsSummary,
        resources: resourcesSummary,
      }));
    });
  };

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {
    this.props.history.push(`/dashboard/leave-request/${event.id}`);
  };

  newEvent = (schedulerData, slotId, slotName, start, end) => {
    if (slotId === this.state.user.id) {
      this.toggleModal();

      this.setState((prevState) => ({
        ...prevState,
        resourceId: slotId,
        startDate: moment(start),
        endDate: moment(end),
      }));
    }
  };
}

export default withRouter(withDragDropContext(withTranslation()(CalendarSummary)));

// import moment from "moment";
// import { Skeleton } from "antd";
// import SectionTitle from "../../../common/SectionTitle/title";
// import Calendar from "../../../common/CalendarTable/table";
// import { GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS } from "../../../graphql/queries/LeaveManagementQueries";
// import { graphql } from "@apollo/react-hoc";
//
// const Events = (props) => {
//   let allData;
//   let allLeaveRequests;
//   let allEvents;
//   const { t } = props;
//   if (props.data && !props.data.loading && props.data.calendarSummery) {
//     allData = props.data.calendarSummery;
//     allLeaveRequests = allData.map(
//       ({
//         id,
//         leaveRequestType: { id: leaveId, leaveTypeName },
//         startDate,
//         endDate,
//         employee: {
//           User: { firstName: employeeName, lastName: employeeLastname, id: userId },
//         },
//       }) => ({
//         id,
//         leaveTypeName,
//         startDate,
//         endDate,
//         employee: `${employeeName} ${employeeLastname}`,
//       })
//     );
//     allEvents = allLeaveRequests.map(({ id, userId, startDate, endDate, employee, leaveTypeName }) => {
//       return {
//         userId,
//         leaveRequestId: id,
//         leaveTypeName,
//         title: `${employee}`,
//         allDay: true,
//         start: moment(startDate).toDate(),
//         end: moment(endDate).endOf("day").toDate(),
//       };
//     });
//   }
//   return (
//     <div style={{ height: "100%" }}>
//       <SectionTitle title={t("sideMenu.LEAVE_MANAGEMENT.CALENDAR_VIEW")} iconType="schedule" />
//       {!props.data.loading && props.data && (
//         <div className="wrapper" style={{ height: "80%" }}>
//           <Calendar events={allEvents} />
//         </div>
//       )}
//       {props.data.error && <div>Error</div>}
//       {props.data.loading && (
//         <div className="wrapper" style={{ height: "80%" }}>
//           <Skeleton
//             className="calendar-skeleton"
//             loading={props.data.loading}
//             active={true}
//             title={{ width: "100%" }}
//             paragraph={{ rows: 5, width: ["100%", "100%", "100%", "100%", "100%"] }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };
//
// export default graphql(GET_EMPLOYEES_UNDER_MANAGEMENT_REQUESTS, {
//   options: () => ({
//     fetchPolicy: "network-only",
//   }),
// })(withTranslation()(Events) as any);
