import React from "react";
import { Button, Popconfirm, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import { DELETE_REVIEW_CYCLE } from "graphql/mutations";
import { GET_REVIEW_CYCLE } from "graphql/queries/Configurations";

const PerformanceReviewCycleDelete = ({ t, rowId }) => {
  return (
    <Mutation
      mutation={DELETE_REVIEW_CYCLE}
      refetchQueries={[
        {
          query: GET_REVIEW_CYCLE,
        },
      ]}
      update={() => {
        notification.success({
          message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.PERFORMANCE_REVIEW_CYCLE_DELETED"),
        });
      }}
      onError={(error) => {
        notification.error({
          message: error.message.split(":")[1],
        });
      }}>
      {(deleteReviewCycle, { loading }) => (
        <Popconfirm
          title={t("popConfirms.TITLE")}
          okText={t("popConfirms.ON_OK")}
          onConfirm={() => deleteReviewCycle({ variables: { ReviewCycleId: rowId } })}>
          <Button size="small" type="danger" className="mr-10" loading={loading} icon="delete" />
        </Popconfirm>
      )}
    </Mutation>
  );
};

export default PerformanceReviewCycleDelete;
