import React, { Component, useState } from "react";
import { Query, Mutation } from "@apollo/react-components";
import { withApollo, WithApolloClient } from "@apollo/react-hoc";
import { Table, Popconfirm, Button, notification, Tag } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
import "./stylebutton.scss";
import { GET_NEW_TIMELOGS, GET_TIMELOGS_NUMBER } from "../../../graphql/queries";
import { DELETE_TIMELOG } from "../../../graphql/mutations";
import { formatDate } from "../../../utils/date";
import { stringToHTML } from "../../../helpers/draftjs";
import { generateKey } from "../../../utils/listKey";
import TitleSection from "../../../common/SectionTitle/title";
import "antd/dist/antd.css";
import AddTimelog from "./components/AddTimelog";
import { AccessControl } from "../../../common/AccessControl";
import { withPermissions } from "../../../decorators/permissions";
import constants from "../../../constants";

import Permissions from "../Permissions";
import EditTimeLog from "./components/EditTimeLog";
import { secondsToDuration } from "../../../helpers/timeDuration";

const tagColors = ["volcano", "green", "purple", "blue", "magenta", "red", "cyan"];

interface ITimelog {
  id: number;
  logDate: Date;
  description: string;
  status: string;
  totalHours: number;
  userId: number;
}

interface IProps {
  permissions?: any;
}

const ActionsComponent = (props) => {
  const [disable, setDisable] = useState(false);

  return (
    <div className="actions">
      <div className="timelog-actions">
        <AccessControl userPermissions={props.permissions} allowedPermissions={["deleteOwn"]}>
          <DeleteButton t={props.t} id={props.id} refetch={props.refetch} changeDisability={() => setDisable} />
        </AccessControl>
        <AccessControl userPermissions={props.permissions} allowedPermissions={["updateOwn"]}>
          <EditTimeLog
            disable={disable}
            description={props.description}
            id={props.id}
            logDate={props.logDate}
            duration={props.duration}
            projectId={props.projectId}
            project={props.project}
            tagId={props.tag?.id}
            tagName={props.tag?.name}
            userId={props.userId}
          />
        </AccessControl>
      </div>
    </div>
  );
};

@withPermissions([constants.permissionResources.TIMELOGS])
class Timelogs extends Component<WithApolloClient<WithTranslation> & IProps> {
  public state = {
    filteredInfo: {},
    timelogsNumber: 0,
  };

  public refetch = [
    {
      query: GET_NEW_TIMELOGS,
      variables: { limit: 30 },
    },
  ];

  public componentDidMount() {
    if (this.props.permissions.timelogs.includes("readOwn")) {
      this.props.client
        .query({ query: GET_TIMELOGS_NUMBER })
        .then((response) =>
          this.setState({
            timelogsNumber: response.data.timelogsCount.timelogsNumber,
          })
        )
        .catch(() => {});
    }
  }

  public handleChange = (filters) => {
    this.setState({
      filteredInfo: filters,
    });
  };

  public getRandomColor = (): string => {
    return tagColors[Math.floor(Math.random() * tagColors.length)];
  };

  public getTagColor = (tags: any[], id: number): string | null => {
    const tag = tags.find((item) => item.id === id);
    return tag ? tag.color : null;
  };

  public render() {
    const { t } = this.props;

    return (
      <>
        <div>
          <TitleSection title={t("sideMenu.TIMELOGS")} iconType="clock-circle" />
          <div className="wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <AccessControl
                userPermissions={this.props.permissions.timelogs}
                allowedPermissions={Permissions.CREATE_TIMELOG}>
                <AddTimelog refetch={this.refetch} />
              </AccessControl>
            </div>
            <AccessControl
              userPermissions={this.props.permissions.timelogs}
              allowedPermissions={constants.permissions.LISTS.READ_TIMELOGS}>
              <Query query={GET_NEW_TIMELOGS} variables={{ limit: 30 }} notifyOnNetworkStatusChange={true}>
                {({ loading, error, data, fetchMore, client }: any) => {
                  let timelogs;

                  if (!loading && data?.getTags && data?.getTimelogs) {
                    const allTags = data.getTags.map((item) => ({ ...item, color: this.getRandomColor() }));

                    timelogs = data.getTimelogs.map((timelog) => ({
                      ...timelog,
                      tag: timelog.tag ? { ...timelog.tag, color: this.getTagColor(allTags, timelog.tag.id) } : null,
                      reporter: `${timelog.employee.User.firstName} ${timelog.employee.User.lastName}`,
                      userId: timelog.employee.User.id,
                      project: timelog.project.name,
                    }));
                  }

                  if (error) {
                    return <div>ERROR</div>;
                  }
                  return (
                    <div>
                      <Table
                        onChange={this.handleChange}
                        bordered={false}
                        size="small"
                        dataSource={timelogs}
                        style={{ whiteSpace: "pre-wrap" }}
                        loading={loading}
                        expandedRowRender={(record: { description: string }) => stringToHTML(record.description)}
                        rowKey={() => generateKey()}
                        pagination={{
                          simple: true,
                          total: this.state.timelogsNumber,
                          defaultPageSize: 30,
                          onChange: (page) => {
                            const queryResult = client.readQuery({
                              query: GET_NEW_TIMELOGS,
                              variables: {
                                limit: 30,
                              },
                            });
                            const logs = queryResult.getTimelogs;
                            if (!logs[30 * (page - 1)]) {
                              fetchMore({
                                query: GET_NEW_TIMELOGS,
                                variables: {
                                  cursor: timelogs[timelogs.length - 1].logDate,
                                  limit: 30,
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                  const previousTimelogs = previousResult.getTimelogs;
                                  const newTimelogs = fetchMoreResult.getTimelogs;
                                  if (JSON.stringify(previousResult) !== JSON.stringify(fetchMoreResult)) {
                                    return {
                                      getTimelogs: [...previousTimelogs, ...newTimelogs],
                                    };
                                  } else {
                                    return {
                                      getTimelogs: [...previousTimelogs],
                                    };
                                  }
                                },
                              });
                            }
                          },
                        }}>
                        <Table.Column
                          title={t("timelogsTableColumns.LOGDATE")}
                          key="logDate"
                          width={150}
                          render={({ logDate }) => formatDate(logDate)}
                        />
                        <Table.Column
                          title={t("timelogsTableColumns.REPORTER")}
                          align="left"
                          width={150}
                          key="reporter"
                          render={({ reporter }) => reporter}
                        />
                        <Table.Column
                          key="duration"
                          title={t("timelogsTableColumns.DURATION")}
                          align="center"
                          width={100}
                          render={({ duration }) => secondsToDuration(duration)}
                        />
                        <Table.Column
                          key="tag"
                          title={t("timelogsTableColumns.TAG")}
                          align="center"
                          width={100}
                          render={({ tag }) =>
                            tag ? (
                              <Tag color={tag?.color} style={{ fontSize: "10pt", textAlign: "center" }}>
                                {tag?.name}
                              </Tag>
                            ) : null
                          }
                        />
                        <Table.Column
                          title={t("timelogsTableColumns.PROJECT")}
                          key="project"
                          width={150}
                          render={({ project }) => project}
                        />
                        <Table.Column
                          title={t("timelogsTableColumns.ACTIONS")}
                          width={200}
                          align="center"
                          render={(item, timelog: ITimelog) => {
                            return (
                              <>
                                <AccessControl
                                  userPermissions={this.props.permissions.timelogs}
                                  allowedPermissions={["update", "updateOwn"]}>
                                  <ActionsComponent
                                    t={t}
                                    permissions={this.props.permissions.timelogs}
                                    refetch={this.refetch}
                                    {...timelog}
                                  />
                                </AccessControl>
                              </>
                            );
                          }}
                        />
                      </Table>
                    </div>
                  );
                }}
              </Query>
            </AccessControl>
          </div>
        </div>
      </>
    );
  }
}

const DeleteButton = ({ t, id, refetch, changeDisability }) => {
  return (
    <Mutation
      mutation={DELETE_TIMELOG}
      refetchQueries={[...refetch]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        notification.success({
          message: t("timelogs.MESSAGES.TIMELOG_DELETED"),
        });
      }}>
      {(deleteTimelog, { loading }) => {
        changeDisability(loading);

        return (
          <Popconfirm
            title={t("popConfirms.TITLE")}
            okText={t("popConfirms.ON_OK")}
            onConfirm={() => deleteTimelog({ variables: { id } })}>
            <Button type="danger" icon="delete" className="mr-10" loading={loading} />
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

export default withTranslation()(withApollo<WithTranslation & IProps>(Timelogs));
