import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Icon, Layout, Spin, Tabs } from "antd";
import PerformanceReviewAdmin from "./components/PerformanceReviewCycle/PerformanceReviewCycle";
import { aionManagers } from "./helpers/aionManagers";
import { getUserData } from "common/AccessControl/Permissions";
import { useQuery } from "@apollo/react-hooks";
import { GET_NEW_USERS } from "graphql/queries";
import ReviewRequests from "./components/ReviewRequests/ReviewRequests";

interface IProps {}
const { Content } = Layout;
const { TabPane } = Tabs;

const PerformanceReview: React.FC<IProps> = () => {
  const { t } = useTranslation();

  const { data: userData, loading, error } = useQuery(GET_NEW_USERS);

  const currentUser = getUserData();
  const loggedInUser = currentUser?.id;
  const managers = aionManagers(userData?.getNewUsers);
  const isNotEmployee = managers.includes(loggedInUser);

  const pathname = window.location.pathname;
  const selectedKd = pathname.split("/")[3];

  if (loading) {
    return (
      <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} size="large" />
    );
  }

  if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      <TitleSection title={t("sideMenu.PERFORMANCE_REVIEW")} iconType="reconciliation" />

      <Content className="wrapper-review">
        <Tabs size="small" type="card" defaultActiveKey={selectedKd ? "2" : "1"}>
          {isNotEmployee && (
            <TabPane
              tab={
                <span>
                  <Icon type="deployment-unit" />
                  {t("appConfiguration.PERFORMANCE_REVIEW.REVIEW_CYCLES")}
                </span>
              }
              key="1">
              <PerformanceReviewAdmin />
            </TabPane>
          )}
          <TabPane
            tab={
              <span>
                <Icon type="pull-request" />
                {t("appConfiguration.PERFORMANCE_REVIEW.REVIEW_REQUESTS")}
              </span>
            }
            key={isNotEmployee ? "2" : "1"}>
            <ReviewRequests />
          </TabPane>
        </Tabs>
      </Content>
    </>
  );
};

export default PerformanceReview;
