import React from "react";
import { Tag } from "antd";
import constants from "../../constants/index";

interface IStatus {
  status: string;
}
export default function StatusTag(props: IStatus) {
  if (props.status === constants.leaveRequestStatus.statusApproved) {
    return (
      <Tag
        color="green"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        {props.status.toUpperCase()}
      </Tag>
    );
  } else if (props.status === constants.leaveRequestStatus.statusPending) {
    return (
      <Tag
        color="orange"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        {props.status.toUpperCase()}
      </Tag>
    );
  } else {
    return (
      <Tag
        color="red"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        {props.status.toUpperCase()}
      </Tag>
    );
  }
}
