import React from "react";
import { TFunction } from "i18next";
import { FormComponentProps } from "antd/lib/form";
import { Mutation } from "@apollo/react-components";
import { UPDATE_USER } from "../../graphql/mutations";
import { Button, Col, Form, notification, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface IProps extends FormComponentProps {
  t: TFunction;
  notes: string | null;
  userId: number | undefined;
}

const NotesForm = (t: TFunction, form: any, notes: string | null) => {
  return (
    <Mutation
      mutation={UPDATE_USER}
      onCompleted={() => {
        notification.success({
          message: t("userProfile.MESSAGES.UPDATED_NOTES"),
        });
      }}>
      {(updateNotes, { loading }): any => {
        return (
          <Form layout="vertical" style={{ marginTop: "20px" }}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item>
                  {form.getFieldDecorator("notes", {
                    initialValue: notes,
                  })(<TextArea style={{ width: "100%", height: 200 }} placeholder={t("userProfile.NOTES")} rows={4} />)}
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    updateNotes({
                      variables: { notes: form.getFieldValue("notes") },
                    }).then();
                  }}>
                  {t("userProfile.SAVE")}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Mutation>
  );
};

const NotesReadOnly = (t: TFunction, notes: string) => {
  return <p style={{ whiteSpace: "pre-wrap" }}>{notes}</p>;
};

const Notes: React.FC<IProps> = ({ t, form, notes, userId }) => {
  return <>{userId ? NotesReadOnly(t, notes ?? "") : NotesForm(t, form, notes)}</>;
};

export default Form.create<IProps>()(Notes);
