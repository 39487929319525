import React, { useState } from "react";
import { Spin, Statistic, Empty, Row, Col } from "antd";
import { PieChart, Pie, Cell, Legend, Sector, Tooltip } from "recharts";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
  statistics: any[];
  loading: boolean;
  utilization: {
    utilizationRate: any;
    allWorkedHours: number;
    totalPossibleHours: number;
  };
}
const Utilization = (props: IProps) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (pieData, index) => {
    setActiveIndex(index);
  };

  let data;
  if (props.statistics) {
    data = props.statistics.map(({ projectName: name, workedHours: value }) => ({
      name,
      value,
    }));
  }
  const { t } = props;
  return props.loading ? (
    <div className="spinner">
      <Spin />
    </div>
  ) : (
    <div className="stats">
      <Row>
        <Col span={8}>
          <Statistic
            title={t("analyticsModal.UTILIZATION")}
            value={`${parseFloat(props.utilization.utilizationRate).toFixed(2)}%`}
          />
        </Col>
        <Col span={8}>
          <Statistic title={t("analyticsModal.LOGGED_HOURS")} value={props.utilization.allWorkedHours} />
        </Col>
        <Col span={8}>
          <Statistic title={t("analyticsModal.AVILABLE")} value={props.utilization.totalPossibleHours} />
        </Col>
      </Row>
      {data.length ? (
        <Row className="piechart">
          <Col>
            <PieChart width={472} height={400}>
              <Pie
                isAnimationActive={true}
                dataKey="value"
                data={data}
                activeShape={renderActiveShape}
                activeIndex={activeIndex}
                cx={236}
                labelLine={false}
                label={renderCustomizedLabel}
                cy={200}
                onMouseEnter={(hoverData, index) => onPieEnter(hoverData, index)}
                outerRadius={100}
                fill="#8884d8">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend layout="horizontal" iconType="square" />
              <Tooltip />
            </PieChart>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="no-data-chart" span={24}>
            <Empty description="No timelogs to display chart" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </div>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#38C2C2", "#FF8042"];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

export default withTranslation()(Utilization);
