import React, { Fragment, useEffect, useState } from "react";
import { Button, Progress, Select, Table, Tag } from "antd";
import PerformanceReviewCycleDetails from "./PerformanceReviewCycleDetails";
import { useTranslation } from "react-i18next";
import PerformanceReviewCreate from "../PerformanceReviewCreate/PerformanceReviewCreate";
import { Query } from "@apollo/react-components";
import { GET_REVIEW_CYCLE } from "graphql/queries/Configurations";
import moment from "moment";
import { reviewCyclesType } from "screens/PerformanceReview/types";
import { calculateSubmittedPercentage } from "screens/PerformanceReview/helpers/calculateParticipation";
import PerformanceReviewCycleDelete from "../PerformanceReviewCycleDelete/PerformanceReviewCycleDelete";
import { getUserData } from "common/AccessControl/Permissions";
import { getPariticipationColor } from "screens/PerformanceReview/helpers/paticipationColor";

const PerformanceReviewCycle = () => {
  const { t } = useTranslation();

  const currentUser = getUserData();
  const admin = currentUser?.roles?.some((role) => role.name === "Admin");

  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [year, setYear] = useState(moment().format("YYYY")); // Initialize with current year
  const [selectedReviewee, setSelectedReviewee] = useState();
  const [selectedManager, setSelectedManager] = useState();

  const generateYearOptions = () => {
    const currentYear = parseInt(moment().format("YYYY"));
    const previousYears = Array.from(
      { length: 50 }, // Adjust the number of previous years as needed
      (_, index) => (currentYear - index - 1).toString()
    );
    return previousYears.map((yearOption) => (
      <Select.Option key={yearOption} value={yearOption}>
        {yearOption}
      </Select.Option>
    ));
  };

  const handleRowClick = (rowKey: number, rowData: any) => {
    setSelectedRowKey(rowKey);
    setSelectedRowData(rowData);
  };

  const columns = [
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CREATED_AT"),
      dataIndex: "createdAt",
      render: (createdAt) => <span>{moment(createdAt).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEWEE"),
      dataIndex: "Employee",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER"),
      dataIndex: "Manager",
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_DATE"),
      dataIndex: "expireDate",
      render: (expireDate) => <span>{moment(expireDate).format("DD/MM/YYYY")}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_STATUS"),
      dataIndex: "hasExpired",
      render: (expireDate) => (
        <Tag color={expireDate ? "red" : "orange"}>{expireDate ? "Has expired" : "In progress"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_AT"),
      dataIndex: "closedAt",
      render: (closedAt) => <span>{closedAt ? moment(closedAt).format("DD/MM/YYYY") : "----------"}</span>,
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CLOSED_STATUS"),
      dataIndex: "closedAt",
      render: (closedAt) => (
        <Tag color={closedAt != null ? "red" : "green"}>{closedAt != null ? "Closed" : "Opened"}</Tag>
      ),
    },
    {
      title: t("appConfiguration.PERFORMANCE_REVIEW.LABELS.PARTICIPATION"),
      key: "action",
      render: (row: reviewCyclesType) => (
        <>
          <Progress
            strokeColor={getPariticipationColor(Number(calculateSubmittedPercentage(row)))}
            percent={Number(calculateSubmittedPercentage(row))}
          />
        </>
      ),
    },
    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row: reviewCyclesType) => (
        <span style={{ display: "flex", gap: 5 }}>
          <Button size="small" type="primary" icon="unordered-list" onClick={() => handleRowClick(row?.id, row)}>
            View Details
          </Button>
          {admin && <PerformanceReviewCycleDelete t={t} rowId={row?.id} />}
        </span>
      ),
    },
  ];

  const handleBackButtonClick = () => {
    setSelectedRowKey(null);
  };

  return (
    <Fragment>
      <Query query={GET_REVIEW_CYCLE}>
        {({ loading, error, data }: any) => {
          let reviewCycles: reviewCyclesType[] = [];

          if (!loading && data?.getReviewCycles) {
            reviewCycles = data?.getReviewCycles;
          }

          const revieweeNameOptions = Array.from(new Set(reviewCycles?.map((review: any) => review?.Employee)));
          const managerNameOptions = Array.from(new Set(reviewCycles?.map((review: any) => review?.Manager)));

          let filteredReviewCycles = reviewCycles;

          if (year) {
            filteredReviewCycles = filteredReviewCycles.filter(
              (item: any) => moment(item?.createdAt).format("YYYY") === year
            );
          }
          if (selectedReviewee) {
            filteredReviewCycles = filteredReviewCycles.filter((item: any) => item?.Employee === selectedReviewee);
          }
          if (selectedManager) {
            filteredReviewCycles = filteredReviewCycles.filter((item: any) => item?.Manager === selectedManager);
          }

          if (error) {
            return <p>Error</p>;
          }

          return (
            <div>
              <span style={{ display: "flex", gap: 20 }}>
                {!selectedRowKey && <PerformanceReviewCreate t={t} />}
                {!selectedRowKey && (
                  <>
                    <Select
                      showSearch
                      value={year}
                      onChange={(value) => setYear(value)}
                      style={{ marginBottom: 16, width: 100 }}>
                      <Select.Option value={moment().format("YYYY")}>{moment().format("YYYY")}</Select.Option>
                      {generateYearOptions()}
                    </Select>
                    <Select
                      allowClear
                      showSearch
                      value={selectedReviewee}
                      placeholder={"Filter by reviewee"}
                      onChange={(value) => setSelectedReviewee(value)}
                      style={{ marginBottom: 16, width: 200 }}>
                      {revieweeNameOptions?.map((nameOptions) => (
                        <Select.Option value={nameOptions}>{nameOptions}</Select.Option>
                      ))}
                    </Select>
                    <Select
                      allowClear
                      showSearch
                      value={selectedManager}
                      placeholder={"Filter by manager"}
                      onChange={(value) => setSelectedManager(value)}
                      style={{ marginBottom: 16, width: 200 }}>
                      {managerNameOptions?.map((nameOptions) => (
                        <Select.Option value={nameOptions}>{nameOptions}</Select.Option>
                      ))}
                    </Select>
                  </>
                )}
              </span>
              {!selectedRowKey && (
                <Table loading={loading} size="middle" dataSource={filteredReviewCycles} columns={columns} />
              )}
              {selectedRowKey && (
                <>
                  <Button style={{ marginBottom: 15 }} icon="left" type="default" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <PerformanceReviewCycleDetails selectedReview={selectedRowData} t={t} />
                </>
              )}
            </div>
          );
        }}
      </Query>
    </Fragment>
  );
};

export default PerformanceReviewCycle;
