import React from "react";
import { Mutation } from "@apollo/react-components";
import { Button, notification, Popconfirm } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
import { UNASSIGN_EMPLOYEE_FROM_PROJECT } from "../../../../../../graphql/mutations";
import { GET_PROJECT_ASSIGNABLE_EMPLOYEES } from "../../../../../../graphql/queries";
import { DataProxy } from "apollo-cache";
interface IQuery {
  Project: {
    assignableEmployees: any[];
    assignableClients: any[];
  };
}
interface IProps extends WithTranslation {
  id: any;
  refetch: any;
  projectId: any;
}
const UnassignButton: React.FC<IProps> = (props) => {
  const { t } = props;
  return (
    <Mutation
      mutation={UNASSIGN_EMPLOYEE_FROM_PROJECT}
      refetchQueries={() => {
        return [...props.refetch];
      }}
      update={(proxy: DataProxy, response) => {
        notification.success({
          message: t("projectsTableColumns.messages.UNASSIGN"),
        });
        const Project = proxy.readQuery<IQuery>({
          query: GET_PROJECT_ASSIGNABLE_EMPLOYEES,
          variables: { id: props.projectId },
        })?.Project;
        const { data } = response;
        const {
          unAssignEmployeeFromProject: { Employee },
        } = data;
        proxy.writeQuery({
          query: GET_PROJECT_ASSIGNABLE_EMPLOYEES,
          variables: { id: props.projectId },
          data: {
            Project: {
              ...Project,
              assignableEmployees: Project?.assignableEmployees.concat([Employee]),
            },
          },
        });
      }}>
      {(unassignEmployee) => {
        return (
          <Popconfirm
            title="Are you sure?"
            okText="Sure"
            onConfirm={() =>
              unassignEmployee({
                variables: {
                  id: props.id,
                },
              })
            }>
            <Button type="danger" icon="delete">
              {t("projectsTableColumns.buttons.UNASSIGN")}
            </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

export default withTranslation()(UnassignButton);
