import React from "react";
import { Tag } from "antd";

interface IStatus {
  approved: boolean;
}
export default function ApprovalTag(props: IStatus) {
  if (props.approved) {
    return (
      <Tag
        color="green"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        Approved
      </Tag>
    );
  } else {
    return (
      <Tag
        color="red"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        Not Approved
      </Tag>
    );
  }
}
