import ApolloClient from "../../graphql/ApolloClient";
import { GET_USER, GET_USER_PERMISSIONS } from "../../graphql/queries";
import { IPermissionsFunc } from "./Interfaces";
import { Permissions } from "__generated__/globalTypes";
import { getPermissions as TGetPermissions } from "__generated__/getPermissions";

const getPermissions: IPermissionsFunc = () => {
  const response: TGetPermissions | null = ApolloClient.readQuery({
    query: GET_USER_PERMISSIONS,
  });

  const permissions: Permissions | null = response && response.userPermissions ? response.userPermissions : null;

  return permissions;
};

export const getUserData = () => {
  const response = ApolloClient.readQuery({
    query: GET_USER,
  });

  return response.User;
};

export const writePermissions = async () => {
  try {
    return await ApolloClient.query({
      query: GET_USER_PERMISSIONS,
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const writeUserData = async () => {
  try {
    return await ApolloClient.query({
      query: GET_USER,
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getViewName = (permissions: [string], roles: [any]): string => {
  if (roles.find(({ isInternal }) => !isInternal)) {
    return "client";
  } else if (permissions.find((permission) => permission === "read")) {
    return "manager";
  } else if (
    permissions.find((permission) => permission === "readOwn") ||
    permissions.find((permission) => permission === "createOwn")
  ) {
    return "employee";
  } else {
    return "none";
  }
};

export default getPermissions;
