import React, { useState } from "react";
import { Button, Form, DatePicker, Dropdown, Menu, Icon, Modal, Col, Row, notification } from "antd";
import moment from "moment";
import { Mutation } from "@apollo/react-components";
import UploadFile from "screens/LeaveManagement/UnderManagement/components/UploadFile";
import { UPLOAD_CONTRACT, CLOSE_CONTRACT } from "../../../graphql/mutations";
import { getContracts } from "graphql/queries/Contracts";
import { withTranslation } from "react-i18next";
import UploadContractPdf from "./uploadContractPdf";
const ActionsComponent: React.FC<any> = ({ item, t, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCancel = () => setIsModalOpen(false);

  const disableDate = (current) => {
    return moment(current).isBefore(item.startDate, "date");
  };

  return (
    <>
      <Mutation
        mutation={CLOSE_CONTRACT}
        refetchQueries={[{ query: getContracts }]}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });

          form.resetFields();
        }}
        onCompleted={() => {
          notification.success({
            message: t("contracts.CONTRACT_STOPPED"),
          });
          onCancel();
        }}>
        {(updateContract, { loading }) => (
          <Modal
            title={t("contracts.modalNames.STOP_CONTRACT", {
              name: `${item.employee.User.firstName} ${item.employee.User.lastName}`,
            })}
            visible={isModalOpen}
            okButtonProps={{
              loading,
            }}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { endDate } = values;
                  updateContract({
                    variables: {
                      endDate: moment(endDate).format("YYYY-MM-DD"),
                      contractId: item.id,
                    },
                  });
                  setIsModalOpen(false);
                }
              });
            }}
            onCancel={onCancel}
            afterClose={() => {
              form.resetFields();
            }}>
            <Form layout="horizontal">
              <Row>
                <Col span={10}>
                  <Form.Item label={t("contracts.form.END_DATE")}>
                    {form.getFieldDecorator("endDate", {
                      rules: [
                        {
                          required: true,
                          message: t("contracts.form.required.END_DATE"),
                        },
                      ],
                      initialValue: moment(),
                    })(<DatePicker disabledDate={disableDate} format="YYYY-MM-DD" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
      <Dropdown
        placement="topRight"
        trigger={["click"]}
        overlay={
          <Menu>
            {/* <Menu.Item key="1" onClick={() => window.open(item?.url as string, "_blank")} disabled={!item?.url}>
              <Icon type="file-pdf" />
              {t("contracts.form.EXPORT_PDF")}
            </Menu.Item> */}
            {item.permissions?.contracts.includes("update") ? (
              <Menu.Item key="2">
                <Button size="small" type="danger" ghost onClick={() => setIsModalOpen(true)} disabled={item?.endDate}>
                  <Icon type="stop" />
                  {t("contracts.modalNames.STOP")}
                </Button>
              </Menu.Item>
            ) : null}
            {item.permissions?.contracts.includes("update") ? (
              <Menu.Item key="3">
                <UploadContractPdf id={item?.id} mutation={UPLOAD_CONTRACT} />
              </Menu.Item>
            ) : null}
          </Menu>
        }>
        <Button size="default">
          <Icon type="cloud-server" />
          {"Options"}
        </Button>
      </Dropdown>
    </>
  );
};

export default Form.create<any>()(withTranslation()(ActionsComponent));
