import React from "react";
import { FormComponentProps } from "antd/lib/form";
import { Button, Checkbox, Col, Form, InputNumber, notification, Row, Divider } from "antd";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { UPDATE_CLIENT } from "../../../graphql/mutations";

interface IProps extends FormComponentProps {
  clientId: number;
  daysDue: number | null;
  active: boolean;
  t: TFunction;
}

const Configuration: React.FC<IProps> = ({ clientId, form, daysDue, active, t }) => {
  return (
    <>
      <Mutation
        mutation={UPDATE_CLIENT}
        onCompleted={() => {
          notification.success({
            message: t("clients.CLIENT_UPDATED"),
          });
        }}>
        {(updateClientInfo, { loading }): any => {
          return (
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item label={t("projectsTableColumns.placeholder.INVOICE_DUE_DAYS")} hasFeedback={true}>
                    {form.getFieldDecorator("invoiceDueDays", {
                      initialValue: daysDue,
                    })(<InputNumber style={{ width: "100%" }} placeholder="20" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={4}>
                  <Form.Item label={t("projectsTableColumns.placeholder.ACTIVE_STATUS")}>
                    {form.getFieldDecorator(`activeStatus_${clientId}`, {
                      valuePropName: "checked",
                      initialValue: active,
                    })(<Checkbox />)}
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={2}>
                  <Button
                    type="primary"
                    loading={loading}
                    style={{ marginTop: "29px" }}
                    onClick={() => {
                      updateClientInfo({
                        variables: {
                          clientId,
                          invoiceDaysDue: form.getFieldValue("invoiceDueDays"),
                          active: form.getFieldValue(`activeStatus_${clientId}`),
                        },
                      }).then();
                    }}>
                    {t("userProfile.SAVE")}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(Configuration);
