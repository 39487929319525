import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { List, Avatar, Spin, Empty, Button } from "antd";
import moment from "moment";
import { TFunction } from "i18next";
import { getContracts } from "graphql/queries/Contracts";
import { anniversaryActiveImg, anniversaryImg } from "helpers/appImages";
import { getDaysFromToday } from "./helpers/anniverseriesHelper";

interface IProps {
  t: TFunction;
}

const HomeAnniversaries: React.FC<IProps> = ({ t }) => {
  const { data, loading, error } = useQuery(getContracts);
  const userDatas = data?.contracts;
  const [visibleItems, setVisibleItems] = useState(3);

  if (loading) {
    return <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} size="default" />;
  }
  if (!data || !data.contracts) {
    return <Empty />;
  }

  if (error) {
    return <p>Error</p>;
  }

  // Filter out Anniversaries with NaN values
  const validAnniversaries = userDatas.filter((anniversaryItem: any) => !isNaN(Date.parse(anniversaryItem.startDate)));
  const today = moment().startOf("day");

  // Get upcoming Anniversaries for this year
  const upcomingAnniversaries = validAnniversaries
    .filter((anniversaryItem: any) => {
      const anniversary = moment(anniversaryItem.startDate, "YYYY-MM-DD");
      const currentYear = moment().year();
      const anniversaryThisYear = anniversary.set("year", currentYear);
      return anniversaryThisYear.isSameOrAfter(moment(), "day");
    })
    .sort((a: any, b: any) => {
      const anniversaryA = moment(a.startDate, "YYYY-MM-DD");
      const anniversaryB = moment(b.startDate, "YYYY-MM-DD");
      return anniversaryA.diff(anniversaryB);
    });

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };

  return (
    <>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={upcomingAnniversaries.slice(0, visibleItems)}
        renderItem={(anniversaryItem: any) => {
          const currentYear = moment().year();
          const anniversary = moment(anniversaryItem.startDate, "YYYY-MM-DD");
          const yearsOfCelebration = moment().year() - anniversary.year();
          const daysFromToday = getDaysFromToday(anniversaryItem);

          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    src={anniversary.isSame(today, "day") ? anniversaryActiveImg : anniversaryImg}
                  />
                }
                title={anniversaryItem?.employee?.User?.firstName + " " + anniversaryItem?.employee?.User?.lastName}
                description={`Anniversary: ${anniversary.set("year", currentYear).format("DD/MM/YYYY")} ${
                  anniversary.isSame(today, "day")
                    ? `(🥳 Congratulations ${anniversaryItem?.employee?.User?.firstName}) Celebrating ${yearsOfCelebration} years)`
                    : `(${daysFromToday} days from today)`
                }`}
              />
            </List.Item>
          );
        }}
      />
      {upcomingAnniversaries.length > visibleItems && (
        <Button size="small" onClick={handleLoadMore}>
          Load More
        </Button>
      )}
    </>
  );
};

export default HomeAnniversaries;
