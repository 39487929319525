import React from "react";
import { Button, notification, Popconfirm } from "antd";
import {
  DELETE_LEAVE_REQUEST,
  UPDATE_LEAVE_REQUEST,
  UPDATE_LEAVE_REQUEST_STATUS,
} from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_LEAVE_REQUEST_DETAILS } from "../../../../graphql/queries/LeaveManagementQueries";
import { Mutation } from "@apollo/react-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as constants from "../../../../constants/leaveRequestStatus";
import * as roleType from "../../../../constants/employmentRoleType";

interface IProps {
  t: any;
  data: {
    leaveRequestId: number;
    deputyStatus: string;
    employeeStatus: string;
    managerStatus: string;
    employee: string;
    employeeId: number;
    managerId: number;
    deputyId: number;
    selectedYear: string;
  };
  loggedUserId?: number;
}

const ActionsCompomonent = (props: RouteComponentProps & IProps) => {
  const { leaveRequestId, employeeStatus, deputyStatus, managerStatus, employee, employeeId, managerId, deputyId } =
    props.data;

  const { t } = props;
  return (
    <>
      {managerId === props.loggedUserId &&
        employeeStatus !== constants.statusCancel &&
        deputyStatus !== constants.statusPending &&
        managerStatus !== constants.statusApproved &&
        managerId !== deputyId && (
          <div className="deputy-buttons">
            <ApproveSubmitRequest
              t={t}
              id={leaveRequestId}
              action="confirm"
              history={props.history}
              role={roleType.MANAGER}
            />
            <DeclineSubmitRequest
              t={t}
              id={leaveRequestId}
              action="decline"
              history={props.history}
              role={roleType.MANAGER}
            />
          </div>
        )}

      {managerId === props.loggedUserId && employeeStatus === constants.statusCancel && managerId !== employeeId && (
        <div className="deputy-buttons">
          <ManagerApproveCancelRequest t={t} id={leaveRequestId} history={props.history} />
          <ManagerDeclineCancelRequest
            t={t}
            id={leaveRequestId}
            history={props.history}
            employeeName={employee}
            status={constants.statusActive}
          />
        </div>
      )}

      {deputyId === props.loggedUserId &&
        employeeStatus === constants.statusActive &&
        deputyStatus === constants.statusPending &&
        managerId !== deputyId && (
          <div className="deputy-buttons">
            <ApproveSubmitRequest
              t={t}
              id={leaveRequestId}
              action="confirm"
              history={props.history}
              role={roleType.DEPUTY}
            />
            <DeclineSubmitRequest
              t={t}
              id={leaveRequestId}
              action="decline"
              history={props.history}
              role={roleType.DEPUTY}
            />
          </div>
        )}
    </>
  );
};

const ApproveSubmitRequest = ({ id, t, action, history, role }) => {
  return (
    <>
      <Mutation
        mutation={UPDATE_LEAVE_REQUEST_STATUS}
        refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
        onCompleted={() => {
          notification.success({
            message:
              role === roleType.MANAGER
                ? t("leaveRequests.MESSAGES.LEAVE_REQUEST_APPROVED")
                : t("leaveRequests.MESSAGES.APPROVED_DEPUTY_REQUEST"),
          });
          history.goBack();
        }}>
        {(updateLeaveRequestStatus, { loading }): any => {
          return (
            <>
              <Button
                icon="like"
                type="primary"
                loading={loading}
                onClick={() => {
                  updateLeaveRequestStatus({
                    variables: { id, action },
                  });
                }}
                className="deputy-button1">
                {t("buttons.APPROVE")}
              </Button>
            </>
          );
        }}
      </Mutation>
    </>
  );
};

const DeclineSubmitRequest = ({ id, t, action, history, role }) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST_STATUS}
      refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
      onCompleted={() => {
        notification.success({
          message:
            role === roleType.MANAGER
              ? t("leaveRequests.MESSAGES.LEAVE_REQUEST_DECLINED")
              : t("leaveRequests.MESSAGES.DECLINED_DEPUTY_REQUEST"),
        });
        history.goBack();
      }}>
      {(updateLeaveRequestStatus, { loading }): any => {
        return (
          <Popconfirm
            title={t("popConfirms.TITLE")}
            okText={t("popConfirms.ON_OK")}
            onConfirm={() => {
              updateLeaveRequestStatus({ variables: { id, action } });
            }}>
            <Button loading={loading} type="danger" icon="dislike" className="deputy-button2">
              {t("buttons.DECLINE")}
            </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

const ManagerApproveCancelRequest = ({ t, id, history }) => {
  return (
    <Mutation
      mutation={DELETE_LEAVE_REQUEST}
      refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
      onCompleted={() => {
        notification.success({
          message: t("leaveRequests.MESSAGES.DELETED_LEAVE_REQUEST"),
        });
        history.goBack();
      }}>
      {(deleteLeaveRequest, { loading }): any => {
        return (
          <>
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                deleteLeaveRequest({ variables: { id } });
              }}>
              <Button loading={loading} type="primary" icon="like" className="deputy-button1">
                {t("buttons.APPROVE")}
              </Button>
            </Popconfirm>
          </>
        );
      }}
    </Mutation>
  );
};

const ManagerDeclineCancelRequest = ({ t, id, history, employeeName, status }) => {
  return (
    <Mutation
      mutation={UPDATE_LEAVE_REQUEST}
      refetchQueries={[{ query: GET_LEAVE_REQUEST_DETAILS, variables: { id } }]}
      onCompleted={() => {
        notification.success({
          message: `${employeeName}'s ${t("leaveRequests.MESSAGES.DECLINED_CANCEL_REQUEST")}`,
        });
        history.goBack();
      }}>
      {(updateLeaveRequest, { loading }): any => {
        return (
          <>
            <Popconfirm
              title={t("popConfirms.TITLE")}
              okText={t("popConfirms.ON_OK")}
              onConfirm={() => {
                updateLeaveRequest({ variables: { id, employeeStatus: status } });
              }}>
              <Button loading={loading} type="danger" icon="dislike" className="deputy-button2">
                {t("buttons.DECLINE")}
              </Button>
            </Popconfirm>
          </>
        );
      }}
    </Mutation>
  );
};

export default withRouter(ActionsCompomonent);
