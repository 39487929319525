import React from "react";
import { TFunction } from "i18next";
import CompanyBranchesTable from "./components/CompanyBranchesTable";
import CompanyBranchesCreate from "./components/CompanyBranchesCreate";

interface IProps {
  t: TFunction;
}

const Tags: React.FC<IProps> = ({ t }) => {
  return (
    <div>
      <CompanyBranchesCreate t={t} />
      <CompanyBranchesTable t={t} />
    </div>
  );
};

export default Tags;
