import React from "react";
import { TFunction } from "i18next";
import { IReportStatistics } from "../../../graphql/queries/Interfaces";

interface IProps {
  t: TFunction;
  stats: IReportStatistics;
}

const SummaryReport: React.FC<IProps> = ({ t, stats }) => {
  return (
    <div className="reportStatistics">
      <span className="stats totalHours">{t("reportsTableColumns.TOTAL_HOURS", { hours: stats.totalHours })}</span>
      &nbsp;&nbsp;&nbsp;
      <span className="stats timeLogs">
        {t("reportsTableColumns.NUMBER_OF_TIME_LOGS", {
          timelogs: stats.totalTimelogsCount,
        })}
      </span>
    </div>
  );
};

export default SummaryReport;
