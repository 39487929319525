import React, { useState } from "react";
import { TFunction } from "i18next";
import { Mutation } from "@apollo/react-components";
import { Button, Col, Input, InputNumber, Form, Modal, notification, Row, Select, DatePicker } from "antd";
import { FormComponentProps } from "antd/lib/form";
import {
  GET_ABSENCE_TYPES,
  GET_COMPANY_BRANCH,
  GET_COMPANY_BRANCH_BY_ID,
} from "../../../../../graphql/queries/Configurations";
import TextArea from "antd/lib/input/TextArea";
import { UPDATE_ABSENCE_TYPE } from "graphql/mutations";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { absenceTypesEnum } from "./AbsenceTypesEnum";
interface IProps extends FormComponentProps {
  t: TFunction;
  absenceType: any;
}

const { Option } = Select;

const AbsenceTypesEdit: React.FC<IProps> = ({ t, absenceType, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const [expirationDateValue, setExpirationDateValue] = useState(absenceType.expirationDate);

  const { loading: loadingBranch, data } = useQuery(GET_COMPANY_BRANCH, { fetchPolicy: "no-cache" });
  const { data: oneBranchData } = useQuery(GET_COMPANY_BRANCH_BY_ID, {
    variables: { id: absenceType.CompanyBranchId },
  });

  const initialValueCompanyBranchCountry = oneBranchData?.getCompanyBranchById?.country
    ? oneBranchData?.getCompanyBranchById?.country
    : "loading..";
  const initialValueCompanyBranchCity = oneBranchData?.getCompanyBranchById?.city
    ? oneBranchData?.getCompanyBranchById?.city
    : "loading..";

  return (
    <>
      <Button icon="edit" onClick={toggleModal} />
      <Mutation
        mutation={UPDATE_ABSENCE_TYPE}
        refetchQueries={[
          {
            query: GET_ABSENCE_TYPES,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.ABSENCE_TYPE_UPDATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(updateAbsenceType, { loading }) => (
          <Modal
            title={t("appConfiguration.ABSENCE_TYPES.EDIT_ABSENCE_TYPE")}
            width={600}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { absenceTypeName, description, numberOfDays, CompanyBranchId } = values;

                  updateAbsenceType({
                    variables: {
                      id: absenceType.id,
                      absenceTypeName,
                      description,
                      CompanyBranchId,
                      numberOfDays,
                      expirationDate: expirationDateValue,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.ABSENCE_TYPE_NAME")}>
                    {form.getFieldDecorator("absenceTypeName", {
                      initialValue: absenceType.absenceTypeName,
                      rules: [
                        {
                          required: false,
                          type: "string",
                        },
                      ],
                    })(
                      <Select placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.ABSENCE_TYPE_NAME")}>
                        {absenceTypesEnum.map((absenceItem) => (
                          <Option value={absenceItem} key={absenceItem}>
                            {absenceItem}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.HOLIDAY_TYPES.LABELS.COMPANY_BRANCH")}>
                    {form.getFieldDecorator("CompanyBranchId", {
                      rules: [
                        {
                          required: false,
                          message: t("appConfiguration.HOLIDAY_TYPES.MESSAGES.COMPANY_BRANCH_REQUIRED"),
                        },
                      ],
                      initialValue: oneBranchData?.getCompanyBranchById.id,
                    })(
                      <Select
                        showSearch
                        value={initialValueCompanyBranchCountry + " / " + initialValueCompanyBranchCity}
                        defaultValue={initialValueCompanyBranchCountry + " / " + initialValueCompanyBranchCity}
                        loading={loadingBranch}
                        placeholder={
                          loadingBranch
                            ? "Loading branch..."
                            : t("appConfiguration.HOLIDAY_TYPES.PLACE_HOLDERS.COMPANY_BRANCH")
                        }>
                        {data?.getCompanyBranches.map((branchItem) => (
                          <Option key={branchItem.id} value={branchItem.id}>
                            {branchItem.country + " / " + branchItem.city}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.HOLIDAY_LIMIT")}>
                    {form.getFieldDecorator("numberOfDays", {
                      initialValue: absenceType.numberOfDays,
                      rules: [
                        {
                          required: false,
                          type: "number",
                        },
                      ],
                    })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.HOLIDAY_LIMIT")}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.ABSENCE_TYPES.LABELS.EXPIRATION_DATE")}>
                    {form.getFieldDecorator("expirationDate", {
                      rules: [
                        {
                          required: false,
                          message: t("appConfiguration.ABSENCE_TYPES.MESSAGES.EXPIRATION_DATE_REQUIRED"),
                        },
                      ],
                      initialValue: moment(expirationDateValue),
                    })(
                      <DatePicker
                        allowClear={false}
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => setExpirationDateValue(dateString || "")}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("appConfiguration.ABSENCE_TYPES.LABELS.DESCRIPTION")}>
                    {form.getFieldDecorator("description", {
                      initialValue: absenceType.description,
                      rules: [
                        {
                          required: false,
                          type: "string",
                        },
                      ],
                    })(
                      <TextArea
                        style={{ width: "100%" }}
                        placeholder={t("appConfiguration.ABSENCE_TYPES.PLACE_HOLDERS.DESCRIPTION")}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </>
  );
};

export default Form.create<IProps>()(AbsenceTypesEdit);
