import React from "react";
import { Mutation } from "@apollo/react-components";
import { Button, Col, DatePicker, Form, Modal, Row, Select, notification } from "antd";
import { CREATE_REVIEW_CYCLE } from "graphql/mutations";
import { GET_REVIEW_CYCLE } from "graphql/queries/Configurations";
import { TFunction } from "i18next";
import { Fragment, useState } from "react";
import { FormComponentProps } from "antd/lib/form";
import moment, { Moment } from "moment";
import { useQuery } from "@apollo/react-hooks";
import { GET_NEW_USERS } from "graphql/queries";
import { aionManagers, aionManagersButNoAdmin } from "screens/PerformanceReview/helpers/aionManagers";
import { getUserData } from "common/AccessControl/Permissions";

interface IProps extends FormComponentProps {
  t: TFunction;
}
const { Option } = Select;

const PerformanceReviewCreate: React.FC<IProps> = ({ t, form }) => {
  const [modalOpen, setToggleModal] = useState<boolean>(false);
  const [selectedExpireDate, setSelectedExpireDate] = useState<moment.Moment | null>(null);

  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
  const [selectedPeerReviewers, setSelectedPeerReviewers] = useState<string[]>([]);
  const [selectedManagerReviewers, setSelectedManagerReviewers] = useState<string[]>([]);

  const handleChange = (value: string | string[], selectType: string) => {
    switch (selectType) {
      case "employee":
        setSelectedEmployee(value as string | null);
        break;
      case "peerReviewers":
        setSelectedPeerReviewers(value as string[]); // Assuming selectedPeerReviewers is an array of strings
        break;
      case "managerReviewers":
        setSelectedManagerReviewers(value as string[]); // Assuming selectedManagerReviewers is an array of strings
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      const formattedDateTime = `${formattedDate}T00:00:00`;
      setSelectedExpireDate(moment.utc(formattedDateTime));
    } else {
      setSelectedExpireDate(null);
    }
  };

  const toggleModal = () => {
    setToggleModal(!modalOpen);
  };

  const { data: userData } = useQuery(GET_NEW_USERS);

  const usersNames = userData?.getNewUsers;

  const usersWithoutClients = usersNames
    ?.map((username) => {
      const userIsClient = username.roles?.find((role) => role?.name === "Client");

      if (!userIsClient) {
        return username;
      }

      return undefined;
    })
    .filter((filteredUser) => filteredUser);

  const currentUser = getUserData();

  const loggedInUser = currentUser?.id;

  const managers = aionManagers(usersNames);
  const managersButNotAdmin = aionManagersButNoAdmin(usersNames, managers);
  const userIsManagerButNotAdmin = managersButNotAdmin.includes(loggedInUser);

  // Filter the employees based on the managerId
  const mentees = usersNames?.filter((user) => user?.employee?.managerId === loggedInUser);

  return (
    <Fragment>
      <span>
        <Button style={{ marginBottom: 15 }} icon="form" type="primary" onClick={toggleModal}>
          {t("appConfiguration.PERFORMANCE_REVIEW.LABELS.CREATE_REVIEW_CYCLE")}
        </Button>
      </span>
      <Mutation
        mutation={CREATE_REVIEW_CYCLE}
        refetchQueries={[
          {
            query: GET_REVIEW_CYCLE,
          },
        ]}
        update={() => {
          notification.success({
            message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.PERFORMANCE_REVIEW_CYCLE_CREATED"),
          });
          toggleModal();
        }}
        onError={(error) => {
          notification.error({
            message: error.message.split(":")[1],
          });
        }}>
        {(createEmployeeAbsenceLog, { loading }) => (
          <Modal
            title={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEW_CYCLE")}
            width={500}
            centered={true}
            okButtonProps={{
              loading,
            }}
            visible={modalOpen}
            onOk={() => {
              form.validateFields((err, values) => {
                if (!err) {
                  const { EmployeeId, peerReviewers, managerReviewers } = values;

                  createEmployeeAbsenceLog({
                    variables: {
                      EmployeeId,
                      expireDate: selectedExpireDate,
                      peerReviewers,
                      managerReviewers,
                    },
                  });
                }
              });
            }}
            afterClose={() => {
              form.resetFields();
            }}
            onCancel={toggleModal}>
            <Form layout="vertical">
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EMPLOYEE_REVIEW")}>
                    {form.getFieldDecorator("EmployeeId", {
                      rules: [
                        {
                          required: true,
                          type: "number",
                          message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.REVIEWEE_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        loading={loading}
                        showSearch
                        allowClear
                        placeholder={t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.SELECT_EMPLOYEE")}
                        filterOption={(inputValue, option: any) =>
                          option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        onChange={(value: string) => handleChange(value, "employee")}>
                        {userIsManagerButNotAdmin
                          ? mentees
                              ?.filter(
                                (user) =>
                                  !selectedPeerReviewers.includes(user?.id) &&
                                  !selectedManagerReviewers.includes(user?.id) &&
                                  loggedInUser !== user?.id
                              )
                              .map((item) => (
                                <Option key={item?.id} value={item?.id}>
                                  {`${item?.firstName} ${item?.lastName}`}
                                </Option>
                              ))
                          : usersWithoutClients
                              ?.filter(
                                (user) =>
                                  !selectedPeerReviewers.includes(user?.id) &&
                                  !selectedManagerReviewers.includes(user?.id) &&
                                  loggedInUser !== user?.id
                              )
                              .map((item) => (
                                <Option key={item?.id} value={item?.id}>
                                  {`${item?.firstName} ${item?.lastName}`}
                                </Option>
                              ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item hasFeedback={true} label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.EXPIRE_DATE")}>
                    {form.getFieldDecorator("expireDate", {
                      rules: [
                        {
                          required: true,
                          validator: (rule, value) => {
                            if (!value || value.length === 0) {
                              return Promise.reject(
                                t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.EXPIRE_DATE_REQUIRED")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ],
                    })(
                      <DatePicker
                        allowClear={false}
                        style={{ width: "100%" }}
                        onChange={handleDateChange}
                        value={selectedExpireDate}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24}>
                  <Form.Item
                    style={{ width: 400 }}
                    hasFeedback={true}
                    label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.PEER_REVIWERS")}>
                    {form.getFieldDecorator("peerReviewers", {
                      rules: [
                        {
                          required: true,
                          type: "array",
                          message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.PEER_REVIEWERS_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        loading={loading}
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder={t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.PEER_REVIEWERS")}
                        filterOption={(inputValue, option: any) =>
                          option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        onChange={(value: string[]) => handleChange(value, "peerReviewers")}>
                        {usersWithoutClients
                          ?.filter(
                            (user) => user?.id !== selectedEmployee && !selectedManagerReviewers.includes(user?.id)
                          )
                          .map((item) => (
                            <Option key={item?.id} value={item?.id}>
                              {`${item?.firstName} ${item?.lastName}`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24}>
                  <Form.Item
                    style={{ width: 400 }}
                    hasFeedback={true}
                    label={t("appConfiguration.PERFORMANCE_REVIEW.LABELS.MANAGER_REVIEW")}>
                    {form.getFieldDecorator("managerReviewers", {
                      rules: [
                        {
                          required: true,
                          type: "array",
                          message: t("appConfiguration.PERFORMANCE_REVIEW.MESSAGES.MANAGER_REVIEW_REQUIRED"),
                        },
                      ],
                    })(
                      <Select
                        loading={loading}
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder={t("appConfiguration.PERFORMANCE_REVIEW.PLACE_HOLDERS.MANAGER_REVIEW")}
                        filterOption={(inputValue, option: any) =>
                          option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        onChange={(value: string[]) => handleChange(value, "managerReviewers")}>
                        {usersWithoutClients
                          ?.filter((user) => user?.id !== selectedEmployee && !selectedPeerReviewers.includes(user?.id))
                          .map((item) => (
                            <Option key={item?.id} value={item?.id}>
                              {`${item?.firstName} ${item?.lastName}`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </Mutation>
    </Fragment>
  );
};
export default Form.create<IProps>()(PerformanceReviewCreate);
