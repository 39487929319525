import React from "react";
import { Table, Select } from "antd";
import { generateKey } from "../../../../utils/listKey";
import { withTranslation, WithTranslation } from "react-i18next";
const { Option } = Select;
const customRenderer = (permissions, type, resource, onChange) => {
  let defaultValue;
  permissions = permissions || [];

  if (permissions.length === 2) {
    defaultValue = "All";
  } else if (permissions.length === 1) {
    defaultValue = "Own";
  } else {
    defaultValue = "None";
  }
  return (
    <>
      <Select onChange={(value) => onChange(value, resource, type)} defaultValue={defaultValue} style={{ width: 80 }}>
        <Option value="Own">Own</Option>
        <Option value="All">All</Option>
        <Option value="None">-</Option>
      </Select>
    </>
  );
};

interface IProps extends WithTranslation {
  permissions: any[];
  loading: boolean;
  onChange: (value: string, resource: string, type: string) => void;
}
const PermissionsTable = ({ permissions, loading, onChange, t }: IProps) => {
  return (
    <div style={{ margin: "20px 0" }}>
      <Table
        dataSource={permissions}
        className="permissions-table"
        rowKey={() => generateKey()}
        pagination={false}
        size="small"
        loading={loading}>
        <Table.Column
          title={t("permissionsTableColumns.RESOURCE")}
          key="resource"
          align="center"
          render={({ resource }) => resource.toUpperCase()}
        />
        <Table.Column
          title={t("permissionsTableColumns.VIEW")}
          key="readPermissions"
          align="center"
          render={({ read, resource }) => customRenderer(read, "read", resource, onChange)}
        />
        <Table.Column
          title={t("permissionsTableColumns.CREATE")}
          key="createPermissions"
          align="center"
          render={({ create, resource }) => customRenderer(create, "create", resource, onChange)}
        />
        <Table.Column
          title={t("permissionsTableColumns.UPDATE")}
          key="updatePermissions"
          align="center"
          render={({ update, resource }) => customRenderer(update, "update", resource, onChange)}
        />
        <Table.Column
          title={t("permissionsTableColumns.DELETE")}
          key="deletePermission"
          align="center"
          render={({ delete: deletePerm, resource }) => customRenderer(deletePerm, "delete", resource, onChange)}
        />
      </Table>
    </div>
  );
};

export default withTranslation()(PermissionsTable);
