import React from "react";
import { Mutation } from "@apollo/react-components";
import { Button, notification, Popconfirm } from "antd";
import { UNASSIGN_USER_FROM_CLIENT } from "../../../../graphql/mutations";
import { GET_PROJECT_ASSIGNABLE_CLIENTS } from "../../../../graphql/queries";

const UnAssign = ({ t, id, refetch, clientId, clientName }) => {
  return (
    <Mutation
      mutation={UNASSIGN_USER_FROM_CLIENT}
      refetchQueries={() => {
        return [...refetch, { query: GET_PROJECT_ASSIGNABLE_CLIENTS, variables: { clientId } }];
      }}
      update={() => {
        notification.success({
          message: `${t("projectsTableColumns.UN_ASSIGN_CLIENT.CLIENT_UNASSIGNED")} ${clientName}!`,
        });
      }}>
      {(unassignEmployee) => {
        return (
          <Popconfirm
            title={t("popConfirms.TITLE")}
            okText={t("popConfirms.ON_OK")}
            onConfirm={() =>
              unassignEmployee({
                variables: {
                  assignmentId: id,
                },
              })
            }>
            <Button type="danger" icon="delete">
              {t("projectsTableColumns.UN_ASSIGN_CLIENT.UN_ASSIGN")}
            </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
};

export default UnAssign;
