import React, {
  useEffect,
  useState,
} from 'react';
import { notification, Icon, Switch } from 'antd';
import { Mutation } from '@apollo/react-components';
import {
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import { GET_REMINDER_STATUS } from 'graphql/queries';
import { TOGGLE_TIMELOG_REMINDER } from 'graphql/mutations';
import { useQuery } from '@apollo/react-hooks';

const SwitchReminder = (props: WithTranslation) => {
  const { t } = props;

  const { data, loading, error } = useQuery(GET_REMINDER_STATUS);

  if (error) {
    notification.error({
      message: error,
      duration: 3,
    });
  }

  // The value from BE is coming as string (either "true" or "false")
  const timelogStatus = data?.employee?.timelogReminder;

  const [isChecked, setIsChecked] = useState<boolean>();

  useEffect(() => {
    if (isChecked === undefined && timelogStatus !== undefined) {
      setIsChecked(timelogStatus === "true")
    }
  }, [isChecked, timelogStatus]);

  if (loading) {
    return (<Icon style={{ padding: 10, fontSize: 20, color: "#1990FF" }} type="loading" spin={true} />)
  }

  const switchElement = (confirmTimelog: any) => (
    <Switch
      checked={isChecked}
      onChange={(checked) => {
        console.log("onChange", checked);
        setIsChecked(checked);
        confirmTimelog({ variables: { timelogReminder: Boolean(checked) } })
        const notificationMessage = checked ? 'ON_TIMELOGS_REMINDER' : 'OFF_TIMELOGS_REMINDER'
        notification.success({
          message: t(`timelogs.MESSAGES.${notificationMessage}`),
          duration: 3,
        });
      }}
    />
  )

  return (
    <div className="timelogs-reminder-container">
      <>
        <div className="timelogs-reminder-title">{t("timelogs.TIMELOGS_REMINDER")}</div>
        <div className="timelogs-reminder-switch">
          <Mutation mutation={TOGGLE_TIMELOG_REMINDER}>
            {switchElement}
          </Mutation>
        </div>
      </>
    </div>
  );
};

export default withTranslation()(SwitchReminder);
