import React, { useState, FC } from 'react';
import {
  Button,
  Col,
  Empty,
  Form,
  Popconfirm,
  Row,
  Table,
} from 'antd';
import { TFunction } from 'i18next';
import Title from 'antd/lib/typography/Title';

import { generateKey } from '../../../../utils/listKey';
import AddInvoiceItem from './addInvoiceItem';
import EditInvoiceItem from './editInvoiceItem';
import { InvoiceItem } from './invoiceItemForm';

interface Props {
  form: any;
  invoice?: any;
  t: TFunction;
  isEditing?: boolean;
  userId: number;
  fetchInvoiceItems?: (invoiceItems: any) => void;
  getTotalPrice: (price: string) => void;
}

interface InvoiceItemTableRow extends InvoiceItem {
  key: number;
}

const InvoiceItemsTable: FC<Props> = ({
  invoice,
  t,
  isEditing = false,
  userId,
  form,
  fetchInvoiceItems,
  getTotalPrice,
}) => {
  const [data, setData] = useState(invoice || []);
  const [count, setCount] = useState(1);
  const calculateTotalPrice = (items): string => {
    let totalPrice = 0;
    items.forEach((item) => {
      totalPrice += item.hours * item.hourlyRate;
    });
    return totalPrice.toFixed(2) + " €";
  };
  const addItem = () => {
    form.validateFields((err, values) => {
      if (!err) {
        const { project, description, hours, hourlyRate } = values;
        const item = {
          key: count,
          project,
          description: description ?? "",
          hours,
          hourlyRate,
        };
        setData([...data, item]);
        fetchInvoiceItems?.([...data, item]);
        getTotalPrice(calculateTotalPrice([...data, item]));
        setCount(count + 1);
      }
    });
  };

  const removeItem = (index: number) => {
    const filteredData = data.filter((item) => item.key !== index);
    setData(filteredData);
    fetchInvoiceItems?.(filteredData);
    getTotalPrice(calculateTotalPrice(filteredData));
  };

  const editItem = (index: number) => {
    form.validateFields((err, values) => {
      if (!err) {
        const { project, description, hours, hourlyRate } = values;
        const item = {
          key: index,
          project,
          description,
          hours,
          hourlyRate,
        };
        const dataIndex = data.findIndex((item) => item.key === index);
        const dataCopy = data;
        dataCopy[dataIndex] = item;
        setData(dataCopy);
        fetchInvoiceItems?.(dataCopy);
      }
    });
  };

  const ActionsComponent = ({ item }: { item: InvoiceItemTableRow }) => {
    return (
      <div className="actions">
        <div className="invoice-items-actions">
          <Popconfirm
            title={t("popConfirms.TITLE")}
            okText={t("popConfirms.ON_OK")}
            onConfirm={() => removeItem(item.key)}>
            <Button type="danger" icon="delete" className="mr-10" />
          </Popconfirm>
          {/* <EditInvoiceItem form={form} userId={userId} editItem={editItem} item={item} /> */}
        </div>
      </div>
    );
  };
  return (
    <Row>
      <Col span={24}>
        <div className={"invoiceDetailsTable"}>
          <Title level={4} className={"invoiceTableDetailsTitle"}>
            {t("invoices.INVOICE_ITEMS_TABLE.INVOICE_ITEMS")}
          </Title>
          {isEditing && <AddInvoiceItem form={form} userId={userId} addItem={addItem} />}
          <Table
            data-cy="table"
            size="small"
            dataSource={invoice || data}
            rowKey={() => generateKey()}
            pagination={{ defaultPageSize: 10 }}
            className="leave-request-table"
            locale={{
              emptyText: (
                <>
                  <Empty description={t("emptyDescriptions.NO_INVOICE_DETAILS")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </>
              ),
            }}>
            <Table.Column title={"Project"} key="invoiceItemProject" render={({ project }) => project.label} />
            <Table.Column
              title={"Description"}
              key="invoiceItemDescription"
              render={({ description }) => description}
            />
            <Table.Column title={"Hours"} key="invoiceItemHours" render={({ hours }) => hours} />
            <Table.Column
              title={"Hourly Rate"}
              key="invoiceItemHourlyRate"
              render={({ hourlyRate }) => `${hourlyRate.toFixed(2)} €`}
            />
            <Table.Column
              title={"Total"}
              key="invoiceTotalLine"
              render={({ hours, hourlyRate }) => `${(hours * hourlyRate).toFixed(2)} €`}
            />
            {isEditing && (
              <Table.Column
                title={"Actions"}
                width={200}
                align="center"
                render={(text, item: InvoiceItemTableRow) => {
                  return <ActionsComponent item={item} />;
                }}
              />
            )}
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default Form.create<Props>()(InvoiceItemsTable);
