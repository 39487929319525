import React, { Component } from "react";
import SelectLeaveType from "./selectLeaveType";
import SelectDeputy from "./selectDeputy";
import { Button, Modal, Input, Form, DatePicker, Row, Col, notification } from "antd";
import { Mutation } from "@apollo/react-components";
import moment from "moment";
import { FormComponentProps } from "antd/lib/form";
import { withTranslation, WithTranslation } from "react-i18next";
import { CREATE_LEAVE_REQUEST } from "../../../../graphql/mutations/LeaveManagementMutations";
import { GET_LEAVE_REQUESTS_BY_ROLE } from "../../../../graphql/queries/LeaveManagementQueries";
interface IAddLeaveRequest extends FormComponentProps {
  selectedYear: any;
  setYear: any;
}

class CreateLeaveRequest extends Component<IAddLeaveRequest & WithTranslation> {
  public state = {
    modalIsOpen: false,
    selectedStartDate: moment(),
  };

  public openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  public closeModal = () => {
    this.props.form.resetFields();
    this.setState({
      modalIsOpen: false,
    });
  };

  public disabledStartDate = (current) => {
    return (
      current < moment().endOf("day").subtract(1, "day") ||
      current.format("dddd") === "Saturday" ||
      current.format("dddd") === "Sunday"
    );
  };

  public disabledEndDate = (current) => {
    return (
      current < this.state.selectedStartDate.endOf("day") ||
      (this.state.selectedStartDate.year() === moment().year() ? current > moment().endOf("year") : null) ||
      current.format("dddd") === "Saturday" ||
      current.format("dddd") === "Sunday"
    );
  };

  public render() {
    const { t } = this.props;
    return (
      <>
        {this.renderNewLeaveRequestButton()}
        <Mutation
          mutation={CREATE_LEAVE_REQUEST}
          refetchQueries={[
            {
              query: GET_LEAVE_REQUESTS_BY_ROLE,
              variables: { leaveRequestRole: "employee", year: this.props.selectedYear },
            },
          ]}
          onCompleted={() => {
            this.props.form.resetFields();
            this.closeModal();
            notification.success({
              message: t("leaveRequests.MESSAGES.LEAVE_REQUEST_CREATED"),
            });
          }}
          onError={(error) => {
            notification.error({
              message: error.message.split(":")[1],
            });
          }}>
          {(createLeaveRequest, { loading }): any => {
            return (
              <Modal
                visible={this.state.modalIsOpen}
                onCancel={this.closeModal}
                title={t("leaveRequests.ADD_LEAVE_REQUEST")}
                data-cy="leaveRequests-modal"
                cancelButtonProps={{ disabled: loading }}
                okButtonProps={{
                  "aria-label": "ok-button",
                  loading,
                }}
                onOk={() => {
                  this.props.form.validateFields((err, values) => {
                    if (!err) {
                      const { startDate, endDate, deputyId, comment, leaveType } = values;
                      const finalValues = {
                        startDate: startDate.format("YYYY-MM-DD"),
                        endDate: endDate.format("YYYY-MM-DD"),
                        deputyId,
                        leaveType,
                        comment,
                      };
                      createLeaveRequest({ variables: finalValues }).then(() => {
                        this.props.setYear(JSON.stringify(moment(startDate).year()));
                      });
                    }
                  });
                }}>
                <Form layout="vertical">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t("leaveRequests.LABELS.START_DATE")} hasFeedback={true}>
                        {this.props.form.getFieldDecorator("startDate", {
                          rules: [
                            {
                              type: "object",
                              required: true,
                              message: t("leaveRequests.MESSAGES.START_DATE_REQUIRED"),
                            },
                          ],
                        })(
                          <DatePicker
                            format="DD-MM-YYYY"
                            disabledDate={this.disabledStartDate}
                            onChange={(val) => {
                              this.setState({ selectedStartDate: val });
                              this.props.form.setFieldsValue({ endDate: val });
                              this.props.form.setFieldsValue({
                                deputyId: undefined,
                              });
                            }}
                            data-cy="start-date"
                            id="start-date"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("leaveRequests.LABELS.END_DATE")} hasFeedback={true}>
                        {this.props.form.getFieldDecorator("endDate", {
                          rules: [
                            {
                              type: "object",
                              required: true,
                              message: t("leaveRequests.MESSAGES.END_DATE_REQUIRED"),
                            },
                          ],
                        })(
                          <DatePicker
                            disabled={!this.props.form.getFieldValue("startDate")}
                            format="DD-MM-YYYY"
                            data-cy="end-date"
                            id="end-date"
                            style={{ width: "100%" }}
                            disabledDate={this.disabledEndDate}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t("leaveRequests.LABELS.LEAVE_TYPE")}>
                        {this.props.form.getFieldDecorator("leaveType", {
                          rules: [
                            {
                              required: true,
                              message: t("leaveRequests.MESSAGES.LEAVE_TYPE_REQUIRED"),
                            },
                          ],
                        })(<SelectLeaveType t={t} />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("leaveRequests.LABELS.DEPUTY")}>
                        {this.props.form.getFieldDecorator("deputyId", {
                          rules: [
                            {
                              required: true,
                              message: t("leaveRequests.MESSAGES.DEPUTY_REQUIRED"),
                            },
                          ],
                        })(
                          <SelectDeputy
                            startDate={this.props.form.getFieldValue("startDate")}
                            endDate={this.props.form.getFieldValue("endDate")}
                            t={t}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label={t("leaveRequests.LABELS.COMMENT")}>
                    {this.props.form.getFieldDecorator("comment", {})(<Input.TextArea autoSize={true} />)}
                  </Form.Item>
                </Form>
              </Modal>
            );
          }}
        </Mutation>
      </>
    );
  }

  public renderNewLeaveRequestButton = () => {
    return (
      <Button type="primary" onClick={() => this.openModal()}>
        {this.props.t("leaveRequests.ADD_LEAVE_REQUEST")}
      </Button>
    );
  };
}

export default Form.create<IAddLeaveRequest>()(withTranslation()(CreateLeaveRequest));
