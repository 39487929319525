import { Emojione } from "react-emoji-render";
import React from "react";

const LeaveTypeEmoji = ({ leaveTypeName }) => {
  let text;
  if (leaveTypeName === "Normal Leave") {
    text = "🏖⛷";
  } else if (leaveTypeName === "Sick Leave") {
    text = "🤒🤕";
  } else if (leaveTypeName === "Martial Leave") {
    text = "🤵👰";
  } else if (leaveTypeName === "Funeral Leave") {
    text = "😔😓";
  } else {
    text = "😔😓";
  }
  return (
    <div className="emoji-leave-type" style={{ textAlign: "center" }}>
      <Emojione text={text} />
    </div>
  );
};

export default LeaveTypeEmoji;
