import React, { useState } from "react";
import { Calendar, Tooltip, Tag, Select, Badge, Spin } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { GET_COMPANY_BRANCH, GET_EMPLOYEE_ABSENCE_REQUESTS } from "graphql/queries/Configurations";
import moment, { Moment } from "moment";
import { absenceColor } from "helpers/approveStatusAndAbsence";
const { Option } = Select;

const getHolidayData = (value: Moment, holidayData) => {
  let holidaylistData: any;

  {
    holidayData?.map((holidayItems) => {
      const holidays = holidayItems?.holidayTypes?.[0]?.date;
      const description = holidayItems?.holidayTypes?.[0]?.description;
      const isHoliday = holidays?.filter((datesFromCalendar: Moment) =>
        value.utc().startOf("day").isSame(datesFromCalendar)
      )?.length;
      if (isHoliday) {
        holidaylistData = holidaylistData
          ? [...holidaylistData, { color: "green", content: description }]
          : [{ color: "green", content: description }];
      }
    });
  }

  return holidaylistData || [];
};

const getAbsenceData = (value: Moment, absenceData) => {
  let absenceListData: any[] = [];

  absenceData?.forEach((absenceItems) => {
    const absenceDays = absenceItems?.datesOfAbsence;
    const firstName = absenceItems?.employee?.firstName;
    const lastName = absenceItems?.employee?.lastName;
    const absenceType = absenceItems?.absenceType?.absenceTypeName;
    const absenceStatus = absenceItems?.approveStatus;

    absenceDays?.forEach((dateFromCalendarString: string) => {
      const dateFromCalendar = moment(dateFromCalendarString); // Parse the date string
      if (value.isSame(dateFromCalendar, "day")) {
        const absenceItem = {
          color: absenceColor(absenceType),
          absenceName: absenceType,
          content: `${firstName} ${lastName}`,
          status: absenceStatus,
        };
        absenceListData.push(absenceItem);
      }
    });
  });

  return absenceListData;
};

const dateCellRender = (holidayData, absenceData) => (value) => {
  const holidaylistData = getHolidayData(value, holidayData);
  const absenceListData = getAbsenceData(value, absenceData);
  return (
    <ul className="events">
      {holidaylistData.map((item) => (
        <li key={item.content}>
          <Tooltip placement="topLeft" title={item.content}>
            <Tag style={{ marginBottom: 5 }} color={item.color}>
              {item.content}
            </Tag>
          </Tooltip>
        </li>
      ))}
      {absenceListData.map(
        (item) =>
          item.status === true && (
            <li key={item.content}>
              <Tooltip
                placement="topLeft"
                title={
                  <span>
                    {item.content}
                    <br />
                    {item.absenceName}
                  </span>
                }>
                <Badge color={item.color} text={item.content} />
              </Tooltip>
            </li>
          )
      )}
    </ul>
  );
};

const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
  return null; // Add a default return value here
};

const monthCellRender = (value) => {
  const num = getMonthData(value);
  return num ? (
    <div className="notes-month">
      <section>{num}</section>
      <span>Absence Requested</span>
    </div>
  ) : null;
};

const CalendarsView = () => {
  const { data: branchData, loading: branchLoading, error: branchError } = useQuery(GET_COMPANY_BRANCH);
  const holidayData = branchData?.getCompanyBranches;
  const {
    data: allAbsenceData,
    loading: allAbsenceLoading,
    error: allAbsenceError,
    refetch,
  } = useQuery(GET_EMPLOYEE_ABSENCE_REQUESTS);

  const [holidaySelectedMultipleData, setHolidaySelectedMultipleData] = useState<string[]>([]);
  const [userNameSelected, setUserNameSelected] = useState<string[]>([]);

  const companyBranchOptions = Array.from(
    new Set<string>(branchData?.getCompanyBranches.map((item) => item?.country + " / " + item?.city))
  );

  const userNameOptions = Array.from(
    new Set<string>(
      allAbsenceData?.getEmployeeAbsenceRequests.map(
        (item) => item?.employee?.firstName + " " + item?.employee?.lastName
      )
    )
  );

  const handleBranchChange = (value) => {
    setHolidaySelectedMultipleData(value);
  };

  const handleUserNameChange = (value) => {
    setUserNameSelected(value);
  };

  const holidayFilteredData = holidayData?.filter((holidayItem) => {
    const foundSelectedData = holidaySelectedMultipleData.find((selectedItem) => {
      const [country, city] = selectedItem.split(" / ");
      return country === holidayItem.country && holidayItem.city === city;
    });
    return foundSelectedData;
  });

  const absenceFilteredData =
    userNameSelected.length === 0
      ? allAbsenceData?.getEmployeeAbsenceRequests
      : allAbsenceData?.getEmployeeAbsenceRequests.filter((item) => {
          const fullName = item?.employee?.firstName + " " + item?.employee?.lastName;
          return userNameSelected.includes(fullName);
        });

  if (branchLoading || allAbsenceLoading)
    return (
      <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} size="large" />
    );

  if (branchError || allAbsenceError) return <p>Error</p>;

  return (
    <>
      {/* <CalendarsLegend /> */}
      <span className="calendar-hoilday-select">
        <Select
          mode="multiple"
          allowClear
          placeholder="Select holiday"
          onChange={handleBranchChange}
          style={{ width: 285 }}>
          {companyBranchOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          allowClear
          placeholder="Select user"
          onChange={handleUserNameChange}
          style={{ width: 285 }}>
          <Option value="" disabled={userNameSelected.length > 0}>
            <p style={{ color: "red", marginBottom: 0 }}>Hide all</p>
          </Option>
          {userNameOptions.map((option) => (
            <Option disabled={userNameSelected.includes("") && option !== ""} key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </span>
      <Calendar
        mode="month"
        className="calendar-container"
        dateCellRender={dateCellRender(holidayFilteredData, absenceFilteredData)}
        monthCellRender={monthCellRender}
      />
    </>
  );
};

export default CalendarsView;
