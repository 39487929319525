import * as React from "react";
import { useState } from "react";
import { Modal, Button, Select, Spin, Icon, Row, Col } from "antd";
import { GET_EMPLOYEE_UTILIZATION } from "../../../graphql/queries";
import { Query } from "@apollo/react-components";
import { withTranslation, WithTranslation } from "react-i18next";
import moment from "moment";
import * as _ from "lodash";
import StatsDisplay from "./statsDisplay";
import MonthSelector from "./monthSelector";
interface IProps extends WithTranslation {
  userId: number;
}
const UserUtilzation = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(undefined);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleonChange = (val) => {
    setValue(val);
    setYear(val);
    setMonth(undefined);
  };
  const { t } = props;
  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t("sideMenu.ANALYTICS.UTILIZATION")}
      </Button>

      <Modal
        visible={visible}
        title={t("analyticsModal.TITLE")}
        footer={[
          <Button key="back" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}>
        <Query
          query={GET_EMPLOYEE_UTILIZATION}
          variables={{
            userId: props.userId,
            month,
            year,
          }}>
          {(state) => {
            const error = state?.error;
            const loading = state.loading;
            const data = state?.data;
            let statistics;
            let utilization;
            let months;
            let years;

            if (!loading && !!data && data.employee) {
              statistics = data.employee.statistics.map(({ projectName, workedHours }) => ({
                projectName,
                workedHours,
              }));
              utilization = data.employee.utilization;
              months = data.employee.monthsWorked;

              let startYear = 2019;
              if (moment(data.employee.contractStart).year() > 2019) {
                startYear = moment(data.employee.contractStart).year();
              }
              years = _.range(startYear, moment().year() + 1);
            }

            if (error) {
              return <div>ERROR</div>;
            }
            const antIcon = <Icon type="loading" style={{ fontSize: 19 }} spin={true} />;
            return (
              <div className="utilization-filters">
                <Row>
                  <Col span={12}>
                    <Select
                      allowClear={true}
                      showSearch={true}
                      optionFilterProp="children"
                      style={{ width: "180px" }}
                      placeholder={loading ? "Loading Years..." : "Select a Year"}
                      value={value}
                      onChange={handleonChange}>
                      {!loading && !!years ? (
                        years.map((item, i) => (
                          <Select.Option value={item} key={i}>
                            {item}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option value="loading">
                          <Spin indicator={antIcon} size="small" style={{ marginLeft: "0px" }} />
                        </Select.Option>
                      )}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <MonthSelector year={year} months={months} month={month} setMonth={setMonth} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StatsDisplay loading={loading} statistics={statistics} utilization={utilization} />
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </Modal>
    </>
  );
};

export default withTranslation()(UserUtilzation);
