import React from "react";
import { Tag } from "antd";

interface IStatus {
  isPaid: boolean;
}
export default function StatusTag(props: IStatus) {
  if (props.isPaid) {
    return (
      <Tag
        color="green"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        Paid
      </Tag>
    );
  } else {
    return (
      <Tag
        color="red"
        style={{
          fontSize: "10pt",
          width: "100px",
          textAlign: "center",
        }}>
        Not Paid
      </Tag>
    );
  }
}
