import React, { Fragment, useState } from "react";
import { TFunction } from "i18next";
import { Button, Icon, Input, Spin, Table, Tag } from "antd";
import { Query } from "@apollo/react-components";
// import CompanyBrenchesDetail from "./OrganizationDetail";
import { GET_NEW_USERS } from "graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { getUserData } from "common/AccessControl/Permissions";

interface IProps {
  t: TFunction;
}

const OrganizationTable: React.FC<IProps> = ({ t }) => {
  const currentUserRole = getUserData();
  const isAdmin = currentUserRole?.roles?.some((role) => role.name === "Admin");

  const [filters, setFilters] = useState({});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilters({ ...filters, [dataIndex]: selectedKeys[0] });
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setFilters({ ...filters, [dataIndex]: "" });
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}>Search</a>
          <a onClick={() => handleReset(clearFilters, dataIndex)}>Reset</a>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (filtered ? <Icon type="file-search" /> : <Icon type="search" />),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const { data } = useQuery(GET_NEW_USERS);
  const companyBranchOptions = Array.from(
    new Set<string>(
      data?.getNewUsers
        ?.filter((item) => item.employee?.companyBranch?.country !== undefined)
        .map((item) => item.employee?.companyBranch?.country + " / " + item.employee?.companyBranch?.city)
    )
  );

  const userRolesOptions = Array.from(new Set<string>(data?.getNewUsers?.map((item) => item.roles?.[0]?.name)));

  const companyBranchFilterOptions = companyBranchOptions.map((option) => ({
    text: option,
    value: option,
  }));

  const userRolesFilterOptions = userRolesOptions.map((option) => ({
    text: option,
    value: option,
  }));

  const columns = [
    {
      title: t("appConfiguration.ORGANIZATION.LABELS.FIRST_NAME"),
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: t("appConfiguration.ORGANIZATION.LABELS.LAST_NAME"),
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: t("appConfiguration.ORGANIZATION.LABELS.EMAIL"),
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: t("appConfiguration.ORGANIZATION.LABELS.ROLE"),
      dataIndex: "roles",
      render: (roles) => {
        return <Tag color="blue">{roles[0].name}</Tag>;
      },

      filters: userRolesFilterOptions,
      onFilter: (value, record) => record.roles.some((role) => role.name === value),
    },
    {
      title: t("appConfiguration.ORGANIZATION.LABELS.COMPANY_BRANCH"),
      dataIndex: "employee.companyBranch",
      render: (dataIndex) => {
        if (!dataIndex || typeof dataIndex !== "object") {
          return <p>No data</p>;
        }

        const { country, city } = dataIndex;

        return <p style={{ marginBottom: -5 }}>{country && city ? `${country} / ${city}` : "No data"}</p>;
      },

      filters: companyBranchFilterOptions,
      onFilter: (value, record) => {
        if (record.employee?.companyBranch?.country !== undefined) {
          const branchOption = `${record.employee.companyBranch.country} / ${record.employee.companyBranch.city}`;
          return branchOption === value;
        }
        return false;
      },
    },

    {
      title: t("appConfiguration.BENCHMARK.LABELS.ACTION"),
      key: "action",
      render: (row) => (
        <Link
          to={{
            pathname: `/dashboard/${row.id}/${isAdmin ? "profile" : "public-profile"}`,
            state: {
              redirectName: "Contracts",
            },
          }}>
          <Button type="primary" icon="profile">
            {t("buttons.DETAILS")}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <Fragment>
      <Query query={GET_NEW_USERS}>
        {({ loading, error, data }: any) => {
          let filteredUserData;
          if (!loading && data?.getNewUsers) {
            const userData = data.getNewUsers;
            filteredUserData = userData?.filter(
              (indexElement) => indexElement.roles[0].name !== "Client" && indexElement.roles[0].name !== "Admin"
            );
          }

          if (error) {
            return <p>Error</p>;
          }
          return (
            <Table
              pagination={{ pageSize: 20 }}
              columns={columns}
              bordered={true}
              size="small"
              rowKey="id"
              loading={loading}
              dataSource={filteredUserData}
            />
          );
        }}
      </Query>
    </Fragment>
  );
};

export default OrganizationTable;
