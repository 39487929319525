import React, { Fragment, useEffect, useState } from "react";
import { Alert, Divider, Icon, Progress, Statistic, Tag } from "antd";
import PerformanceReviewSelf from "../PerformanceReviewReviewers/PerformanceReviewSelf";
import PerformanceReviewRules from "../PerformanceReviewRules/PerformanceReviewRules";
import PerformanceReviewInformation from "./PerformanceReviewInformation";
import PerformanceReviewClose from "../PerformanceReviewClose/PerformanceReviewClose";
import PerformanceReviewManagerComment from "../PerformanceReviewManagerComment/PerformanceReviewManagerComment";
import PerformanceReviewPeer from "../PerformanceReviewReviewers/PerformanceReviewPeer";
import PerformanceReviewManager from "../PerformanceReviewReviewers/PerformanceReviewManager";
import { calculateSubmittedPercentage } from "screens/PerformanceReview/helpers/calculateParticipation";
import moment from "moment";
import ViewAllReviewAnswers from "../ViewAllReviewAnswers/ViewAllReviewAnswers";
import { getUserData } from "common/AccessControl/Permissions";

const PerformanceReviewCycleDetails = ({ selectedReview, t }) => {
  const deadline = selectedReview?.expireDate;

  const currentUser = getUserData();
  const loggedInUser = currentUser?.id;
  const managerId = selectedReview?.ManagerId;
  const admin = currentUser?.roles?.some((role) => role.name === "Admin");
  const cycleIsClosed = selectedReview?.closedAt;
  const isManagerOfReviewCycle = loggedInUser === managerId;
  const submittedPercentage = calculateSubmittedPercentage(selectedReview);

  return (
    <>
      <span style={!isManagerOfReviewCycle || !admin ? { marginTop: "-3rem" } : {}} className="review-buttons-wrapper">
        {cycleIsClosed ? (
          <span style={cycleIsClosed ? { marginTop: -10 } : {}}>
            <Alert
              style={{ marginBottom: 10 }}
              message={t("appConfiguration.PERFORMANCE_REVIEW.CLOSED_BY_MANAGER")}
              type="error"
              showIcon
            />
          </span>
        ) : isManagerOfReviewCycle || admin ? (
          <PerformanceReviewClose selectedReview={selectedReview} t={t} />
        ) : null}
        {!cycleIsClosed && !isManagerOfReviewCycle && !admin && (
          <span style={cycleIsClosed ? { marginTop: -10 } : {}}>
            <Alert
              style={{ marginBottom: 10 }}
              message={t("appConfiguration.PERFORMANCE_REVIEW.NOT_CLOSED_YET")}
              type="warning"
              showIcon
            />
          </span>
        )}
      </span>
      <div className="split-screen-review">
        <div className="left-side-review">
          <div className="content-review">
            <h3 className="review-title">{t("appConfiguration.PERFORMANCE_REVIEW.LABELS.REVIEW_RULES")}</h3>
            <p>{t("appConfiguration.PERFORMANCE_REVIEW.LABELS.READ_REVIEW_RULES")}</p>
            <PerformanceReviewRules t={t} />
            <Divider style={{ marginTop: 0, marginBottom: 10 }} />
            <PerformanceReviewInformation selectedReview={selectedReview} t={t} />
          </div>
        </div>
        <div className="middle-side-review">
          <div className="content-review-middle">
            <h3 className="review-title">{t("appConfiguration.PERFORMANCE_REVIEW.LABELS.PARTICIPATION")}</h3>
            <div className="stats">
              <Progress
                width={150}
                strokeColor={{
                  "100%": "#f7505e",
                  "80%": "#cc5656",
                  "50%": "#f9d838",
                  "30%": "#87d068",
                  "0%": "#20c552",
                }}
                type="dashboard"
                percent={Number(submittedPercentage)}
              />
            </div>
            <Divider />
            <PerformanceReviewManagerComment selectedReview={selectedReview} t={t} />
          </div>
        </div>
        <div className="right-side-review">
          <div className="countdown">
            {moment(deadline).format("DD/MM/YYYY") > moment().format("DD/MM/YYYY") ? (
              <Statistic.Countdown
                style={{
                  marginBottom: -15,
                  marginTop: 10,
                }}
                title="Review deadline"
                value={deadline}
                format="DD:HH:mm:ss"
              />
            ) : (
              <span style={{ display: "flex", gap: 10 }}>
                <Icon
                  style={{
                    marginTop: 20,
                    fontSize: 20,
                    color: "red",
                  }}
                  type="issues-close"
                />
                <h2
                  style={{
                    marginBottom: -15,
                    marginTop: 15,
                  }}>
                  {"Review cycle has expired"}
                </h2>
              </span>
            )}
          </div>
          <Divider />
          {(admin || isManagerOfReviewCycle) && (
            <>
              <span style={{ display: "flex", justifyContent: "center", marginBottom: -20, marginTop: -10 }}>
                <ViewAllReviewAnswers t={t} selectedReview={selectedReview} />
              </span>
              <Divider />
            </>
          )}
          <div className="content-review-right">
            <h4 className="title-reviewrs">Self review</h4>
            <PerformanceReviewSelf selectedReview={selectedReview} />
            <h4 className="title-reviewrs">Peer review</h4>
            <PerformanceReviewPeer selectedReview={selectedReview} />
            <h4 className="title-reviewrs">Manager review</h4>
            <PerformanceReviewManager selectedReview={selectedReview} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceReviewCycleDetails;
