import * as React from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import OrganizationTable from "./components/OrganizationTable";
import { Icon, Layout, Tabs } from "antd";
import OrganizationChart from "./components/OrganizationChart";
// import OrganizationChart from "./CompanyBranches/components/OrganizationChart";

interface IProps {}
const { TabPane } = Tabs;
const { Content } = Layout;
const Organization: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TitleSection title={t("sideMenu.ORGANIZATION")} iconType="apartment" />
      <Content className="wrapper">
        <Tabs type="card" defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <Icon type="ordered-list" />
                {t("appConfiguration.ORGANIZATION.EMPLOYEE_LIST")}
              </span>
            }
            key="1">
            <OrganizationTable t={t} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="apartment" />
                {t("appConfiguration.ORGANIZATION.ORG_CHART")}
              </span>
            }
            key="2">
            <OrganizationChart t={t} />
          </TabPane>
        </Tabs>
      </Content>
    </div>
  );
};

export default Organization;
