import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";

i18next.languages = ["en"];

i18next
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: "en",
    fallbackLng: "en",
    resources: {
      en,
    },
  })
  .catch((err) => console.error(err));

export default i18next;
