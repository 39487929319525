import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Button, Icon, Layout, Spin, Table, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Query } from "@apollo/react-components";
import moment from "moment";
import * as employmentTypes from "../../../constants/employmentType";
import { GET_CONTRACTS_HISTORY_TIMELINE } from "graphql/queries/Contracts";

interface IProps extends RouteComponentProps<{ id?: string }> {
  userId?: number;
}

interface Contract {
  id: number;
  employmentType: string;
  position: string;
  startDate: string;
  endDate: string | null;
  hourlyRate: number;
  workingHoursPerMonth: number;
  salary: number;
}

const TimeLineContact: React.FC<IProps> = ({ match, userId }) => {
  const { t } = useTranslation();

  return (
    <Query
      query={GET_CONTRACTS_HISTORY_TIMELINE}
      variables={{ userId: match.params.id || userId ? Number(match.params.id || userId) : undefined }}>
      {({ loading, error, data }: any) => {
        let contracts;

        if (!loading && data?.employee?.contracts) {
          contracts = data.employee.contracts;
        }

        if (loading)
          return (
            <Spin
              style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}
              size="large"
            />
          );

        if (error) return <p>Error</p>;

        const columns = [
          {
            title: t("contracts.form.EXPIRE"),
            dataIndex: "contractExpired",
            key: "contractExpired",
            render: (contractExpired) =>
              contractExpired ? <Tag color="red">Expired</Tag> : <Tag color="green">Not expired</Tag>,
          },
          {
            title: t("contracts.form.EMPLOYMENT_TYPE"),
            dataIndex: "employmentType",
            key: "employmentType",
          },
          {
            title: t("contracts.form.POSITION"),
            dataIndex: "position",
            key: "position",
          },
          {
            title: t("contracts.form.PERIOD"),
            dataIndex: "period",
            key: "period",
          },
          {
            title: t("contracts.form.HOURLY_RATE"),
            dataIndex: "hourlyRate",
            key: "hourlyRate",
          },
          {
            title: t("contracts.form.WORKING_HOURS_PER_MONTH"),
            dataIndex: "workingHoursPerMonth",
            key: "workingHoursPerMonth",
          },
          {
            title: t("contracts.form.SALARY"),
            dataIndex: "salary",
            key: "salary",
          },
          {
            title: t("contractsTableColumns.CONTRACT"),
            dataIndex: "url",
            key: "url",
            render: (url) => (
              <Tooltip title="Export contract">
                <Button size="small" key="1" onClick={() => window.open(url as string, "_blank")} disabled={!url}>
                  <Icon type="file-pdf" />
                  {"Export"}
                </Button>
              </Tooltip>
            ),
          },
        ];

        const dataSource = contracts.map((contract) => {
          const contractExpired = moment().isAfter(moment(contract.endDate));
          const startDate = moment(contract.startDate).format("DD MMM YYYY");
          const endDate = contract.endDate ? moment(contract.endDate).format("DD MMM YYYY") : t("general.PRESENT");

          const rowData = {
            key: contract?.id,
            employmentType: contract?.employmentType,
            position: contract?.position,
            period: `${startDate} - ${endDate}`,
            hourlyRate: contract?.hourlyRate,
            workingHoursPerMonth: contract?.workingHoursPerMonth,
            salary: contract?.salary,
            contractExpired: contractExpired,
            url: contract?.url,
          };

          return rowData;
        });

        return (
          <Layout.Content className="user-contracts-timeline">
            <Table
              columns={columns}
              dataSource={dataSource}
              rowClassName={(record: Contract) => (moment().isAfter(moment(record.endDate)) ? "grey" : "green")}
            />
          </Layout.Content>
        );
      }}
    </Query>
  );
};

export default withRouter(TimeLineContact);
