import * as _ from "lodash";
import Resources from "../constants/permission_resources";
export const formatPermissions = (permissions) => {
  const array: any[] = [];

  const resources = Object.keys(permissions);
  const resourcesNumber = Object.keys(permissions).length;

  for (let i = 0; i < resourcesNumber; i++) {
    const object: any = {};
    object.resource = resources[i];
    const resourcePermissions = permissions[resources[i]];
    if (!resourcePermissions) {
      continue;
    }
    for (let j = 0; j < resourcePermissions.length; j++) {
      if (permissions[resources[i]][j] === "read") {
        if (object.read) {
          object.read.push("read");
        } else {
          object.read = ["read"];
        }
      }
      if (permissions[resources[i]][j] === "readOwn") {
        if (object.read) {
          object.read.push("readOwn");
        } else {
          object.read = ["readOwn"];
        }
      }
      if (permissions[resources[i]][j] === "create") {
        if (object.create) {
          object.create.push("create");
        } else {
          object.create = ["create"];
        }
      }
      if (permissions[resources[i]][j] === "createOwn") {
        if (object.create) {
          object.create.push("createOwn");
        } else {
          object.create = ["createOwn"];
        }
      }
      if (permissions[resources[i]][j] === "update") {
        if (object.update) {
          object.update.push("update");
        } else {
          object.update = ["update"];
        }
      }
      if (permissions[resources[i]][j] === "updateOwn") {
        if (object.update) {
          object.update.push("updateOwn");
        } else {
          object.update = ["updateOwn"];
        }
      }
      if (permissions[resources[i]][j] === "deleteOwn") {
        if (object.delete) {
          object.delete.push("deleteOwn");
        } else {
          object.delete = ["deleteOwn"];
        }
      }
      if (permissions[resources[i]][j] === "delete") {
        if (object.delete) {
          object.delete.push("delete");
        } else {
          object.delete = ["delete"];
        }
      }
    }
    array.push(object);
  }

  return array;
};

export const removeTypeName = (permissionsResponseData: any) => {
  delete permissionsResponseData.__typename;
  return permissionsResponseData;
};

export const getNewPermissions = (permissions: any, resource: string, type: string, value: string): any => {
  const valueToChange = permissions[resource];

  const newResoursePermissions = getNewResourcePermissions(valueToChange, type, value);

  permissions[resource] = newResoursePermissions;
  return permissions;
};

const getNewResourcePermissions = (valueToChange: [string], type: string, value: string) => {
  const update: [string?] = [];
  if (value === "Own") {
    update.push(`${type}${value}`);
  } else if (value === "All") {
    update.push(type);
    update.push(`${type}Own`);
  }
  const newResoursePermissions = valueToChange.filter((permission) => !permission.match(type));
  return [...newResoursePermissions, ...update];
};

export const getAccessibleRoute = (): string => {
  const isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;
  return isAdmin ? "/dashboard/companies" : "/dashboard/home";
};

export const renderEmployeeFields = (permissions: any): boolean => {
  if (!permissions) {
    return false;
  }
  const leaveRequestPermissions = permissions[Resources.LEAVE_REQUESTS];
  const timelogsPermissions = permissions[Resources.TIMELOGS];

  const hasLeaveRequestCreatePermission = leaveRequestPermissions && leaveRequestPermissions.indexOf("create") !== -1;
  const hasTimelogsCreatePermission = timelogsPermissions && timelogsPermissions.indexOf("create") !== -1;
  const hasTimelogsCreateOwnPermission = timelogsPermissions && timelogsPermissions.indexOf("createOwn") !== -1;
  const hasLeaveRequestCreateOwnPermission =
    leaveRequestPermissions && leaveRequestPermissions.indexOf("createOwn") !== -1;

  if (
    hasLeaveRequestCreateOwnPermission ||
    hasLeaveRequestCreatePermission ||
    hasTimelogsCreateOwnPermission ||
    hasTimelogsCreatePermission
  ) {
    return true;
  }
  return false;
};

export const mergeRolesPermissions = (rolesPermissions: any[]) => {
  if (rolesPermissions.length === 1) {
    return rolesPermissions[0];
  }
  let result;
  for (let i = 1; i < rolesPermissions.length; i++) {
    result = _.mergeWith(rolesPermissions[0], rolesPermissions[i], customizer);
  }

  return result;
};

const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.uniq(objValue.concat(srcValue));
  }
};
