import * as React from "react";
import { Input, Button, Icon } from "antd";

const getColumnSearchProps = (component: any, dataIndex: string, state: string, placeholder: string) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="search-filter-dropdown">
        <Input
          ref={(node) => {
            component[state] = node;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          // tslint:disable-next-line:jsx-no-lambda
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={handleSearch(selectedKeys, confirm, component, state)}
          style={{ width: 200, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={handleSearch(selectedKeys, confirm, component, state)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={handleReset(clearFilters, component)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
  };
};

const handleSearch = (selectedKeys: string[], confirm: any, component: any, state: string): any => {
  return () => {
    confirm();
    component.setState({ state: selectedKeys[0] });
  };
};

const handleReset = (clearFilters, component) => {
  return () => {
    clearFilters();
    component.setState({ state: "" });
  };
};

export default getColumnSearchProps;
