import React from "react";
import { Form } from "antd";
import { Query } from "@apollo/react-components";
import { FormComponentProps } from "antd/lib/form";
import { TFunction } from "i18next";
import { GET_COMPANY_INFORMATION } from "graphql/queries";
import CompanyForm from "./components/CompanyForm";
interface IProps extends FormComponentProps {
  t: TFunction;
}

const CompanyInformation: React.FC<IProps> = ({ t }) => {
  return (
    <Query query={GET_COMPANY_INFORMATION}>
      {({ loading, error, data }: any) => {
        let company;

        if (!loading && data?.company) {
          company = data.company;
          return <CompanyForm t={t} company={company} />;
        }

        if (error) {
          return <p>Error</p>;
        }
        return null;
      }}
    </Query>
  );
};

export default Form.create<IProps>()(CompanyInformation);
