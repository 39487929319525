import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TitleSection from "../../common/SectionTitle/title";
import { Icon, Layout, Spin, Tabs } from "antd";
import PublicProfileInfo from "./components/PublicProfileInfo";
import { Query } from "@apollo/react-components";
import { GET_USER_PROFILE } from "graphql/queries";
import { Link } from "react-router-dom";

interface IProps {}
const { TabPane } = Tabs;
const { Content } = Layout;
const PublicProfile: React.FC<IProps> = () => {
  const { t } = useTranslation();

  const [rowId, setRowId] = useState<string>("");

  useEffect(() => {
    const pathname = window.location.pathname;
    const id = pathname.split("/")[2]; // Extract the row.id from the URL
    setRowId(id);
  }, []);

  return (
    <div>
      <Query query={GET_USER_PROFILE} variables={{ userId: Number(rowId) }}>
        {({ loading, error, data }: any) => {
          let userData;
          if (!loading && data?.User) {
            userData = data.User;
          }
          if (loading)
            return (
              <Spin
                style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}
                size="large"
              />
            );

          if (error) return <p>Error</p>;
          return (
            <>
              <TitleSection title={`${userData?.firstName}'s ${t("userProfile.PROFILE_PANEL")}`} iconType="user" />
              <Content className="wrapper">
                <div style={{ border: 0 }}>
                  <Link to="/dashboard/organization">
                    <Icon type="left" style={{ marginRight: "10px", color: "black", fontSize: "16px" }} />
                    <span>
                      {userData && `${t("sideMenu.ORGANIZATION")} / ${userData.firstName} ${userData.lastName}'s `}
                    </span>
                  </Link>
                </div>
              </Content>
              <Content className="wrapper">
                <Tabs defaultActiveKey="1">
                  <TabPane tab={t("appConfiguration.PUBLIC_PROFILE.TITLE")} key="1">
                    <PublicProfileInfo userData={userData} t={t} />
                  </TabPane>
                </Tabs>
              </Content>
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default PublicProfile;
